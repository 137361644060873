export function translateStatusOS(status, currentLanguage){
    switch (status) {
        case 'PENDING':
            return currentLanguage.status_pending;
        case 'PARTIAL':
            return currentLanguage.status_partial;
        case 'FINISHED':
            return currentLanguage.status_finished;
        case 'CANCELLED':
            return currentLanguage.status_cancelled;
            default:
                return 'Error ao buscar Status';  
    }
}

