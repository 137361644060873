import React from 'react';
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import { Flex1150Div, FlexColumnDiv, FlexDiv, PrimaryButton } from '../components/components';

export class InspectionTypeDialogTabGeneral extends React.Component {

    render () {
        const {
            submitLabel,
            categories,
            inspectionTypeData,
            allValidAndNonEmpty,
            closeDialog,
            handleSubmit,
            updateState,
            currentLanguage
        } = this.props

        return (
            <React.Fragment>
                <DialogContent style={{height: 'calc(100vh - 20rem)'}}>
                    <FlexDiv>
                        <Flex1150Div>
                            <FlexColumnDiv>
                                <TextField
                                    required
                                    margin="normal"
                                    label={currentLanguage.name}
                                    value={inspectionTypeData.name}
                                    onChange={event => updateState({ inspectionTypeData: { ...inspectionTypeData, name: event.target.value } })}
                                />
                                <FormControlLabel
                                    margin="normal"
                                    label={currentLanguage.able_subcategory}
                                    control={
                                        <Checkbox
                                            checked={inspectionTypeData.enabled}
                                            onChange={event => updateState({ inspectionTypeData: { ...inspectionTypeData, enabled: event.target.checked } })}
                                        />
                                    }
                                />
                            </FlexColumnDiv>
                        </Flex1150Div>
                        <Flex1150Div style={{ marginLeft: '3rem' }}>
                            <FlexColumnDiv>
                                <FormControl margin="normal" required>
                                    <InputLabel htmlFor="categoryId-select">{currentLanguage.category}</InputLabel>
                                    <Select
                                        inputProps={{ id: 'categoryId-select' }}
                                        value={inspectionTypeData.categoryId}
                                        onChange={event => updateState({ inspectionTypeData: { ...inspectionTypeData, categoryId: event.target.value } })}>
                                        {categories.map(category => (
                                            <MenuItem key={category.id} value={category.id} disabled={!category.enabled}>{category.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </FlexColumnDiv>
                        </Flex1150Div>
                    </FlexDiv>
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between'}}>
                    <div>
                    </div>

                    <div>
                        <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                        <PrimaryButton onClick={handleSubmit} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                    </div>
                </DialogActions>
            </React.Fragment>
        )
    }

}
