import React from 'react';

import { TableSortLabel, withStyles } from '@material-ui/core';
import { HeaderTableCell } from './components';

const SortingHeaderLabel = withStyles({
    root: {
        width: '100%',
        '&:focus': {color: 'rgb(213, 117, 48)'},
        '&:hover': {color: 'rgb(213, 117, 48)'},
    },
    icon:{
        color: '#FFFFFF!important'
    },
    active: {
        color: 'rgb(213, 117, 48)'
    }
    
})(TableSortLabel);

export class SortHeaderCell extends React.Component {

    render () {
        const { sorter, field, onSort, width, children } = this.props;

        return (
            <HeaderTableCell style={{ width, cursor: 'pointer' }} onClick={() => onSort(field)}>
                <SortingHeaderLabel active={sorter?.isActive(field)} direction={sorter?.direction} children={children} style={{ justifyContent: 'space-between', }} />
            </HeaderTableCell>
        );
    }

}
