import React from 'react';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import { EquipmentService } from '../equipment/equipmentService';
import { FlexBaselineDiv } from '../components/components';

export class EquipmentDialogTabGeneral extends React.Component {

    buildAttribute = (inspectionTypeAttribute, index) => {
        return (
            <FlexBaselineDiv key={index}>
                <div>{inspectionTypeAttribute.attribute}: {inspectionTypeAttribute.answer}</div>
            </FlexBaselineDiv>
        )
    }

    render () {
        const { closeDialog, equipment, currentLanguage } = this.props

        return (
            <React.Fragment>
                <DialogContent style={{height: 'calc(100vh - 20rem)', padding: '0rem'}}>
                    <div style={{padding: '1rem'}}>
                        <div style={{display: 'flex'}}>
                            <div style={{flex: '1 1 auto'}}>
                                <div><b>{equipment.product.sku}</b></div>
                                <div>{equipment.product.name}</div>
                                <div style={{marginTop: '1rem'}}>{currentLanguage.tag} #{equipment.tagSerialNumber}</div>
                                <div>{currentLanguage.equipment_information}: {equipment.extraLabel}</div>
                                <div>{currentLanguage.number_of_control}: {equipment.extraCode}</div>
                                {equipment.inspectionTypeAttribute.map(this.buildAttribute)}
                                <div style={{marginTop: '1rem'}}>{currentLanguage.last_inspection_status}: {EquipmentService.formatExpirationStatus(equipment.lastInspectionExpirationStatus, currentLanguage)}</div>
                                <div>{currentLanguage.due_date_status}: {EquipmentService.formatExpirationStatus(equipment.dueDateExpirationStatus, currentLanguage)}</div>
                            </div>
                            <div style={{flex: '0 0 16rem', textAlign: 'center', height: '10rem'}}>
                                <img style={{maxHeight: '10rem'}} alt="product" src={`/web/products/${equipment.product.id}/image?update=${equipment.product.updateDate}`}/>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between'}}>
                    <div>
                    </div>

                    <div>
                        <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                    </div>
                </DialogActions>
            </React.Fragment>
        )
    }

}
