import { UserApi } from './userApi';

function formatUserStatus (status, currentLanguage) {
    switch (status) {
        case 'ACTIVE': return currentLanguage.active
        case 'INACTIVE': return currentLanguage.inactive
        case 'PENDING': return currentLanguage.pending
        case 'MIGRATED': return currentLanguage.migrated
        case 'DELETED': return currentLanguage.removed
        default: throw new Error(`Unimplemented: ${status}`)
    }
}

function formatUserRole (userRole, currentLanguage) {
    switch (userRole) {
        case 'COMPANY_OPERATOR': return currentLanguage.company_operator
        case 'COMPANY_ADMIN': return currentLanguage.company_admin
        case 'COMPANY_GROUP_ADMIN': return currentLanguage.company_group_admin
        case 'MOBCHECK_ADMIN': return currentLanguage.mobcheck_admin
        default: throw new Error(`Unimplemented: ${userRole}`)
    }
}

export const UserService = {
    list: () => UserApi.list().then(response => response.data),
    activateGeneric: (userId, activateGenericData) => UserApi.activateGeneric(userId, activateGenericData).then(response => response.data),
    migrateGenericPending: (userId, migrateGenericPendingData) => UserApi.migrateGenericPending(userId, migrateGenericPendingData).then(response => response.data),
    migrate: (userId, migrateData) => UserApi.migrate(userId, migrateData).then(response => response.data),
    updatePassword: (userId, passwordData) => UserApi.updatePassword(userId, passwordData).then(response => response.data),
    formatUserStatus,
    formatUserRole,
    delete: (userEmail) => UserApi.delete(userEmail).then(response => response.data)
};
