import React from 'react'
import LogoMobcheck from './images/register_header.png'

export function AnonymousHeader (props) {
    return (

        <div>
            <img alt="logo" src={LogoMobcheck}  height="100px"/>
        </div>
    )
}
