import React from 'react';

import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';

import {
    BodyTableCell,
    ContainerDiv,
    FlexSpaceBetweenDiv,
    formatMainDescription,
    HeaderTableCell, OverflowXDiv,
    PrimaryButton
} from '../components/components';
import { CompanyUserService } from './companyUserService';
import { StringService } from '../utils/stringService';
import { LoggedUserService } from '../loggedUser/loggedUserService';
import { CompanyUserDialogButton } from './companyUserDialogButton';
import { TableFooterWithPaginator } from '../components/tableFooterWithPaginator';
import { SortHeaderCell } from '../components/sortHeaderCell';
import SearchIcon from '../equipment/images/search_list_ico.png';
import { withSnackbar } from 'notistack';
import { CompanyService } from '../company/companyService';
import { CollectionViewUpdater } from '../utils/collectionViewUpdater';
import { UserService } from '../user/userService';
import Spinner from 'react-spinner-material'

class CompanyUsersPage extends React.Component {

    constructor (props) {
        super(props)

        this.collectionUpdater = CollectionViewUpdater.ofCompanyUsers(this)

        this.state = {
            isLoading: false,
            companies: [],
            companyData: {id: null, name: ''},
            filterQuery: '',
            filterStatus: '',
            isUserDialogOpen: false,
            collection: this.collectionUpdater.collection
        }
    }

    componentDidMount () {
        CompanyUserService.list().then(items => {
            this.collectionUpdater.initialData(items)
            this.setState({isLoading: false})
        });

        LoggedUserService.getCompanyData().then(companyData => this.setState({ companyData }))
        CompanyService.list().then(companies => this.setState({ companies }))
    }

    notify = message => () => this.props.enqueueSnackbar(message)
    notifyError = userData => error => {
        if (error.response.data === 'USER_CREATE_EMAIL_EXISTS') {
            this.props.enqueueSnackbar(StringService.formatTextVariable(this.props.currentLanguage.email_exists, userData.email), {variant: 'error'});
        }

        return Promise.reject(error);
    }

    handleCreateSubmit = userData => CompanyUserService.create(userData, this.state.companies)
        .then(this.collectionUpdater.createItem)
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.created_user, userData.name)))
        .catch(this.notifyError(userData));
    handleUpdateSubmit = (userId, userData) => CompanyUserService.update(userId, userData, this.state.companies)
        .then(this.collectionUpdater.updateItem)
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.updated_user, userData.name)))
        .catch(this.notifyError(userData));
    handleDeleteSubmit = (userId, userEmail) => CompanyUserService.remove(userId)
        .then(this.collectionUpdater.deleteItem)
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.removed_user, userEmail)));

    buildList = () => {
        const { isAdmin, currentLanguage } = this.props
        const { isLoading, collection, companies } = this.state

        if (isLoading) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={5}><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></BodyTableCell>
                </TableRow>
            )
        }

        const items = collection.paginator.pagedItems;

        if (items.length === 0) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={5}>{currentLanguage.empty_list}</BodyTableCell>
                </TableRow>
            )
        }

        return items.map(user => (
            <TableRow key={user.id}>
                <BodyTableCell>{formatMainDescription(user.name, user.email)}</BodyTableCell>
                <BodyTableCell>{formatMainDescription(user.companyName, user.occupation)}</BodyTableCell>
                <BodyTableCell align="center">{StringService.formatPhoneNumber(user.phone)}</BodyTableCell>
                <BodyTableCell align="center">{UserService.formatUserStatus(user.status, currentLanguage)}</BodyTableCell>
                <BodyTableCell align="center">
                    <CompanyUserDialogButton
                        currentLanguage={currentLanguage}
                        submitLabel={currentLanguage.update}
                        showAdmin={isAdmin}
                        showRemove={isAdmin}
                        userData={{
                            name: user.name,
                            email: user.email,
                            phone: user.phone,
                            occupation: user.occupation,
                            companyId: user.companyId,
                            role: user.role,
                            status: user.status,
                            password: ''
                        }}
                        companies={companies}
                        onSubmit={userData => this.handleUpdateSubmit(user.id, userData)}
                        onDelete={() => this.handleDeleteSubmit(user.id, user.email)}
                        ModalButton={({onClick}) => (
                            <IconButton onClick={onClick} style={{alignSelf: 'center'}}><img src={SearchIcon} alt="search" /></IconButton>
                        )}
                    />
                </BodyTableCell>
            </TableRow>
        ));
    };

    render () {
        const { isAdmin, currentLanguage } = this.props
        const { collection, companies, filterQuery, filterStatus } = this.state
        const { sorter } = collection

        const { updateCollection, handleFilterChange, handleSort } = this.collectionUpdater

        return (
            <React.Fragment>
                <ContainerDiv>
                    <FlexSpaceBetweenDiv style={{flexWrap: 'wrap'}}>
                        <FormControl margin="normal">
                            <TextField label={currentLanguage.search} helperText={currentLanguage.name+", "+currentLanguage.email+", "+currentLanguage.company+", "+currentLanguage.role+", "+currentLanguage.or+" "+currentLanguage.phone} value={filterQuery} style={{marginBottom: '1rem'}}
                                       onChange={event => handleFilterChange({ filterQuery: event.target.value })} />
                        </FormControl>
                        <FormControl margin="normal" style={{width: '10rem'}}>
                            <InputLabel htmlFor="status-select">{currentLanguage.status}</InputLabel>
                            <Select value={filterStatus} onChange={event => handleFilterChange({ filterStatus: event.target.value })} inputProps={{ id: 'status-select' }}>
                                <MenuItem value="">{currentLanguage.all}</MenuItem>
                                <MenuItem value="ACTIVE">{currentLanguage.active.toUpperCase()}</MenuItem>
                                <MenuItem value="INACTIVE">{currentLanguage.inactive.toUpperCase()}</MenuItem>
                                <MenuItem value="PENDING">{currentLanguage.pending.toUpperCase()}</MenuItem>
                            </Select>
                        </FormControl>
                        <CompanyUserDialogButton
                            currentLanguage={currentLanguage}
                            submitLabel={currentLanguage.add} 
                            showAdmin={isAdmin}
                            showRemove={false}
                            userData={{
                                name: '',
                                email: '',
                                phone: '(__) ____-____',
                                occupation: '',
                                companyId: '',
                                role: '',
                                status: '',
                                password: ''
                            }}
                            companies={companies}
                            onSubmit={this.handleCreateSubmit}
                            ModalButton={({onClick}) => (
                                <PrimaryButton onClick={onClick} style={{alignSelf: 'center'}}>{currentLanguage.add_user}</PrimaryButton>
                            )}
                        />
                    </FlexSpaceBetweenDiv>
                </ContainerDiv>

                <OverflowXDiv>
                <Table>
                    <TableHead>
                        <TableRow>
                            <SortHeaderCell sorter={sorter} onSort={handleSort} field="name">{currentLanguage.employee}</SortHeaderCell>
                            <SortHeaderCell sorter={sorter} onSort={handleSort} field="companyName">{currentLanguage.company}</SortHeaderCell>
                            <SortHeaderCell sorter={sorter} onSort={handleSort} field="phone">{currentLanguage.phone}</SortHeaderCell>
                            <SortHeaderCell sorter={sorter} onSort={handleSort} field="status" justifyContent="center">{currentLanguage.status}</SortHeaderCell>
                            <HeaderTableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.buildList()}
                    </TableBody>
                    <TableFooterWithPaginator collection={collection} onCollectionUpdate={updateCollection} colSpan={5} />
                </Table>
                </OverflowXDiv>
            </React.Fragment>
        );
    }
}

export default withSnackbar(CompanyUsersPage);
