import React from 'react'
import { FlexDiv } from './components'
import { Checkbox, withStyles } from '@material-ui/core'
import languages from "../../translations"

export const ValidationCheckbox = withStyles({
    root: {
        padding: '0rem 0.5rem 0rem 0rem'
    }
})(Checkbox);

function Requirement (props) {
    const { label, proposition } = props
    return (
        <li>
            <FlexDiv>
                <ValidationCheckbox checked={proposition === true} disabled={true} style={{color: 'rgb(14, 74, 100)'}} />
                <div>{ label }</div>
            </FlexDiv>
        </li>
    );
}

export function PasswordRequirements (props) {
    var { validator, currentLanguage } = props

    if(!currentLanguage) 
        currentLanguage = languages.pt
    

    return (
        <div>
            A nova senha deve atender aos seguintes critérios:
            <ul style={{listStyleType: 'none'}}>
                <Requirement proposition={validator.atLeast6Characters} label={currentLanguage.at_least_characters} />
                <Requirement proposition={validator.atLeast1Number} label={currentLanguage.at_least_numeral} />
                <Requirement proposition={validator.atLeast1UpperCase} label={currentLanguage.at_least_uppercase} />
                <Requirement proposition={validator.atLeast1LowerCase} label={currentLanguage.at_least_lowercase} />
                <Requirement proposition={validator.atLeast1SpecialCharacter} label={currentLanguage.at_least_special_character} />
            </ul>
            <div>{currentLanguage.valid_special_character}</div>
        </div>

    )
}
