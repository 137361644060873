import React from 'react'
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography,
    FormControl,
    ButtonBase,
    InputLabel,
    MenuItem,
    Select
} from '@material-ui/core'
import { DashboardService } from './dashboardService'
import {
    BodyTableCell,
    ContainerDiv,
    HeaderTableCell,
    OrangeDiv,
    OrangeSpan,
    FlexSpaceBetweenDiv,
    PrimaryButton
} from '../components/components'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { DateTimeService } from '../utils/dateTimeService'
import { HistogramChart } from './histogramChart'
import { EmployeeChart } from './employeeChart'
import { CategoryChart } from './categoryChart'
import { PercentageChart } from './percentageChart'
import { HistogramService } from './histogramService'
import { PercentageData } from './percentageData'
import { EquipmentService } from '../equipment/equipmentService'
import { LoggedUserService } from '../loggedUser/loggedUserService'
import { RoleConstant } from '../../app/roleConstant'
import { HistoryService } from '../../app/historyService'
import { FilterDatePicker } from '../components/filterDatePicker'
import Search from '@material-ui/icons/Search'
import ArrowForward from '@material-ui/icons/ArrowForward'
import moment from 'moment'
import { CollectionViewUpdater } from '../utils/collectionViewUpdater';
import { CompanyService } from '../company/companyService';


export class DashboardPage extends React.Component {



    state = {
        percentageData: null,
        histogramData: null,
        employeeData: null,
        categoryData: null,
        inspectionTypeData: null,
        productData: null,
        users: DashboardService.buildEmptyStats(),
        equipments: DashboardService.buildEmptyStats(),
        inspections: DashboardService.buildEmptyStats(),
        loggedUser: {},
        dateSearchFrom: null,
        dateSearchUpTo: null,
        companies: [],
    }
    

    componentDidMount() {
        CompanyService.list().then(companies => this.setState({ companies }))
        this.collectionUpdater = CollectionViewUpdater.ofEquipments(this);
        const { handleFilterChange } = this.collectionUpdater

        const { currentLanguage } = this.props


        DashboardService.equipmentStatus().then(equipmentStatus => this.setState({
            percentageData: PercentageData.of(equipmentStatus, ['EXPIRED', 'IMPROPER', 'VALID'], EquipmentService.calculateStatusLabel, EquipmentService.calculateStatusColor, currentLanguage)
        }))
        DashboardService.monthlyInspections().then(monthlyInspections => this.setState({
            histogramData: HistogramService.convertData(monthlyInspections)
        }))
        DashboardService.employeeInspections('2018-01-01', moment(Date.now()).format('YYYY-MM-DD')).then(employeeInspections => this.setState({
            employeeData: employeeInspections
        }))
        DashboardService.productInspections('2018-01-01', moment(Date.now()).format('YYYY-MM-DD')).then(categoryInspections => this.setState({
            categoryData: categoryInspections
        }))


        DashboardService.summary().then(summary => this.setState({
            users: {
                allTimeTotal: summary.users.allTimeTotal,
                last7DaysTotal: summary.users.last7DaysTotal,
                lastCreateTime: DateTimeService.formatDateTime(summary.users.lastCreateTime)
            },
            equipments: {
                allTimeTotal: summary.equipments.allTimeTotal,
                last7DaysTotal: summary.equipments.last7DaysTotal,
                lastCreateTime: DateTimeService.formatDateTime(summary.equipments.lastCreateTime)
            },
            inspections: {
                allTimeTotal: summary.inspections.allTimeTotal,
                last7DaysTotal: summary.inspections.last7DaysTotal,
                lastCreateTime: DateTimeService.formatDateTime(summary.inspections.lastCreateTime)
            },
        }))



        LoggedUserService.getLoggedUser().then(loggedUser => {
            this.setState({ loggedUser })
        })
    }

    handleSearchButton = () => {
        const {
            dateSearchFrom,
            dateSearchUpTo
        } = this.state

        var dateStart = moment(dateSearchFrom).format('YYYY-MM-DD')
        var dateEnd = moment(dateSearchUpTo).format('YYYY-MM-DD')

        DashboardService.productInspections(dateStart, dateEnd).then(categoryInspections => {
            this.setState({
                categoryData: categoryInspections

            })
        })

        DashboardService.employeeInspections(dateStart, dateEnd).then(employeeInspections => this.setState({
            employeeData: employeeInspections
        }))
    }
    handleFilterChange = (companyId) =>{
        const {employeeData} = this.state


    }

    

    render() {
        const {
            percentageData,
            histogramData,
            employeeData,
            categoryData,
            users,
            equipments,
            inspections,
            loggedUser,
            dateSearchFrom,
            dateSearchUpTo,
            companies
        } = this.state

        const { currentLanguage } = this.props

        return (
            <React.Fragment>
                {inspections.allTimeTotal === 0 ?
                    <ContainerDiv style={{ marginTop: '1rem' }}>
                        ✔ {currentLanguage.text_initial_video_lessons} <ButtonBase style={{ textAlign: 'center' }} onClick={() => HistoryService.goto('/video-lessons')}> <ArrowForward /> </ButtonBase> <br />
                        ✔ {currentLanguage.text_initial_buy_tags} <ButtonBase style={{ textAlign: 'center' }} onClick={() => HistoryService.goto('/buy-tag')}>  <ArrowForward /> </ButtonBase>
                    </ContainerDiv> : null}

                {loggedUser.currentRole !== RoleConstant.COMPANY_OPERATOR ?

                    (<ContainerDiv style={{ marginTop: '1rem' }}>

                        <div style={{ textAlign: 'center' }}>
                            <FormControl  style={{ flex: '1 0 9rem', marginLeft: '1rem', width: '25ch' }}>
                                <InputLabel htmlFor='companyId-select'>{currentLanguage.company}</InputLabel>
                                <Select  onChange={event => this.handleFilterChange({ filterCompanyId: event.target.value })} inputProps={{ id: 'companyId-select' }} id= "companyId-select">
                                    <MenuItem value="">{currentLanguage.all.toUpperCase()}</MenuItem>
                                    {companies.map(company => (
                                        <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FilterDatePicker disableFuture style={{ flex: '1 0 9rem', marginLeft: '1rem' }} onChange={date => this.setState({ dateSearchFrom: date })} value={dateSearchFrom} label="De:" />
                            <FilterDatePicker disableFuture style={{ flex: '1 0 9rem', marginLeft: '1rem' }} onChange={date => this.setState({ dateSearchUpTo: date })} value={dateSearchUpTo} label="Até:" />
                            <PrimaryButton disabled={dateSearchFrom === null || dateSearchUpTo === null} style={{ width: '2rem', marginTop: '0.6rem', marginLeft: '1rem' }} onClick={this.handleSearchButton} ><Search /></PrimaryButton>
                        </div>
                        <FlexSpaceBetweenDiv style={{ flexWrap: 'wrap' }}>
                            <FormControl margin="normal" style={{ flex: '1 0 10rem' }} >
                                <div style={{ height: '5rem', margin: '0.2rem' }}>
                                    <Card style={{ borderRadius: 10 }}>
                                        <CardContent>
                                            <Typography variant="h6" style={{ textAlign: 'center' }}>{currentLanguage.inspections_by_products}</Typography>
                                            <CategoryChart data={categoryData} language={currentLanguage} />
                                        </CardContent>
                                    </Card>
                                </div>
                            </FormControl>

                            <FormControl margin="normal" style={{ flex: '1 0 10rem' }} >
                                <div style={{ height: '5rem', margin: '0.2rem' }}>
                                    <Card style={{ borderRadius: 10 }}>
                                        <CardContent>
                                            <Typography variant="h6" style={{ textAlign: 'center' }}>{currentLanguage.equipments_status}</Typography>
                                            <PercentageChart data={percentageData} />
                                        </CardContent>
                                    </Card>
                                </div>
                            </FormControl>

                            <FormControl margin="normal" style={{ flex: '1 0 10rem' }} >
                                <div style={{ height: '5rem', margin: '0.2rem' }}>
                                    <Card style={{ borderRadius: 10 }}>
                                        <CardContent>
                                            <Typography variant="h6" style={{ textAlign: 'center' }}>{currentLanguage.inspections_by_employees}</Typography>
                                            <EmployeeChart data={employeeData} />
                                        </CardContent>
                                    </Card>

                                </div>
                            </FormControl>
                        </FlexSpaceBetweenDiv>
                    </ContainerDiv>) : null}

                <ContainerDiv>
                    <div style={{ height: '20rem', marginTop: '20rem', marginBottom: '20rem' }}>
                        <Card style={{ borderRadius: 10 }}>

                            <CardContent>
                                <Typography variant="h6" style={{ textAlign: 'center' }}>{currentLanguage.inspections_by_month}</Typography>
                                <HistogramChart data={histogramData} />
                            </CardContent>
                        </Card>
                    </div>
                </ContainerDiv>

                <Table>
                    <TableHead>
                        <TableRow>
                            <HeaderTableCell>{currentLanguage.total}</HeaderTableCell>
                            <HeaderTableCell>{currentLanguage.last_seven_days}</HeaderTableCell>
                            <HeaderTableCell>{currentLanguage.last_interactions}</HeaderTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <BodyTableCell><OrangeSpan>{users.allTimeTotal}</OrangeSpan> {currentLanguage.users} </BodyTableCell>
                            <BodyTableCell><OrangeSpan>{users.last7DaysTotal}</OrangeSpan> {currentLanguage.users} </BodyTableCell>
                            <BodyTableCell><div>{currentLanguage.new_user}</div><OrangeDiv>{users.lastCreateTime}</OrangeDiv></BodyTableCell>
                        </TableRow>
                        <TableRow>
                            <BodyTableCell><OrangeSpan>{equipments.allTimeTotal}</OrangeSpan> {currentLanguage.equipments} </BodyTableCell>
                            <BodyTableCell><OrangeSpan>{equipments.last7DaysTotal}</OrangeSpan> {currentLanguage.equipments} </BodyTableCell>
                            <BodyTableCell><div> {currentLanguage.new_equipment} </div><OrangeDiv>{equipments.lastCreateTime}</OrangeDiv></BodyTableCell>
                        </TableRow>
                        <TableRow>
                            <BodyTableCell><OrangeSpan>{inspections.allTimeTotal}</OrangeSpan> {currentLanguage.inspections} </BodyTableCell>
                            <BodyTableCell><OrangeSpan>{inspections.last7DaysTotal}</OrangeSpan> {currentLanguage.inspections} </BodyTableCell>
                            <BodyTableCell><div>{currentLanguage.new_inspection}</div><OrangeDiv>{inspections.lastCreateTime}</OrangeDiv></BodyTableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </React.Fragment>
        )
    }
}
