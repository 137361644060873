import { RegisterUserApi } from './registerUserApi';

function getUsageTerms () {
    return RegisterUserApi.getUsageTerms().then(response => response.data);
}

function register (registerUserData) {
    return RegisterUserApi.register(registerUserData).then(response => response.data);
}

export const RegisterUserService = { getUsageTerms, register };
