import * as d3 from 'd3';
import _ from 'lodash';
import moment from 'moment';

function convertData (data) {
    const width = 1000;
    const height = 250;
    const xMargin = 4;
    const interval = d3.timeMonth;
    const today = new Date();

    const [ xMinDate, xMaxDate ] = [ interval.offset(today, -24), interval.offset(interval(today)) ];
    const xDomain = [ xMinDate, xMaxDate ];
    const yDomain = [ 0, d3.max(data, item => item.value) || 1 ];

    const xScale = d3.scaleTime().domain(xDomain).rangeRound([0, width]);
    const yScale = d3.scaleLinear().domain(yDomain).range([height, 0]);

    const valueByYearMonth = _(data).keyBy('yearMonth').mapValues('value').value();

    const xy = interval.range(xMinDate, xMaxDate).map(date => {
        const yearMonth = moment(date).format('YYYY-MM');
        const firstDay = date;
        const lastDay = interval.offset(date);
        const value = valueByYearMonth[yearMonth] || 0;

        return {
            xStart: xScale(firstDay) + xMargin,
            xEnd: xScale(lastDay) - xMargin,
            yStart: yScale(value),
            yEnd: yScale(0),
            yearMonth,
            value
        };
    });

    return { xScale, yScale, xy, width, height, interval };
}

export const HistogramService = { convertData };
