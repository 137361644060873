import { TagApi } from './tagApi'

function list () {
    return TagApi.list().then(response => response.data)
}

function toggleTag (serialNumber, toggle) {
    return TagApi.toggleTag(serialNumber, toggle)
}

function listReplaceTag() {
    return TagApi.listReplaceTag().then(response => response.data)
}

function replaceTag (oldSerialNumber, newSerialNumber) {
    return TagApi.replaceTag(oldSerialNumber, newSerialNumber)
}

function getGeneratedQty (companyId) {
    return TagApi.getGeneratedQty(companyId)
}

function downloadQrCodeTag (companyId, qrCodeQty) {
    return new Promise(function(resolve, reject) {
        TagApi.downloadGeneratedTag(companyId, qrCodeQty)

        resolve("Ok!")
    })
}

function exportQrCodeTag (companyId, qrCodeQty) {
    return new Promise(function(resolve, reject) {
        TagApi.exportGeneratedTag(companyId, qrCodeQty)

        resolve("Ok!")
    })
}

export const TagService = { list, toggleTag, listReplaceTag, replaceTag, getGeneratedQty, downloadQrCodeTag, exportQrCodeTag }
