import React, { Component } from 'react';
import Spinner from 'react-spinner-material'
import { HorizontalBar } from 'react-chartjs-2'
import { HistoryService } from '../../app/historyService'

const dataChart = {
    type: 'horizontalBar',
    labels: ['Funcionário 1', 'Funcionário 2', 'Funcionário 3'],
    datasets: [
        {
            label: 'Inspeções por Funcionários',
            backgroundColor: 'rgba(153,153,255,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(102,102,255,1)',
            data: [10, 15, 7]
        }
    ]
}

const options = {
    legend: {
        maintainAspectRatio: false,
        display: false
    },
    scales: {
        xAxes: [{
            gridLines: {
                display: false
            },
            ticks: {
                beginAtZero: true
            }
        }],
        yAxes: [{
            gridLines: {
                display: false
            },
            ticks: {
                beginAtZero: true
            }
        }]
    },
    onClick: (event, elements) => {
        if (elements[0] !== undefined) {
            const chart = elements[0]._chart;
            const element = chart.getElementAtEvent(event)[0]
            HistoryService.sendData('/inspections', { employeeData: element._model.label})
        }
    }
}

export class EmployeeChart extends Component {

    render() {
        const { data: employeeData } = this.props

        if (!employeeData) {
            return (<div style={{ position: 'relative', top: '45%', left: '45%' }}> <Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /> </div>)
        } else {
            dataChart.labels = []
            dataChart.datasets[0].data = []

            employeeData.map(data => (
                dataChart.labels.push(data.label)
            ))

            employeeData.map(data => (
                dataChart.datasets[0].data.push(data.qtt)
            ))
        }

        return (
            <HorizontalBar
                data={dataChart}
                width={50}
                height={20}
                options={options}
            />
        )
    }
}
