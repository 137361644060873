import React from 'react'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core'

import {
    Flex1150Div,
    Flex11Div,
    FlexColumnDiv,
    FlexDialogTitle,
    FlexDiv,
    PrimaryButton,
    SecondaryButton
} from '../components/components'
import { StringService } from '../utils/stringService'
import { PhoneInput } from '../components/phoneInput'
import { Tooltip } from 'react-tippy'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import 'react-tippy/dist/tippy.css'

const STAGES = {
    DETAILS: 'INPUTTING',
    REMOVAL: 'REMOVAL',
    REMOVING: 'REMOVING'
}

const CONFIRMATION = 'CONFIRMO'

export class CompanyUserDialogButton extends React.Component {

    state = {
        confirmation: '',
        isDialogOpen: false,
        userData: {},
        stage: STAGES.INPUTTING
    };

    handleDialogOpen = () => this.setState({ isDialogOpen: true, stage: STAGES.INPUTTING, userData: this.props.userData });
    closeDialog = () => this.setState({ isDialogOpen: false });

    updateUserData = newData => this.setState(state => ({ userData: { ...state.userData, ...newData } }));

    handleValueChange = field => event => this.updateUserData({ [field]: event.target.value });
    handleDigitsChange = field => event => this.updateUserData({ [field]: StringService.extractDigits(event.target.value) });

    handleStageChange = stage => event => this.setState({ stage, confirmation: '' });

    handleSubmit = () => this.props.onSubmit(this.state.userData).then(this.closeDialog);
    handleDelete = () => {
        const { onDelete } = this.props;

        this.setState({ stage: STAGES.REMOVING });

        onDelete().then(this.closeDialog);
    };

    buildUserUpdateForm = () => {
        const { userData } = this.state
        const { showAdmin, showRemove, submitLabel, companies, currentLanguage } = this.props

        return (
            <React.Fragment>
                <DialogContent>
                    <FlexDiv>
                        <Flex1150Div>
                            <FlexColumnDiv>
                                <TextField margin="normal" required onChange={this.handleValueChange('name')} value={userData.name} label={currentLanguage.name} />
                                <TextField margin="normal" required onChange={this.handleValueChange('email')} value={userData.email} label={currentLanguage.email} />
                                <TextField margin="normal" required onChange={this.handleDigitsChange('phone')} value={userData.phone} label={currentLanguage.phone} InputProps={{ inputComponent: PhoneInput }} />
                            </FlexColumnDiv>
                        </Flex1150Div>
                        <Flex1150Div style={{ marginLeft: '1rem' }}>
                            <FlexColumnDiv>
                                <FormControl margin="normal" required>
                                    <InputLabel htmlFor="companyId-select">{currentLanguage.company}</InputLabel>
                                    <Select value={userData.companyId} onChange={this.handleValueChange('companyId')} inputProps={{ id: 'companyId-select' }}>
                                        {companies.map(company => (
                                            <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField margin="normal" required onChange={this.handleValueChange('occupation')} value={userData.occupation} label="Cargo" />
                            </FlexColumnDiv>
                        </Flex1150Div>
                    </FlexDiv>
                    <FlexDiv style={{ marginTop: '2rem' }}>
                        <Flex1150Div>
                            <FlexColumnDiv>
                                <TextField margin="normal" type="password" onChange={this.handleValueChange('password')} value={userData.password} label="Senha" />
                            </FlexColumnDiv>
                        </Flex1150Div>
                        <Flex1150Div style={{ marginLeft: '1rem' }}>
                            <FlexColumnDiv>
                                <FormControl margin="normal" required>
                                    <InputLabel htmlFor="status-select">{currentLanguage.status}</InputLabel>
                                    <Select value={userData.status} onChange={this.handleValueChange('status')} inputProps={{ id: 'status-select' }}>
                                        <MenuItem value="ACTIVE">{currentLanguage.active}</MenuItem>
                                        <MenuItem value="INACTIVE">{currentLanguage.inactive}</MenuItem>
                                        <MenuItem value="PENDING">{currentLanguage.pending}</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl margin="normal" required>
                                    <InputLabel htmlFor="role-select">{currentLanguage.profile}</InputLabel>
                                    <Select value={userData.role} onChange={this.handleValueChange('role')} inputProps={{ id: 'role-select' }}>
                                        <MenuItem value="COMPANY_OPERATOR">{currentLanguage.company_operator}</MenuItem>
                                        <MenuItem value="COMPANY_ADMIN">{currentLanguage.company_admin}</MenuItem>
                                        {/*showAdmin ? (<MenuItem value="COMPANY_GROUP_ADMIN">Administrador Grupo de Empresas</MenuItem>) : (<span/>)*/}
                                        <MenuItem value="COMPANY_GROUP_ADMIN">{currentLanguage.company_group_admin}</MenuItem>
                                        {showAdmin ? (<MenuItem value="MOBCHECK_ADMIN">{currentLanguage.mobcheck_admin}</MenuItem>) : (<span />)}
                                    </Select>
                                </FormControl>
                            </FlexColumnDiv>
                            <Tooltip
                                        title={currentLanguage.user_profile_Tippy}
                                        arrow={true}
                                        arrowSize={'small'}
                                        style={{ position: "relative", top: "-60px", left: '430px' }}>
                                        <InfoOutlinedIcon style={{ fontSize: "small", }} />
                                    </Tooltip>
                        </Flex1150Div>
                    </FlexDiv>
                </DialogContent>
                <DialogActions>
                    <Flex11Div>
                        {showRemove ? (<SecondaryButton onClick={this.handleStageChange(STAGES.REMOVAL)}>{currentLanguage.remove}</SecondaryButton>) : (<span />)}
                    </Flex11Div>
                    <Button onClick={this.closeDialog}>{currentLanguage.cancel}</Button>
                    <PrimaryButton onClick={this.handleSubmit}>{submitLabel}</PrimaryButton>
                </DialogActions>
            </React.Fragment>
        );
    };

    buildUserRemoval = () => {
        const { confirmation } = this.state
        const { userData, companies, currentLanguage } = this.props

        const companyName = companies.filter(company => company.id === userData.companyId).map(company => company.name).pop();

        return (
            <React.Fragment>
                <FlexDialogTitle disableTypography>
                    <Typography variant="h6">{currentLanguage.remove} <i>{userData.email}</i>?</Typography>
                </FlexDialogTitle>
                <DialogContent>
                    <FlexDiv>
                        <Flex1150Div>
                            <FlexDiv><div style={{ width: '6rem', textAlign: 'right', marginRight: '1rem' }}>{currentLanguage.name}: </div><div>{userData.name}</div></FlexDiv>
                            <FlexDiv><div style={{ width: '6rem', textAlign: 'right', marginRight: '1rem' }}>{currentLanguage.role}: </div><div>{userData.occupation}</div></FlexDiv>
                            <FlexDiv><div style={{ width: '6rem', textAlign: 'right', marginRight: '1rem' }}>{currentLanguage.company}: </div><div>{companyName}</div></FlexDiv>
                        </Flex1150Div>
                        <Flex1150Div>
                            <FlexDiv style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <TextField margin="normal" required variant="outlined" onChange={event => this.setState({ confirmation: event.target.value.toUpperCase() })} value={confirmation} label={currentLanguage.confirmation} helperText={StringService.formatTextVariable(currentLanguage.remove_confirmation, CONFIRMATION)} />
                            </FlexDiv>
                        </Flex1150Div>
                    </FlexDiv>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleStageChange(STAGES.INPUTTING)}>{currentLanguage.cancel}</Button>
                    <PrimaryButton onClick={this.handleDelete} disabled={confirmation !== CONFIRMATION}>{currentLanguage.remove}</PrimaryButton>
                </DialogActions>
            </React.Fragment>
        )
    }

    buildUserRemoving = () => {
        const { userData, currentLanguage } = this.props

        return (
            <React.Fragment>
                <FlexDialogTitle disableTypography>
                    <Typography variant="h6">{currentLanguage.removing} <i>{userData.email}</i>...</Typography>
                </FlexDialogTitle>
            </React.Fragment>
        )
    }

    buildContent = () => {
        const { stage } = this.state

        switch (stage) {
            case STAGES.INPUTTING: return this.buildUserUpdateForm();
            case STAGES.REMOVAL: return this.buildUserRemoval();
            case STAGES.REMOVING: return this.buildUserRemoving();
            default: throw new Error(`unimplemented: ${stage}`);
        }
    };

    render = () => {
        const { isDialogOpen } = this.state
        const { ModalButton } = this.props

        return (
            <React.Fragment>
                <ModalButton onClick={this.handleDialogOpen} />
                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">
                    {this.buildContent()}
                </Dialog>
            </React.Fragment>
        )
    }

}

