import React from 'react'

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField, Typography
} from '@material-ui/core'

import {
    CenterTitleDiv,
    Flex1150Div,
    FlexBaselineDiv,
    FlexColumnDiv,
    FlexDiv,
    PrimaryButton
} from '../components/components'

import { NonNegativeInput } from '../components/nonNegativeInput'
import { UniqueNonEmptyValidator } from '../utils/uniqueNonEmptyValidator'
import { ProductService } from './productService'
import { isEmpty } from 'lodash'
import Spinner from 'react-spinner-material'
import NoImage from './images/mobcheck-gray.png'

function calculateCategoryId(inspectionTypes, inspectionTypeId) {
    return inspectionTypes.filter(inspectionType => inspectionType.id === inspectionTypeId).map(inspectionType => inspectionType.categoryId).shift() || '';
}

export class ProductFormModalButton extends React.Component {

    constructor(props) {
        super(props)

        const { inspectionTypes, productData } = this.props

        this.state = {
            tabValue: 0,
            isDialogOpen: false,
            categoryId: calculateCategoryId(inspectionTypes, productData.inspectionTypeId),
            photo: null,
            previewDataUrl: null,
            maintenanceParts: null,
            removingMaintenancePartIndex: null,
            productData,
            disableButton: false
        }
    }

    handleDialogOpen = () => {
        const { inspectionTypes, productData, photoUrl, productId } = this.props

        this.setState({
            tabValue: 0,
            isDialogOpen: true,
            categoryId: calculateCategoryId(inspectionTypes, productData.inspectionTypeId),
            photo: null,
            previewDataUrl: photoUrl,
            maintenanceParts: null,
            removingMaintenancePartIndex: null,
            productData,
            disableButton: false
        })

        ProductService.listMaintenanceParts(productId).then(maintenanceParts => this.setState({ maintenanceParts }))
    };

    closeDialog = () => this.setState({ isDialogOpen: false })

    updateProductData = newData => this.setState(state => ({ productData: { ...state.productData, ...newData } }))

    handleCategoryChange = event => {
        this.setState({ categoryId: event.target.value })
        this.updateProductData({ inspectionTypeId: '' })
    };



    showGeneral = () => {
        const { showIsDvExclusive, isReadOnly, categories, inspectionTypes, currentLanguage } = this.props
        const { productData, categoryId, previewDataUrl } = this.state

        this.handlePhotoUpload = event => {
            const photo = event.target.files[0];
            const reader = new FileReader();
            reader.onload = event => this.setState({ previewDataUrl: event.target.result });
            reader.readAsDataURL(photo);

            this.setState({ photo });
        }

        this.handlePhotoNull = _ => {
            console.log('teste', previewDataUrl)
        }

        this.handleSubmit = () => {
            const { onSubmit, isReadOnly } = this.props
            const { productData, photo, maintenanceParts } = this.state
            this.setState({ disableButton: true })
            console.log('handlesubmit isReadOnly? ', this.state.disableButton)

         if (photo == null) {

            this.handlePhotoUpload = () => {
                const reader = new FileReader();
                const blob = this.dataURItoBlob(NoImage);
            
                reader.onload = (event) => this.setState({ previewDataUrl: event.target.result });
                reader.readAsDataURL(blob);
            
                this.setState({ photo: NoImage });
              };
            
              this.dataURItoBlob = (dataURI) => {
                const byteString = atob(dataURI.split(',')[1]);
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
            
                for (let i = 0; i < byteString.length; i++) {
                  ia[i] = byteString.charCodeAt(i);
                }
            
                return new Blob([ab], { type: 'image/png' });
              };
            

            } 

            return onSubmit(productData, photo, maintenanceParts).then(this.closeDialog)
        };

        const categoryInspectionTypes = inspectionTypes.filter(inspectionType => inspectionType.categoryId === categoryId)

        return (
            <React.Fragment>
                <FlexDiv>
                    <Flex1150Div>
                        <FlexColumnDiv>
                            <TextField
                                required
                                margin="normal"
                                disabled={isReadOnly}
                                label={currentLanguage.name}
                                value={productData.name}
                                onChange={event => this.updateProductData({ name: event.target.value })}
                            />
                            <TextField
                                required
                                margin="normal"
                                disabled={isReadOnly}
                                label={currentLanguage.sku}
                                value={productData.sku}
                                onChange={event => this.updateProductData({ sku: event.target.value })}
                            />
                            <FormControlLabel
                                margin="normal"
                                disabled={isReadOnly}
                                label={currentLanguage.able}
                                control={
                                    <Checkbox
                                        checked={productData.enabled}
                                        onChange={event => this.updateProductData({ enabled: event.target.checked })}
                                    />
                                }
                            />
                            {showIsDvExclusive ? (<FormControlLabel margin="normal" label={currentLanguage.product_exclusive} control={<Checkbox checked={productData.isDvExclusive} onChange={event => this.updateProductData({ isDvExclusive: event.target.checked })} />} />) : (<span />)}
                        </FlexColumnDiv>
                    </Flex1150Div>
                    <Flex1150Div>
                        <FlexColumnDiv style={{ alignItems: 'center' }}>
                            <FlexDiv style={{ height: '10rem', alignItems: 'center' }}>
                                <img src={previewDataUrl} alt="" style={{ maxHeight: '10rem' }} />
                            </FlexDiv>
                            <Button variant="contained" component="label" disabled={isReadOnly}>
                                {currentLanguage.change_photo}
                                <input type="file" accept="image/*" style={{ display: "none" }} onChange={this.handlePhotoUpload} />
                            </Button>
                        </FlexColumnDiv>
                    </Flex1150Div>
                </FlexDiv>
                <FlexDiv>
                    <Flex1150Div>
                        <FlexColumnDiv>
                            <FormControl margin="normal" disabled={isReadOnly} required >
                                <InputLabel htmlFor="categoryId-select">{currentLanguage.category}</InputLabel>
                                <Select value={categoryId} onChange={this.handleCategoryChange} inputProps={{ id: 'categoryId-select' }}>
                                    {categories.map(category => (
                                        <MenuItem key={category.id} value={category.id} disabled={!category.enabled}>{category.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl margin="normal" disabled={isReadOnly} required error={!!categoryId && !productData.inspectionTypeId}>
                                <InputLabel htmlFor="inspectionTypeId-select">{currentLanguage.subcategory}</InputLabel>
                                <Select value={productData.inspectionTypeId} onChange={event => this.updateProductData({ inspectionTypeId: event.target.value })} inputProps={{ id: 'inspectionTypeId-select' }}>
                                    {categoryInspectionTypes.map(inspectionType => (
                                        <MenuItem key={inspectionType.id} value={inspectionType.id} disabled={!inspectionType.enabled}>{inspectionType.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                multiline
                                rowsMax="8"
                                margin="normal"
                                disabled={isReadOnly}
                                label={currentLanguage.description}
                                value={productData.shortDescription}
                                onChange={event => this.updateProductData({ shortDescription: event.target.value })}
                            />
                        </FlexColumnDiv>

                    </Flex1150Div>
                    <Flex1150Div style={{ marginLeft: '1rem' }}>
                        <FlexColumnDiv>
                            <FormControl margin="normal" disabled={isReadOnly} required >
                                <InputLabel htmlFor="inspectionSchedule-select">{currentLanguage.inspection_periodicity}</InputLabel>
                                <Select value={productData.inspectionSchedule} onChange={event => this.updateProductData({ inspectionSchedule: event.target.value })} inputProps={{ id: 'inspectionSchedule-select' }}>
                                    <MenuItem value="360">{currentLanguage.yearly}</MenuItem>
                                    <MenuItem value="180">{currentLanguage.biannual}</MenuItem>
                                    <MenuItem value="90">{currentLanguage.quarterly}</MenuItem>
                                    <MenuItem value="30">{currentLanguage.monthly}</MenuItem>
                                    <MenuItem value="15">{currentLanguage.biweekly}</MenuItem>
                                    <MenuItem value="7">{currentLanguage.weekly}</MenuItem>
                                    <MenuItem value="1">Diário</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                required
                                margin="normal"
                                disabled={isReadOnly}
                                label={currentLanguage.inspection_expiration_days}
                                value={productData.daysNotificationNextInspectionDate}
                                InputProps={{ inputComponent: NonNegativeInput }}
                                onChange={event => this.updateProductData({ daysNotificationNextInspectionDate: event.target.value })}
                            />

                            <FormControlLabel
                                margin="normal"
                                disabled={isReadOnly}
                                label={currentLanguage.has_expiration_date}
                                control={
                                    <Checkbox
                                        checked={productData.isDueDate}
                                        onChange={event => this.updateProductData({ isDueDate: event.target.checked })}
                                    />
                                }
                            />

                            <TextField
                                required
                                margin="normal"
                                disabled={isReadOnly || !this.state.productData.isDueDate}
                                label={currentLanguage.days_notification_due_date}
                                value={productData.daysNotificationDueDate}
                                InputProps={{ inputComponent: NonNegativeInput }}
                                onChange={event => this.updateProductData({ daysNotificationDueDate: event.target.value })}
                            />
                        </FlexColumnDiv>
                    </Flex1150Div>
                </FlexDiv>
            </React.Fragment>
        );
    };

    removeMaintenancePart = (index) => {
        const { maintenanceParts } = this.state

        if (isEmpty(maintenanceParts[index].name)) {
            this.setState({ maintenanceParts: maintenanceParts.filter((attribute, i) => i !== index) })
        } else {
            this.setState({ removingMaintenancePartIndex: index })
        }
    };

    showMaintenanceParts = () => {
        const { maintenanceParts, removingMaintenancePartIndex } = this.state
        const { currentLanguage } = this.props

        const validator = UniqueNonEmptyValidator.ofNullable(maintenanceParts, 'name')

        if (maintenanceParts === null) {
            return (<CenterTitleDiv><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></CenterTitleDiv>);
        }

        if (maintenanceParts.length === 0) {
            return (<CenterTitleDiv>{currentLanguage.empty_list}</CenterTitleDiv>);
        }

        if (removingMaintenancePartIndex !== null) {
            return (
                <React.Fragment>
                    <Typography variant="h6">{currentLanguage.remove_maintenance_part}</Typography>
                    <div style={{ padding: '2rem', fontSize: '1.2rem' }}>{maintenanceParts[removingMaintenancePartIndex].name}</div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {maintenanceParts.map((maintenancePart, index) => (
                    <FlexBaselineDiv key={index}>
                        <TextField
                            required
                            margin="normal"
                            style={{ flex: '1 1 auto' }}
                            value={maintenancePart.name}
                            label={currentLanguage.maintenance_part}
                            error={!validator.isValid(maintenancePart.name)}
                            onChange={event => this.setState({ maintenanceParts: maintenanceParts.map((part, i) => i === index ? { ...part, name: event.target.value } : part) })}
                        />
                        <Select
                            style={{ marginLeft: '1rem', width: '10rem' }}
                            value={maintenancePart.unit}
                            onChange={event => this.setState({ maintenanceParts: maintenanceParts.map((part, i) => i === index ? { ...part, unit: event.target.value } : part) })}
                            inputProps={{ id: 'unit-select' }}
                        >
                            <MenuItem value="UNITS">{currentLanguage.units}</MenuItem>
                            <MenuItem value="LITRES">{currentLanguage.litres}</MenuItem>
                            <MenuItem value="KILOGRAMS">{currentLanguage.kilograms}</MenuItem>
                            <MenuItem value="METRES">{currentLanguage.metres}</MenuItem>
                            <MenuItem value="NA">{currentLanguage.na}</MenuItem>
                        </Select>
                        <Button style={{ marginLeft: '1rem' }} onClick={event => this.removeMaintenancePart(index)}>{currentLanguage.remove}</Button>
                    </FlexBaselineDiv>
                ))}
            </React.Fragment>
        );
    };

    showTabContent = () => {
        const { tabValue } = this.state

        switch (tabValue) {
            case 0: return this.showGeneral()
            case 1: return this.showMaintenanceParts()
            default: throw new Error(`unimplemented: ${tabValue}`);
        }
    }

    showGeneralButtons = () => {
        const { isReadOnly, submitLabel, currentLanguage } = this.props
        const { maintenanceParts } = this.state

        const validator = UniqueNonEmptyValidator.ofNullable(maintenanceParts, 'name')

        return (
            <React.Fragment>
                <div>
                </div>

                <div>
                    <Button onClick={this.closeDialog}>{currentLanguage.close}</Button>
                    <PrimaryButton onClick={this.handleSubmit} disabled={this.state.disableButton || !validator.isAllValidAndNonEmpty()}>{submitLabel}</PrimaryButton>
                </div>

            </React.Fragment>
        );
    };

    showMaintenancePartsButtons = () => {
        const { isReadOnly, submitLabel, currentLanguage } = this.props
        const { maintenanceParts, removingMaintenancePartIndex } = this.state

        const emptyMaintenancePart = { id: null, name: '', unit: 'NA' };

        const validator = UniqueNonEmptyValidator.ofNullable(maintenanceParts, 'name');

        if (removingMaintenancePartIndex !== null) {
            return (
                <React.Fragment>
                    <div>
                        <Button onClick={event => this.setState({ removingMaintenancePartIndex: null })}>{currentLanguage.cancel}</Button>
                    </div>

                    <div>
                        <PrimaryButton style={{ marginLeft: '1rem' }} onClick={event => this.setState({
                            removingMaintenancePartIndex: null,
                            maintenanceParts: maintenanceParts.filter((attribute, i) => i !== removingMaintenancePartIndex)
                        })} >
                            {currentLanguage.remove_maintenance_question}
                        </PrimaryButton>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div>
                    <PrimaryButton disabled={isReadOnly} onClick={() => this.setState({ maintenanceParts: [...maintenanceParts, emptyMaintenancePart] })}>Adicionar Peça</PrimaryButton>
                </div>

                <div>
                    <Button onClick={this.closeDialog}>{currentLanguage.close}</Button>
                    <PrimaryButton onClick={this.handleSubmit} disabled={isReadOnly || !validator.isAllValidAndNonEmpty()}>{submitLabel}</PrimaryButton>
                </div>

            </React.Fragment>
        );
    };

    showTabButtons = () => {
        const { tabValue } = this.state

        switch (tabValue) {
            case 0: return this.showGeneralButtons()
            case 1: return this.showMaintenancePartsButtons()
            default: throw new Error(`unimplemented: ${tabValue}`)
        }
    }

    render() {
        const { ModalButton, currentLanguage } = this.props
        const { tabValue, isDialogOpen } = this.state

        return (
            <React.Fragment>
                <ModalButton onClick={this.handleDialogOpen} />
                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">
                    <Tabs value={tabValue} onChange={(event, tabValue) => this.setState({ tabValue })}>
                        <Tab style={{ color: 'white' }} label={currentLanguage.general_information} />
                        <Tab style={{ color: 'white' }} label={currentLanguage.maintenance_parts} />
                    </Tabs>

                    <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                        {this.showTabContent()}
                    </DialogContent>

                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        {this.showTabButtons()}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }

}
