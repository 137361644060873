import React from 'react';

import NumberFormat from 'react-number-format';

export function NonNegativeInput (props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat {...other} getInputRef={inputRef} allowNegative={false} onValueChange={values => onChange({ target: { value: values.value, }, })} />
    );
}
