import axios from 'axios';

const formDataConfig = { headers: { 'Content-Type': 'multipart/form-data' } };

function buildFileFormData (file) {
    const formData = new FormData();
    formData.append('file', file);
    return formData;
}

export const ProductApi = {
    list: () => axios.get('/web/products/'),
    listMaintenanceParts: (productId) => axios.get(`/web/products/${productId}/maintenance-parts/`),
    create: (productData) => axios.post(`/web/products/`, productData),
    update: (productId, productData) => axios.put(`/web/products/${productId}/`, productData),
    updatePhoto: (productId, photoFile) => axios.put(`/web/products/${productId}/image/`, buildFileFormData(photoFile), formDataConfig),
    updateMaintenanceParts: (productId, maintenanceParts) => axios.put(`/web/products/${productId}/maintenance-parts/`, maintenanceParts)
};
