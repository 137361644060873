export default {
  // WORDS
  search: "Search",
  tags: "Tags",
  tag: "Tag",
  cnpj: "CNPJ",
  category: "Category",
  categories: "Categories",
  subcategory: "Subcategory",
  subcategories: "Subcategories",
  product: "Product",
  products: "Products",
  status: "Status",
  register: "Register",
  from: "From",
  to: "To",
  last_inspection: "Last inspection",
  last_maintenance: "Last maintenance",
  due_date: "Due date",
  date: "Date",
  inspected_by: "Inspected by",
  items_by_page: "Items per page",
  valid: "Valid",
  validP: "Valid",
  able: "Able",
  yes: "Yes",
  no: "No",
  all: "All",
  name: "Name",
  last_update: "Last update",
  exclusive: "Exclusive",
  sku: "SKU",
  image: "Image",
  description: "Description",
  options: "Options",
  employee: "Employee",
  company: "Company",
  phone: "Phone",
  active: "Active",
  inactive: "Inactive",
  migrated: "Migrated",
  email: "E-mail",
  pending: "Pending",
  expired: "Expired",
  expiredP: "Expired",
  improper: "Not conform",
  improper: "Not conform",
  expiring: "Expiring",
  expired_or_expiring: "Expired or expiring",
  export: "Export",
  empty_list: "Empty list.",
  general_information: "General Information",
  modifications: "Modifications",
  number_of_control: "Number of Control",
  last_inspection_status: "Last inspection status",
  due_date_status: "Due date status",
  close: "Close",
  responsible: "Responsible",
  responsible_name: "Responsible name",
  responsible_email: "Responsible E-mail",
  responsible_phone: "Responsible phone",
  responsible_role: "Responsible role",
  attributes: "Attributes",
  attribute: "Attribute",
  attribute_register: "Attribute register",
  values: "Values",
  questions: "Questions",
  answers: "Answers",
  question_initial: "Q",
  answer_initial: "A",
  modified_by: "Modified by",
  equipment: "Equipment",
  parts: "Parts",
  maintenance_parts: "Maintenance Parts",
  replacments: "Replacments",
  without_changing_parts: "Without changing parts",
  units: "Units",
  litres: "Litres",
  kilograms: "Kilograms",
  metres: "Metres",
  without_questions_answers: "Without questions/answers",
  activated: "Activated",
  disabled: "Disabled",
  disable: "Disable",
  none: "None",
  created: "Created",
  updated: "Updated",
  update: "Update",
  removed: "Removed",
  already_exists: "Already exists",
  email_exists: "E-mail #vr0# already exists.",
  updated_user: "User #vr0# updated successfully.",
  created_user: "User #vr0# created successfully.",
  removed_user: "User #vr0# removed successfully.",
  profile: "Profile",
  cancel: "Cancel",
  removing: "Removing",
  remove: "Remove",
  confirmation: "Confirmation",
  remove_confirmation: "To confirm this operation, enter #vr0#",
  password: "Password",
  text: "Text",
  custom: "Custom",
  positive: "Positive",
  negative: "Negative",
  change_password: "Change password",
  current_password: "Current password",
  new_password: "New password",
  confirm_new_password: "Confirm new password",
  change_password_requiriment: "The new password must meet the following criteria:",
  at_least_characters: "At least 6 characters",
  at_least_numeral: "At least 1 numeral",
  at_least_uppercase: "At least 1 uppercase letter",
  at_least_lowercase: "At least 1 lowercase letter",
  at_least_special_character: "At least 1 special character",
  valid_special_character: "Valid special characters: !@#$%&*()_-+=",
  logout: "Logout",
  my_plan: "My plan",
  add: "Add",
  qr_code: "QR Code",
  generate_qr_code: "Generate QR Code",
  export_qr_code: "Export QR Code",
  video_lessons: "Video Lessons",
  clear_fields: "Clear Fields",
  os: "Order of Service",
  id_equipament: "Equipment id",
  name_equipament: "Name",
  priority_equipament: "Priority",
  type_equipament: "Type",
  responsible_user_equipament: "Responsible User",
  completion_date_equipament: "Completion Date",
  report_equipament: "Report",
  // MENU HEADER
  dashboard: "Dashboard",
  reports: "Reports",
  report: "Report",
  registrations: "Registrations",
  employees: "Employees",
  companies: "Companies",
  company_group: "Company Group",
  all_users: "All users",
  settings: "Settings",
  maintenances: "Maintenances",
  list: "List",
  tag_replace: "Tag replace",
  user: "User",
  system: "Sistem",
  or: "or",
  na: "N/A",
  // DASHBOARD
  inspections_by_products: "Inspections per products",
  equipments_status: "Equipments status",
  inspections_by_employees: "Inspections per employee",
  inspections_by_month: "Inspections by month (Last 24 months)",
  total: "Total",
  users: "Users",
  equipments: "Equipments",
  inspections: "Inspections",
  last_seven_days: "Last 7 days",
  last_interactions: "Last interactions",
  new_user: "New user",
  new_equipment: "New equipment",
  new_inspection: "New inspection",
  text_initial_video_lessons: "Is this your first time at MOB Inspection? See the system video lessons!",
  text_initial_buy_tags: "Do you want to buy Tags? See our options!",
  // INSPECTIONS REPORT
  inspection_reports: "Inspection Reports",
  equipment_sku: "Equipment/SKU",
  equipment_information: "Equipment informations",
  active_tags_only: "Active tags only",
  // MAINTENANCES REPORT
  maintenance_reports: "Maintenance Reports",
  maintenance_done_by: "Maintenance done by",
  // CATEGORIES
  add_category: "Add Category",
  update_category: "Update category",
  update_subcategory: "Update subcategory",
  created_category: "Category #vr0# created.",
  updated_category: "Category #vr0# updated.",
  created_subcategory: "Subcategory #vr0# created.",
  updated_subcategory: "Subcategory #vr0# updated.",
  // SUB CATEGORIES
  add_subcategory: "Add Subcategory",
  inspection_questions: "Inspection questions",
  inspection_question: "Inspection question",
  maintenance_questions: "Maintenance questions",
  maintenance_question: "Maintenance question",
  able_subcategory: "Able Subcategory",
  add_inspection_question: "Add inspection question",
  add_maintenance_question: "Add maintenance question",
  add_attribute: "Add attribute",
  custom_answer: "Custom answer",
  add_custom_answer: "Add custom answer",
  // PRODUCT
  add_product: "Add Product",
  update_product: "Update product",
  created_product: "Product #vr0# created",
  updated_product: "Product #vr0# updated",
  inspection_periodicity: "Inspection periodicity",
  yearly: "Yearly",
  biannual: "Biannual",
  quarterly: "Quarterly",
  monthly: "Monthly",
  biweekly: "Biweekly",
  weekly: "Weekly",
  inspection_expiration_days: "Inspection expiration days",
  has_expiration_date: "Has expiration date",
  days_notification_due_date: "Days for notification of vadility",
  remove_maintenance_part: "Do you want to remove the following Maintenance Part?",
  maintenance_part: "Maintenance part",
  remove_maintenance_question: "Remove maintenance question",
  add_maintenance_part: "Add Maintenance Part",
  // LIST TAGS
  equipment_serial: "Equipment Serial",
  product_name: "Product name",
  product_exclusive: "Exclusive product?",
  active_tag: "Active Tag?",
  list_tag_table_description: "Products marked as exclusive do not consume or affect the total available tags.",
  // TAG REPLACE
  new_equipament_serial: "New equipment serial",
  request_date: "Request date",
  tag_replace_table_description: "Listed here are all requests for tag replacement.",
  new_tag_information: "NEW tag information",
  old_tag_information: "OLD tag information",
  recommended_transfer_equipment: "Recommended transfer equipment",
  find_other_equipment: "Find other equipment",
  find_equipment: "Find equipment",
  selected_equipment: "Selected equipment",
  select_equipment: "Select equipament",
  transfer_inspections_and_maintenances: "Transfer Inspections and Maintenances",
  select_an_equipment_to_transfer_information: "Select an equipment to transfer information",
  replacement_done: "Data transferred successfully!",
  // EMPLOYEES
  add_user: "Add user",
  role: "Role",
  company_operator: "Company Operator",
  company_admin: "Company Admin",
  company_group_admin: "Company Group Admin",
  mobcheck_admin: "MOB Inspeção Admin",
  // COMPANIES
  company_name: "Company name",
  main_contact: "Main contact",
  tags_qtt_max: "Tags (Máx quantity)",
  tags_qtt_used: "Tags (Used)",
  add_company: "Add company",
  company_created: "Company #vr0# created.",
  company_updated: "Company #vr0# updated.",
  change_photo: "Change Photo",
  // COMPANY GROUP
  company_group_database_name: "Company group name",
  select_company: "Select a company",
  add_company_group: "Add Company Group",
  company_group_created: "Company group #vr0# created.",
  migrate_error: "We were unable to migrate.",
  missing_field: "Please fill in the required fields.",
  company_group_already_exists: "Company group already exists: #vr0#",
  company_group_invalid_name: "Invalid name: mobcheck_#vr0#",
  schema_name: "Schema name",
  tags_limit: "Tags limit",
  creating: "Creating",
  // ALL USERS
  registred_on: "Registred on",
  activated_user_success: "User #vr0# successfully activated.",
  migrated_user_success: "User #vr0# successfully migrated.",
  updated_password_success: "User #vr0# password successfully updated.",
  deleted_user_success: "User #vr0# successfully deleted.",
  active_pending_user: "Active pending user",
  // SETTINGS
  system_configuration: "System configuration",
  general_settings: "General settings",
  languagem: "Language",
  //USER
  update_user_success: "User updated successfully.",
  // Tippy
  attributeType_Tippy: "Customized attributes to all the products of the subcategories, to be filled in the moment of the register of an equipment (ex. car license plate)",
  due_date_Tippy: "The product's due date",
  maintenance_date_Tippy: "The product's last maintennce date",
  inspection_date_Tippy: "The product's last inspection date",
  inspection_question_Tippy: "Type of answer to be selected",
  user_profile_Tippy: "User's profile permissions type",
  inspection_customQuestion_Tippy: "Type of system's reaction when selected the customized answer",
  // ORDER OF SERVICE
  number_os: "Os number",
  os_created: "Work order created.",
  os_updated: "Updated work order.",
  start_date: "Start date",
  end_date: "End date",
  user_current: "User current",
  responsible_user: "Responsible User",
  responsible_user_select: "Select a user",
  comments: "Comments",
  os_status: "Status",
  os_status_select: "Select a status",
  status_pending: "Pending",
  status_partial: "Partial",
  status_finished: "Finished",
  status_cancelled: "Cancelled",
  pending: "No equipment inspected/maintenance",
  partial: "Incomplete Inspection/Maintenance",
  finished: "All inspections and maintenance completed",
  canceled: "Exclusion",
  equipment: "Equipment",
  priority: "Priority",
  priority_select: "Select a priority",
  priority_lowest: "Lowest",
  priority_low: "Low",
  priority_average: "Average",
  priority_high: "High",
  priority_very_high: "Very high",
  select_type: "Select a type",
  os_type: "Type",
  os_inspection: "Inspection",
  os_maintenance: "Maintenance",
  os_select_equipment: "select an equipment",
  os_completion_date: "Completion Date"
}
