import React from 'react'
import { Button } from '@material-ui/core'
import { AnonymousHeader } from '../components/anonymousHeader'
import Footer from '../../components/footer'
import { ActiveEnvironmentService } from './activeEnvironmentService'
import { LoggedUserService } from '../../authenticated/loggedUser/loggedUserService'
import { HistoryService } from '../../app/historyService'
import * as queryString from 'query-string'
import {
    FlexDiv
} from '../../authenticated/components/components'

class ActiveEnvironmentPage extends React.Component {
    state = {
        password: '',
        verification: ''
    }

    handleValueChange = field => event => this.setState({ [field]: event.target.value });

    handleSubmit = event => { 
        const { onActiveEnvironment } = this.props

        const { t: emailToken } = queryString.parse('?' + window.location.hash.split('?')[1])

        ActiveEnvironmentService.emailActiveEnvironment(emailToken).then(() => LoggedUserService.getSessionLoggedUser().then(onActiveEnvironment))
    }

    render() {

        this.handleSubmit()

        return (
            <React.Fragment>
                <div style={{background: "#005d7b"}}>
                    <AnonymousHeader />
                </div>
                
                <div style={{ padding: '2rem', fontSize: '1.2rem' }}>Bem vindo!</div>
                <div style={{ padding: '2rem', marginTop: '1rem' }}>
                    Seu cadastro foi ativado com sucesso!
                </div>
                <FlexDiv style={{ justifyContent: 'center' }}>
                    <Button color="primary" variant="outlined" onClick={() => HistoryService.goto('/#/')}>Ir para página inicial</Button>
                </FlexDiv>
                <br />
                <Footer />
            </React.Fragment>
        )
    }
}

export default ActiveEnvironmentPage
