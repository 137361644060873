import React from 'react'
import Spinner from 'react-spinner-material'
import { Doughnut } from 'react-chartjs-2'
import { HistoryService } from '../../app/historyService'

const dataChart = {
    labels: [],
    Skus: [],
    datasets: [{
        data: [],
        backgroundColor: [
            '#EB7B36',
            '#FF6363',
            '#54A843'
        ],
        hoverBackgroundColor: [
            '#E05E0D',
            '#FF2E2E',
            '#36C918'
        ]
    }],
}


const options = {
    onClick: (event, elements) => {
        if (elements[0] !== undefined) {
            const chart = elements[0]._chart;
            const element = chart.getElementAtEvent(event)[0]
            HistoryService.sendData('/inspections', { Doughnut: element._index })
        }
    }
}

export class PercentageChart extends React.Component {

    render() {
        const { data: percentageData } = this.props;


        if (!percentageData) {
            return (<div style={{ position: 'relative', top: '45%', left: '45%' }}> <Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /> </div>);
        } else {
            dataChart.labels = []
            dataChart.datasets[0].data = []
            dataChart.Skus = []

            percentageData.xData.map(data => (
                dataChart.labels.push(data.label),
                dataChart.Skus.push('SKU')
            ))

            percentageData.xData.map(data => (
                dataChart.datasets[0].data.push(data.value)
            ))

        }

        return (
            <Doughnut width={50} height={20} data={dataChart} options={options} />
        )
    }
}
