import React from 'react'

import { Typography } from '@material-ui/core'

import { DashboardService } from './dashboardService'
import { ContainerDiv } from '../components/components'
import { HistogramChart } from './histogramChart'
import { HistogramService } from './histogramService'

export class DashboardCommonPage extends React.Component {
    state = {
        histogramData: null
    }
    

    componentDidMount () {
        const { currentLanguage } = this.props

        DashboardService.monthlyInspections().then(monthlyInspections => this.setState({
            histogramData: HistogramService.convertData(monthlyInspections)
        }))
    }

    render () {
        const { histogramData } = this.state

        return (
            <React.Fragment>
                <ContainerDiv style={{marginTop: '1rem'}}>
                    <Typography variant="h6">Inspeções por mês (últimos 24 meses)</Typography>
                    <div style={{height: '20rem'}}>
                        <HistogramChart data={histogramData} />
                    </div>
                </ContainerDiv>
            </React.Fragment>
        );
    }
}
