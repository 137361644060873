import { DefaultFilter } from './defaultFilter';
import { SingleFieldSorter } from '../utils/singleFieldSorter';
import { Paginator } from '../utils/paginator';

export class DefaultCollection {

    constructor({ items, filter, sorter, paginator }) {
        this.items = items;
        this.filter = filter;
        this.sorter = sorter;
        this.paginator = paginator;

        Object.freeze(this);
    }

    static of = (config) => {
        const { items, filter, sorter, paginator } = config;

        return new DefaultCollection({ items, filter, sorter, paginator });
    };

    static ofItemsAndPredicates = (items, predicates) => {
        const filter = DefaultFilter.ofItemsAndPredicates(items, predicates);
        const sorter = SingleFieldSorter.initial(filter.filteredItems);
        const paginator = Paginator.initial(sorter.sortedItems);

        return DefaultCollection.of({ items, filter, sorter, paginator })
    };

    static withPaginator = (collection, paginator) => {
        return DefaultCollection.of({ ...collection, paginator });
    };
    static withRowsPerPage = (collection, currentRowsPerPage) => DefaultCollection.withPaginator(collection, Paginator.ofRowsPerPage(collection.paginator, currentRowsPerPage));

    static withNextSorter = (collection, field) => {
        const sorter = SingleFieldSorter.next(collection.sorter, field);
        const paginator = Paginator.ofItems(collection.paginator, sorter.sortedItems);

        return DefaultCollection.of({ ...collection, sorter, paginator });
    };

    static withItems = (collection, items, predicates) => {
        const filter = DefaultFilter.ofItemsAndPredicates(items, predicates);
        const sorter = SingleFieldSorter.of({ ...collection.sorter, items: filter.filteredItems });
        const paginator = Paginator.ofItems(collection.paginator, sorter.sortedItems);

        return DefaultCollection.of({ items, filter, sorter, paginator });
    };

    static withItemsFilterOS = (collection, items, filters) => {
        const filter = DefaultFilter.ofItemsAndFilters(items, filters);
        const sorter = SingleFieldSorter.of({ ...collection.sorter, items: filter.filteredItems });
        const paginator = Paginator.ofItems(collection.paginator, sorter.sortedItems);

        return DefaultCollection.of({ items, filter, sorter, paginator });
    };

    static withCreatedItem = (collection, identifierFieldKey, createdItem, predicates) => DefaultCollection.withItems(
        collection,
        collection.items.concat(createdItem),
        predicates
    );

    static withUpdatedItem = (collection, identifierFieldKey, updatedItem, predicates) => DefaultCollection.withItems(
        collection,
        collection.items.map(item => item[identifierFieldKey] === updatedItem[identifierFieldKey] ? { ...item, ...updatedItem } : item),
        predicates
    );

    static withoutDeletedItem = (collection, identifierFieldKey, deletedItem, predicates) => DefaultCollection.withItems(
        collection,
        collection.items.filter(item => item[identifierFieldKey] !== deletedItem[identifierFieldKey]),
        predicates
    );

}
