import React from 'react'
import { Button, TextField } from '@material-ui/core'
import { FlexColumnDiv, FlexSpaceAroundDiv, PrimaryButton, RightDiv } from '../../authenticated/components/components'
import { PasswordValidator } from '../../authenticated/loggedUser/passwordValidator'
import { AnonymousHeader } from '../components/anonymousHeader'
import { PasswordRequirements } from '../../authenticated/components/passwordRequirements'
import Footer from '../../components/footer'
import { PasswordResetService } from './passwordResetService'
import { LoggedUserService } from '../../authenticated/loggedUser/loggedUserService'
import { HistoryService } from '../../app/historyService'
import * as queryString from 'query-string'

class PasswordResetPage extends React.Component {
    state = {
        password: '',
        verification: ''
    }

    handleValueChange = field => event => this.setState({ [field]: event.target.value });

    handleSubmit = event => {
        const { onPasswordReset } = this.props
        const { password } = this.state

        //const { t: emailToken } = HistoryService.querystring()
        const{ t: emailToken } = queryString.parse('?'+window.location.hash.split('?')[1])

        console.log("TESTE")
        console.log(emailToken)

        PasswordResetService.resetPassword(emailToken, password).then(() => LoggedUserService.getSessionLoggedUser().then(onPasswordReset))
    }

    render() {
        const { password, verification } = this.state
        const { currentLanguage } = this.props
        const passwordValidator = PasswordValidator.validatePasswordCreate(password, verification)

        return (
            <React.Fragment>
                <AnonymousHeader />
                <div style={{ marginLeft: '6rem', marginRight: '6rem', marginBottom: '3rem' }}>
                    <FlexSpaceAroundDiv style={{ marginTop: '3rem', marginBottom: '3rem' }}>
                        <FlexColumnDiv style={{ width: '30rem' }}>
                            <TextField margin="normal" type="password" required onChange={this.handleValueChange('password')} value={password} label="Senha" />
                            <TextField margin="normal" type="password" required onChange={this.handleValueChange('verification')} value={verification} label="Confirmar senha" error={passwordValidator.samePassword === false} />
                        </FlexColumnDiv>
                        <PasswordRequirements validator={passwordValidator} />
                    </FlexSpaceAroundDiv>
                    <RightDiv>
                        <Button color="inherit" onClick={() => HistoryService.goto('/')}>{currentLanguage.cancel}</Button>
                        <PrimaryButton onClick={this.handleSubmit} disabled={!passwordValidator.allValid}>{currentLanguage.change_password}</PrimaryButton>
                    </RightDiv>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default PasswordResetPage
