import { CategoryApi } from './categoryApi';

function list () {
    return CategoryApi.list().then(response => response.data);
}

function listInspectionAttributes (categoryId) {
    if (!categoryId) {
        return Promise.resolve([]);
    }

    return CategoryApi.listInspectionAttributes(categoryId).then(response => response.data);
}

function listMaintenanceAttributes (categoryId) {
    if (!categoryId) {
        return Promise.resolve([]);
    }

    return CategoryApi.listMaintenanceAttributes(categoryId).then(response => response.data);
}

function update (categoryId, categoryData, inspectionAttributes, maintenanceAttributes) {
    return CategoryApi.update(categoryId, categoryData)
        .then(() => CategoryApi.updateInspectionAttributes(categoryId, inspectionAttributes))
        .then(() => CategoryApi.updateMaintenanceAttributes(categoryId, maintenanceAttributes))
        .then(() => ({ id: categoryId, updateDate: new Date().toISOString(), ...categoryData }));
}

function create (categoryData, inspectionAttributes, maintenanceAttributes) {
    return CategoryApi.create(categoryData)
        .then(response => response.data)
        .then(categoryId => CategoryApi.updateInspectionAttributes(categoryId, inspectionAttributes).then(() => categoryId))
        .then(categoryId => CategoryApi.updateMaintenanceAttributes(categoryId, maintenanceAttributes).then(() => categoryId))
        .then(categoryId => ({ id: categoryId, updateDate: new Date().toISOString(), ...categoryData }));
}

export const CategoryService = { list, listInspectionAttributes, listMaintenanceAttributes, update, create };
