import React from 'react';
import { UserService } from './userService';
import { CollectionViewUpdater } from '../utils/collectionViewUpdater';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';
import {
    BodyTableCell,
    ContainerDiv,
    FlexSpaceBetweenDiv,
    formatMainDescription,
    HeaderTableCell,
    OverflowXDiv
} from '../components/components';
import { StringService } from '../utils/stringService';
import { SortHeaderCell } from '../components/sortHeaderCell';
import { TableFooterWithPaginator } from '../components/tableFooterWithPaginator';
import { UserDialogButton } from './userDialogButton';
import { CompanyGroupService } from '../companyGroup/companyGroupService';
import { withSnackbar } from 'notistack';
import { DateTimeService } from '../utils/dateTimeService';
import Spinner from 'react-spinner-material'

class UsersPage extends React.Component {

    constructor (props) {
        super(props)

        this.collectionUpdater = CollectionViewUpdater.ofUsers(this)

        this.state = {
            isLoading: false,
            companyGroups: [],
            filterQuery: '',
            filterStatus: 'PENDING',
            collection: this.collectionUpdater.collection
        }
    }

    componentDidMount () {
        UserService.list().then(items => {
            this.collectionUpdater.initialData(items)
            this.setState({isLoading: false})
        })

        CompanyGroupService.list().then(companyGroups => this.setState({ companyGroups }))
    }

    notify = message => () => this.props.enqueueSnackbar(message)

    activateGeneric = (userName) => (userId, activateGenericData) => UserService.activateGeneric(userId, activateGenericData)
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.activated_user_success, userName)))

    migrateGenericPending = (userName) => (userId, migrateGenericPendingData) => UserService.migrateGenericPending(userId, migrateGenericPendingData)
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.activated_user_success, userName)))

    migrate = (userName) => (userId, migrateData) => UserService.migrate(userId, migrateData)
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.migrated_user_success, userName)))

    updatePassword = (userName) => (userId, passwordData) => UserService.updatePassword(userId, passwordData)
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.updated_password_success, userName)))

    deleteUser = (userName) => (userEmail) => UserService.delete(userEmail)
        .then(this.collectionUpdater.deleteItem)
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.deleted_user_success, userName)))


    buildList = () => {
        const { isLoading, collection, companyGroups } = this.state
        const { currentLanguage } = this.props

        if (isLoading) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={6}><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></BodyTableCell>
                </TableRow>
            )
        }

        const items = collection.paginator.pagedItems

        if (items.length === 0) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={6}>{currentLanguage.empty_list}</BodyTableCell>
                </TableRow>
            )
        }

        return items.map(user => (
            <TableRow key={user.id}>
                <BodyTableCell>
                    {formatMainDescription(user.companyName, user.companyGroup)}
                </BodyTableCell>
                <BodyTableCell>
                    {formatMainDescription(user.name, user.occupation)}
                </BodyTableCell>
                <BodyTableCell>
                    {formatMainDescription(user.email, StringService.formatPhoneNumber(user.phone))}
                </BodyTableCell>
                <BodyTableCell>
                    {formatMainDescription(UserService.formatUserStatus(user.status, currentLanguage), UserService.formatUserRole(user.role, currentLanguage))}
                </BodyTableCell>
                <BodyTableCell>
                    {DateTimeService.formatDate(user.createdAt)}
                </BodyTableCell>
                <BodyTableCell align="center">
                    <UserDialogButton
                        currentLanguage={currentLanguage}
                        user={user}
                        companyGroups={companyGroups}
                        activateGeneric={this.activateGeneric(user.name)}
                        migrateGenericPending={this.migrateGenericPending(user.name)}
                        migrate={this.migrate(user.name)}
                        updatePassword={this.updatePassword(user.name)}
                        deleteUser={this.deleteUser(user.email)}
                    />
                </BodyTableCell>
            </TableRow>
        ));
    };

    render () {
        const { collection, filterQuery, filterStatus } = this.state
        const { currentLanguage } = this.props
        const { sorter } = collection

        const { updateCollection, handleFilterChange, handleSort } = this.collectionUpdater

        return (
            <React.Fragment>
                <ContainerDiv>
                    <FlexSpaceBetweenDiv style={{flexWrap: 'wrap'}}>
                        <FormControl margin="normal">
                            <TextField
                                label={currentLanguage.search}
                                helperText={currentLanguage.name+", "+currentLanguage.email+", "+currentLanguage.role+", "+currentLanguage.phone+", "+currentLanguage.company+" "+currentLanguage.or+" "+currentLanguage.company_group}
                                value={filterQuery}
                                style={{marginBottom: '1rem'}}
                                onChange={event => handleFilterChange({ filterQuery: event.target.value })} />
                        </FormControl>
                        <FormControl margin="normal" style={{width: '10rem'}}>
                            <InputLabel htmlFor="status-select">Status</InputLabel>
                            <Select value={filterStatus} onChange={event => handleFilterChange({ filterStatus: event.target.value })} inputProps={{ id: 'status-select' }}>
                                <MenuItem value="">{currentLanguage.all}</MenuItem>
                                <MenuItem value="ACTIVE">{currentLanguage.active}</MenuItem>
                                <MenuItem value="INACTIVE">{currentLanguage.inactive}</MenuItem>
                                <MenuItem value="PENDING">{currentLanguage.pending}</MenuItem>
                                <MenuItem value="MIGRATED">{currentLanguage.migrated}</MenuItem>
                                <MenuItem value="DELETED">{currentLanguage.removed}</MenuItem>
                            </Select>
                        </FormControl>
                    </FlexSpaceBetweenDiv>
                </ContainerDiv>

                <OverflowXDiv>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <SortHeaderCell sorter={sorter} onSort={handleSort} field="companyName">{currentLanguage.company}/{currentLanguage.company_group}</SortHeaderCell>
                                <SortHeaderCell sorter={sorter} onSort={handleSort} field="name">{currentLanguage.user}/{currentLanguage.role}</SortHeaderCell>
                                <SortHeaderCell sorter={sorter} onSort={handleSort} field="email">{currentLanguage.email}/{currentLanguage.phone}</SortHeaderCell>
                                <SortHeaderCell sorter={sorter} onSort={handleSort} field="status">{currentLanguage.status}/{currentLanguage.profile}</SortHeaderCell>
                                <SortHeaderCell sorter={sorter} onSort={handleSort} field="createdAt">{currentLanguage.registred_on}</SortHeaderCell>
                                <HeaderTableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.buildList()}
                        </TableBody>
                        <TableFooterWithPaginator collection={collection} onCollectionUpdate={updateCollection} colSpan={6} />
                    </Table>
                </OverflowXDiv>
            </React.Fragment>
        );
    }

}

export default withSnackbar(UsersPage);
