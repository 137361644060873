import React from 'react';
import { TextField, Button } from '@material-ui/core';
import { FlexColumnDiv, FlexSpaceAroundDiv } from '../components/components';
import { PasswordValidator } from './passwordValidator';
import { LoggedUserService } from './loggedUserService';
import { withSnackbar } from 'notistack';
import { PasswordRequirements } from '../components/passwordRequirements';

class ChangePasswordPage extends React.Component {
    state = {
        currentPassword: '',
        newPassword: '',
        verification: ''
    };

    handleValueChange = field => event => this.setState({ [field]: event.target.value });

    handleSubmitPassword = () => {
        const { currentPassword, newPassword } = this.state;
        const { enqueueSnackbar } = this.props;

        LoggedUserService.changePassword(currentPassword, newPassword).then(() => {
            enqueueSnackbar('Senha atualizada');
            this.setState({ currentPassword: '', newPassword: '', verification: '' });
        }).catch(() => {
            enqueueSnackbar('Senha atual inválida');
        });
    };

    render () {
        const { currentPassword, newPassword, verification } = this.state
        const { currentLanguage } = this.props

        const validator = PasswordValidator.validatePasswordUpdate(currentPassword, newPassword, verification);

        return (
            <FlexSpaceAroundDiv style={{marginTop: '3rem', marginBottom: '3rem'}}>
                <FlexColumnDiv>
                    <TextField margin="normal" type="password" required onChange={this.handleValueChange('currentPassword')} value={currentPassword} label={currentLanguage.current_password} />
                    <TextField margin="normal" type="password" required onChange={this.handleValueChange('newPassword')} value={newPassword} label={currentLanguage.new_password} />
                    <TextField margin="normal" type="password" required onChange={this.handleValueChange('verification')} value={verification} label={currentLanguage.confirm_new_password} error={validator.samePassword === false} />
                    <Button variant="contained" color="primary" onClick={this.handleSubmitPassword} style={{marginTop: '2rem'}} disabled={!validator.allValid}>{currentLanguage.change_password}</Button>
                </FlexColumnDiv>
                <PasswordRequirements currentLanguage={currentLanguage} validator={validator} />
            </FlexSpaceAroundDiv>
        );
    }

}

export default withSnackbar(ChangePasswordPage);
