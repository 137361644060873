import { deburr, toLower, trim } from 'lodash/string';
import { isNil, isBoolean, isNumber, isString } from 'lodash/lang';
import { isEmpty } from 'lodash';

const numberOnly = /^\d+$/;

function sortNormalize (value) {
    if (isNil(value)) {
        return '';
    }

    if (isNumber(value)) {
        return value;
    }

    if (isBoolean(value)) {
        return value;
    }

    if (!isString(value)) {
        throw new Error('value is non string: ' + value);
    }

    if (numberOnly.test(value)) {
        return parseInt(value);
    }

    return toLower(deburr(trim(value)));
}

function normalize (value) {
    return toLower(deburr(String(value)));
}

function formatCnpj (rawCnpj) {
    if (!rawCnpj) {
        return '__.___.___/____.__';
    }

    const pivot = 8;
    const firstPart = rawCnpj.substring(0, pivot);
    const lastPart = rawCnpj.substring(pivot);

    return `${firstPart.substring(0, 2)}.${firstPart.substring(2, 5)}.${firstPart.substring(5)}/${lastPart.substring(0, 4)}.${lastPart.substring(4)}`;
}

function formatPhoneNumber (rawPhone) {
    if (!rawPhone) {
        return '(__) ____-____';
    }

    const pivot = rawPhone.length === 11 ? 7 : 6;
    const ddd = rawPhone.substring(0, 2);
    const firstPart = rawPhone.substring(2, pivot);
    const lastPart = rawPhone.substring(pivot);

    return `(${ddd}) ${firstPart}-${lastPart}`;
}

function extractDigits (value) {
    const digits = value && value.match(/\d/g);
    return digits && digits.join('');
}

function extractLowerLetters (value) {
    const digits = value && value.match(/[a-zA-Z]/g);
    return digits ? digits.join('').toLowerCase() : '';
}

function contains (value, query) {
    return isEmpty(query) || normalize(value).includes(normalize(query));
}

function formatTextVariable(str, ...args) {
    for(var i=0; i<args.length; ++i) {
        const varReplace = `#vr${i}#`
        str = str.replace(varReplace, args[i])
    }

    return str
}

export const StringService = {
    sortNormalize,
    normalize,
    formatCnpj,
    formatPhoneNumber,
    formatTextVariable,
    extractDigits,
    extractLowerLetters,
    contains
};
