import React from 'react'
import { Dialog, DialogActions, DialogContent, TextField, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {
    Flex1150Div,
    Flex11Div,
    FlexColumnDiv,
    FlexDialogTitle,
    FlexDiv,
    PrimaryButton
} from '../components/components'
import { NonNegativeInput } from '../components/nonNegativeInput'
import { TagService } from './tagService'

const STAGES = {
    INPUTTING: 'INPUTTING',
    SUBMITTING: 'SUBMITTING'
}

export class TagQrCodeExportDialogButton extends React.Component {

    constructor (props) {
        super(props)

        this.state = {
            isDialogOpen: false,
            generateTagQty: 1,
            stage: STAGES.INPUTTING
        }
    }

    handleDialogOpen = () => {
        this.setState({
            isDialogOpen: true,
            generateTagQty: 1,
            stage: STAGES.INPUTTING
        })
    }

    closeDialog = () => this.setState({ isDialogOpen: false })

    handleSubmit = () => {
        const { updateLoggedUser, loggedUser } = this.props
        const { generateTagQty } = this.state

        TagService.exportQrCodeTag(loggedUser.companyId, generateTagQty).then(updateLoggedUser(generateTagQty))

        this.setState({stage: STAGES.SUBMITTING})
    }

    buildForm = () => {
        const { generateTagQty } = this.state
        const { currentLanguage, loggedUser } = this.props

        return (
            <React.Fragment>
                <DialogContent>
                    <FlexDiv>
                        <Flex1150Div>
                            <FlexColumnDiv>
                                <TextField
                                    required
                                    margin="normal"
                                    label="Quantidade"
                                    InputProps={{ inputComponent: NonNegativeInput }}
                                    value={generateTagQty}
                                    onChange={event => this.setState({ generateTagQty: event.target.value })}
                                />
                            </FlexColumnDiv>
                        </Flex1150Div>
                    </FlexDiv>
                </DialogContent>
                <DialogActions>
                    <Flex11Div></Flex11Div>
                    <Button onClick={this.closeDialog}>{currentLanguage.cancel}</Button>
                    <PrimaryButton onClick={this.handleSubmit}>Exportar</PrimaryButton>
                </DialogActions>
            </React.Fragment>
        )
    }

    buildSubmitting = () => {
        const { currentLanguage } = this.props

        return (
            <React.Fragment>
                <DialogContent>
                    <FlexDialogTitle disableTypography>
                        <Typography variant="h6">Gerando arquivo com os QR Code's, o download começará em breve, o tempo de espera é relativo a quantidade de QR Code's solicitados...</Typography>
                    </FlexDialogTitle>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeDialog}>{currentLanguage.close}</Button>
                </DialogActions>
            </React.Fragment>
        )
    }

    buildContent = () => {
        const { stage } = this.state

        switch (stage) {
            case STAGES.INPUTTING: return this.buildForm()
            case STAGES.SUBMITTING: return this.buildSubmitting()
            default: throw new Error(`unimplemented: ${stage}`)
        }
    };

    render () {
        const { isDialogOpen } = this.state
        const { loggedUser } = this.props        
        return (
            <React.Fragment>
                <PrimaryButton onClick={this.handleDialogOpen} style={{alignSelf: 'center'}}>Exportar Tags</PrimaryButton>
                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">
                    {this.buildContent()}
                </Dialog>
            </React.Fragment>
        );
    }
}