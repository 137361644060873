import React from 'react'
import Login from './login'
import ForgotPassword from './forgotPassword'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import BackgrounImage from './images/bg-factory.png'
import IconMob from './images/ic-mob.png'
import { FlexDiv } from '../../authenticated/components/components'

class PlanPage extends React.Component {

    state = {
        width: 0,
        height: 0
    }

    componentDidMount() {
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions = () => this.setState({ width: window.innerWidth, height: window.innerHeight })

    render() {
        const { onAuthentication } = this.props
        const { width } = this.state

        return (
            <div>
                <div style={{ background: `#464b4e url(${BackgrounImage}) no-repeat center`, height: '46rem' }}>
                    <FlexDiv style={{ justifyContent: 'flex-end', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                        <div>
                            <Login onAuthentication={onAuthentication} width={width} />
                            <ForgotPassword />
                        </div>
                    </FlexDiv>

                    {this.buildPlans()}
                </div>
            </div>
        )
    }

    buildPlans = () => {
        return (
            <FlexDiv style={{flexWrap: 'wrap', justifyContent: 'center', position: 'relative', padding: 0, marginTop: 70}}>
                <div style={{ padding: 30 }}>
                    <Card style={{ maxWidth: 250, borderRadius: 10}}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                height="140"
                                image={IconMob}
                                title="Contemplative Reptile"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                <Typography variant="h5" color="#000000" style={{fontWeight: 500, fontSize: '1.4rem', textAlign: 'center'}}>Plano Start</Typography>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    - Até 50 Equipamentos
                                    <br />
                                    <Typography variant="h5" style={{fontWeight: 500, color: '#dd7b19',  fontSize: '1.6rem', textAlign: 'center', marginTop: '2rem'}}>
                                        <FlexDiv style={{flexWrap: 'wrap', justifyContent: 'center', position: 'relative', padding: 0, marginTop: 70}}>
                                            R$359 <Typography style={{fontWeight: 500, color: '#606060',  fontSize: '0.6rem', textAlign: 'center', marginTop: '1rem'}}>/mês</Typography>
                                        </FlexDiv>
                                    </Typography>
                                    
                                    <br />
                                    <Typography variant="h5" style={{fontWeight: 500, color: '#0e5197',  fontSize: '1.0rem', textAlign: 'center', marginTop: '2rem'}}>SELECIONAR</Typography>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>

                <div style={{ padding: 30 }}>
                    <Card style={{ maxWidth: 250, borderRadius: 10}}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                height="140"
                                image={IconMob}
                                title="Contemplative Reptile"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                <Typography variant="h5" color="#000000" style={{fontWeight: 500, fontSize: '1.4rem', textAlign: 'center'}}>Plano Standard</Typography>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    - Até 100 Equipamentos
                                    <br />
                                    <Typography variant="h5" style={{fontWeight: 500, color: '#dd7b19',  fontSize: '1.6rem', textAlign: 'center', marginTop: '2rem'}}>
                                        <FlexDiv style={{flexWrap: 'wrap', justifyContent: 'center', position: 'relative', padding: 0, marginTop: 70}}>
                                            R$529 <Typography style={{fontWeight: 500, color: '#606060',  fontSize: '0.6rem', textAlign: 'center', marginTop: '1rem'}}>/mês</Typography>
                                        </FlexDiv>
                                    </Typography>
                                    <br />
                                    <Typography variant="h5" style={{fontWeight: 500, color: '#0e5197',  fontSize: '1.0rem', textAlign: 'center', marginTop: '2rem'}}>SELECIONAR</Typography>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>

                <div style={{ padding: 30 }}>
                    <Card style={{ maxWidth: 250, borderRadius: 10}}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                height="140"
                                image={IconMob}
                                title="Contemplative Reptile"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                <Typography variant="h5" color="#000000" style={{fontWeight: 500, fontSize: '1.4rem', textAlign: 'center'}}>Plano Premium</Typography>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    - Até 500 Equipamentos
                                    <br />
                                    <Typography variant="h5" style={{fontWeight: 500, color: '#dd7b19',  fontSize: '1.6rem', textAlign: 'center', marginTop: '2rem'}}>
                                        <FlexDiv style={{flexWrap: 'wrap', justifyContent: 'center', position: 'relative', padding: 0, marginTop: 70}}>
                                            R$1.049 <Typography style={{fontWeight: 500, color: '#606060',  fontSize: '0.6rem', textAlign: 'center', marginTop: '1rem'}}>/mês</Typography>
                                        </FlexDiv>
                                    </Typography>
                                    <br />
                                    <Typography variant="h5" style={{fontWeight: 500, color: '#0e5197',  fontSize: '1.0rem', textAlign: 'center', marginTop: '2rem'}}>SELECIONAR</Typography>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>

                <div style={{ padding: 30 }}>
                    <Card style={{ maxWidth: 250, borderRadius: 10}}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                height="140"
                                image={IconMob}
                                title="Contemplative Reptile"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                <Typography variant="h5" color="#000000" style={{fontWeight: 500, fontSize: '1.4rem', textAlign: 'center'}}>Plano Enterprise</Typography>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    - Até 1000 Equipamentos
                                    <br />
                                    <Typography variant="h5" style={{fontWeight: 500, color: '#dd7b19',  fontSize: '1.6rem', textAlign: 'center', marginTop: '2rem'}}>
                                        <FlexDiv style={{flexWrap: 'wrap', justifyContent: 'center', position: 'relative', padding: 0, marginTop: 70}}>
                                            R$1.439 <Typography style={{fontWeight: 500, color: '#606060',  fontSize: '0.6rem', textAlign: 'center', marginTop: '1rem'}}>/mês</Typography>
                                        </FlexDiv>
                                    </Typography>
                                    <br />
                                    <Typography variant="h5" style={{fontWeight: 500, color: '#0e5197',  fontSize: '1.0rem', textAlign: 'center', marginTop: '2rem'}}>SELECIONAR</Typography>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
            </FlexDiv>
        )
    }
}

export default PlanPage
