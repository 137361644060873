import { MaintenanceApi } from './maintenanceApi';

function downloadPdf (maintenanceId) {
    return MaintenanceApi.downloadPdf(maintenanceId);
}

function downloadExcel (maintenanceId) {
    return MaintenanceApi.downloadExcel(maintenanceId);
}

export const MaintenanceService = {
    downloadPdf,
    downloadExcel
}