import React from 'react';
import ReactDOM from 'react-dom';

import { HashRouter } from 'react-router-dom';

import moment from 'moment'
import 'moment/locale/pt-br'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { SnackbarProvider } from 'notistack'

import { App } from './app/app'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import { AxiosService } from './app/axiosService'
import { ThemeService } from './app/themeService'

// ========================================

document.body.style.minHeight = '100vh';

const theme = ThemeService.buildTheme();
const locale = 'pt-br';
moment.locale(locale);

AxiosService.initializeInterceptors();

// ========================================

ReactDOM.render(
    (
        <HashRouter>
            <MuiThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}  locale={locale} moment={moment}>
                <App />
            </MuiPickersUtilsProvider>
            </SnackbarProvider>
            </MuiThemeProvider>
        </HashRouter>
    ),
    document.getElementById('root')
);

