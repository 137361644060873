import axios from 'axios';

export const InspectionTypeApi = {
    list: () => axios.get('/web/inspection-types/'),
    listInspectionQuestions: (inspectionTypeId) => axios.get(`/web/inspection-types/${inspectionTypeId}/inspection-questions/`),
    listMaintenanceQuestions: (inspectionTypeId) => axios.get(`/web/inspection-types/${inspectionTypeId}/maintenance-questions/`),
    listInspectionTypeAttributes: (inspectionTypeId) => axios.get(`/web/inspection-types/${inspectionTypeId}/inspection-type-attribute/`),
    create: (inspectionTypeData) => axios.post(`/web/inspection-types/`, inspectionTypeData),
    update: (inspectionTypeId, inspectionTypeData) => axios.put(`/web/inspection-types/${inspectionTypeId}/`, inspectionTypeData),
    updateInspectionQuestions: (inspectionTypeId, inspectionQuestions) => axios.put(`/web/inspection-types/${inspectionTypeId}/inspection-questions/`, inspectionQuestions),
    updateMaintenanceQuestions: (inspectionTypeId, maintenanceQuestions) => axios.put(`/web/inspection-types/${inspectionTypeId}/maintenance-questions/`, maintenanceQuestions),
    updateInspectionTypeAttributes: (inspectionTypeId, inspectionTypeAttributes) => axios.put(`/web/inspection-types/${inspectionTypeId}/inspection-type-attribute/`, inspectionTypeAttributes)
};
