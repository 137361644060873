import React from 'react';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import { FlexBaselineDiv, FlexDiv } from '../../components/components';
import { getPriorityLabel, getStatusLabel } from '../utilsOS/getString';
import moment from 'moment';


export class OsDialogTabGeneral extends React.Component {

    buildAttribute = (inspectionTypeAttribute, index) => {
        return (
            <FlexBaselineDiv key={index}>
                <div>{inspectionTypeAttribute.attribute}: {inspectionTypeAttribute.answer}</div>
            </FlexBaselineDiv>
        )
    }


    render() {
        const { closeDialog, os, currentLanguage } = this.props
        const styleBold = { marginRight: '0.3rem' }

        return (
            <React.Fragment>
                <DialogContent style={{ height: 'calc(100vh - 20rem)', padding: '0rem' }}>
                    <FlexDiv style={{ padding: '1rem', flexDirection: 'column' }}>
                        <FlexDiv style={{ marginBottom: '1rem', }}>
                            <b style={styleBold}>{currentLanguage?.number_os}:</b>{os?.id}
                        </FlexDiv>
                        <FlexDiv style={{ marginBottom: '1rem', flexDirection: 'row' }}>
                            <div>
                                <b style={styleBold}>{currentLanguage?.start_date}:</b>{moment(os?.startDate).format('DD/MM/YYYY')}
                            </div>
                            <div style={{ margin: '0 1rem', borderLeft: '1px solid #ccc' }} />
                            <div>
                                <b style={styleBold}>{currentLanguage?.end_date}:</b>{moment(os?.endDate).format('DD/MM/YYYY')}
                            </div>
                        </FlexDiv>
                        <FlexDiv style={{ marginBottom: '1rem', flexDirection: 'column' }}>
                            <div>
                                <b style={styleBold}>{currentLanguage?.responsible_user}:</b>{os?.responsibleUserName}
                            </div>
                            <div>
                                <b style={styleBold}>{currentLanguage?.user_current}:</b>{os?.requestUserName}
                            </div>
                        </FlexDiv>
                        <FlexDiv style={{ marginBottom: '1rem', flexDirection: 'column' }}>
                            <div>
                                <b style={styleBold}>{currentLanguage?.priority}:</b>{getPriorityLabel(os?.priority, currentLanguage)}
                            </div>
                            <div>
                                <b style={styleBold}>{currentLanguage?.os_status}:</b>{getStatusLabel(os?.status, currentLanguage)}
                            </div>
                        </FlexDiv>
                        <FlexDiv style={{ marginBottom: '1rem', flexDirection: 'column' }}>
                            <div>
                                {/* <b style={styleBold}>{currentLanguage?.comments}:</b>{os?.observation} */}
                                <p align='justify' style={{ color: 'black' }}><b style={styleBold}>{currentLanguage?.comments}:</b>{os?.observation || currentLanguage.not_comments}</p>
                            </div>
                        </FlexDiv>
                    </FlexDiv>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <div>
                    </div>

                    <div>
                        <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                    </div>
                </DialogActions>
            </React.Fragment>
        )
    }

}
