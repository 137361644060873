
export class Paginator {
    static itemsPerPageOptions = [10, 100, 1000];

    constructor ({ items, pagedItems, currentPage, currentRowsPerPage, firstPage, lastPage, nextPage, previousPage, isFirstPage, isLastPage }) {
        this.items = items;
        this.pagedItems = pagedItems;
        this.currentPage = currentPage;
        this.currentRowsPerPage = currentRowsPerPage;

        this.firstPage = firstPage;
        this.lastPage = lastPage;
        this.nextPage = nextPage;
        this.previousPage = previousPage;
        this.isFirstPage = isFirstPage;
        this.isLastPage = isLastPage;

        Object.freeze(this);
    }

    static of = ({ items, currentPage, currentRowsPerPage }) => {
        const firstPage = 0;
        const lastPage = Math.max(0, Math.ceil(items?.length / currentRowsPerPage) - 1);

        const safeCurrentPage = Math.min(currentPage, lastPage);

        return new Paginator({
            items,
            currentRowsPerPage,
            firstPage,
            lastPage,
            nextPage: Math.min(safeCurrentPage + 1, lastPage),
            previousPage: Math.max(safeCurrentPage - 1, firstPage),
            isFirstPage: safeCurrentPage === firstPage,
            isLastPage: safeCurrentPage === lastPage,
            currentPage: safeCurrentPage,
            pagedItems: items?.slice(safeCurrentPage * currentRowsPerPage, (safeCurrentPage + 1) * currentRowsPerPage)
        });
    };

    static initial = items => Paginator.of({items, currentPage: 0, currentRowsPerPage: 10});
    static ofItems = (paginator, items) => Paginator.of({ ...paginator, items });
    static ofPage = (paginator, currentPage) => Paginator.of({ ...paginator, currentPage });
    static ofRowsPerPage = (paginator, currentRowsPerPage) => Paginator.of({ ...paginator, currentRowsPerPage });

}
