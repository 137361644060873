import React, { Component } from 'react';
import { DateTimeService } from '../utils/dateTimeService';
import Spinner from 'react-spinner-material'
import { Line } from 'react-chartjs-2'
import { HistoryService } from '../../app/historyService'

const dataChart = {
    labels: [],
    datasets: [
        {
            fill: false,
            lineTension: 0.2,
            label: 'Inspeções',
            backgroundColor: 'rgba(0,191,255,1)',
            borderColor: 'rgba(0,191,255,1)',
            borderJoinStyle: 'round',
            borderWidth: 3,
            pointBorderWidth: 3,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(199,230,242,1)',
            pointHoverBorderWidth: 5,
            hoverBackgroundColor: 'rgba(0,191,255,1)',
            data: []
        }
    ]
}

const options = {
    legend: {
        maintainAspectRatio: false,
        display: false
    },
    scales: {
        xAxes: [{
            gridLines: {
                display: false
            }
        }],
        yAxes: [{
            gridLines: {
                display: false
            }
        }],

    },
    onClick: (event, elements) => {
        if (elements[0] !== undefined) {
            const chart = elements[0]._chart;
            const element = chart.getElementAtEvent(event)[0];
            HistoryService.sendData('/inspections', { histogramData: element._index })
        }
    }
}

export class HistogramChart extends Component {


    render() {
        const { data: histogramData } = this.props;

        if (!histogramData) {
            return (<div style={{ position: 'relative', top: '45%', left: '45%' }}> <Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /> </div>)
        } else {
            dataChart.labels = []
            dataChart.datasets[0].data = []

            histogramData.xy.map(data => (
                dataChart.labels.push(DateTimeService.formatMonthYear(data.yearMonth))
            ))

            histogramData.xy.map(data => (
                dataChart.datasets[0].data.push(data.value)
            ))
        }

        return (
            <Line
                data={dataChart}
                width={50}
                height={10}
                options={options}
            />
        )
    }
}
