import React from 'react';

import { IconButton, TablePagination } from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { Paginator } from '../utils/paginator';
import { HighOrderComponent } from './highOrderComponent';

function TablePaginationActions(props) {
    const { paginator, onChangePage } = props;

    return (
        <React.Fragment>
            <IconButton disabled={paginator?.isFirstPage} onClick={event => onChangePage(paginator?.firstPage)}><FirstPageIcon /></IconButton>
            <IconButton disabled={paginator?.isFirstPage} onClick={event => onChangePage(paginator?.previousPage)}><KeyboardArrowLeft /></IconButton>
            <IconButton disabled={paginator?.isLastPage} onClick={event => onChangePage(paginator?.nextPage)}><KeyboardArrowRight /></IconButton>
            <IconButton disabled={paginator?.isLastPage} onClick={event => onChangePage(paginator.lastPage)}><LastPageIcon /></IconButton>
        </React.Fragment>
    );
}

export function TablePaginator(props) {
    const { paginator, onChangePage, onChangeRowsPerPage, colSpan } = props;

    return (
        <TablePagination
            SelectProps={{ native: true }}
            colSpan={colSpan}
            labelRowsPerPage="Items por página"
            rowsPerPageOptions={Paginator.itemsPerPageOptions}
            count={paginator?.items?.length}
            rowsPerPage={paginator?.currentRowsPerPage}
            page={paginator?.currentPage}
            onChangePage={page => onChangePage(Paginator?.ofPage(paginator, page))}
            onChangeRowsPerPage={event => onChangeRowsPerPage(parseInt(event.target.value))}
            ActionsComponent={HighOrderComponent.inject({ paginator })(TablePaginationActions)}
        />
    );
}
