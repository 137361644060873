import { OsApi } from './osApi';

function list () {
    return OsApi.list().then(response => response.data);
}

function userList () {
    return OsApi.userList().then(response => response.data);
}

function equipments () {
    return OsApi.equipmentsList().then(response => response.data);
}

function update (id, osData) {
    return OsApi.update(id, osData)
        .then(() => ({ id, updateDate: new Date().toISOString(), ...osData }))
}

function create (osDataSubmit) {
    return OsApi.create(osDataSubmit)
        .then(response => response.data)
        .then(id => ({ id, updateDate: new Date().toISOString(), ...osDataSubmit }))
}

export const OsService = {
    list,
    update,
    create,
    userList,
    equipments,
    delete: (userEmail) => OsApi.delete(userEmail).then(response => response.data)
};
