import React from 'react'
import {
    Typography
} from '@material-ui/core'
import {
    ContainerDiv,
    FlexDiv,
    Flex1150Div
} from '../components/components'
import { withSnackbar } from 'notistack'
import { TagQrCodeGenerateDialogButton } from './tagQrCodeGenerateDialogButton'
import { TagQrCodeExportDialogButton } from './tagQrCodeExportDialogButton'

class TagQrCodePage extends React.Component {

    constructor (props) {
        super(props)

        this.state = {
            generatedTagQty: null,
            loggedUser: {}
        }
    }

    componentDidMount() {
        const { loggedUser } = this.props

        this.setState({ loggedUser })
    }

    updateLoggedUser = (tagsGenerated) => {
        const { loggedUser } = this.props

        loggedUser.generated_tag_qty = parseInt(loggedUser.generated_tag_qty) + parseInt(tagsGenerated)
        
        this.setState({ loggedUser })
        
        /*LoggedUserService.getLoggedUser().then(loggedUser => {
            this.setState({ loggedUser })
        })*/
    }

    notify = message => () => this.props.enqueueSnackbar(message)

    render() {
        const { currentLanguage } = this.props
        const { loggedUser } = this.state

        return (
            <React.Fragment>
                <ContainerDiv>
                    <Typography variant="h5" style={{ fontWeight: 500, fontSize: '1.6rem', textAlign: 'center', marginTop: '1rem' }}>
                        {currentLanguage.generate_qr_code}
                    </Typography>
                    <FlexDiv>
                        <Flex1150Div>
                            <b>Quantidade de Tags QR Code geradas esse mês:</b> {loggedUser.generated_tag_qty}
                        </Flex1150Div>
                    </FlexDiv>

                    <br />

                    <TagQrCodeGenerateDialogButton updateLoggedUser={this.updateLoggedUser} loggedUser={loggedUser} currentLanguage={currentLanguage} />
                    {" "}
                    <TagQrCodeExportDialogButton updateLoggedUser={this.updateLoggedUser} loggedUser={loggedUser} currentLanguage={currentLanguage} />
                    
                </ContainerDiv>
                
            </React.Fragment>
            
        )
    }
}

export default withSnackbar(TagQrCodePage)
