import React from 'react';
import { Button, DialogActions, DialogContent, Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import { BodyTableCell, formatMainDescription, HeaderTableCell } from '../../components/components';
import { DateTimeService } from '../../utils/dateTimeService';

import Spinner from 'react-spinner-material'
import { getTypeLabel } from '../utilsOS/getString';
import { OsService } from '../osService';

export class OsDialogTabEquipments extends React.Component {

    buildSnapshotList = () => {
        const { currentLanguage, equipments } = this.props

        if (!equipments?.length || equipments?.length === 0) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={7}>{currentLanguage.empty_list}</BodyTableCell>
                </TableRow>
            )
        }

        return !!equipments && equipments?.length > 0 && equipments?.map(equip => (
            <TableRow key={equip?.id + Math.floor(Math.random() * Date.now())}>
                <BodyTableCell>{equip?.equipmentSerialNumber}</BodyTableCell>
                <BodyTableCell>{equip?.extraLabel}</BodyTableCell>
                <BodyTableCell>{equip?.product?.name}</BodyTableCell>
                <BodyTableCell>{equip?.product?.sku}</BodyTableCell>
                <BodyTableCell>{getTypeLabel(equip?.type, currentLanguage)}</BodyTableCell>
                <BodyTableCell align='center'>
                    {equip?.completionDate ? moment(equip?.completionDate).format('DD/MM/YYYY') : <b style={{ color: '#731820' }}>{currentLanguage?.status_pending}</b>}
                </BodyTableCell>
            </TableRow>
        ))

    }

    render() {
        const { closeDialog, snapshots, currentLanguage } = this.props

        return (
            <React.Fragment>
                <DialogContent style={{ height: 'calc(100vh - 20rem)', padding: '0rem' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <HeaderTableCell field="equipment_serial">
                                    {currentLanguage.equipment_serial}
                                </HeaderTableCell>
                                <HeaderTableCell field="extra_nome_equipment">
                                    {currentLanguage.extra_nome_equipment}
                                </HeaderTableCell>
                                <HeaderTableCell field="product_name">
                                    {currentLanguage.product_name}
                                </HeaderTableCell>
                                <HeaderTableCell field="sku">
                                    {currentLanguage.sku}
                                </HeaderTableCell>
                                <HeaderTableCell field="description">
                                    {currentLanguage.os_type}
                                </HeaderTableCell>
                                <HeaderTableCell field="description">
                                    {currentLanguage.completion_date_equipament}
                                </HeaderTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.buildSnapshotList(snapshots)}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <div>
                    </div>

                    <div>
                        <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                    </div>
                </DialogActions>
            </React.Fragment>
        )
    }

}
