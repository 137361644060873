import { InspectionTypeApi } from './inspectionTypeApi';

function list () {
    return InspectionTypeApi.list().then(response => response.data);
}

function listInspectionQuestions (inspectionTypeId) {
    if (!inspectionTypeId) {
        return Promise.resolve([]);
    }

    return InspectionTypeApi.listInspectionQuestions(inspectionTypeId).then(response => response.data);
}

function listMaintenanceQuestions (inspectionTypeId) {
    if (!inspectionTypeId) {
        return Promise.resolve([]);
    }

    return InspectionTypeApi.listMaintenanceQuestions(inspectionTypeId).then(response => response.data);
}

function listInspectionTypeAttributes (inspectionTypeId) {
    if (!inspectionTypeId) {
        return Promise.resolve([]);
    }

    return InspectionTypeApi.listInspectionTypeAttributes(inspectionTypeId).then(response => response.data);
}

function update (inspectionTypeId, inspectionTypeData, inspectionQuestions, maintenanceQuestions, categoryName, inspectionTypeAttributes) {
    return InspectionTypeApi.update(inspectionTypeId, inspectionTypeData)
        .then(() => InspectionTypeApi.updateInspectionQuestions(inspectionTypeId, inspectionQuestions))
        .then(() => InspectionTypeApi.updateMaintenanceQuestions(inspectionTypeId, maintenanceQuestions))
        .then(() => InspectionTypeApi.updateInspectionTypeAttributes(inspectionTypeId, inspectionTypeAttributes))
        .then(() => ({ id: inspectionTypeId, updateDate: new Date().toISOString(), ...inspectionTypeData }));
}

function create (inspectionTypeData, inspectionQuestions, maintenanceQuestions, categoryName, inspectionTypeAttributes) {
    return InspectionTypeApi.create(inspectionTypeData)
        .then(response => response.data)
        .then(inspectionTypeId => InspectionTypeApi.updateInspectionQuestions(inspectionTypeId, inspectionQuestions).then(() => inspectionTypeId))
        .then(inspectionTypeId => InspectionTypeApi.updateMaintenanceQuestions(inspectionTypeId, maintenanceQuestions).then(() => inspectionTypeId))
        .then(inspectionTypeId => InspectionTypeApi.updateInspectionTypeAttributes(inspectionTypeId, inspectionTypeAttributes).then(() => inspectionTypeId))
        .then(inspectionTypeId => ({ id: inspectionTypeId, updateDate: new Date().toISOString(), ...inspectionTypeData, categoryName }));
}

export const InspectionTypeService = { list, listInspectionQuestions, listMaintenanceQuestions, listInspectionTypeAttributes, update, create };
