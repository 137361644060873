import React from 'react';

import { Dialog, Tab, Tabs, IconButton, DialogActions, MenuItem, DialogContent, TextField, Select } from '@material-ui/core'
import { CenterTitleDiv, FlexBaselineDiv, PrimaryButton } from '../components/components'
import Button from '@material-ui/core/Button'
import QAIcon from './images/icon-qa.png'
import Spinner from 'react-spinner-material'
import { UniqueNonEmptyValidator } from '../utils/uniqueNonEmptyValidator'
import { CustomAnswerService } from '../customAnswer/customAnswerService'
import { Tooltip } from 'react-tippy'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import 'react-tippy/dist/tippy.css'

export class InspectionTypeCustomAnswerButton extends React.Component {

    constructor(props) {
        super(props)

        const { inspectionQuestion } = props

        this.state = {
            tabValue: 0,
            isDialogOpen: false,
            inspectionCustomAnswers: null,
            inspectionQuestion,
            buttonClicked: false
        }
    }

    handleDialogOpen = () => {
        const { inspectionQuestion } = this.props

        this.setState({
            tabValue: 0,
            isDialogOpen: true,
            inspectionCustomAnswers: [],
            inspectionQuestion
        })

        CustomAnswerService.listInspectionCustomAnswer(inspectionQuestion.id).then(inspectionCustomAnswers => this.setState({ inspectionCustomAnswers }))
    }

    closeDialog = () => this.setState({ isDialogOpen: false })

    handleSubmit = () => {
        const { inspectionQuestion } = this.props
        const { inspectionCustomAnswers } = this.state

        CustomAnswerService.updateInspectionCustomAnswer(inspectionQuestion.id, inspectionCustomAnswers)
            .then(this.setState({ buttonClicked: false }))
            .then(this.closeDialog())
    }

    updateState = (stateData) => this.setState(state => stateData)

    checkDoubleClick = () => {
        const { buttonClicked } = this.state

        if (!buttonClicked)
            this.handleSubmit()

        this.setState({ buttonClicked: true })
    }

    buildRow = (inspectionCustomAnswer, index) => {
        const { inspectionCustomAnswers } = this.state
        const { currentLanguage } = this.props

        const customAnswerValidator = UniqueNonEmptyValidator.ofNullable(inspectionCustomAnswers, 'answer')

        if (inspectionCustomAnswer.id === null) {
            return (
                <FlexBaselineDiv key={index}>
                    <TextField
                        required
                        margin="normal"
                        style={{ flex: '1 1 auto' }}
                        value={inspectionCustomAnswer.answer}
                        label={`${currentLanguage.custom_answer} #${index + 1}`}
                        error={!customAnswerValidator.isValid(inspectionCustomAnswer.answer)}
                        onChange={event => this.updateState({ inspectionCustomAnswers: inspectionCustomAnswers.map((answer, i) => i === index ? { ...answer, answer: event.target.value } : answer) })}
                    />
                    <Select
                        style={{ marginLeft: '1rem', width: '10rem' }}
                        inputProps={{ id: 'type-select' }}
                        value={inspectionCustomAnswer.type}
                        onChange={event => this.updateState({ inspectionCustomAnswers: inspectionCustomAnswers.map((answer, i) => i === index ? { ...answer, type: event.target.value } : answer) })}
                    >
                        <MenuItem value="POSITIVE">{currentLanguage.positive.toUpperCase()}</MenuItem>
                        <MenuItem value="NEGATIVE">{currentLanguage.negative.toUpperCase()}</MenuItem>
                        <MenuItem value="NA">{currentLanguage.na.toUpperCase()}</MenuItem>
                    </Select>
                    <Tooltip
                        title={currentLanguage.inspection_customQuestion_Tippy}
                        arrow={true}
                        arrowSize={'small'}
                        style={{ position: "relative", top: "-10px" }}>
                        <InfoOutlinedIcon style={{ fontSize: "small" }} />
                    </Tooltip>

                    <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={event => this.updateState({ inspectionCustomAnswers: inspectionCustomAnswers.filter((answer, i) => i !== index) })}>{currentLanguage.remove}</Button>
                </FlexBaselineDiv>
            )
        }

        if (inspectionCustomAnswer.enabled === false) {
            return (
                <FlexBaselineDiv key={index}>
                    <TextField
                        disabled
                        margin="normal"
                        style={{ flex: '1 1 auto' }}
                        value={inspectionCustomAnswer.answer}
                        label={`${currentLanguage.custom_answer} #${index + 1}`}
                        error={!customAnswerValidator.isValid(inspectionCustomAnswer.answer)}
                        onChange={event => this.updateState({ inspectionCustomAnswers: inspectionCustomAnswers.map((answer, i) => i === index ? { ...answer, answer: event.target.value } : answer) })}
                    />
                    <Select
                        disabled
                        style={{ marginLeft: '1rem', width: '10rem' }}
                        inputProps={{ id: 'type-select' }}
                        value={inspectionCustomAnswer.type}
                        onChange={event => this.updateState({ inspectionCustomAnswers: inspectionCustomAnswers.map((answer, i) => i === index ? { ...answer, type: event.target.value } : answer) })}
                    >
                        <MenuItem value="POSITIVE">{currentLanguage.positive.toUpperCase()}</MenuItem>
                        <MenuItem value="NEGATIVE">{currentLanguage.negative.toUpperCase()}</MenuItem>
                        <MenuItem value="NA">{currentLanguage.na.toUpperCase()}</MenuItem>
                    </Select>
                    <Tooltip
                        title={currentLanguage.inspection_customQuestion_Tippy}
                        arrow={true}
                        arrowSize={'small'}
                        style={{ position: "relative", top: "-10px" }}>
                        <InfoOutlinedIcon style={{ fontSize: "small" }} />
                    </Tooltip>
                    <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={event => this.updateState({ inspectionCustomAnswers: inspectionCustomAnswers.map((answer, i) => i === index ? { ...answer, enabled: true } : answer) })}>{currentLanguage.able}</Button>
                </FlexBaselineDiv>
            )
        }

        return (
            <FlexBaselineDiv key={index}>
                <TextField
                    required
                    margin="normal"
                    style={{ flex: '1 1 auto' }}
                    value={inspectionCustomAnswer.answer}
                    label={`${currentLanguage.custom_answer} #${index + 1}`}
                    error={!customAnswerValidator.isValid(inspectionCustomAnswer.answer)}
                    onChange={event => this.updateState({ inspectionCustomAnswers: inspectionCustomAnswers.map((answer, i) => i === index ? { ...answer, answer: event.target.value } : answer) })}
                />
                <Select
                    style={{ marginLeft: '1rem', width: '10rem' }}
                    inputProps={{ id: 'type-select' }}
                    value={inspectionCustomAnswer.type}
                    onChange={event => this.updateState({ inspectionCustomAnswers: inspectionCustomAnswers.map((answer, i) => i === index ? { ...answer, type: event.target.value } : answer) })}
                >
                    <MenuItem value="POSITIVE">{currentLanguage.positive.toUpperCase()}</MenuItem>
                    <MenuItem value="NEGATIVE">{currentLanguage.negative.toUpperCase()}</MenuItem>
                    <MenuItem value="NA">{currentLanguage.na.toUpperCase()}</MenuItem>
                </Select>

                <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={event => this.updateState({ inspectionCustomAnswers: inspectionCustomAnswers.map((answer, i) => i === index ? { ...answer, enabled: false } : answer) })}>{currentLanguage.disable}</Button>
            </FlexBaselineDiv>
        )
    }

    buildList = () => {
        const {
            inspectionQuestion,
            currentLanguage
        } = this.props

        const {
            inspectionCustomAnswers
        } = this.state

        const emptyInspectionQuestion = { id: null, inspectionQuestionId: inspectionQuestion.id, answer: '', type: 'POSITIVE', enabled: true }

        const addInspectionQuestion = () => {
            if (inspectionCustomAnswers.length < 5)
                this.updateState({ inspectionCustomAnswers: [...inspectionCustomAnswers, emptyInspectionQuestion] })
        }

        if (inspectionCustomAnswers === null) {
            return (
                <React.Fragment>
                    <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                        <CenterTitleDiv><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></CenterTitleDiv>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <div />

                        <div>
                            <Button onClick={this.closeDialog}>{currentLanguage.close}</Button>
                            <PrimaryButton onClick={this.checkDoubleClick} disabled={true}>{currentLanguage.update}</PrimaryButton>
                        </div>
                    </DialogActions>
                </React.Fragment>
            )
        }

        if (inspectionCustomAnswers.length === 0) {
            return (
                <React.Fragment>
                    <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                        <CenterTitleDiv>{currentLanguage.empty_list}</CenterTitleDiv>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <div>
                            <PrimaryButton disabled={inspectionCustomAnswers.length > 4} onClick={addInspectionQuestion}>{currentLanguage.add_custom_answer}</PrimaryButton>
                        </div>

                        <div>
                            <Button onClick={this.closeDialog}>{currentLanguage.close}</Button>
                            <PrimaryButton onClick={this.checkDoubleClick} disabled={true}>{currentLanguage.update}</PrimaryButton>
                        </div>
                    </DialogActions>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                    {inspectionCustomAnswers.map(this.buildRow)}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <div>
                        <PrimaryButton disabled={inspectionCustomAnswers.length > 4} onClick={addInspectionQuestion}>{currentLanguage.add_custom_answer}</PrimaryButton>
                    </div>

                    <div>
                        <Button onClick={this.closeDialog}>{currentLanguage.close}</Button>
                        <PrimaryButton onClick={this.checkDoubleClick} disabled={false}>{currentLanguage.update}</PrimaryButton>
                    </div>
                </DialogActions>
            </React.Fragment>
        )
    }

    buildContent = () => {
        const { tabValue } = this.state
        const { currentLanguage } = this.props

        return (
            <React.Fragment>
                <React.Fragment>
                    <DialogContent>
                        <Tabs value={tabValue} onChange={(event, tabValue) => this.setState({ tabValue })}>
                            <Tab style={{ color: 'white' }} label={currentLanguage.custom_answer} />
                        </Tabs>

                        {this.buildList()}

                    </DialogContent>
                </React.Fragment>
            </React.Fragment>
        )
    }

    render() {
        const { visibility } = this.props
        const { isDialogOpen } = this.state

        return (
            <React.Fragment>
                <IconButton
                    onClick={this.handleDialogOpen}
                    style={{ alignSelf: 'center', marginTop: '1.5rem', visibility: visibility }}>

                    <img src={QAIcon} alt="search" />
                </IconButton>

                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">
                    {this.buildContent()}
                </Dialog>
            </React.Fragment>
        )
    }
}
