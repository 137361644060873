import React from 'react'
import { Button, DialogActions, DialogContent, MenuItem, Select, TextField } from '@material-ui/core'
import { CenterTitleDiv, FlexBaselineDiv, PrimaryButton } from '../components/components'
import Spinner from 'react-spinner-material'
import { InspectionTypeCustomAnswerButton } from './inspectionTypeCustomAnswerButton'
import { Tooltip } from 'react-tippy'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import 'react-tippy/dist/tippy.css'

export class InspectionTypeDialogTabInspectionQuestions extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            buttonClicked: false
        }
    }

    buildRow = (inspectionQuestion, index) => {
        const {
            inspectionQuestions,
            inspectionQuestionsValidator,
            updateState,
            allValidAndNonEmpty,
            currentLanguage
        } = this.props

        if (inspectionQuestion.id === null) {
            return (
                <FlexBaselineDiv key={index}>
                    <TextField
                        required
                        margin="normal"
                        style={{ flex: '1 1 auto' }}
                        value={inspectionQuestion.question}
                        label={`${currentLanguage.inspection_question} #${index + 1}`}
                        error={!inspectionQuestionsValidator.isValid(inspectionQuestion.question)}
                        onChange={event => updateState({ inspectionQuestions: inspectionQuestions.map((question, i) => i === index ? { ...question, question: event.target.value } : question) })}
                    />
                    <Select
                        style={{ marginLeft: '1rem', width: '10rem' }}
                        inputProps={{ id: 'type-select' }}
                        value={inspectionQuestion.type}
                        onChange={event => updateState({ inspectionQuestions: inspectionQuestions.map((question, i) => i === index ? { ...question, type: event.target.value } : question) })}
                    >
                        <MenuItem value="YES_NO_NA">{currentLanguage.yes}/{currentLanguage.no}/{currentLanguage.na.replace('/', '')}</MenuItem>
                        <MenuItem value="TEXT">{currentLanguage.text}</MenuItem>
                        <MenuItem value="CUSTOM">{currentLanguage.custom}</MenuItem>
                    </Select>
                    <Tooltip
                        title={currentLanguage.inspection_question_Tippy}
                        arrow={true}
                        arrowSize={'small'}
                        style={{ position: "relative", top: "-10px" }}>
                        <InfoOutlinedIcon style={{ fontSize: "small" }} />
                    </Tooltip>

                    <InspectionTypeCustomAnswerButton
                        currentLanguage={currentLanguage}
                        visibility={'hidden'}
                        submitLabel={currentLanguage.update_subcategory}
                        inspectionQuestion={inspectionQuestion}
                        inspectionQuestionsValidator={inspectionQuestionsValidator}
                        allValidAndNonEmpty={allValidAndNonEmpty}
                    />

                    <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={event => updateState({ inspectionQuestions: inspectionQuestions.filter((question, i) => i !== index) })}>{currentLanguage.remove}</Button>
                </FlexBaselineDiv>
            )
        }

        if (inspectionQuestion.enabled === false) {
            return (
                <FlexBaselineDiv key={index}>
                    <TextField
                        disabled
                        margin="normal"
                        style={{ flex: '1 1 auto' }}
                        value={inspectionQuestion.question}
                        label={`${currentLanguage.inspection_question} #${index + 1}`}
                        error={!inspectionQuestionsValidator.isValid(inspectionQuestion.question)}
                        onChange={event => updateState({ inspectionQuestions: inspectionQuestions.map((question, i) => i === index ? { ...question, question: event.target.value } : question) })}
                    />
                    <Select
                        disabled
                        style={{ marginLeft: '1rem', width: '10rem' }}
                        inputProps={{ id: 'type-select' }}
                        value={inspectionQuestion.type}
                        onChange={event => updateState({ inspectionQuestions: inspectionQuestions.map((question, i) => i === index ? { ...question, type: event.target.value } : question) })}
                    >
                        <MenuItem value="YES_NO_NA">{currentLanguage.yes}/{currentLanguage.no}/{currentLanguage.na.replace('/', '')}</MenuItem>
                        <MenuItem value="TEXT">{currentLanguage.text}</MenuItem>
                        <MenuItem value="CUSTOM">{currentLanguage.custom}</MenuItem>
                    </Select>
                    <Tooltip
                        title={currentLanguage.inspection_question_Tippy}
                        arrow={true}
                        arrowSize={'small'}
                        style={{ position: "relative", top: "-10px" }}>
                        <InfoOutlinedIcon style={{ fontSize: "small" }} />
                    </Tooltip>

                    <InspectionTypeCustomAnswerButton
                        currentLanguage={currentLanguage}
                        visibility={'hidden'}
                        submitLabel={currentLanguage.custom_subcategory}
                        inspectionQuestion={inspectionQuestion}
                        inspectionQuestionsValidator={inspectionQuestionsValidator}
                        allValidAndNonEmpty={allValidAndNonEmpty}
                    />

                    <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={event => updateState({ inspectionQuestions: inspectionQuestions.map((question, i) => i === index ? { ...question, enabled: true } : question) })}>{currentLanguage.able}</Button>
                </FlexBaselineDiv>
            )
        }

        return (
            <FlexBaselineDiv key={index}>
                <TextField
                    required
                    margin="normal"
                    style={{ flex: '1 1 auto' }}
                    value={inspectionQuestion.question}
                    label={`${currentLanguage.inspection_question} #${index + 1}`}
                    error={!inspectionQuestionsValidator.isValid(inspectionQuestion.question)}
                    onChange={event => updateState({ inspectionQuestions: inspectionQuestions.map((question, i) => i === index ? { ...question, question: event.target.value } : question) })}
                />
                <Select
                    style={{ marginLeft: '1rem', width: '10rem' }}
                    inputProps={{ id: 'type-select' }}
                    value={inspectionQuestion.type}
                    onChange={event => updateState({ inspectionQuestions: inspectionQuestions.map((question, i) => i === index ? { ...question, type: event.target.value } : question) })}
                >
                    <MenuItem value="YES_NO_NA">{currentLanguage.yes}/{currentLanguage.no}/{currentLanguage.na.replace('/', '')}</MenuItem>
                    <MenuItem value="TEXT">{currentLanguage.text}</MenuItem>
                    <MenuItem value="CUSTOM">{currentLanguage.custom}</MenuItem>
                </Select>
                <Tooltip
                    title={currentLanguage.inspection_question_Tippy}
                    arrow={true}
                    arrowSize={'small'}
                    style={{ position: "relative", top: "-10px" }}>
                    <InfoOutlinedIcon style={{ fontSize: "small" }} />
                </Tooltip>

                <InspectionTypeCustomAnswerButton
                    currentLanguage={currentLanguage}
                    visibility={inspectionQuestion.type === 'CUSTOM' ? 'visible' : 'hidden'}
                    submitLabel={currentLanguage.update_subcategory}
                    inspectionQuestion={inspectionQuestion}
                    inspectionQuestionsValidator={inspectionQuestionsValidator}
                    allValidAndNonEmpty={allValidAndNonEmpty}
                />

                <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={event => updateState({ inspectionQuestions: inspectionQuestions.map((question, i) => i === index ? { ...question, enabled: false } : question) })}>{currentLanguage.disable}</Button>
            </FlexBaselineDiv>
        )
    }

    checkDoubleClick = () => {
        const { handleSubmit } = this.props
        const { buttonClicked } = this.state

        if (!buttonClicked)
            handleSubmit()

        this.setState({ buttonClicked: true })
    }

    render() {
        const {
            submitLabel,
            inspectionQuestions,
            allValidAndNonEmpty,
            closeDialog,
            updateState,
            currentLanguage
        } = this.props

        const emptyInspectionQuestion = { id: null, type: 'YES_NO_NA', question: '', enabled: true }

        const addInspectionQuestion = () => updateState({ inspectionQuestions: [...inspectionQuestions, emptyInspectionQuestion] });

        if (inspectionQuestions === null) {
            return (
                <React.Fragment>
                    <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                        <CenterTitleDiv><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></CenterTitleDiv>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <div />

                        <div>
                            <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                            <PrimaryButton onClick={this.checkDoubleClick} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                        </div>
                    </DialogActions>
                </React.Fragment>
            );
        }

        if (inspectionQuestions.length === 0) {
            return (
                <React.Fragment>
                    <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                        <CenterTitleDiv>Lista vazia</CenterTitleDiv>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <div>
                            <PrimaryButton onClick={addInspectionQuestion}>{currentLanguage.add_inspection_question}</PrimaryButton>
                        </div>

                        <div>
                            <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                            <PrimaryButton onClick={this.checkDoubleClick} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                        </div>
                    </DialogActions>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                    {inspectionQuestions.map(this.buildRow)}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <div>
                        <PrimaryButton onClick={addInspectionQuestion}>{currentLanguage.add_inspection_question}</PrimaryButton>
                    </div>

                    <div>
                        <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                        <PrimaryButton onClick={this.checkDoubleClick} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                    </div>
                </DialogActions>
            </React.Fragment>
        );
    }

}
