import React, { Component } from 'react'
import Spinner from 'react-spinner-material'
import { Bar } from 'react-chartjs-2'
import { HistoryService } from '../../app/historyService'


const dataChart = {
    labels: [],
    Skus: [],
    datasets: [
        {
            label: '',
            data: [],
            backgroundColor: 'rgb(54, 162, 235)',
        },
        {
            label: '',
            data: [],
            backgroundColor: 'rgb(255, 99, 132)',
        }
    ]
}

const options = {
    legend: {
        maintainAspectRatio: false,
        display: false
    },
    scales: {
        xAxes: [{
            stacked: true,
            display: false,
            gridLines: {
                display: false
            },
            ticks: {
                beginAtZero: true
            }
        }],
        yAxes: [{
            stacked: true,
            gridLines: {
                display: false
            },
            ticks: {
                beginAtZero: true
            }
        }]
    },
    onClick: (event, elements) => {
        if (elements[0] !== undefined) {
            const chart = elements[0]._chart;
            const element = chart.getElementAtEvent(event)[0];
            const xLabel = chart.data.labels[element._index];
            const xLabelStatus = chart.data.datasets[element._datasetIndex].label
            HistoryService.sendData('/inspections', { categoryData: xLabel, categoryDataStatus: xLabelStatus })
        }
    }
}

export class CategoryChart extends Component {
    state = {
        data: null
    }

    render() {
        const { data: categoryData, language: currentLanguage} = this.props
        
        if (!categoryData) {
            return (<div style={{ position: 'relative', top: '45%', left: '45%' }}> <Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /> </div>);
        } else {
            dataChart.labels = []
            dataChart.datasets[0].data = []
            dataChart.Skus = []

            categoryData.map(data => {
                if (!dataChart.labels.includes(data.name)) {
                    dataChart.labels.push(data.name)
                    dataChart.Skus.push('sku')
                }

                if (data.answer === 'NO') {
                    dataChart.datasets[1].data.push(data.qtt)
                    dataChart.datasets[1].label = currentLanguage.improper
                } else {
                    dataChart.datasets[0].data.push(data.qtt)
                    dataChart.datasets[0].label = currentLanguage.valid
                }


            })


        }

        return (
            <Bar
                data={dataChart}
                width={50}
                height={20}
                options={options}
            />
        )
    }
}
