import React from 'react'
import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core'

import {
    Flex1150Div,
    Flex11Div,
    FlexColumnDiv, FlexDiv,
    FlexSpaceBetweenDiv,
    PrimaryButton
} from '../../authenticated/components/components'

import { PasswordValidator } from '../../authenticated/loggedUser/passwordValidator'
import { RegisterUserService } from './registerUserService'
import { AnonymousHeader } from '../components/anonymousHeader'
import { RegisterUserValidator } from './registerUserValidator'
import { PasswordRequirements } from '../../authenticated/components/passwordRequirements'
import { StringService } from '../../authenticated/utils/stringService'
import Footer from '../../components/footer'
import { PhoneInput } from '../../authenticated/components/phoneInput'
import { HistoryService } from '../../app/historyService'
import { withSnackbar } from 'notistack'
import { CnpjInput } from '../../authenticated/components/cnpjInput'
import { CepInput } from '../../authenticated/components/cepInput'
import * as queryString from 'query-string'
import { MyPlanService } from '../../authenticated/myPlan/myPlanService'
import LoadingScreen from 'react-loading-screen'
import cep from 'cep-promise'
import * as cnpj from "@fnando/cnpj"
import LOGO from '../../anonymous/landpage/images/logo-white.png'

const STAGES = {
    INPUTTING: 'INPUTTING',
    SUBMITTING: 'SUBMITTING',
    DONE: 'DONE'
};

class RegisterUserPage extends React.Component {
    state = {
        cnpjCompany: '__.___.___/____.__',
        name: '',
        fantasyName: '',
        address: '',
        state: '',
        city: '',
        neighborhood: '',
        cep: '_____-___',
        complement: '',
        email: '',
        phone: '(__) ____-____',
        occupation: '',
        company: '',
        password: '',
        verification: '',
        usageTerms: '',
        usageTermsAccepted: '',
        stage: STAGES.INPUTTING,
        selectedPlanId: 0,
        equipmentQtt: 0,
        productIdVindi: 0,
        planIdVindi: 0,
        paymentMethod: 'bank_slip'
    }

    componentDidMount() {
        RegisterUserService.getUsageTerms().then(usageTerms => this.setState({ usageTerms }))

        const { selectedPlanId } = queryString.parse('?' + window.location.hash.split('?')[1])
        const { equipmentQtt } = queryString.parse('?' + window.location.hash.split('?')[1])
        const { productIdVindi } = queryString.parse('?' + window.location.hash.split('?')[1])
        const { planIdVindi } = queryString.parse('?' + window.location.hash.split('?')[1])

        this.setState({ selectedPlanId, equipmentQtt, productIdVindi, planIdVindi })
    }

    handleValueChange = field => event => this.setState({ [field]: event.target.value })
    handleDigitsChange = field => event => this.setState({ [field]: StringService.extractDigits(event.target.value) || '(__) ____-____' })
    handleCnpjChange = field => event => this.setState({ [field]: StringService.extractDigits(event.target.value) || '__.___.___/____.__' })
    handleCepChange = field => event => {
        var cepText = event.target.value.replace('_', '').replace('-', '')

        if (cepText.length === 8) {
            cep(cepText).then(res => this.setState({
                state: res.state,
                city: res.city,
                address: res.street,
                neighborhood: res.neighborhood
            }))
        }

        this.setState({ [field]: StringService.extractDigits(event.target.value) || '_____-___' })
    }
    handleCheckChange = field => event => this.setState({ [field]: event.target.checked })

    handleRegisterFailure = (error) => {
        const { enqueueSnackbar } = this.props
        const { email } = this.state

        switch (error.response.data) {
            case 'USER_REGISTER_EMAIL_EXISTS': enqueueSnackbar(`Email ${email} já existe`, { variant: 'error' }); break;
            case 'USER_REGISTER_EMAIL_INVALID': enqueueSnackbar(`Email ${email} é inválido`, { variant: 'error' }); break;
            default: break
        }

        this.setState({ stage: STAGES.INPUTTING })
    }

    handleSubmit = event => {
        const { name, email, phone, occupation, cnpjCompany, company, fantasyName, address, complement, state, city, neighborhood, cep, password } = this.state

        this.setState({ stage: STAGES.SUBMITTING })

        RegisterUserService.register({ name, email, phone, occupation, cnpjCompany, company, fantasyName, address, complement, state, city, neighborhood, cep, password })
            .then((response) => {
                try {
                    const { selectedPlanId, paymentMethod, planIdVindi, productIdVindi, equipmentQtt } = this.state

                    if (selectedPlanId === undefined || selectedPlanId === null || selectedPlanId === 5) {
                        this.setState({ stage: STAGES.DONE })
                    } else {

                        var subscriptionData = {
                            plan_id: planIdVindi,
                            customer_id: response.vindi_id,
                            payment_method_code: paymentMethod,
                            product_items: [
                                {
                                    "product_id": productIdVindi,
                                    "quantity": selectedPlanId === 4 ? equipmentQtt : 1
                                }
                            ]
                        }

                        MyPlanService.createSubscription(subscriptionData).then(res => {
                            var body = JSON.parse(res.body)

                            window.open(body.bill.url, '_blank')

                            this.setState({ stage: STAGES.DONE })
                        })

                    }
                } catch (e) {
                    console.log(e)
                }
            })
            .catch(this.handleRegisterFailure)
    }

    buildRegisterButton = allValid => {
        const { stage, selectedPlanId } = this.state

        switch (stage) {
            case STAGES.INPUTTING: return (<PrimaryButton style={{ width: '8rem' }} onClick={this.handleSubmit} disabled={!allValid}>{(selectedPlanId === undefined || selectedPlanId === null || selectedPlanId === 5) ? "Registrar" : "Pagar"}</PrimaryButton>)
            case STAGES.SUBMITTING: return (<PrimaryButton style={{ width: '8rem' }} disabled={true}>Registrando...</PrimaryButton>)
            default: return (<span />)
        }

    }

    buildLoadingContent = () => {
        return (
            <LoadingScreen
                loading={true}
                bgColor='#000000'
                spinnerColor='#FFFFFF'
                textColor='#FFFFFF'
                text="Realizando seu cadastro"
                logoSrc={LOGO}
            >

            </LoadingScreen>
        )
    }

    handlePaymentMethodChange = (paymentMethodSelected) => {
        this.setState({ paymentMethod: paymentMethodSelected })
    }

    handleCnpjError = (cnpjCompany) => {

        console.log(cnpj.isValid(cnpjCompany))

        return !cnpj.isValid(cnpjCompany)
    }

    buildUserRegisterForm = () => {
        const {
            name,
            email,
            phone,
            occupation,
            cnpjCompany,
            company,
            fantasyName,
            address,
            complement,
            state,
            city,
            neighborhood,
            cep,
            password,
            verification,
            usageTerms,
            usageTermsAccepted
        } = this.state

        const passwordValidator = PasswordValidator.validatePasswordCreate(password, verification);
        const registerUserValidator = RegisterUserValidator.of(name, email, phone, occupation, company, passwordValidator, usageTermsAccepted);

        return (
            <React.Fragment>
                <div style={{ textAlign: 'center' }}>
                    <br />
                    <b>Preencha os dados abaixo para seguir com sua assinatura</b>
                    <br />
                    <br />
                </div>

                <div style={{ textAlign: 'left', marginLeft: '30px', fontSize: '12px' }}>
                    <br />
                    <b>Informações Importantes:</b>
                    <br />- Você pode cancelar o seu plano a qualquer momento.
                    <br />- Não há custo ou multas para cancelar o plano.
                    <br />- O plano freemium não possui nenhum custo.
                    <br />- Os planos são cobrados mensalmente, à partir do dia do pagamento da primeira mensalidade.
                    <br />
                    <br />
                </div>
                <FlexSpaceBetweenDiv style={{ flexWrap: 'wrap' }}>
                    <Flex1150Div style={{ minWidth: '16rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
                        <FlexColumnDiv>
                            <div style={{ textAlign: 'center' }}>
                                Dados do responsável
                            </div>
                            <TextField margin="normal" required onChange={this.handleValueChange('name')} value={name} label="Nome" />
                            <TextField margin="normal" required onChange={this.handleValueChange('email')} value={email} label="Email" error={!!email && !registerUserValidator.validEmail} />
                            <TextField margin="normal" required onChange={this.handleDigitsChange('phone')} value={phone} label="Telefone" InputProps={{ inputComponent: PhoneInput }} />
                            <TextField margin="normal" required onChange={this.handleValueChange('occupation')} value={occupation} label="Cargo" />
                            <br />
                            <br />
                            <TextField margin="normal" type="password" required onChange={this.handleValueChange('password')} value={password} label="Senha" />
                            <TextField margin="normal" type="password" required onChange={this.handleValueChange('verification')} value={verification} label="Confirmar senha" error={passwordValidator.samePassword === false} />
                            <PasswordRequirements validator={passwordValidator} />
                        </FlexColumnDiv>
                    </Flex1150Div>
                    <Flex1150Div style={{ minWidth: '16rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
                        <FlexColumnDiv>
                            <div style={{ textAlign: 'center' }}>
                                Dados da empresa
                            </div>
                            <TextField margin="normal" required onChange={this.handleCnpjChange('cnpjCompany')} error={this.handleCnpjError(cnpjCompany)} value={cnpjCompany} label="CNPJ" InputProps={{ inputComponent: CnpjInput }} />
                            <TextField margin="normal" required onChange={this.handleValueChange('company')} value={company} label="Nome da Empresa" />
                            <TextField margin="normal" required onChange={this.handleValueChange('fantasyName')} value={fantasyName} label="Nome Fantasia da Empresa" />
                            <TextField margin="normal" required onChange={this.handleCepChange('cep')} value={cep} label="CEP" InputProps={{ inputComponent: CepInput }} />
                            <TextField margin="normal" required onChange={this.handleValueChange('address')} value={address} label="Endereço" />
                            <TextField margin="normal" onChange={this.handleValueChange('complement')} value={complement} label="Complemento" />
                            <TextField margin="normal" required onChange={this.handleValueChange('state')} maxLength="2" value={state} label="Estado" />
                            <TextField margin="normal" required onChange={this.handleValueChange('city')} value={city} label="Cidade" />
                            <TextField margin="normal" required onChange={this.handleValueChange('neighborhood')} value={neighborhood} label="Bairro" />
                        </FlexColumnDiv>
                    </Flex1150Div>
                </FlexSpaceBetweenDiv>

                <div style={{ padding: '2rem 2rem 0rem 2rem' }}>
                    <pre style={{ whiteSpace: 'pre-wrap', width: '100%', height: '10rem', padding: '1rem', border: '1px solid gray' }}>{usageTerms}</pre>
                    <FormControlLabel margin="normal" label="Aceito os termos" control={<Checkbox checked={usageTermsAccepted} onChange={this.handleCheckChange('usageTermsAccepted')} />} />
                </div>

                <div style={{ padding: '0rem 2rem 2rem 2rem', textAlign: 'right' }}>
                    <Button color="inherit" onClick={() => HistoryService.goto('/')} style={{ marginRight: '1rem' }}>Cancelar</Button>
                    {this.buildRegisterButton(registerUserValidator.allValid && cnpj.isValid(cnpjCompany))}
                </div>
            </React.Fragment>
        )
    }

    buildDone = () => {
        const { name } = this.state

        return (
            <React.Fragment>
                <div style={{ padding: '2rem', fontSize: '1.2rem' }}>Bem vindo, {name}!</div>
                <div style={{ padding: '2rem', marginTop: '1rem' }}>
                    Seu cadastro foi realizado com sucesso!
                    <br />

                </div>
                <FlexDiv style={{ justifyContent: 'center' }}>
                    <Button color="primary" variant="outlined" onClick={() => HistoryService.goto('/#/')}>Ir para página inicial</Button>
                </FlexDiv>
            </React.Fragment>
        )
    }

    buildContent = () => {
        const { stage } = this.state

        switch (stage) {
            case STAGES.INPUTTING: return this.buildUserRegisterForm()
            case STAGES.SUBMITTING: return this.buildLoadingContent()
            case STAGES.DONE: return this.buildDone()
            default: throw new Error(`unimplemented: ${stage}`)
        }
    }

    render = () => {
        return (
            <FlexColumnDiv style={{ minHeight: '100vh' }}>

                <div style={{ background: "#005d7b" }}>
                    <AnonymousHeader />
                </div>

                <Flex11Div>
                    {this.buildContent()}
                </Flex11Div>

                <Footer />
            </FlexColumnDiv>
        )
    }
}

export default withSnackbar(RegisterUserPage)
