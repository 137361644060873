import React from 'react';
import { Dialog, DialogActions, DialogContent, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {
    Flex1150Div,
    Flex11Div,
    FlexColumnDiv,
    FlexDialogTitle,
    FlexDiv,
    PrimaryButton
} from '../components/components';
import { PhoneInput } from '../components/phoneInput';
import { StringService } from '../utils/stringService';
import { CnpjInput } from '../components/cnpjInput';
import { NonNegativeInput } from '../components/nonNegativeInput';
import InputAdornment from '@material-ui/core/InputAdornment';

const STAGES = {
    INPUTTING: 'INPUTTING',
    SUBMITTING: 'SUBMITTING'
};

export class CompanyGroupRegisterDialogButton extends React.Component {

    constructor (props) {
        super(props)

        this.state = {
            isDialogOpen: false,
            companyGroupData: {
                companyCnpj: '__.___.___/____.__',
                companyName: '',
                companyGroup: '',
                companyTagsLimit: 1,
                responsibleName: '',
                responsibleEmail: '',
                responsiblePhone: '(__) ____-____',
                responsibleOccupation: '',
                responsiblePassword: ''
            },
            stage: STAGES.INPUTTING
        }
    }

    handleDialogOpen = () => {
        this.setState({
            isDialogOpen: true,
            companyGroupData: {
                companyCnpj: '__.___.___/____.__',
                companyName: '',
                companyGroup: '',
                companyTagsLimit: 1,
                responsibleName: '',
                responsibleEmail: '',
                responsiblePhone: '(__) ____-____',
                responsibleOccupation: '',
                responsiblePassword: ''
            },
            stage: STAGES.INPUTTING
        })
    }

    closeDialog = () => this.setState({ isDialogOpen: false });

    updateCompanyGroupData = newData => this.setState(state => ({companyGroupData: { ...state.companyGroupData, ...newData }}));

    handleSubmit = () => {
        const { onSubmit } = this.props
        const { companyGroupData } = this.state

        this.setState({ stage: STAGES.SUBMITTING })

        onSubmit(companyGroupData).then(this.closeDialog).catch(() => this.setState({ stage: STAGES.INPUTTING }))
    }

    buildForm = () => {
        const { companyGroupData } = this.state
        const { currentLanguage } = this.props

        return (
            <React.Fragment>
                <DialogContent>
                    <FlexDiv>
                        <Flex1150Div>
                            <FlexColumnDiv>
                                <TextField
                                    margin="normal"
                                    label={currentLanguage.cnpj}
                                    InputProps={{ inputComponent: CnpjInput }}
                                    value={companyGroupData.companyCnpj}
                                    onFocus={event => event.target.setSelectionRange(0, -1)}
                                    onBlur={event => companyGroupData.companyCnpj === null && this.updateCompanyGroupData({ companyCnpj: '__.___.___/____.__' })}
                                    onChange={event => this.updateCompanyGroupData({ companyCnpj: StringService.extractDigits(event.target.value) })}
                                />
                                <TextField
                                    required
                                    margin="normal"
                                    label={currentLanguage.company_name}
                                    value={companyGroupData.companyName}
                                    inputProps={{ maxLength: 255 }}
                                    onChange={event => this.updateCompanyGroupData({ companyName: event.target.value })}
                                />
                                <TextField
                                    required
                                    margin="normal"
                                    label={currentLanguage.schema_name}
                                    inputProps={{ maxLength: 64 }}
                                    InputProps={{ startAdornment: (<InputAdornment position="start">mobcheck_</InputAdornment>) }}
                                    value={companyGroupData.companyGroup}
                                    onChange={event => this.updateCompanyGroupData({ companyGroup: StringService.extractLowerLetters(event.target.value) })}
                                />
                                <TextField
                                    required
                                    margin="normal"
                                    label={currentLanguage.tags_limit}
                                    InputProps={{ inputComponent: NonNegativeInput }}
                                    value={companyGroupData.companyTagsLimit}
                                    onChange={event => this.updateCompanyGroupData({ companyTagsLimit: event.target.value })}
                                />
                            </FlexColumnDiv>
                        </Flex1150Div>
                        <Flex1150Div style={{marginLeft: '1rem'}}>
                            <FlexColumnDiv>
                                <TextField
                                    required
                                    margin="normal"
                                    label={currentLanguage.responsible_name}
                                    inputProps={{ maxLength: 40 }}
                                    value={companyGroupData.responsibleName}
                                    onChange={event => this.updateCompanyGroupData({ responsibleName: event.target.value })}
                                />
                                <TextField
                                    required
                                    margin="normal"
                                    label={currentLanguage.responsible_email}
                                    value={companyGroupData.responsibleEmail}
                                    onChange={event => this.updateCompanyGroupData({ responsibleEmail: event.target.value })}
                                />
                                <TextField
                                    required
                                    margin="normal"
                                    label={currentLanguage.responsible_phone}
                                    InputProps={{ inputComponent: PhoneInput }}
                                    value={companyGroupData.responsiblePhone}
                                    onFocus={event => event.target.setSelectionRange(0, -1)}
                                    onBlur={event => companyGroupData.responsiblePhone === null && this.updateCompanyGroupData({ responsiblePhone: '(__) ____-____' })}
                                    onChange={event => this.updateCompanyGroupData({ responsiblePhone: StringService.extractDigits(event.target.value) })}
                                />
                                <TextField
                                    required
                                    margin="normal"
                                    label={currentLanguage.responsible_role}
                                    inputProps={{ maxLength: 100 }}
                                    value={companyGroupData.responsibleOccupation}
                                    onChange={event => this.updateCompanyGroupData({ responsibleOccupation: event.target.value })}
                                />
                                <TextField
                                    required
                                    margin="normal"
                                    type="password"
                                    label={currentLanguage.password}
                                    value={companyGroupData.responsiblePassword}
                                    onChange={event => this.updateCompanyGroupData({ responsiblePassword: event.target.value })}
                                />
                            </FlexColumnDiv>
                        </Flex1150Div>
                    </FlexDiv>
                </DialogContent>
                <DialogActions>
                    <Flex11Div></Flex11Div>
                    <Button onClick={this.closeDialog}>{currentLanguage.cancel}</Button>
                    <PrimaryButton onClick={this.handleSubmit}>{currentLanguage.register}</PrimaryButton>
                </DialogActions>
            </React.Fragment>
        );
    };

    buildSubmitting = () => {
        const { companyGroupData } = this.state
        const { currentLanguage } = this.props

        return (
            <FlexDialogTitle disableTypography>
                <Typography variant="h6">{currentLanguage.creating} <i>{companyGroupData.companyName}</i>...</Typography>
            </FlexDialogTitle>
        );
    };

    buildContent = () => {
        const { stage } = this.state;

        switch (stage) {
            case STAGES.INPUTTING: return this.buildForm();
            case STAGES.SUBMITTING: return this.buildSubmitting();
            default: throw new Error(`unimplemented: ${stage}`);
        }
    };

    render () {
        const { isDialogOpen } = this.state
        const { currentLanguage } = this.props

        return (
            <React.Fragment>
                <PrimaryButton onClick={this.handleDialogOpen} style={{alignSelf: 'center'}}>{currentLanguage.add_company_group}</PrimaryButton>
                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">
                    {this.buildContent()}
                </Dialog>
            </React.Fragment>
        );
    }

}
