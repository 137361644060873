import React from 'react';
import styled from 'styled-components';
import { withSnackbar } from 'notistack';
import { get } from 'lodash/object';

import Button from '@material-ui/core/Button';

import UserIcon from './images/icon-user.png';
import PasswordIcon from './images/icon-password.png';
import { AuthenticationService } from '../../app/authenticationService';
import { LoggedUserService } from '../../authenticated/loggedUser/loggedUserService';
import { HistoryService } from '../../app/historyService';
import { Flex11Div, FlexDiv } from '../../authenticated/components/components';
import { Session } from '../../app/session';

const BaseButton = styled.button`
    border-radius: 0px;
    border: 0px;
    border-left: 1px solid #13556a;
    text-transform: uppercase;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    padding: 15px 24px;
    margin: 0;
    height: 49px;

    &:hover {
        color: #fff;
    }
`;

const RegisterButton = styled(BaseButton)`
    flex: 1 1 auto;
    background: #136d8a;

    &:hover {
        background: #12627c;
    }
`;

const LoginButton = styled(BaseButton)`
    flex: 1 1 auto;
    background: #0a5e79;

    &:hover {
        background: #0a5770;
    }
`;

const LoginInput = styled.input`
    background: #2e2e2e;
    border-radius: 0px;
    border: 0px;
    padding: 0;
    font-size: 14px;
    color: #828282;
    border-right: 1px solid #404040;
    height: 49px;
    width: 100%;

    &:focus {
        color: #de893e;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
    }
`;

class Login extends React.Component {

    state = {
        email: Session.get('email'),
        password: ''
    };

    handleChange = (field) => event => this.setState({ [field]: event.target.value });

    whenKey = (key, callback) => event => event.key === key && callback(event);

    handleLoginSuccess = response => LoggedUserService.getSessionLoggedUser().then(this.props.onAuthentication);

    handleLoginFailure = error => {
        const { enqueueSnackbar, closeSnackbar } = this.props;

        this.setState({ password: '' });

        if (get(error, 'response.data') === 'AUTHENTICATION_PENDING_USER_STATUS') {
            const action = (key) => (
                <React.Fragment>
                    <Button onClick={() => closeSnackbar(key)}>Fechar</Button>
                </React.Fragment>
            );
            enqueueSnackbar('Seu cadastrado está em análise. Qualquer dúvida, entre em contato conosco pelo email suporte@mobsd.com.br.', {
                variant: 'info',
                persist: true,
                action: action
            });
            return;
        }

        enqueueSnackbar('email e/ou senha inválidos', { variant: 'error' });
    };

    login = (email, password) => AuthenticationService.authenticate(email, password)
            .then(this.handleLoginSuccess)
            .catch(this.handleLoginFailure);

    render () {
        const { email, password } = this.state;
        const { width } = this.props;

        const marginRight = width > 800 ? '5rem' : '0rem';

        return (
            <FlexDiv style={{flexWrap: 'wrap', justifyContent: 'center', position: 'relative', padding: 0, margin: 0, marginRight}}>

                <Flex11Div style={{marginBottom: '0rem', background: '#2e2e2e no-repeat 24px 15px', paddingLeft: '48px', backgroundImage: `url('${UserIcon}')`}}>
                    <LoginInput type="text" placeholder="E-mail" onChange={this.handleChange('email')} value={email} />
                </Flex11Div>

                <Flex11Div style={{marginBottom: '0rem', background: '#2e2e2e no-repeat 24px 15px', paddingLeft: '48px', backgroundImage: `url('${PasswordIcon}')`}}>
                    <LoginInput type="password" placeholder="Password" onChange={this.handleChange('password')} value={password}
                                onKeyPress={this.whenKey('Enter', () => this.login(email, password))} />
                </Flex11Div>

                <FlexDiv style={{flex: '1 1 auto', flexWrap: 'wrap'}}>
                    <LoginButton onClick={() => this.login(email, password)}>Entrar</LoginButton>
                    <RegisterButton onClick={() => HistoryService.goto('/register')}>Registre-se</RegisterButton>
                </FlexDiv>
            </FlexDiv>
        );
    }
}

export default withSnackbar(Login);
