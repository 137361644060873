import React from 'react';
import { Button, DialogActions, DialogContent, TextField } from '@material-ui/core';
import { CenterTitleDiv, FlexBaselineDiv, PrimaryButton } from '../components/components';
import Spinner from 'react-spinner-material'

export class InspectionTypeDialogTabAttribute extends React.Component {

    buildRow = (inspectionTypeAttribute, index) => {
        const {
            inspectionTypeAttributes,
            inspectionTypeAttributesValidator,
            updateState,
            currentLanguage
        } = this.props

        if (inspectionTypeAttribute.id === null) {
            return (
                <FlexBaselineDiv key={index}>
                    <TextField
                        required
                        margin="normal"
                        style={{ flex: '1 1 auto' }}
                        value={inspectionTypeAttribute.attribute}
                        label={`${currentLanguage.attribute} #${index + 1}`}
                        error={!inspectionTypeAttributesValidator.isValid(inspectionTypeAttribute.attribute)}
                        onChange={event => updateState({ inspectionTypeAttributes: inspectionTypeAttributes.map((attribute, i) => i === index ? { ...attribute, attribute: event.target.value } : attribute) })}
                    />
                    <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={event => updateState({ inspectionTypeAttributes: inspectionTypeAttributes.filter((attribute, i) => i !== index) })}>{currentLanguage.remove}</Button>
                </FlexBaselineDiv>
            );
        }

        if (inspectionTypeAttribute.enabled === false) {
            return (
                <FlexBaselineDiv key={index}>
                    <TextField
                        disabled
                        margin="normal"
                        style={{ flex: '1 1 auto' }}
                        value={inspectionTypeAttribute.attribute}
                        label={`${currentLanguage.attribute} #${index + 1}`}
                        error={!inspectionTypeAttributesValidator.isValid(inspectionTypeAttribute.question)}
                        onChange={event => updateState({ inspectionTypeAttributes: inspectionTypeAttributes.map((attribute, i) => i === index ? { ...attribute, attribute: event.target.value } : attribute) })}
                    />
                    <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={event => updateState({ inspectionTypeAttributes: inspectionTypeAttributes.map((attribute, i) => i === index ? { ...attribute, enabled: true } : attribute) })}>{currentLanguage.able}</Button>
                </FlexBaselineDiv>
            );
        }

        return (
            <FlexBaselineDiv key={index}>
                <TextField
                    required
                    margin="normal"
                    style={{ flex: '1 1 auto' }}
                    value={inspectionTypeAttribute.attribute}
                    label={`${currentLanguage.attribute} #${index + 1}`} 
                    error={!inspectionTypeAttributesValidator.isValid(inspectionTypeAttribute.attribute)}
                    onChange={event => updateState({ inspectionTypeAttributes: inspectionTypeAttributes.map((attribute, i) => i === index ? { ...attribute, attribute: event.target.value } : attribute) })}
                />
                
                {/*<Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={event => updateState({ inspectionTypeAttributes: inspectionTypeAttributes.filter((attribute, i) => i !== index) })}>Remover</Button>*/}
                <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={event => updateState({ inspectionTypeAttributes: inspectionTypeAttributes.map((attribute, i) => i === index ? { ...attribute, enabled: false } : attribute) })}>{currentLanguage.disable}</Button>
            </FlexBaselineDiv>
        );
    };

    render () {
        const {
            submitLabel,
            inspectionTypeAttributes,
            allValidAndNonEmpty,
            closeDialog,
            handleSubmit,
            updateState,
            currentLanguage
        } = this.props

        const emptyInspectionTypeAttribute = { id: null, attribute: '' };

        const addInspectionTypeAttribute = () => updateState({ inspectionTypeAttributes: [ ...inspectionTypeAttributes, emptyInspectionTypeAttribute ] });

        if (inspectionTypeAttributes === null) {
            return (
                <React.Fragment>
                    <DialogContent style={{height: 'calc(100vh - 20rem)'}}>
                        <CenterTitleDiv><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></CenterTitleDiv>
                    </DialogContent>
                    <DialogActions style={{justifyContent: 'space-between'}}>
                        <div />

                        <div>
                            <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                            <PrimaryButton onClick={handleSubmit} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                        </div>
                    </DialogActions>
                </React.Fragment>
            );
        }

        if (inspectionTypeAttributes.length === 0) {
            return (
                <React.Fragment>
                    <DialogContent style={{height: 'calc(100vh - 20rem)'}}>
                        <CenterTitleDiv>Lista vazia</CenterTitleDiv>
                    </DialogContent>
                    <DialogActions style={{justifyContent: 'space-between'}}>
                        <div>
                            <PrimaryButton onClick={addInspectionTypeAttribute}>{currentLanguage.add_attribute}</PrimaryButton>
                        </div>

                        <div>
                            <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                            <PrimaryButton onClick={handleSubmit} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                        </div>
                    </DialogActions>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <DialogContent style={{height: 'calc(100vh - 20rem)'}}>
                    {inspectionTypeAttributes.map(this.buildRow)}
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between'}}>
                    <div>
                        <PrimaryButton onClick={addInspectionTypeAttribute}>{currentLanguage.add_attribute}</PrimaryButton>
                    </div>

                    <div>
                        <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                        <PrimaryButton onClick={handleSubmit} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                    </div>
                </DialogActions>
            </React.Fragment>
        );
    }

}
