import { InspectionApi } from './inspectionApi'

function downloadPdf (inspectionId) {
    return InspectionApi.downloadPdf(inspectionId)
}

function downloadExcel (inspectionId) {
    return InspectionApi.downloadExcel(inspectionId)
}

export const InspectionService = { downloadPdf, downloadExcel }
