export default {
  // WORDS
  search: "Buscar",
  tags: "Tags",
  tag: "Tag",
  cnpj: "CNPJ",
  category: "Categoria",
  categories: "Categorias",
  subcategory: "Subcategoria",
  subcategories: "Subcategorias",
  product: "Produto",
  products: "Produtos",
  status: "Status",
  register: "Cadastro",
  from: "A partir de",
  to: "Até",
  last_inspection: "Última inspeção",
  last_maintenance: "Última manutenção",
  due_date: "Validade",
  date: "Data",
  inspected_by: "Inspecionado por",
  items_by_page: "Itens por página",
  valid: "Válida",
  validP: "Válidas",
  able: "Habilitado",
  yes: "Sim",
  no: "Não",
  all: "Todas",
  name: "Nome",
  last_update: "Última atualização",
  exclusive: "Exclusivo",
  sku: "SKU",
  image: "Imagem",
  description: "Descrição",
  options: "Opções",
  employee: "Funcionário",
  company: "Empresa",
  phone: "Telefone",
  active: "Ativo",
  inactive: "Inativo",
  migrated: "Migrado",
  email: "E-mail",
  pending: "Pendente",
  general_information: "Informações Gerais",
  modifications: "Modificações",
  number_of_control: "Número de Controle",
  last_inspection_status: "Status da última inspeção",
  due_date_status: "Status da data de validade",
  close: "Fechar",
  responsible: "Responsável",
  responsible_name: "Nome do responsável",
  responsible_email: "E-mail do responsável",
  responsible_phone: "Telefone do responsável",
  responsible_role: "Cargo do responsável",
  attributes: "Atributos",
  attribute: "Atributo",
  attribute_register: "Atributo de cadastro",
  values: "Valores",
  questions: "Questões",
  answers: "Respostas",
  question_initial: "P",
  answer_initial: "R",
  modified_by: "Modificado por",
  equipment: "Equipamento",
  parts: "Peças",
  maintenance_parts: "Peças de Manutenção",
  replacments: "Substituições",
  without_changing_parts: "Sem troca de peças",
  units: "Unidades",
  litres: "Litros",
  kilograms: "Quilogramas",
  metres: "Metros",
  without_questions_answers: "Sem questões/respostas",
  activated: "Ativada",
  disabled: "Desativada",
  disable: "Desabilitar",
  none: "Nenhum",
  cancel: "Cancelar",
  remove: "Excluir",
  removing: "Removendo",
  confirmation: "Confirmação",
  remove_confirmation: "Para confirmar essa operação, digite #vr0#",
  password: "Senha",
  text: "Texto",
  custom: "Personalizado",
  positive: "Positiva",
  negative: "Negativa",
  change_password: "Alterar senha",
  current_password: "Senha atual",
  new_password: "Senha nova",
  confirm_new_password: "Confirmar senha nova",
  change_password_requiriment: "A nova senha deve atender aos seguintes critérios:",
  at_least_characters: "No mínimo 6 caracteres",
  at_least_numeral: "No mínimo 1 numero",
  at_least_uppercase: "No mínimo 1 letra maiúscula",
  at_least_lowercase: "No mínimo 1 letra minúscula",
  at_least_special_character: "No mínimo 1 caracter especial",
  valid_special_character: "Caracteres especiais válidos: !@#$%&*()_-+=",
  logout: "Sair",
  my_plan: "Meu plano",
  video_lessons: "Vídeo Aulas",
  clear_fields: "Limpar Filtros",
  os: "Ordem de serviços",
  id_equipament: "Id do Equipamento",
  name_equipament: "Nome",
  priority_equipament: "Prioridade",
  type_equipament: "Tipo",
  responsible_user_equipament: "Usuário Responsável",
  completion_date_equipament: "Data de Conclusão",
  report_equipament: "Laudo",
  // MENU HEADER
  dashboard: "Dashboard",
  reports: "Relatórios",
  report: "Relatório",
  registrations: "Cadastros",
  employees: "Funcionários",
  companies: "Empresas",
  company_group: "Grupos de Empresa",
  all_users: "Todos usuários",
  settings: "Configurações",
  maintenances: "Manutenções",
  list: "Listar",
  tag_replace: "Substituir Tag",
  user: "Usuário",
  system: "Sistema",
  or: "ou",
  na: "N/A",
  expired: "Expirada",
  expiredP: "Expiradas",
  improper: "Não conforme",
  improperP: "Não conformes",
  expiring: "Expirando",
  expired_or_expiring: "Expirada ou próxima a expirar",
  export: "Exportar",
  empty_list: "Lista vazia.",
  created: "Criado",
  updated: "Atualizado",
  update: "Atualizar",
  removed: "Removido",
  already_exists: "Já existe",
  email_exists: "E-mail #vr0# já existe.",
  updated_user: "Usuário #vr0# atualizado com sucesso.",
  created_user: "Usuário #vr0# criado com sucesso.",
  removed_user: "Usuário #vr0# removido com sucesso.",
  profile: "Perfil",
  add: "Adicionar",
  qr_code: "QR Code",
  generate_qr_code: "Gerar QR Code",
  export_qr_code: "Exportar QR Code",
  // DASHBOARD
  inspections_by_products: "Inspeções por produtos",
  equipments_status: "Status dos equipamentos",
  inspections_by_employees: "Inspeções por funcionários",
  inspections_by_month: "Inspeções por mês (últimos 24 meses)",
  total: "Total",
  users: "Usuários",
  equipments: "Equipamentos",
  inspections: "Inspeções",
  last_seven_days: "Nos últimos 7 dias",
  last_interactions: "Últimas interações",
  new_user: "Novo usuário",
  new_equipment: "Novo equipamento",
  new_inspection: "Nova inspeção",
  text_initial_video_lessons: "É sua primeira vez no MOB Inspeção? Veja as vídeo aulas do sistema!",
  text_initial_buy_tags: "Deseja comprar Tags? Veja nossas opções!",
  // INSPECTIONS REPORT
  inspection_reports: "Relatórios de Inspeção",
  equipment_sku: "Equipamento/SKU",
  equipment_information: "Informações do Equipamento",
  active_tags_only: "Somente tags ativas",
  // MAINTENANCES REPORT
  maintenance_reports: "Relatórios de Manutenção",
  maintenance_done_by: "Manutenção feita por",
  // CATEGORIES
  add_category: "Adicionar categoria",
  update_category: "Atualizar categoria",
  update_subcategory: "Atualizar subcategoria",
  created_category: "Categoria #vr0# criada.",
  updated_category: "Categoria #vr0# atualizada.",
  created_subcategory: "Subcategoria #vr0# criada.",
  updated_subcategory: "Subcategoria #vr0# atualizada.",
  // SUB CATEGORIES
  add_subcategory: "Adicionar subcategoria",
  inspection_questions: "Questões de Inspeção",
  inspection_question: "Questão de inspeção",
  maintenance_questions: "Questões de Manutenção",
  maintenance_question: "Questão de inspeção",
  able_subcategory: "Habilitar Subcategoria",
  add_inspection_question: "Adicionar questão de inspeção",
  add_maintenance_question: "Adicionar questão de manutenção",
  add_attribute: "Adicionar atributo",
  custom_answer: "Resposta personalizada",
  add_custom_answer: "Adicionar resposta personalizada",
  // PRODUCT
  add_product: "Adicionar produto",
  update_product: "Atualizar produto",
  created_product: "Produto #vr0# criado",
  updated_product: "Produto #vr0# atualizado",
  inspection_periodicity: "Periodicidade da Inspeção",
  yearly: "Anual",
  biannual: "Semestral",
  quarterly: "Trimestral",
  monthly: "Mensal",
  biweekly: "Quinzenal",
  weekly: "Semanal",
  inspection_expiration_days: "Dias para notificação do vencimento da inspeção",
  has_expiration_date: "Possui data de validade",
  days_notification_due_date: "Dias para notificação da validade",
  remove_maintenance_part: "Deseja remover a seguinte Peça de Manutenção?",
  maintenance_part: "Peça de Manutenção",
  remove_maintenance_question: "Remover questão de manutenção",
  add_maintenance_part: "Adicionar peça de manutenção",
  // LIST TAGS
  equipment_serial: "Serial do equipamento",
  product_name: "Nome do Produto",
  product_exclusive: "Produto Exclusivo?",
  active_tag: "Tag ativa?",
  list_tag_table_description: "Produtos marcados como exclusivo não consomem nem afetam o total de tags disponíveis.",
  // TAG REPLACE
  new_equipament_serial: "Novo serial do equipamento",
  request_date: "Data da solicitação",
  tag_replace_table_description: "Aqui estão listadas todas as solicitações de substituição de Tag.",
  new_tag_information: "Informações da tag NOVA",
  old_tag_information: "Informações da tag ANTIGA",
  recommended_transfer_equipment: "Equipamento recomendado para transferência",
  find_other_equipment: "Procurar outro equipamento",
  find_equipment: "Procurar equipamento",
  search_equipments: "Buscar equipamentos",
  search_equipments_for_register: "Buscar equipamentos para cadastro",
  selected_equipment: "Equipamento selecionado",
  select_equipment: "Selecionar equipamento",
  transfer_inspections_and_maintenances: "Transferir Inspeções e Manutenções",
  select_an_equipment_to_transfer_information: "Selecione um equipamento para transferir as informações",
  replacement_done: "Dados transferidos com sucesso!",
  // EMPLOYEES
  add_user: "Adicionar usuário",
  role: "Cargo",
  company_operator: "Operador da Empresa",
  company_admin: "Administrador da Empresa",
  company_group_admin: "Administrador Grupo de Empresas",
  mobcheck_admin: "Administrador MOB Inspeção",
  // COMPANIES
  company_name: "Nome da Empresa",
  main_contact: "Principal Contato",
  tags_qtt_max: "Tags (Quantidade máxima)",
  tags_qtt_used: "Tags (Utilizadas)",
  add_company: "Adicionar Empresa",
  company_created: "Empresa #vr0# criada.",
  company_updated: "Empresa #vr0# atualizada.",
  change_photo: "Alterar foto",
  // COMPANY GROUP
  company_group_database_name: "Nome do Grupo de Empresa",
  select_company: "Selecione uma empresa",
  add_company_group: "Cadastrar Grupo de Empresa",
  company_group_created: "Grupo de empresa #vr0# criado.",
  migrate_error: "Não foi possível migrar.",
  missing_field: "Por favor, preencha os campos obrigatórios.",
  company_group_already_exists: "Grupo de empresa já existe: #vr0#",
  company_group_invalid_name: "Nome inválido: mobcheck_#vr0#",
  schema_name: "Nome do Schema",
  tags_limit: "Limite de tags",
  creating: "Criando",
  // ALL USERS
  registred_on: "Cadastrado em",
  activated_user_success: "Usuário #vr0# ativado com sucesso.",
  migrated_user_success: "Usuário #vr0# migrado com sucesso.",
  updated_password_success: "Senha do usuário #vr0# atualizado com sucesso.",
  deleted_user_success: "Usuário #vr0# deletado com sucesso.",
  active_pending_user: "Ativar usuário pendente",
  // SETTINGS
  system_configuration: "Configurações do Sistema",
  general_settings: "Configurações gerais",
  languagem: "Idioma",
  // User
  update_user_success: "Usuário atualizado com sucesso",
  // Tippy
  attributeType_Tippy: "Atributos personalizados para todos os produtos das subcategorias, para serem preenchidos no momento do cadastro de um equipamento (ex. Placa de carro)",
  due_date_Tippy: "Data referente a validade do produto",
  maintenance_date_Tippy: "Data referente a última manutenção do produto",
  inspection_date_Tippy: "Data referente a última inspeção do produto",
  inspection_question_Tippy: "Tipo de resposta a ser selecionada",
  user_profile_Tippy: "Tipo de perfil de permissões para usuário",
  inspection_customQuestion_Tippy: "Tipo de reação do sistema ao selecionar resposta personalizada",
  // ORDER OF SERVICE
  number_os: "Número da Os",
  os_created: "Ordem de serviço criada.",
  os_updated: "Ordem de serviço atualizada.",
  start_date: "Data Início",
  end_date: "Data Fim",
  user_current: "Usuário Solicitante",
  responsible_user: "Usuário Responsável",
  responsible_user_select: "Selecione um usuário",
  comments: "Observações",
  not_comments: "Não há observações para esta Ordem de Serviço",
  os_status: "Status",
  os_status_select: "Selecione um status",
  status_pending: "Pendente",
  status_partial: "Parcial",
  status_finished: "Finalizado",
  status_cancelled: "Cancelado",
  pending: "Nenhum equipamento inspecionado/manutenção",
  partial: "Inspeção/Manutenção incompletas",
  finished: "Todas inspeções e manutenções concluídas",
  canceled: "Exclusão",
  equipment: "Equipamento",
  priority: "Prioridade",
  priority_select: "Selecione uma prioridade",
  priority_lowest: "Baixíssima",
  priority_low: "Baixa",
  priority_average: "Média",
  priority_high: "Alta",
  priority_very_high: "Altíssima",
  select_type: "Selecione um tipo",
  os_type: "Tipo",
  os_inspection: "Inspeção",
  os_maintenance: "Manutenção",
  os_select_equipment: "Selecione um equipamento",
  os_completion_date: "Data de conclusão",
  filter: "Filtrar",
  filter_os: "Filtrar OS",
  add_new_os: "Adicionar Nova OS",
  register_os_abbreviated: "Cadastrar OS",
  register_new_os_abbreviated: "Cadastrar Nova OS",
  register_new_os: "Cadastrar Nova Ordem de Serviço",
  search_os: "Buscar Ordens de Serviço",
  change_os: "Alterar Ordens de Serviço",
  list_os: "Relação de Ordens de Serviço",
  change_os_singular: "Alterar Ordem de Serviço",
  change_os_abbreviated: "Alterar OS",
  insert_equipment: "Inserir Equipamento",
  extra_nome_equipment: "Nome do Equipamento",
  selects: "Selecionado",
  addSelecteds: "Adicionar Selecionados",
  list_os_abbreviated: "Relação de OS",
  select: "Selecionar"
}
