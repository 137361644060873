import MaskedInput from 'react-text-mask';
import React from 'react';

function cepMask () {
    return [ /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/ ];
}

// TODO: fix last digit
export function CepInput (props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput {...other} mask={cepMask} showMask keepCharPositions guide ref={ref => inputRef(ref ? ref.inputElement : null)} />
    );
}
