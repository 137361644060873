import React from 'react'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Paper,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import { Autocomplete, TextField } from '@mui/material'
import { Flex1150Div, FlexColumnDiv, FlexDiv, PrimaryButton, HeaderTableCell, BodyTableCell, SecondaryButton } from '../components/components';
import { CollectionViewUpdater } from '../utils/collectionViewUpdater';

export class OsFormFindEquipments extends React.Component {

    constructor(props) {
        super(props)
        this.collectionUpdater = CollectionViewUpdater.ofEquipments(this);
        this.state = {
            isDialogOpen: false,
            equipmentList: [],
            equipmentListRender: [],
            listNamesEquipmentsSearch: [],
        }
    }

    handleDialogOpen = () => {
        const { equipmentListProp } = this.props
        let listNamesEquip = equipmentListProp.map(equip => ({ id: equip?.tagSerialNumber, label: equip.extraLabel }));

        this.setState({
            listNamesEquipmentsSearch: listNamesEquip,
            equipmentListRender: equipmentListProp.map(equip => ({ id: equip?.tagSerialNumber, label: equip?.product?.name, isCheck: false, ...equip })),
            equipmentList: equipmentListProp.map(equip => ({ id: equip?.tagSerialNumber, label: equip?.product?.name, isCheck: false, ...equip })),
            isDialogOpen: true,
        })
    }

    closeDialog = () => this.setState({ isDialogOpen: false })

    updateCompanyData = newData => {
        if (!newData?.id || newData?.id === '') {
            return this.setState({
                equipmentListRender: this.state.equipmentList
            })
        }

        let filterList = this.state.equipmentList.filter(equip => equip.id === newData.id)
        this.setState({
            equipmentListRender: filterList
        })
    }

    toggleEnabled = (equipTAGSerialNumber, check) => {
        let togleListRender = this.state.equipmentListRender.map(equip => {
            if (equip.tagSerialNumber === equipTAGSerialNumber) {
                return {
                    ...equip,
                    isCheck: check
                }
            } else {
                return equip
            }
        })

        let togleList = this.state.equipmentList.map(equip => {
            if (equip.tagSerialNumber === equipTAGSerialNumber) {
                return {
                    ...equip,
                    isCheck: check
                }
            } else {
                return equip
            }
        })

        this.setState({
            equipmentListRender: togleListRender,
            equipmentList: togleList
        })
    }

    handleValueChange = field => event => this.updateCompanyData({ [field]: event.target.value });

    handleSubmit = () => {
        this.props.onSubmit(this.state.equipmentList)
        this.closeDialog();
    }

    render() {
        const {
            isDialogOpen,
            equipmentList,
            listNamesEquipmentsSearch,
            equipmentListRender
        } = this.state
        const { submitLabel, ModalButton, currentLanguage, isNotSelectAll, onSelectButton } = this.props

        return (
            <React.Fragment>
                <ModalButton onClick={this.handleDialogOpen} />
                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">

                    <FlexDiv
                        style={{ alignItems: 'center', justifyContent: 'center', background: 'rgb(14, 74, 100)' }}>
                        <Typography variant="subtitle1" style={{ fontWeight: 500, fontSize: '1.2rem', textAlign: 'center', marginTop: '0.5rem', marginBottom: '0.5rem', color: '#ffffff' }}>
                            {currentLanguage.search_equipments_for_register}
                        </Typography>
                    </FlexDiv>

                    <DialogContent style={{ marginBottom: '1rem', }} >
                        <FlexDiv>
                            <Flex1150Div>
                                <FlexColumnDiv>
                                    <FormControl margin="none">
                                        <Autocomplete
                                            options={equipmentList}
                                            onChange={(event, newValue) => this.updateCompanyData({ id: newValue?.id ?? '' })}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                fullWidth
                                                variant="standard"
                                                label={currentLanguage.product_name}
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />}
                                        />
                                    </FormControl>
                                </FlexColumnDiv>
                            </Flex1150Div>

                            <Flex1150Div style={{ marginLeft: '1rem' }}>
                                <FlexColumnDiv>

                                    <FormControl margin="none">
                                        <Autocomplete
                                            options={listNamesEquipmentsSearch}
                                            onChange={(event, newValue) => this.updateCompanyData({ id: newValue?.id ?? '' })}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                fullWidth
                                                variant="standard"
                                                label={currentLanguage.extra_nome_equipment}
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />
                                            }
                                        />
                                    </FormControl>
                                </FlexColumnDiv>
                            </Flex1150Div>
                        </FlexDiv>
                    </DialogContent>

                    <Paper style={{ overflowX: 'auto', maxHeight: '22rem' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <HeaderTableCell field="id">
                                        {currentLanguage.equipment_serial}
                                    </HeaderTableCell>
                                    <HeaderTableCell field="startDate">
                                        {currentLanguage.extra_nome_equipment}
                                    </HeaderTableCell>
                                    <HeaderTableCell field="endDate">
                                        {currentLanguage.product_name}
                                    </HeaderTableCell>
                                    <HeaderTableCell field="userCurrent">
                                        {currentLanguage.sku}
                                    </HeaderTableCell>
                                    <HeaderTableCell />
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {equipmentListRender?.map(equip => (
                                    <TableRow key={equip?.id + Math.floor(Math.random() * Date.now())}>
                                        <BodyTableCell>{equip?.tagSerialNumber}</BodyTableCell>
                                        <BodyTableCell>{equip?.extraLabel}</BodyTableCell>
                                        <BodyTableCell>{equip?.product?.name}</BodyTableCell>
                                        <BodyTableCell>{equip?.product?.sku}</BodyTableCell>
                                        <BodyTableCell>
                                            {isNotSelectAll ?
                                                <SecondaryButton size='small' onClick={() => {
                                                    console.log(equip);
                                                    onSelectButton(equip);
                                                    this.closeDialog();
                                                }} style={{ alignSelf: 'center', margin: '0' }}>
                                                    {currentLanguage.select}
                                                </SecondaryButton> :
                                                <Checkbox color="primary" checked={equip?.isCheck} onChange={event => this.toggleEnabled(equip?.tagSerialNumber, event.target.checked)} />
                                            }
                                        </BodyTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </Paper>

                    <DialogActions style={{ marginTop: '1rem' }}>
                        <Button onClick={this.closeDialog}>{currentLanguage.cancel}</Button>
                        {!isNotSelectAll && <PrimaryButton onClick={this.handleSubmit}>{submitLabel}</PrimaryButton>}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }

}

