import moment from 'moment'
import { isString } from 'lodash/lang';
import { get } from 'lodash/object';
import { deburr, toLower } from 'lodash/string';

function isFromValid(fieldKey, filterValue) {
    return filterValue ? element => !!get(element, fieldKey) && !filterValue.isAfter(get(element, fieldKey), 'day') : null
}

function isUpToValid(fieldKey, filterValue) {
    return filterValue ? element => !!get(element, fieldKey) && !filterValue.isBefore(get(element, fieldKey), 'day') : null
}

function isSameString(fieldKey, filterValue) {
    return filterValue ? element => (String(get(element, fieldKey)) === filterValue) : null;
}

function acceptExpirationStatus(a, b) {
    return a === 'EXPIRED_OR_EXPIRING' && (b === 'EXPIRED' || b === 'EXPIRING');
}

function equivalentExpirationStatus(a, b) {
    return a === b || acceptExpirationStatus(a, b) || acceptExpirationStatus(b, a)
}

function isEquivalentExpirationStatus(fieldKey, filterValue) {
    return filterValue ? element => equivalentExpirationStatus(String(get(element, fieldKey)), filterValue) : null;
}

function isStrictEqual(fieldKey, filterValue) {
    return filterValue ? element => (get(element, fieldKey) === filterValue) : null
}

function normalize(value) {
    return toLower(deburr(String(value)))
}

function normalizeItems(items, keys) {
    //return items.map(item => keys.map(key => normalize(item[key])))
    return items.map(item => keys.map(key => normalize(get(item, key))))
}

function containsQuery(query, items, keys) {
    if (!isString(query) || query.length === 0) {
        return null;
    }

    const normalizedValues = normalizeItems(items, keys)
    const predicate = query.length < 3 ? (value => value === query) : (value => value.indexOf(query) !== -1)

    return (element, index) => normalizedValues[index].some(predicate)
}

function filter(items, allFilters) {
    const filters = allFilters.filter(filter => !!filter);

    return items.filter((element, index, array) => filters.length === 0 || filters.every(filter => filter(element, index)))
}

function filterWithPredicates(items, allPredicates) {
    const predicates = allPredicates.filter(predicate => !!predicate);

    return items.filter((element, index, array) => predicates.length === 0 || predicates.every(filter => filter(element, index)))
}

function filterAllItensSelected(items, filters) {

    if (!filters) {
        return items;
    }

    let currentFilteredList = items;

    let arrayFilters = Object.entries(filters).map(entry => {
        return {
            key: entry[0],
            value: entry[1]
        };
    });

    arrayFilters.forEach(filter => {
        currentFilteredList = currentFilteredList.filter(item => {

            if ((filter.value === null || item[filter.key] === null) ||
                filter.value === '') return true;

            if ((filter.key === 'equipment' && !filter.value?.tagSerialNumber) ||
                (!!item?.equipments &&
                    item?.equipments?.length > 0 &&
                    item?.equipments?.findIndex(item => item?.equipmentSerialNumber === filter.value?.tagSerialNumber) !== -1)
            ) return true

            if (filter.key === 'startDate' && moment(item.startDate).isSameOrAfter(filter?.value?.format('YYYY-MM-DD'))) {
                return true
            }

            if (filter.key === 'endDate' && moment(item.endDate).isSameOrBefore(filter?.value?.format('YYYY-MM-DD'))) {
                return true
            }

            if (filter.key === 'observation' && item?.observation?.toLowerCase().includes(filter?.value?.toLowerCase())) {
                return true
            }

            if (item[filter.key]?.toString()?.toLowerCase() === filter?.value?.toString()?.toLowerCase()) {
                return true
            }
        });
    });

    return currentFilteredList;
}

export const FilterService = {
    isFromValid,
    isUpToValid,
    isSameString,
    isEquivalentExpirationStatus,
    isStrictEqual,
    containsQuery,
    filter,
    filterWithPredicates,
    filterAllItensSelected
};
