import axios from 'axios';

function loginWithEmailAndPassword (email, password) {
    const headers = { 
        'Content-Type': 'application/json',
        'Authorization': `Email ${email}`
    };

    return axios.post('/web/authentication/', password, { headers });
}

function logout () {
    return axios.delete('/web/authentication/');
}

export const AuthenticationApi = { loginWithEmailAndPassword, logout };
