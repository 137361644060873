const getStatusLabel = (status, currentLanguage) => {
    const listStatus = {
        'PENDING': currentLanguage.status_pending,
        'PARTIAL': currentLanguage.status_partial,
        'FINISHED': currentLanguage.status_finished,
        'CANCELLED': currentLanguage.status_cancelled,
    }
    return listStatus[status];
}

const getPriorityLabel = (priority, currentLanguage) => {
    const listStatus = {
        'LOWEST': currentLanguage.priority_lowest,
        'LOW': currentLanguage.priority_low,
        'AVERAGE': currentLanguage.priority_average,
        'HIGH': currentLanguage.priority_high,
        'HIGHEST': currentLanguage.priority_very_high,
    }
    return listStatus[priority];
}

const getTypeLabel = (type, currentLanguage) => {
    const listStatus = {
        'INSPECTION': currentLanguage.os_inspection,
        'MAINTENANCE': currentLanguage.os_maintenance,
    }
    return listStatus[type];
}

export { getPriorityLabel, getStatusLabel, getTypeLabel }