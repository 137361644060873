import React from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { get } from 'lodash/object'
import { AuthenticationService } from '../../app/authenticationService'
import { LoggedUserService } from '../../authenticated/loggedUser/loggedUserService'
import { Session } from '../../app/session'
import { withStyles } from "@material-ui/core/styles"
import ForgotPassword from './forgotPassword'
import Logo from './images/logo.png'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const styles = theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundColor:
            '#dd8521',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
})

class Login2 extends React.Component {

    state = {
        email: Session.get('email'),
        password: '',
        isChecked: false
    }

    componentDidMount() {
        setTimeout(function () {
            if (localStorage.checkbox === true && localStorage.email !== undefined && localStorage.password !== undefined && localStorage.token !== undefined) {
                AuthenticationService.authenticate(localStorage.email, localStorage.password)
                    .then(this.handleLoginSuccess)
                    .catch(this.handleLoginFailure)
            }
        }, 2000)
    }

    handleChange = (field) => event => this.setState({ [field]: event.target.value })

    login = (email, password, isChecked) => {

        if (isChecked === true && email !== "" && password !== "") {
            localStorage.username = email
            localStorage.password = password
            localStorage.checkbox = isChecked
        }
        AuthenticationService.authenticate(email, password)
            .then(this.handleLoginSuccess)
            .catch(this.handleLoginFailure)
    }

    handleLoginSuccess = response => LoggedUserService.getSessionLoggedUser().then(this.props.onAuthentication)

    handleLoginFailure = () => {

        const MySwal = withReactContent(Swal)

        localStorage.password = ""

        MySwal.fire({
            title: 'E-mail e/ou Senha incorreto(s)',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#F08302',
            confirmButtonText: 'Ok',
        })
    }

    onChangeCheckbox = event => {
        this.setState({
            isChecked: event.target.checked
        })
    }

    render() {
        const { email, password, isChecked } = this.state
        const { classes } = this.props

        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <img alt="logo" src={Logo} height="100px" />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            value={email}
                            label="E-mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={this.handleChange('email')}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Senha"
                            type="password"
                            id="password"
                            value={password}
                            autoComplete="current-password"
                            onChange={this.handleChange('password')}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" checked={isChecked} onChange={this.onChangeCheckbox} />}
                            label="Lembrar login"
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={e => this.login(email, password, isChecked)}
                            className={classes.submit}
                        >
                            Entrar
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <ForgotPassword />
                            </Grid>
                            <Grid item>
                                <Link href="#/register" variant="body2">
                                    {"Registrar"}
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={5}>
                            <Typography variant="body2" color="textSecondary" align="center">
                                <Link color="inherit" href="http://mobsd.com.br">
                                    MOB Soluções Digitais
                             </Link>
                            </Typography>
                        </Box>

                    </div>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Login2)
