import { PasswordResetApi } from './passwordResetApi';
import { Session } from '../../app/session';

function emailPasswordReset (email) {
    return PasswordResetApi.emailPasswordReset(email).then(response => response.data);
}

function resetPassword (emailToken, password) {
    return PasswordResetApi.resetPassword(emailToken, password)
        .then(response => response.data)
        .then(token => {
            Session.set('token', token);
            return token;
        });
}

export const PasswordResetService = { emailPasswordReset, resetPassword };
