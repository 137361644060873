import axios from 'axios';

export const UserApi = {
    list: () => axios.get('/web/users/'),
    activateGeneric: (userId, activateGenericData) => axios.put(`/web/users/${userId}/activate-generic-pending`, activateGenericData),
    migrateGenericPending: (userId, migrateGenericPendingData) => axios.put(`/web/users/${userId}/migrate-generic-pending`, migrateGenericPendingData),
    migrate: (userId, migrateData) => axios.put(`/web/users/${userId}/migrate`, migrateData),
    updatePassword: (userId, passwordData) => axios.put(`/web/users/${userId}/password`, passwordData),
    delete: (userEmail) => axios.delete(`/web/users/${userEmail}`)
};
