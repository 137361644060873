import React from 'react';
import styled from 'styled-components';

import { Button, DialogTitle, TableCell, Typography, withStyles } from '@material-ui/core';

export const HeaderTableCell = withStyles({
    head: {
        color: 'white',
        backgroundColor: 'rgb(14, 74, 100)',
        whiteSpace: 'nowrap'
    },
    root: {
        padding: '0.5rem'
    }
})(TableCell);

export const SectionTableCell = withStyles({
    head: {
        color: 'white',
        backgroundColor: 'rgb(213, 117, 48)',
        whiteSpace: 'nowrap'
    },
    root: {
        padding: '0.5rem'
    }
})(TableCell);

export const BodyTableCell = withStyles({
    root: {
        wordWrap: 'break-word',
        padding: '0.5rem'
    }
})(TableCell);

export const FlexDialogTitle = styled(DialogTitle)`
    display: flex;
    justify-content: space-between;
`;

export const OrangeSpan = styled.span` color: rgb(213, 117, 48); `;
export const RedSpan = styled.span` color: rgb(165, 35, 47); `;

export const MainDiv = styled.div` color: rgb(14, 74, 100); `;
export const SubDiv = styled.div` color: rgb(145, 145, 145); `;

export const RedDiv = styled.div` color: rgb(165, 35, 47); `;
export const OrangeDiv = styled.div` color: rgb(213, 117, 48); `;
export const BlackDiv = styled.div` color: rgb(0, 0, 0); `;

export const RightDiv = styled.div` text-align: right; `;

export const FlexDiv = styled.div` display: flex; `;
export const FlexColumnDiv = styled.div` display: flex; flex-direction: column; `;
export const FlexSpaceBetweenDiv = styled.div` display: flex; justify-content: space-between; `;
export const FlexSpaceAroundDiv = styled.div` display: flex; justify-content: space-around; `;
export const FlexBaselineDiv = styled.div` display: flex; align-items: baseline; `;
export const Flex1150Div = styled.div` flex: 1 1 50%; `;
export const Flex11Div = styled.div` flex: 1 1 auto; `;

export const ContainerDiv = styled.div`
    padding-bottom: 0.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
`;

export const OverflowXDiv = styled.div` overflow-x: auto; max-width: 100vw; `;


export const CenterTitleDiv = props => {
    const { children } = props;

    return (
        <CenterMessageDiv>
            <Typography variant="h6">{children}</Typography>
        </CenterMessageDiv>
    );
};

/*
TODO: Realizar teste.

export const CenterMessageDiv = props => {
    const { children } = props;

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {children}
        </div>
    );
};
*/


export const CenterMessageDiv = props => {
    const { children } = props;

    return (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {children}
        </div>
    );
};

/////////////
// Buttons //
/////////////

export const PrimaryButton = props => {
    return (<Button variant="contained" color="primary" {...props}/>);
};

export const SecondaryButton = props => {
    return (<Button variant="contained" color="secondary" {...props}/>);
};

export const SecondaryTextButton = props => {
    return (<Button variant="text" color="secondary" {...props}/>);
};

export function formatMainDescription (main, description) {
    return (
        <React.Fragment>
            <MainDiv>{main}</MainDiv>
            <SubDiv>{description}</SubDiv>
        </React.Fragment>
    )
}

