import React from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash/object';
import * as queryString from 'query-string';




import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';

import {
    BodyTableCell,
    ContainerDiv,
    FlexBaselineDiv,
    FlexColumnDiv,
    FlexSpaceBetweenDiv,
    formatMainDescription,
    HeaderTableCell,
    OverflowXDiv,
    RightDiv,
    SectionTableCell
} from '../components/components';

import { EquipmentService } from './equipmentService';
import { CompanyService } from '../company/companyService';

import { EquipmentDialogButton } from './equipmentDialogButton';
import { DateTimeService } from '../utils/dateTimeService';

import PdfIcon from './images/icon-pdf-file.png';
import ExcelIcon from './images/icon-excel-file.png';
import { SortHeaderCell } from '../components/sortHeaderCell';
import { FilterDatePicker } from '../components/filterDatePicker';
import { TableFooterWithPaginator } from '../components/tableFooterWithPaginator';
import { InspectionTypeService } from '../inspectionType/inspectionTypeService';
import { CategoryService } from '../category/categoryService';
import { ProductService } from '../product/productService';
import { DateWithExpiration } from '../components/dateWithExpiration';
import TableCell from '@material-ui/core/TableCell';
import { CollectionViewUpdater } from '../utils/collectionViewUpdater';
import { StringService } from '../utils/stringService';
import Spinner from 'react-spinner-material';
import { PrimaryButton } from '../components/components';
import { Tooltip } from 'react-tippy';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import 'react-tippy/dist/tippy.css';
import { useEffect } from 'react';
import moment, { now } from 'moment'

function calculateStatusColor(status) {
    switch (status) {
        case 'VALID': return 'transparent';
        case 'IMPROPER': return 'rgb(165, 35, 47)';
        case 'EXPIRED': return 'rgb(213, 117, 48)';
        case 'NA': return 'rgb(145, 145, 145)';
        default: throw new Error(`Unimplemented: ${status}`);
    }
}



class EquipmentsPage extends React.Component {


    constructor(props) {
        super(props);

        const { location } = props;

        const { view } = queryString.parse(location.search);

        this.collectionUpdater = CollectionViewUpdater.ofEquipments(this);

        this.state = {
            isLoading: false,
            categories: [],
            inspectionTypes: [],
            products: [],
            companies: [],
            filterQuery: '',
            filterStatus: '',
            filterEnabledTagsOnly: true,
            filterCategoryId: '',
            filterInspectionTypeId: '',
            filterProductId: '',
            filterCompanyId: '',
            filterCreateFrom: null,
            filterCreateUpTo: null,
            filterLastInspectionFrom: null,
            filterLastInspectionUpTo: null,
            filterLastInspectionExpirationStatus: view === 'inspections-report' ? 'EXPIRED_OR_EXPIRING' : '',
            filterDueDateFrom: null,
            filterDueDateUpTo: null,
            filterDueDateExpirationStatus: '',
            collection: this.collectionUpdater.collection,
            clearedfilter: false
        };
    }



    calculateQuery = () => {

        const {
            filterQuery: query,
            filterStatus: status,
            filterEnabledTagsOnly: enabledTagsOnly,
            filterCategoryId: categoryId,
            filterInspectionTypeId: inspectionTypeId,
            filterProductId: productId,
            filterCompanyId: companyId,
            filterCreateFrom: createFrom,
            filterCreateUpTo: createUpTo,
            filterLastInspectionFrom: lastInspectionFrom,
            filterLastInspectionUpTo: lastInspectionUpTo,
            filterLastInspectionExpirationStatus: lastInspectionExpirationStatus,
            filterDueDateFrom: dueDateFrom,
            filterDueDateUpTo: dueDateUpTo,
            filterDueDateExpirationStatus: dueDateExpirationStatus,
            collection
        } = this.state;
        const { field, direction } = collection.sorter;

        return {
            query,
            status,
            enabledTagsOnly,
            categoryId,
            inspectionTypeId,
            productId,
            companyId,
            createFrom: createFrom && createFrom.format('YYYY-MM-DD'),
            createUpTo: createUpTo && createUpTo.format('YYYY-MM-DD'),
            lastInspectionFrom: lastInspectionFrom && lastInspectionFrom.format('YYYY-MM-DD'),
            lastInspectionUpTo: lastInspectionUpTo && lastInspectionUpTo.format('YYYY-MM-DD'),
            lastInspectionExpirationStatus,
            dueDateFrom: dueDateFrom && dueDateFrom.format('YYYY-MM-DD'),
            dueDateUpTo: dueDateUpTo && dueDateUpTo.format('YYYY-MM-DD'),
            dueDateExpirationStatus,
            field,
            direction: field && direction
        };
    };


    componentDidMount() {

        this.setState({ isLoading: true });

        const { handleFilterChange } = this.collectionUpdater
        const { filterLastInspectionFrom, filterLastInspectionUpTo, lastInspectionExpirationStatus, filterQuery, clearedfilter } = this.state


        let dataSearch = queryString.parse(this.props.location.search)


        if (clearedfilter === false) {

            function employeeSearch() {
                if (dataSearch.employeeData !== undefined) {
                    let employeeSelect = dataSearch.employeeData.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    return (
                        handleFilterChange({ filterQuery: employeeSelect.toLocaleLowerCase() })
                    )
                }
            }
            employeeSearch()

            function equipmentSearch() {
                if (dataSearch.categoryData !== undefined) {
                    var regExp = /\(([^)]+)\)/
                    let skuValue = regExp.exec(dataSearch.categoryData)
                    let statusValueChart
                    if (dataSearch.categoryDataStatus == 'Válidas') {
                        statusValueChart = 'VALID'
                    } else {
                        statusValueChart = 'IMPROPER'
                    }
                    return (
                        handleFilterChange({ filterQuery: skuValue[1].toLowerCase() })
                    )
                }
            }
            equipmentSearch()


            function doughnutSearch() {
                if (dataSearch.Doughnut !== undefined) {
                    let statusValue
                    if (parseInt(dataSearch.Doughnut, 10) === 0) statusValue = 'EXPIRED'
                    if (parseInt(dataSearch.Doughnut, 10) === 1) statusValue = 'IMPROPER'
                    if (parseInt(dataSearch.Doughnut, 10) === 2) statusValue = 'VALID'
                    return (
                        handleFilterChange({ filterStatus: statusValue })
                    )
                }
            }
            doughnutSearch()

            function histogramSearch() {
                let dateToFilter = new Date(Date.now())
                let convertedNum = 23 - parseInt(dataSearch.histogramData, 10)
                if (dataSearch.histogramData !== undefined) {
                    dateToFilter.setMonth(dateToFilter.getMonth() - convertedNum)
                    return (
                        handleFilterChange({ filterLastInspectionFrom: moment(new Date(dateToFilter.getFullYear(), dateToFilter.getMonth(), 1)) }),
                        handleFilterChange({ filterLastInspectionUpTo: moment(new Date(dateToFilter.getFullYear(), dateToFilter.getMonth() + 1, 0)) }))
                }
            }
            histogramSearch()
        }

        EquipmentService.summary().then(items => {
            this.collectionUpdater.initialData(items);
            this.setState({ isLoading: false });
        });

        CategoryService.list().then(categories => this.setState({ categories }));
        InspectionTypeService.list().then(inspectionTypes => this.setState({ inspectionTypes }));
        ProductService.list().then(products => this.setState({ products }))
        CompanyService.list().then(companies => this.setState({ companies }))

    }

    downloadPdf = () => EquipmentService.downloadPdf(this.calculateQuery());
    downloadExcel = () => EquipmentService.downloadExcel(this.calculateQuery());

    buildList = () => {
        const { isLoading, collection } = this.state
        const { currentLanguage } = this.props


        if (isLoading) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={9}><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></BodyTableCell>
                </TableRow>
            )
        }

        const items = collection.paginator.pagedItems;

        if (items.length === 0) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={9}>Lista vazia.</BodyTableCell>
                </TableRow>
            )
        }

        // return items.sort((a, b) => EquipmentService.compareLastInspection(a, b)).map((equipment, index) => (
        return items.map((equipment, index) => (
            <TableRow key={index} style={{ borderLeftStyle: 'solid', borderLeftWidth: '0.5rem', borderLeftColor: calculateStatusColor(equipment.status), opacity: equipment.enabledTag ? 1 : 0.5 }}>
                <BodyTableCell align='start'>{formatMainDescription(equipment.product.name, equipment.product.sku)}</BodyTableCell>
                <BodyTableCell align='start'>{formatMainDescription(equipment.extraLabel, equipment.extraCode)}</BodyTableCell>
                <BodyTableCell align='start'>{DateTimeService.formatDate(equipment.createdAt)}</BodyTableCell>
                <BodyTableCell align='start'>
                    <DateWithExpiration
                        date={equipment.dueDate}
                        daysToExpire={equipment.dueDateDaysToExpire}
                        expirationStatus={equipment.dueDateExpirationStatus} />
                </BodyTableCell>
                <BodyTableCell align='start'>{EquipmentService.formatExpirationStatus(equipment.dueDateExpirationStatus, currentLanguage)}</BodyTableCell>
                <BodyTableCell align='start'>
                    <DateWithExpiration
                        date={get(equipment, 'lastInspection.createdAt')}
                        daysToExpire={equipment.lastInspectionDaysToExpire}
                        expirationStatus={equipment.lastInspectionExpirationStatus} />
                </BodyTableCell>
                <BodyTableCell align='start'>{EquipmentService.formatExpirationStatus(equipment.status, currentLanguage)}</BodyTableCell>
                <BodyTableCell align='start'>{formatMainDescription(get(equipment, 'lastInspection.userName'), get(equipment, 'lastInspection.userEmail'))}</BodyTableCell>
                <BodyTableCell align='right'><EquipmentDialogButton equipment={equipment} currentLanguage={currentLanguage} /></BodyTableCell>
            </TableRow>
        ));


    }

    render() {
        const { companies,
            collection,
            categories,
            inspectionTypes,
            products,
            filterQuery,
            filterStatus,
            filterCategoryId,
            filterInspectionTypeId,
            filterProductId,
            filterCompanyId,
            filterEnabledTagsOnly,
            filterCreateFrom,
            filterCreateUpTo,
            filterLastInspectionFrom,
            filterLastInspectionUpTo,
            filterLastInspectionExpirationStatus,
            filterDueDateFrom,
            filterDueDateUpTo,
            filterDueDateExpirationStatus
        } = this.state
        const { currentLanguage } = this.props
        const { updateCollection, handleFilterChange, handleSort } = this.collectionUpdater;

        const categoryInspectionTypes = inspectionTypes.filter(inspectionType => inspectionType.categoryId === filterCategoryId);
        const inspectionTypeProducts = products.filter(product => product.inspectionTypeId === filterInspectionTypeId);

        this.clearFilters = async (event) => {

            event.preventDefault()

            await handleFilterChange({
                categories: [],
                inspectionTypes: [],
                products: [],
                filterQuery: '',
                filterStatus: '',
                filterEnabledTagsOnly: true,
                filterCategoryId: '',
                filterInspectionTypeId: '',
                filterProductId: '',
                filterCompanyId: '',
                filterCreateFrom: null,
                filterCreateUpTo: null,
                filterLastInspectionFrom: null,
                filterLastInspectionUpTo: null,
                filterLastInspectionExpirationStatus: this.view === 'inspections-report' ? 'EXPIRED_OR_EXPIRING' : '',
                filterDueDateFrom: null,
                filterDueDateUpTo: null,
                filterDueDateExpirationStatus: '',
                collection: this.collectionUpdater.collection,
                clearedfilter: true
            })
            this.componentDidMount()

        }


        return (
            <React.Fragment>
                <ContainerDiv>
                    <Typography variant="h5" style={{ fontWeight: 500, fontSize: '1.6rem', textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>{currentLanguage.inspection_reports}</Typography>

                    <FlexSpaceBetweenDiv style={{ flexWrap: 'wrap' }}>
                        <FlexColumnDiv style={{ flex: '1 0 10rem', marginRight: '1rem' }}>
                            <FormControl margin="normal">
                                <TextField
                                    label={currentLanguage.search}
                                    helperText={currentLanguage.equipment_sku + ", " + currentLanguage.equipment_information + " " + currentLanguage.or + " " + currentLanguage.inspected_by}
                                    value={filterQuery}
                                    onChange={event => handleFilterChange({ filterQuery: StringService.normalize(event.target.value) })}
                                />
                            </FormControl>
                            <FormControl margin="normal" style={{ top: '-2.7%' }}>
                                <InputLabel htmlFor="status-select">Status</InputLabel>
                                <Select value={filterStatus} onChange={event => handleFilterChange({ filterStatus: event.target.value })} inputProps={{ id: 'status-select' }}>
                                    <MenuItem value="">{currentLanguage.all.toUpperCase()}</MenuItem>
                                    <MenuItem value="NA">{currentLanguage.na.toUpperCase()}</MenuItem>
                                    <MenuItem value="VALID">{currentLanguage.valid.toUpperCase()}</MenuItem>
                                    <MenuItem value="EXPIRED">{currentLanguage.expired.toUpperCase()}</MenuItem>
                                    <MenuItem value="IMPROPER">{currentLanguage.improper.toUpperCase()}</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControlLabel
                                margin="normal"
                                label={currentLanguage.active_tags_only}
                                control={
                                    <Checkbox
                                        checked={filterEnabledTagsOnly}
                                        onChange={event => handleFilterChange({ filterEnabledTagsOnly: event.target.checked })}
                                    />
                                }
                            />
                        </FlexColumnDiv>

                        <FlexColumnDiv style={{ flex: '1 0 8rem', marginRight: '1rem' }}>
                            <FormControl margin="normal">
                                <InputLabel htmlFor="companyId-select">{currentLanguage.company}</InputLabel>
                                <Select value={filterCompanyId} onChange={event => handleFilterChange({ filterCompanyId: event.target.value })} inputProps={{ id: 'companyId-select' }}>
                                    <MenuItem value="">{currentLanguage.all.toUpperCase()}</MenuItem>
                                    {companies.map(company => (
                                        <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl margin="normal">
                                <InputLabel htmlFor="categoryId-select">{currentLanguage.category}</InputLabel>
                                <Select value={filterCategoryId} onChange={event => handleFilterChange({ filterCategoryId: event.target.value, filterInspectionTypeId: '' })} inputProps={{ id: 'categoryId-select' }}>
                                    <MenuItem value="">{currentLanguage.all.toUpperCase()}</MenuItem>
                                    {categories.map(category => (
                                        <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl margin="normal">
                                <InputLabel htmlFor="inspectionTypeId-select">{currentLanguage.subcategories}</InputLabel>
                                <Select value={filterInspectionTypeId} onChange={event => handleFilterChange({ filterInspectionTypeId: event.target.value })} inputProps={{ id: 'inspectionTypeId-select' }}>
                                    <MenuItem value="">{currentLanguage.all.toUpperCase()}</MenuItem>
                                    {categoryInspectionTypes.map(inspectionType => (
                                        <MenuItem key={inspectionType.id} value={inspectionType.id}>{inspectionType.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl margin="normal">
                                <InputLabel htmlFor="productId-select">{currentLanguage.product}</InputLabel>
                                <Select value={filterProductId} onChange={event => handleFilterChange({ filterProductId: event.target.value })} inputProps={{ id: 'productId-select' }}>
                                    <MenuItem value="">{currentLanguage.all.toUpperCase()}</MenuItem>
                                    {inspectionTypeProducts.map(product => (
                                        <MenuItem key={product.id} value={product.id}>{product.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FlexColumnDiv>


                        <FlexColumnDiv>
                            <FormControl margin="normal">
                                <FlexBaselineDiv style={{ flexWrap: 'wrap' }}>
                                    <div style={{ width: '8rem', textAlign: 'right' }}>{currentLanguage.register}</div>
                                    <FilterDatePicker disableFuture style={{ flex: '1 0 9rem', marginLeft: '1rem' }} value={filterCreateFrom} onChange={date => handleFilterChange({ filterCreateFrom: date })} label={currentLanguage.from} />
                                    <FilterDatePicker disableFuture style={{ flex: '1 0 9rem', marginLeft: '1rem' }} value={filterCreateUpTo} onChange={date => handleFilterChange({ filterCreateUpTo: date })} label={currentLanguage.to} />
                                    <div style={{ flex: '1 0 9rem', marginLeft: '1rem' }} />
                                </FlexBaselineDiv>
                            </FormControl>
                            <FormControl margin="normal">
                                <FlexBaselineDiv style={{ flexWrap: 'wrap' }}>
                                    <div style={{ width: '8rem', textAlign: 'right' }}>{currentLanguage.last_inspection}</div>
                                    <FilterDatePicker disableFuture style={{ flex: '1 0 9rem', marginLeft: '1rem' }} value={filterLastInspectionFrom} onChange={date => handleFilterChange({ filterLastInspectionFrom: date }, console.log(date))} label={currentLanguage.from} />
                                    <FilterDatePicker disableFuture style={{ flex: '1 0 9rem', marginLeft: '1rem' }} value={filterLastInspectionUpTo} onChange={date => handleFilterChange({ filterLastInspectionUpTo: date })} label={currentLanguage.to} />

                                    <FormControl style={{ flex: '1 0 9rem', marginLeft: '1rem' }}>
                                        <InputLabel htmlFor="lastInspectionExpirationStatus-select">Status</InputLabel>
                                        <Select value={filterLastInspectionExpirationStatus} onChange={event => handleFilterChange({ filterLastInspectionExpirationStatus: event.target.value })} inputProps={{ id: 'lastInspectionExpirationStatus-select' }}>
                                            <MenuItem value="">{currentLanguage.all.toUpperCase()}</MenuItem>
                                            <MenuItem value="NA">{currentLanguage.na.toUpperCase()}</MenuItem>
                                            <MenuItem value="VALID">{currentLanguage.valid.toUpperCase()}</MenuItem>
                                            <MenuItem value="EXPIRED">{currentLanguage.expired.toUpperCase()}</MenuItem>
                                            <MenuItem value="EXPIRING"> {currentLanguage.expiring} </MenuItem>
                                            <MenuItem value="EXPIRED_OR_EXPIRING">{currentLanguage.expired_or_expiring}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </FlexBaselineDiv>
                            </FormControl>
                            <FormControl margin="normal">
                                <FlexBaselineDiv style={{ flexWrap: 'wrap' }}>
                                    <div style={{ width: '8rem', textAlign: 'right' }}>{currentLanguage.due_date}</div>
                                    <FilterDatePicker style={{ flex: '1 0 9rem', marginLeft: '1rem' }} value={filterDueDateFrom} onChange={date => handleFilterChange({ filterDueDateFrom: date })} label={currentLanguage.from} />
                                    <FilterDatePicker style={{ flex: '1 0 9rem', marginLeft: '1rem' }} value={filterDueDateUpTo} onChange={date => handleFilterChange({ filterDueDateUpTo: date })} label={currentLanguage.to} />

                                    <FormControl style={{ flex: '1 0 9rem', marginLeft: '1rem' }}>
                                        <InputLabel htmlFor="dueDateExpirationStatus-select">{currentLanguage.status}</InputLabel>
                                        <Select value={filterDueDateExpirationStatus} onChange={event => handleFilterChange({ filterDueDateExpirationStatus: event.target.value })} inputProps={{ id: 'dueDateExpirationStatus-select' }}>
                                            <MenuItem value="">{currentLanguage.all.toUpperCase()}</MenuItem>
                                            <MenuItem value="NA">{currentLanguage.na.toUpperCase()}</MenuItem>
                                            <MenuItem value="VALID">{currentLanguage.valid.toUpperCase()}</MenuItem>
                                            <MenuItem value="EXPIRED">{currentLanguage.expired.toUpperCase()}</MenuItem>
                                            <MenuItem value="EXPIRING">{currentLanguage.expiring}</MenuItem>
                                            <MenuItem value="EXPIRED_OR_EXPIRING">{currentLanguage.expired_or_expiring}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </FlexBaselineDiv>
                            </FormControl>
                        </FlexColumnDiv>
                    </FlexSpaceBetweenDiv>

                </ContainerDiv>

                <ContainerDiv>
                    <RightDiv>
                        <span>{currentLanguage.export}: </span>
                        <Button onClick={this.downloadPdf}><img src={PdfIcon} alt="pdf" />PDF</Button>
                        <Button onClick={this.downloadExcel}><img src={ExcelIcon} alt="excel" />XLSX</Button>
                    </RightDiv>
                </ContainerDiv>

                <OverflowXDiv>
                    <Table id="table-equipments">
                        <TableHead>
                            <PrimaryButton onClick={event => this.clearFilters(event)} style={{ left: '10%', top: '10%', position: "relative" }}>{currentLanguage.clear_fields}</PrimaryButton>
                            <TableRow>
                                <TableCell colSpan={3} />
                                <SectionTableCell colSpan={2} style={{ background: 'rgb(21,110,148)' }}>{currentLanguage.due_date}</SectionTableCell>
                                <SectionTableCell colSpan={4} style={{ background: 'rgb(17,85,119)' }}>{currentLanguage.last_inspection}</SectionTableCell>
                            </TableRow>
                            <TableRow>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} width="25%" field="product.name">{currentLanguage.equipment_sku}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} width="10%" field="extraLabel">{currentLanguage.equipment_information}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} width="10%" field="createdAt">{currentLanguage.register}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} width="10%" field="dueDate">{currentLanguage.date}
                                    <Tooltip
                                        title={currentLanguage.due_date_Tippy}
                                        arrow={true}
                                        arrowSize={'small'}
                                        style={{ position: "relative", right: "20%" }}
                                    ><InfoOutlinedIcon style={{ fontSize: "small", position: "relative", top: "-10px" }} /></Tooltip></SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} width="5%" field="dueDateExpirationStatus">{currentLanguage.status}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} width="10%" field="lastInspection.createdAt">{currentLanguage.date}
                                    <Tooltip
                                        title={currentLanguage.inspection_date_Tippy}
                                        arrow={true}
                                        style={{ position: "relative", right: "20%" }}
                                        arrowSize={'small'}
                                    ><InfoOutlinedIcon style={{ fontSize: "small", position: "relative", top: "-10px" }} /></Tooltip></SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} width="5%" field="status">{currentLanguage.status}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} width="10%" field="lastInspection.userName">{currentLanguage.inspected_by}</SortHeaderCell>
                                <HeaderTableCell style={{ width: '5%' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.buildList()}
                        </TableBody>
                        <TableFooterWithPaginator collection={collection} onCollectionUpdate={updateCollection} colSpan={9} />
                    </Table>
                </OverflowXDiv>
            </React.Fragment>
        );
    }
}

export default withRouter(EquipmentsPage);
