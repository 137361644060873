import React from 'react'
import { TextField } from '@material-ui/core'
import { LoggedUserService } from './loggedUserService'
import {
    Flex1150Div,
    FlexColumnDiv,
    FlexDiv,
    PrimaryButton,
    RightDiv
} from '../components/components'
import { withSnackbar } from 'notistack'
import { StringService } from '../utils/stringService'
import { PhoneInput } from '../components/phoneInput'

class LoggedUserPage extends React.Component {

    state = {
        loggedUser: {},
        loggedUserData: { name: '', phone: '(__) ____-____', occupation: '' }
    }

    componentDidMount () {
        const { onUpdate } = this.props

        LoggedUserService.getLoggedUser().then(loggedUser => {
            onUpdate(loggedUser)

            this.setState({
                loggedUser,
                loggedUserData: {
                    name: loggedUser.name,
                    phone: loggedUser.phone,
                    occupation: loggedUser.occupation
                }
            })
        })
    }

    updateLoggedUserData = newData => this.setState(state => ({ loggedUserData: { ...state.loggedUserData, ...newData } }));

    handleValueChange = field => event => this.updateLoggedUserData({ [field]: event.target.value });
    handleDigitsChange = field => event => this.updateLoggedUserData({ [field]: StringService.extractDigits(event.target.value) });

    handleSubmit = event => {
        const { onUpdate, enqueueSnackbar, currentLanguage } = this.props
        const { loggedUser, loggedUserData } = this.state

        LoggedUserService.update(loggedUserData).then(() => {
            onUpdate({ ...loggedUser, ...loggedUserData })

            enqueueSnackbar(currentLanguage.update_user_success)
        });
    };

    render () {
        const { loggedUser, loggedUserData } = this.state
        const { currentLanguage } = this.props

        return (
            <div style={{ margin: '3rem' }}>
                <FlexDiv>
                    <Flex1150Div>
                        <FlexColumnDiv>
                            <TextField margin="normal" required onChange={this.handleValueChange('name')} value={loggedUserData.name} label={currentLanguage.name} />
                            <TextField margin="normal" disabled value={loggedUser.email || ''} label={currentLanguage.email} />
                            <TextField margin="normal" required onChange={this.handleDigitsChange('phone')} value={loggedUserData.phone} label={currentLanguage.phone} InputProps={{ inputComponent: PhoneInput }}/>
                        </FlexColumnDiv>
                    </Flex1150Div>
                    <Flex1150Div style={{ marginLeft: '3rem' }}>
                        <FlexColumnDiv>
                            <TextField margin="normal" disabled value={loggedUser.companyName || ''} label={currentLanguage.company} />
                            <TextField margin="normal" required onChange={this.handleValueChange('occupation')} value={loggedUserData.occupation} label={currentLanguage.role} />
                        </FlexColumnDiv>
                    </Flex1150Div>
                </FlexDiv>
                <RightDiv>
                    <PrimaryButton onClick={this.handleSubmit}>{currentLanguage.update}</PrimaryButton>
                </RightDiv>
            </div>
        );
    }

}

export default withSnackbar(LoggedUserPage)
