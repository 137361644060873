import { CompanyApi } from './companyApi';

function list () {
    return CompanyApi.list().then(response => response.data);
}

function update (id, companyData) {
    return CompanyApi.update(id, companyData)
        .then(() => ({ id, updateDate: new Date().toISOString(), ...companyData }))
}

function create (companyData) {
    return CompanyApi.create(companyData)
        .then(response => response.data)
        .then(id => ({ id, updateDate: new Date().toISOString(), ...companyData }))
}

export const CompanyService = { list, update, create };
