import moment from 'moment';

const ISO_DATE_FORMAT = 'YYYY-MM-DD';

function formatDate (isoDate) {
    if (!isoDate) {
        return '--/--/----';
    }

    const dateOnly = moment.utc(isoDate, ISO_DATE_FORMAT, true);

    if (dateOnly.isValid()) {
        return dateOnly.format('L');
    }

    return moment.utc(isoDate).format('L');
}

function formatDateTime (isoDate) {
    if (!isoDate) {
        return '--/--/---- --:--';
    }

    return moment.utc(isoDate).format('L HH:mm');
}

function formatMonth (yearMonth) {
    return moment(yearMonth).format('MMM');
}

function formatMonthYear (yearMonth) {
    const date = moment(yearMonth);
    return date.month() === 0 ? date.format('MMM/YYYY') : date.format('MMM')
}

function formatYearJanuaryOnly (yearMonth) {
    const date = moment(yearMonth);
    return date.month() === 0 ? date.format('YYYY') : '';
}

export const DateTimeService = { formatDate, formatDateTime, formatMonth, formatMonthYear, formatYearJanuaryOnly };
