import React from 'react'
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core'
import {
    BodyTableCell,
    ContainerDiv,
    FlexSpaceBetweenDiv,
    HeaderTableCell, OverflowXDiv,
    PrimaryButton
} from '../components/components'
import { DefaultCollection } from '../collection/defaultCollection'
import { StringService } from '../utils/stringService'
import { FilterService } from '../utils/filterService'
import { TableFooterWithPaginator } from '../components/tableFooterWithPaginator'
import { SortHeaderCell } from '../components/sortHeaderCell'
import SearchIcon from '../equipment/images/search_list_ico.png'
import { withSnackbar } from 'notistack'
import { CompanyService } from './companyService'
import { CompanyFormDialogButton } from './companyFormDialogButton'
import { LoggedUserService } from '../loggedUser/loggedUserService'
import { RoleConstant } from '../../app/roleConstant'

class CompaniesPage extends React.Component {
    state = {
        companyData: {id: null, name: ''},
        filterQuery: '',
        filterEnabled: '',
        collection: DefaultCollection.ofItemsAndPredicates([], []),
        loggedUser: {}
    }

    buildPredicates = (items, filterValues) => {
        const { filterQuery, filterEnabled } = filterValues

        return [
            FilterService.containsQuery(filterQuery, items, ['cnpj', 'name', 'mainContactName', 'email', 'phone']),
            FilterService.isSameString('enabled', filterEnabled)
        ]
    }

    componentDidMount () {
        CompanyService.list().then(companies => this.setState(state => ({ collection: DefaultCollection.ofItemsAndPredicates(companies, this.buildPredicates(companies, state)) })))

        LoggedUserService.getLoggedUser().then(loggedUser => {
            this.setState({ loggedUser })
        })
    }

    updateCollection = updater => this.setState(state => ({ collection: updater(state.collection, state) }));

    handleFilterChange = newFilterState => {
        this.setState(newFilterState);

        return this.updateCollection((collection, state) => DefaultCollection.withItems(collection, collection.items, this.buildPredicates(collection.items, state)))
    };
    handleSort = field => this.updateCollection(collection => DefaultCollection.withNextSorter(collection, field));

    updateWithCreatedItem = createdCompany => (collection, state) => DefaultCollection.withCreatedItem(collection, 'id', createdCompany, this.buildPredicates(collection.items, state))
    updateWithUpdatedItem = updatedCompany => (collection, state) => DefaultCollection.withUpdatedItem(collection, 'id', updatedCompany, this.buildPredicates(collection.items, state))

    notify = message => () => this.props.enqueueSnackbar(message)

    handleCreateSubmit = companyData => CompanyService.create(companyData)
        .then(createdCompany => this.updateCollection(this.updateWithCreatedItem(createdCompany)))
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.company_created, companyData.name)))
    handleUpdateSubmit = (companyId, companyData) => CompanyService.update(companyId, companyData)
        .then(updatedCompany => this.updateCollection(this.updateWithUpdatedItem(updatedCompany)))
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.company_updated, companyData.name)))

    render () {
        const { collection, filterQuery, filterEnabled, loggedUser } = this.state
        const { currentLanguage } = this.props
        const { sorter, paginator } = collection
        const pageItems = paginator.pagedItems

        return (
            <React.Fragment>
                <ContainerDiv>
                    <FlexSpaceBetweenDiv style={{flexWrap: 'wrap'}}>
                        <FormControl margin="normal">
                            <TextField label={currentLanguage.search} helperText={currentLanguage.company_name+", "+currentLanguage.cnpj+", "+currentLanguage.main_contact+", "+currentLanguage.email+", "+currentLanguage.or+" "+currentLanguage.phone} value={filterQuery} style={{marginBottom: '1rem'}}
                                       onChange={event => this.handleFilterChange({ filterQuery: event.target.value })} />
                        </FormControl>

                        <FormControl margin="normal" style={{width: '10rem'}}>
                            <InputLabel htmlFor="enabled-select">{currentLanguage.able}</InputLabel>
                            <Select value={filterEnabled} onChange={event => this.handleFilterChange({ filterEnabled: event.target.value })} inputProps={{ id: 'enabled-select' }}>
                                <MenuItem value="">{currentLanguage.all}</MenuItem>
                                <MenuItem value="true">{currentLanguage.yes}</MenuItem>
                                <MenuItem value="false">{currentLanguage.no}</MenuItem>
                            </Select>
                        </FormControl>

                        {loggedUser.currentRole === RoleConstant.MOBCHECK_ADMIN ? 

                        (<CompanyFormDialogButton
                                                 currentLanguage={currentLanguage} 
                                                 submitLabel={currentLanguage.add}
                                                 companyData={{
                                                     name: '',
                                                     cnpj: '__.___.___/____.__',
                                                     mainContactName: '',
                                                     email: '',
                                                     phone: '(__) ____-____',
                                                     enabled: false,
                                                     tagsLimit: '0'
                                                 }}
                                                 onSubmit={this.handleCreateSubmit}
                                                 ModalButton={({onClick}) => (
                                                     <PrimaryButton onClick={onClick} style={{alignSelf: 'center'}}>Adicionar empresa</PrimaryButton>
                                                 )} />)
                        
                        : null}
                    </FlexSpaceBetweenDiv>
                </ContainerDiv>

                <OverflowXDiv>
                <Table>
                    <TableHead>
                        <TableRow>
                            <SortHeaderCell sorter={sorter} onSort={this.handleSort} field="name">{currentLanguage.company_name}</SortHeaderCell>
                            <SortHeaderCell sorter={sorter} onSort={this.handleSort} field="cnpj">{currentLanguage.cnpj}</SortHeaderCell>
                            <SortHeaderCell sorter={sorter} onSort={this.handleSort} field="mainContactName">{currentLanguage.main_contact}</SortHeaderCell>
                            <SortHeaderCell sorter={sorter} onSort={this.handleSort} field="email">{currentLanguage.email}</SortHeaderCell>
                            <SortHeaderCell sorter={sorter} onSort={this.handleSort} field="phone">{currentLanguage.phone}</SortHeaderCell>
                            <SortHeaderCell sorter={sorter} onSort={this.handleSort} field="tagsEnabled">{currentLanguage.tags_qtt_used}</SortHeaderCell>
                            <HeaderTableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pageItems.map(company => (
                            <TableRow key={company.id}>
                                <BodyTableCell>{company.name}</BodyTableCell>
                                <BodyTableCell>{StringService.formatCnpj(company.cnpj)}</BodyTableCell>
                                <BodyTableCell>{company.mainContactName}</BodyTableCell>
                                <BodyTableCell>{company.email}</BodyTableCell>
                                <BodyTableCell>{StringService.formatPhoneNumber(company.phone)}</BodyTableCell>
                                <BodyTableCell>{company.tagsEnabled}</BodyTableCell>
                                <BodyTableCell align="center">
                                    <CompanyFormDialogButton 
                                                             currentLanguage={currentLanguage}
                                                             submitLabel={currentLanguage.update}
                                                             companyData={{
                                                                 name: company.name,
                                                                 cnpj: StringService.extractDigits(company.cnpj),
                                                                 mainContactName: company.mainContactName,
                                                                 email: company.email,
                                                                 phone: StringService.extractDigits(company.phone),
                                                                 enabled: company.enabled,
                                                                 image: company.image,
                                                                 tagsLimit: company.tagsLimit
                                                             }}
                                                             onSubmit={companyData => this.handleUpdateSubmit(company.id, companyData)}
                                                             ModalButton={({onClick}) => (
                                                                 <IconButton onClick={onClick}><img src={SearchIcon} alt="search" /></IconButton>
                                                             )} />
                                </BodyTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooterWithPaginator collection={collection} onCollectionUpdate={this.updateCollection} colSpan={8} />
                </Table>
                </OverflowXDiv>
            </React.Fragment>
        );
    }
}

export default withSnackbar(CompaniesPage)
