import { ConfigSystemApi } from './configSystemApi'

function list () {
    return ConfigSystemApi.list().then(response => response.data)
}

function toggleConfig (configSystem, toggle) {
    return ConfigSystemApi.toggleConfig(configSystem.id, toggle)
}

function updateConfig (configSystem) {
    return ConfigSystemApi.updateConfig(configSystem.id, configSystem)
}

function updateConfigToggle (configSystem, toggle) {
    configSystem.configValue = toggle*1

    
    return ConfigSystemApi.updateConfig(configSystem.id, configSystem.title, configSystem.configType, configSystem.configValue, configSystem.enabled, configSystem.param)
}

export const ConfigSystemService = { list, toggleConfig, updateConfig, updateConfigToggle }
