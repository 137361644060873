import React from 'react';
import {
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core'

import {
    BodyTableCell,
    ContainerDiv,
    FlexBaselineDiv,
    FlexColumnDiv,
    FlexSpaceBetweenDiv,
    formatMainDescription,
    OverflowXDiv
} from '../components/components'
import { DateTimeService } from '../utils/dateTimeService'
import { TagService } from './tagService'
import { SortHeaderCell } from '../components/sortHeaderCell'
import { FilterDatePicker } from '../components/filterDatePicker'
import { FilterService } from '../utils/filterService'
import { DefaultCollection } from '../collection/defaultCollection'
import { StringService } from '../utils/stringService'
import { TableFooterWithPaginator } from '../components/tableFooterWithPaginator'
import { withSnackbar } from 'notistack'
import { PrimaryButton } from '../components/components'

class TagsPage extends React.Component {
    state = {
        filterQuery: '',
        filterIsDvExclusive: '',
        filterEnabled: '',
        filterUpdateFrom: null,
        filterUpdateUpTo: null,
        collection: DefaultCollection.ofItemsAndPredicates([], [])
    }

    buildPredicates = (items, filterValues) => {
        const { filterQuery, filterIsDvExclusive, filterEnabled, filterUpdateFrom, filterUpdateUpTo } = filterValues;

        return [
            FilterService.containsQuery(filterQuery, items, ['serialNumber', 'equipmentExtraLabel', 'equipmentExtraCode', 'productName']),
            FilterService.isSameString('productIsDvExclusive', filterIsDvExclusive),
            FilterService.isSameString('enabled', filterEnabled),
            FilterService.isFromValid('updateDate', filterUpdateFrom),
            FilterService.isUpToValid('updateDate', filterUpdateUpTo)
        ]
    }

    componentDidMount() {
        TagService.list().then(tags => this.setState(state => ({ collection: DefaultCollection.ofItemsAndPredicates(tags, this.buildPredicates(tags, state)) })));
    }

    updateCollection = updater => this.setState(state => ({ collection: updater(state.collection, state) }));

    updateWithUpdatedItem = updatedTag => (collection, state) => DefaultCollection.withUpdatedItem(collection, 'serialNumber', updatedTag, this.buildPredicates(collection.items, state));
    notify = message => () => this.props.enqueueSnackbar(message);

    toggleEnabled = (tag, toggle) => TagService.toggleTag(tag.serialNumber, toggle)
        .then(() => this.updateCollection(this.updateWithUpdatedItem({ ...tag, enabled: toggle })))
        .then(this.notify(`Tag ${tag.serialNumber} ${toggle ? this.props.currentLanguage.activated : this.props.currentLanguage.disabled}`));

    handleFilterChange = newFilterState => {
        this.setState(newFilterState);

        return this.updateCollection((collection, state) => DefaultCollection.withItems(collection, collection.items, this.buildPredicates(collection.items, state)));
    };
    handleSort = field => this.updateCollection(collection => DefaultCollection.withNextSorter(collection, field));

    render() {
        const { allowTagToggle, currentLanguage, loggedUser } = this.props
        const { collection, filterQuery, filterIsDvExclusive, filterUpdateFrom, filterUpdateUpTo, filterEnabled } = this.state;
        const pageItems = collection.paginator.pagedItems

        this.clearFilters = (event) => {

            event.preventDefault();

            this.handleFilterChange({
                filterQuery: '',
                filterIsDvExclusive: '',
                filterEnabled: '',
                filterUpdateFrom: null,
                filterUpdateUpTo: null,
                collection: DefaultCollection.ofItemsAndPredicates([], [])
            })

            this.componentDidMount()

        }

        return (
            <React.Fragment>
                <ContainerDiv>
                    <FlexSpaceBetweenDiv style={{ flexWrap: 'wrap' }}>
                        <FormControl margin="normal" style={{ flex: '1 0 10rem' }} >
                            <TextField label={currentLanguage.search} helperText={currentLanguage.equipment_serial + ", " + currentLanguage.description + ", " + currentLanguage.or + " " + currentLanguage.name} value={filterQuery}
                                onChange={event => this.handleFilterChange({ filterQuery: StringService.normalize(event.target.value) })} />
                        </FormControl>

                        <FlexColumnDiv style={{ flex: '1 0 10rem', marginLeft: '1rem' }} >
                            <FormControl margin="normal">
                                <InputLabel htmlFor="isDvExclusive-select">{currentLanguage.product_exclusive}</InputLabel>
                                <Select value={filterIsDvExclusive} onChange={event => this.handleFilterChange({ filterIsDvExclusive: event.target.value })} inputProps={{ id: 'isDvExclusive-select' }}>
                                    <MenuItem value="">{currentLanguage.all}</MenuItem>
                                    <MenuItem value="true">{currentLanguage.yes}</MenuItem>
                                    <MenuItem value="false">{currentLanguage.no}</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl margin="normal">
                                <InputLabel htmlFor="enabled-select">{currentLanguage.active_tag}</InputLabel>
                                <Select value={filterEnabled} onChange={event => this.handleFilterChange({ filterEnabled: event.target.value })} inputProps={{ id: 'enabled-select' }}>
                                    <MenuItem value="">{currentLanguage.all}</MenuItem>
                                    <MenuItem value="true">{currentLanguage.yes}</MenuItem>
                                    <MenuItem value="false">{currentLanguage.no}</MenuItem>
                                </Select>
                            </FormControl>
                        </FlexColumnDiv>

                        <FormControl margin="normal">
                            <FlexBaselineDiv style={{ flexWrap: 'wrap' }}>
                                <div style={{ flex: '1 0 8rem', textAlign: 'right' }}>{currentLanguage.last_update}</div>
                                <FilterDatePicker disableFuture style={{ flex: '1 0 9rem', marginLeft: '1rem' }} value={filterUpdateFrom} onChange={date => this.handleFilterChange({ filterUpdateFrom: date })} label={currentLanguage.from} />
                                <FilterDatePicker disableFuture style={{ flex: '1 0 9rem', marginLeft: '1rem' }} value={filterUpdateUpTo} onChange={date => this.handleFilterChange({ filterUpdateUpTo: date })} label={currentLanguage.to} />
                            </FlexBaselineDiv>
                        </FormControl>
                    </FlexSpaceBetweenDiv>
                    <PrimaryButton onClick={event => this.clearFilters(event)} style={{ position: "relative", left: '0%' }}>{currentLanguage.clear_fields}</PrimaryButton>
                </ContainerDiv>

                <div style={{ paddingLeft: '1rem' }}>Tags utilizadas: {loggedUser.active_tags_qty}</div>
                <br />
                <div style={{ paddingLeft: '1rem' }}>* {currentLanguage.list_tag_table_description}</div>

                <OverflowXDiv>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <SortHeaderCell sorter={collection.sorter} onSort={this.handleSort} field="equipmentSerialNumber">{currentLanguage.equipment_serial}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={this.handleSort} field="equipmentExtraLabel">{currentLanguage.description}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={this.handleSort} field="productName">{currentLanguage.product_name}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={this.handleSort} field="productIsDvExclusive" align="center">{currentLanguage.product_exclusive}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={this.handleSort} field="updateDate" align="center">{currentLanguage.last_update}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={this.handleSort} field="enabled" align="center">{currentLanguage.active_tag}</SortHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pageItems.map(tag => (
                                <TableRow key={tag.serialNumber}>
                                    <BodyTableCell>{tag.serialNumber}</BodyTableCell>
                                    <BodyTableCell>{formatMainDescription(tag.equipmentExtraLabel, tag.equipmentExtraCode)}</BodyTableCell>
                                    <BodyTableCell>{tag.productName}</BodyTableCell>
                                    <BodyTableCell align="center">{tag.productIsDvExclusive ? currentLanguage.yes : currentLanguage.no}</BodyTableCell>
                                    <BodyTableCell align="center">{DateTimeService.formatDate(tag.updateDate)}</BodyTableCell>
                                    <BodyTableCell align="center"><Checkbox color="primary" checked={tag.enabled} disabled={!allowTagToggle} onChange={event => this.toggleEnabled(tag, event.target.checked)} /></BodyTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooterWithPaginator collection={collection} onCollectionUpdate={this.updateCollection} colSpan={6} />
                    </Table>
                </OverflowXDiv>
            </React.Fragment>
        );
    }
}

export default withSnackbar(TagsPage);
