import React from 'react';
import { Dialog } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { CategoryService } from './categoryService';
import { UniqueNonEmptyValidator } from '../utils/uniqueNonEmptyValidator';
import { CategoryDialogTabGeneral } from './categoryDialogTabGeneral';
import { CategoryDialogTabInspectionAttributes } from './categoryDialogTabInspectionAttributes';
import { CategoryDialogTabMaintenanceAttributes } from './categoryDialogTabMaintenanceAttributes';

export class CategoryDialogButton extends React.Component {

    constructor (props) {
        super(props);

        const { categoryData } = props;

        this.state = {
            tabValue: 0,
            isDialogOpen: false,
            inspectionAttributes: null,
            maintenanceAttributes: null,
            categoryData
        };
    }

    handleDialogOpen = () => {
        const { categoryId, categoryData } = this.props;

        this.setState({
            tabValue: 0,
            isDialogOpen: true,
            inspectionAttributes: null,
            maintenanceAttributes: null,
            categoryData
        });

        CategoryService.listInspectionAttributes(categoryId).then(inspectionAttributes => this.setState({ inspectionAttributes }));
        CategoryService.listMaintenanceAttributes(categoryId).then(maintenanceAttributes => this.setState({ maintenanceAttributes }));
    };

    closeDialog = () => this.setState({ isDialogOpen: false })

    handleSubmit = () => {
        const { onSubmit } = this.props
        const { categoryData, inspectionAttributes, maintenanceAttributes } = this.state

        return onSubmit(categoryData, inspectionAttributes, maintenanceAttributes).then(this.closeDialog)
    };

    updateState = (stateData) => this.setState(state => stateData)

    showTabContent = () => {
        const { submitLabel, currentLanguage } = this.props
        const { tabValue, categoryData, inspectionAttributes, maintenanceAttributes } = this.state

        const inspectionAttributesValidator = UniqueNonEmptyValidator.ofNullable(inspectionAttributes, 'name');
        const maintenanceAttributesValidator = UniqueNonEmptyValidator.ofNullable(maintenanceAttributes, 'name');

        const validInspectionAttributes = inspectionAttributesValidator.isAllValidAndNonEmpty();
        const validMaintenanceAttributes = maintenanceAttributesValidator.isAllValidAndNonEmpty();

        switch (tabValue) {
            case 0: return (
                <CategoryDialogTabGeneral
                    currentLanguage={currentLanguage}
                    submitLabel={submitLabel}
                    categoryData={categoryData}
                    allValidAndNonEmpty={validInspectionAttributes && validMaintenanceAttributes}
                    closeDialog={this.closeDialog}
                    handleSubmit={this.handleSubmit}
                    updateState={this.updateState}
                />
            );
            case 1: return (
                <CategoryDialogTabInspectionAttributes
                    submitLabel={submitLabel}
                    inspectionAttributes={inspectionAttributes}
                    allValidAndNonEmpty={validInspectionAttributes && validMaintenanceAttributes}
                    inspectionAttributesValidator={inspectionAttributesValidator}
                    closeDialog={this.closeDialog}
                    handleSubmit={this.handleSubmit}
                    updateState={this.updateState}
                />
            );
            case 2: return (
                <CategoryDialogTabMaintenanceAttributes
                    submitLabel={submitLabel}
                    maintenanceAttributes={maintenanceAttributes}
                    allValidAndNonEmpty={validInspectionAttributes && validMaintenanceAttributes}
                    maintenanceAttributesValidator={maintenanceAttributesValidator}
                    closeDialog={this.closeDialog}
                    handleSubmit={this.handleSubmit}
                    updateState={this.updateState}
                />
            );
            default: throw new Error(`unimplemented: ${tabValue}`);
        }
    };

    render () {
        const { tabValue, isDialogOpen } = this.state
        const { ModalButton, currentLanguage } = this.props

        return (
            <React.Fragment>
                <ModalButton onClick={this.handleDialogOpen} />
                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">
                    <Tabs value={tabValue} onChange={(event, tabValue) => this.setState({ tabValue })}>
                        <Tab style={{ color: 'white' }} label={currentLanguage.general_information} />
                        {/*<Tab style={{ color: 'white' }} label="Atributos de Inspeção" />
                        <Tab style={{ color: 'white' }} label="Atributos de Manutenção" />*/}
                    </Tabs>
                    {this.showTabContent()}
                </Dialog>
            </React.Fragment>
        );
    }

}

