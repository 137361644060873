import { isEmpty } from 'lodash';
import * as EmailValidator from 'email-validator';
import { StringService } from '../../authenticated/utils/stringService';

function isNonEmpty (value) {
    return !isEmpty(value);
}

function isValidEmail (email) {
    return EmailValidator.validate(email);
}

function isValidPhone (phone) {
    return StringService.extractDigits(phone) !== null;
}

export class RegisterUserValidator {

    constructor (nonEmptyName, validEmail, validPhone, validOccupation, validCompany, validPassword, acceptedUsageTerms, allValid) {
        this.nonEmptyName = nonEmptyName;
        this.validEmail = validEmail;
        this.validPhone = validPhone;
        this.validOccupation = validOccupation;
        this.validCompany = validCompany;
        this.validPassword = validPassword;
        this.acceptedUsageTerms = acceptedUsageTerms;
        this.allValid = allValid;

        Object.freeze(this);
    }

    static of (name, email, phone, occupation, company, passwordValidator, usageTermsAccepted) {
        const nonEmptyName = isNonEmpty(name);
        const validEmail = isValidEmail(email);
        const validPhone = isValidPhone(phone);
        const validOccupation = isNonEmpty(occupation);
        const validCompany = isNonEmpty(company);
        const validPassword = passwordValidator.allValid;
        const acceptedUsageTerms = usageTermsAccepted;
        const allValid = (nonEmptyName && validEmail && validPhone && validOccupation && validCompany && validPassword && acceptedUsageTerms);

        return new RegisterUserValidator(
            nonEmptyName,
            validEmail,
            validPhone,
            validOccupation,
            validCompany,
            validPassword,
            acceptedUsageTerms,
            allValid
        );
    }

}
