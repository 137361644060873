import React from 'react'
import { Button, DialogActions, DialogContent, Typography } from '@material-ui/core'
import { FlexBaselineDiv } from '../components/components'
import GoogleMapReact from 'google-map-react'

const AnyReactComponent = ({ text }) => (
    <div style={{
      color: 'white', 
      background: 'red',
      padding: '5px 5px',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)'
    }}>
      {text}
    </div>
  );

export class EquipmentDialogTabMaps extends React.Component {
    buildAttribute = (inspectionTypeAttribute, index) => {
        return (
            <FlexBaselineDiv key={index}>
                <div>{inspectionTypeAttribute.attribute}: {inspectionTypeAttribute.answer}</div>
            </FlexBaselineDiv>
        )
    }

    render () {
        const { closeDialog, inspection, currentLanguage } = this.props

        return (
            <React.Fragment>
                <DialogContent style={{height: 'calc(100vh - 20rem)', padding: '0rem'}}>
                    {inspection.latitude && inspection.longitude ? 
                    <div style={{width: '100%', height: '50vh'}}>   
                        
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyC_ihG198tOATBUO7GVpwp5sOWhFd2nifY" }}
                            defaultCenter={{lat: inspection.latitude, lng: inspection.longitude}}
                            defaultZoom={11}>

                            <AnyReactComponent 
                                lat={inspection.latitude} 
                                lng={inspection.longitude} 
                                text={''} />

                        </GoogleMapReact> 
                        
                    </div>
                    : 
                    <Typography style={{fontSize: '1rem', color:'#000000', textAlign: 'center', marginTop: '10rem',}}> Inspeção sem Localização. </Typography> }
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between'}}>
                    <div>
                        <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                    </div>
                </DialogActions>
            </React.Fragment>
        )
    }

}