import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Typography,
    IconButton,
} from '@material-ui/core'
import { Info } from '@material-ui/icons'
import { Autocomplete, TextField } from '@mui/material'
import { Flex1150Div, FlexColumnDiv, FlexDiv, PrimaryButton, FlexBaselineDiv } from '../components/components';
import { CollectionViewUpdater } from '../utils/collectionViewUpdater';
import { FilterDatePicker } from '../components/filterDatePicker';
import { OsService } from './osService';
import { getPriorityLabel, getStatusLabel, getTypeLabel } from './utilsOS/getString';

export class OsFormDialogUpdateOS extends React.Component {

    constructor(props) {
        super(props)
        const { osData } = props

        this.collectionUpdater = CollectionViewUpdater.ofEquipments(this);
        this.state = {
            isDialogOpen: false,
            osData,
            userList: [],
            loggedUser: {},
            listStatusDisable: [],
            clearAutoClompletes: 'key'
        }
    }

    handleDialogOpen = async () => {
        const { osData } = this.props

        this.setState({ isDialogOpen: true, osData })

        const response = await OsService.equipments();

        if (!!osData?.equipments && osData?.equipments?.length > 0) {
            let listEquipSelected = [];
            osData.equipments.forEach(equip => {
                const findEquipSelected = response?.find(equipResponse => equipResponse?.tagSerialNumber === equip?.equipmentSerialNumber);

                if (!!findEquipSelected) {
                    listEquipSelected.push({ ...equip, label: findEquipSelected?.product?.name });
                }
            });

            this.setState(state => {
                return {
                    osData: {
                        ...state.osData,
                        equipments: listEquipSelected
                    }
                }
            })

        }

        OsService.userList().then(userList => this.setState({ userList: userList.map(user => ({ id: user.id, label: user.name, ...user })) }));
        this.getStatusError();
    }

    getStatusError = (listEquip) => {
        if (this.verifyAutomaticStatusOS(listEquip) === 'PENDING') {
            this.setState({
                listStatusDisable: [
                    {
                        id: 'FINISHED',
                        errorMessage: 'Conclua todas as inspeções para finalizar.'
                    },
                    {
                        id: 'PARTIAL',
                        errorMessage: 'Conclua ao menos inspeção para alterar o status como PARCIAL.'
                    },
                ],
            })
        }
        if (this.verifyAutomaticStatusOS(listEquip) === 'PARTIAL') {
            this.setState({
                listStatusDisable: [
                    {
                        id: 'PENDING',
                        errorMessage: 'Para estatus PENDENTE, não pode haver inspeções concluídas.'
                    },
                    {
                        id: 'FINISHED',
                        errorMessage: 'Conclua todas as inspeções para finalizar.'
                    },
                ],
            })
        }
        if (this.verifyAutomaticStatusOS(listEquip) === 'FINISHED') {
            this.setState({
                listStatusDisable: [
                    {
                        id: 'PENDING',
                        errorMessage: 'Para estatus PENDENTE, não pode haver inspeções concluídas.'
                    },
                    {
                        id: 'PARTIAL',
                        errorMessage: 'Para status PARCIAL é necessário que ao menos uma inspeção esteja sem data de conclusão.'
                    },
                ],
            })
        }
        if (this.verifyAutomaticStatusOS(listEquip) === 'CANCELLED') {
            this.setState({
                listStatusDisable: [
                    {
                        id: 'PENDING',
                        errorMessage: 'Não é possível alterar o status de uma OS cancelada.'
                    },
                    {
                        id: 'PARTIAL',
                        errorMessage: 'Não é possível alterar o status de uma OS cancelada.'
                    },
                    {
                        id: 'FINISHED',
                        errorMessage: 'Não é possível alterar o status de uma OS cancelada.'
                    },
                ],
            })
        }
        this.setState({
            clearAutoClompletes: 'keyChange'
        })
        return this.verifyAutomaticStatusOS(listEquip);
    }

    closeDialog = () => this.setState({ isDialogOpen: false })

    updateCompanyData = newData => this.setState(state => ({
        osData: { ...state.osData, ...newData }
    }))

    handleButtonErrorStatus = (id) => {
        const { enqueueSnackbar } = this.props;
        const { listStatusDisable } = this.state;
        const statusDisable = listStatusDisable.find(statusError => statusError.id === id);

        enqueueSnackbar(statusDisable.errorMessage, {
            variant: 'error',
        })
    }

    handleValueChange = field => event => this.updateCompanyData({ [field]: event.target.value });

    handleSubmit = () => {
        const { osData } = this.state
        const dataRequest = {
            startDate: osData?.startDate,
            endDate: osData?.endDate,
            requestUserId: osData?.requestUserId,
            responsibleUserId: osData?.responsibleUserId,
            status: osData?.status,
            priority: osData?.priority,
            observation: osData?.observation,
            equipments: osData?.equipments?.map(equip => ({
                id: equip?.id,
                serviceOrderId: equip?.serviceOrderId,
                equipmentSerialNumber: equip?.equipmentSerialNumber,
                name: equip?.name,
                type: osData?.type,
                responsibleUserId: osData?.responsibleUserId,
                completionDate: equip?.completionDate,
            })),
        }
        this.props.onSubmit(dataRequest).then(this.closeDialog)
    }

    onChangeCompletionDate = (date) => {
        const { osData } = this.state;
        const updatedEquipmentList = osData?.equipments.map(equip => {
            if (equip.equipmentSerialNumber === osData?.equipmentSerialNumber) {
                return {
                    ...equip,
                    completionDate: date
                }
            } else return equip;
        })

        this.setState(state => {
            return {
                osData: {
                    ...state.osData,
                    equipments: updatedEquipmentList
                },
                clearAutoClompletes: 'keyChange'
            }
        })

        const newStatus = this.getStatusError(updatedEquipmentList);

        this.setState(state => ({
            osData: {
                ...state.osData,
                status: newStatus
            },
            clearAutoClompletes: 'keyChange'
        }))
    }

    verifyAutomaticStatusOS = (listEquip) => {
        const { osData } = this.state;
        let amountItensWithDate = 0;

        if (osData?.status === "CANCELLED") {
            return "CANCELLED";
        }

        if (!!listEquip && listEquip?.length > 0) {
            listEquip.forEach(equip => {
                if (!!equip?.completionDate && equip?.completionDate !== null && equip?.completionDate !== '') {
                    amountItensWithDate += 1;
                }
            });
            if (amountItensWithDate === 0) return 'PENDING'
            if (amountItensWithDate === osData.equipments?.length) return 'FINISHED'
            if (amountItensWithDate > 0 && amountItensWithDate < osData.equipments?.length) return 'PARTIAL'
        } else {
            if (!!osData.equipments && osData.equipments?.length > 0) {
                osData.equipments.forEach(equip => {
                    if (!!equip?.completionDate && equip?.completionDate !== null && equip?.completionDate !== '') {
                        amountItensWithDate += 1;
                    }
                });
                if (amountItensWithDate === 0) return 'PENDING'
                if (amountItensWithDate === osData.equipments?.length) return 'FINISHED'
                if (amountItensWithDate > 0 && amountItensWithDate < osData.equipments?.length) return 'PARTIAL'
            }
        }
    }

    render() {
        const { isDialogOpen, osData, userList, listStatusDisable } = this.state
        const { submitLabel, ModalButton, currentLanguage, idOS } = this.props

        return (
            <React.Fragment>
                <ModalButton onClick={this.handleDialogOpen} />
                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">

                    <FlexDiv
                        style={{ alignItems: 'center', justifyContent: 'center', background: 'rgb(14, 74, 100)' }}>
                        <Typography variant="subtitle1" style={{ fontWeight: 500, fontSize: '1.2rem', textAlign: 'center', marginTop: '0.5rem', marginBottom: '0.5rem', color: '#ffffff' }}>
                            {`${currentLanguage.change_os_singular} de número: ${idOS}`}
                        </Typography>
                    </FlexDiv>
                    <DialogContent>
                        <FlexDiv>
                            <Flex1150Div>
                                <FlexColumnDiv>
                                    <FlexBaselineDiv style={{ flexWrap: 'wrap' }}>
                                        <FilterDatePicker
                                            style={{ flex: '1 0 9rem' }}
                                            value={osData?.startDate}
                                            onChange={date => this.updateCompanyData({ startDate: date })}
                                            label={currentLanguage.start_date}
                                            required
                                        />
                                    </FlexBaselineDiv>

                                    <TextField
                                        margin="normal"
                                        variant="standard"
                                        label={currentLanguage.comments}
                                        value={osData?.observation}
                                        onChange={event => this.updateCompanyData({ observation: event.target.value })}
                                        required
                                    />

                                    <FormControl margin="normal">
                                        <Autocomplete
                                            options={[
                                                { id: 'LOWEST', label: currentLanguage.priority_lowest },
                                                { id: 'LOW', label: currentLanguage.priority_low },
                                                { id: 'AVERAGE', label: currentLanguage.priority_average },
                                                { id: 'HIGH', label: currentLanguage.priority_high },
                                                { id: 'HIGHEST', label: currentLanguage.priority_very_high },
                                            ]}
                                            onChange={(event, newValue) => this.updateCompanyData({ priority: newValue?.id ?? '' })}
                                            defaultValue={!!osData?.priority ? { id: osData.priority, label: getPriorityLabel(osData.priority, currentLanguage) } : null}
                                            key={this?.state?.clearAutoClompletes}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => <TextField
                                                required
                                                {...params}
                                                fullWidth
                                                variant="standard"
                                                label={currentLanguage.priority}
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />}
                                        />
                                    </FormControl>

                                    <FormControl style={{ flex: 1 }} margin="normal">
                                        <Autocomplete
                                            options={[
                                                { id: 'INSPECTION', label: currentLanguage.os_inspection },
                                                { id: 'MAINTENANCE', label: currentLanguage.os_maintenance },
                                            ]}
                                            onChange={(event, newValue) => this.updateCompanyData({ type: newValue?.id ?? '' })}
                                            defaultValue={!!osData.type ? { id: osData.type, label: getTypeLabel(osData.type, currentLanguage) } : null}
                                            key={this?.state?.clearAutoClompletes}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => <TextField
                                                required
                                                {...params}
                                                fullWidth
                                                variant="standard"
                                                label={currentLanguage.os_type}
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />}
                                        />
                                    </FormControl>
                                </FlexColumnDiv>
                            </Flex1150Div>

                            <Flex1150Div style={{ marginLeft: '1rem' }}>
                                <FlexColumnDiv>
                                    <FlexBaselineDiv style={{ flexWrap: 'wrap' }}>
                                        <FilterDatePicker
                                            style={{ flex: '1 0 9rem' }}
                                            onChange={date => this.updateCompanyData({ endDate: date })}
                                            label={currentLanguage.end_date}
                                            value={osData?.endDate}
                                            required
                                        />
                                    </FlexBaselineDiv>

                                    <FormControl margin="normal">
                                        <Autocomplete
                                            id="country-select-demo"
                                            options={userList}
                                            defaultValue={!!osData.responsibleUserId ? { id: osData.responsibleUserId, label: osData.responsibleUserName } : null}
                                            onChange={(event, newValue) => this.updateCompanyData({ responsibleUserId: newValue?.id ?? '' })}
                                            key={this?.state?.clearAutoClompletes}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    variant="standard"
                                                    label={currentLanguage.responsible_user}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                />
                                            }
                                        />
                                    </FormControl>

                                    <FormControl margin="normal">
                                        <Autocomplete
                                            options={[
                                                { id: 'PENDING', label: currentLanguage.status_pending },
                                                { id: 'PARTIAL', label: currentLanguage.status_partial },
                                                { id: 'FINISHED', label: currentLanguage.status_finished },
                                                { id: 'CANCELLED', label: currentLanguage.status_cancelled },
                                            ]}
                                            onChange={(event, newValue) => this.updateCompanyData({ status: newValue?.id ?? '' })}
                                            defaultValue={!!osData.status ? { id: osData.status, label: getStatusLabel(osData.status, currentLanguage) } : null}
                                            value={!!osData.status ? { id: osData.status, label: getStatusLabel(osData.status, currentLanguage) } : null}
                                            getOptionLabel={(option) => option.label}
                                            key={this?.state?.clearAutoClompletes}
                                            renderOption={(props, option, { selected }) => {
                                                const statusDisable = listStatusDisable?.find(statusError => statusError?.id === option?.id);
                                                return (
                                                    <li
                                                        {...props}
                                                        onClick={statusDisable?.id === option.id ? () => { } : props.onClick}
                                                    >
                                                        <FlexDiv style={{ flex: 1, alignItems: "center", justifyContent: 'space-between' }}>
                                                            <span style={{ opacity: statusDisable?.id === option.id ? 0.5 : 1 }}>
                                                                {option.label}</span>{statusDisable?.id === option.id &&
                                                                    <IconButton onClick={() => this.handleButtonErrorStatus(option.id)}>
                                                                        <Info color='error' />
                                                                    </IconButton>}
                                                        </FlexDiv>

                                                    </li>
                                                )
                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    variant="standard"
                                                    label={currentLanguage.os_status}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </FlexColumnDiv>
                            </Flex1150Div>
                        </FlexDiv>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog}>{currentLanguage.cancel}</Button>
                        <PrimaryButton onClick={this.handleSubmit}>{submitLabel}</PrimaryButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }

}

