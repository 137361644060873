import React from 'react';
import { Button, DialogActions, DialogContent, TextField } from '@material-ui/core'
import { FlexSpaceBetweenDiv, PrimaryButton } from '../components/components'
import ReplaceImage from './images/replace_tag.png'
import Spinner from 'react-spinner-material'
import styled from 'styled-components'
import { TagService } from './tagService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const STAGES = Object.freeze({
    REPLACE_EQUIPMENT: 'REPLACE_EQUIPMENT',
    CHOOSE_EQUIPMENT: 'CHOOSE_EQUIPMENT'
})

const Suggestion = styled.div`
    cursor: pointer;
    &:hover {
        color: rgb(213, 117, 48);
    };
`;

export class TagReplaceDialogTabGeneral extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            searchSerialEquipment: null,
            chosenEquipment: null,
            showAlert: false,
            stage: STAGES.REPLACE_EQUIPMENT
        }
    }

    showReplaceEquipment = () => this.setState({ stage: STAGES.REPLACE_EQUIPMENT })
    showChooseEquipment = () => this.setState({ stage: STAGES.CHOOSE_EQUIPMENT })

    buildSuggestedEquipment = () => {
        const { equipment, currentLanguage } = this.props
        const { chosenEquipment } = this.state

        if (chosenEquipment) {
            return (
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '1 1 auto', textAlign: 'center' }}>
                        <div><b>{currentLanguage.new_tag_information}</b></div>
                        <img style={{ height: '6rem' }} alt="preview" src={`/web/products/${equipment.productId}/image`} />
                        <div style={{ marginTop: '12px' }}>{equipment.productName}</div>
                        <div style={{ marginTop: '1rem' }}>{currentLanguage.tag} #{equipment.tagSerialNumber}</div>
                        <div>{currentLanguage.equipment_information}: {equipment.extraLabel}</div>
                        <div>{currentLanguage.number_of_control}: {equipment.extraCode}</div>
                    </div>
                    <div style={{ flex: '1 1 auto', marginTop: '6rem', textAlign: 'center' }}>
                        <img src={ReplaceImage} width="150" alt="search" />
                    </div>
                    <div style={{ flex: '1 1 auto', textAlign: 'center' }}>

                        <div><b>{currentLanguage.old_tag_information}</b></div>
                        <div style={{ fontSize: '12px' }}>*{currentLanguage.recommended_transfer_equipment}</div>
                        <img style={{ height: '6rem' }} alt="preview" src={`/web/products/${chosenEquipment.productId}/image`} />
                        <div>{chosenEquipment.productName}</div>
                        <div style={{ marginTop: '1rem' }}>{currentLanguage.tag} #{chosenEquipment.tagSerialNumber}</div>
                        <div>{currentLanguage.equipment_information}: {chosenEquipment.extraLabel}</div>
                        <div>{currentLanguage.number_of_control}: {chosenEquipment.extraCode}</div>

                        <PrimaryButton style={{ alignSelf: 'center', marginTop: '1rem' }} onClick={this.showChooseEquipment}>
                            {currentLanguage.find_other_equipment}
                        </PrimaryButton>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '1 1 auto', textAlign: 'center' }}>
                        <div><b>{currentLanguage.new_tag_information}</b></div>
                        <img style={{ height: '6rem' }} alt="preview" src={`/web/products/${equipment.productId}/image`} />
                        <div style={{ marginTop: '12px' }}>{equipment.productName}</div>
                        <div style={{ marginTop: '1rem' }}>{currentLanguage.tag} #{equipment.tagSerialNumber}</div>
                        <div>{currentLanguage.equipment_information}: {equipment.extraLabel}</div>
                        <div>{currentLanguage.number_of_control}: {equipment.extraCode}</div>
                    </div>
                    <div style={{ flex: '1 1 auto', marginTop: '6rem', textAlign: 'center' }}>
                        <img src={ReplaceImage} width="150" alt="search" />
                    </div>
                    <div style={{ flex: '1 1 auto', textAlign: 'center' }}>
                        <div><b>{currentLanguage.old_tag_information}</b></div>
                        <PrimaryButton style={{ alignSelf: 'center', marginTop: '1rem' }} onClick={this.showChooseEquipment}>
                            {currentLanguage.find_equipment}
                        </PrimaryButton>
                    </div>
                </div>
            )
        }
    }

    buildChooseEquipmentContent = () => {
        const { currentLanguage } = this.props
        const { chosenEquipment, searchSerialEquipment } = this.state

        return (
            <React.Fragment>
                <FlexSpaceBetweenDiv style={{ width: '100%' }}>
                    <div>
                        <div>{currentLanguage.selected_equipment}: <span style={{ fontWeight: 500, fontSize: '1.2rem' }}> {chosenEquipment === null ? currentLanguage.none : `#${chosenEquipment.tagSerialNumber}`} </span> </div>
                    </div>
                </FlexSpaceBetweenDiv>

                <div style={{ borderTop: '1px solid gray', width: '100%', textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}></div>

                <FlexSpaceBetweenDiv style={{ width: '100%' }}>
                    <TextField
                        label={currentLanguage.search}
                        helperText={currentLanguage.equipment_serial+", "+currentLanguage.name+", "+currentLanguage.description+" "+currentLanguage.or+" "+currentLanguage.number_of_control}
                        value={searchSerialEquipment === null ? '' : searchSerialEquipment}
                        onFocus={event => event.target.setSelectionRange(0, -1)}
                        onChange={event => this.setState({ searchSerialEquipment: event.target.value })}
                    />
                </FlexSpaceBetweenDiv>
                <div style={{ height: '8rem', width: '100%', overflowY: 'auto', marginTop: '2rem' }}>
                    {this.buildChooseEquipmentProductList()}
                </div>
            </React.Fragment>
        )
    }

    buildChooseEquipmentProductList = () => {
        const { searchSerialEquipment } = this.state
        const { equipmentList, currentLanguage } = this.props

        const equipmentListAux = equipmentList.filter(function (equipment, i, array) {
            return String(equipment.tagSerialNumber).includes(searchSerialEquipment) || searchSerialEquipment === null
        })

        if (equipmentListAux.length === 0) {
            return (
                <div>{currentLanguage.empty_list}</div>
            )
        }

        return equipmentListAux.map(e => (
            <Suggestion
                key={e.tagSerialNumber}
                onClick={event => this.setState({ chosenEquipment: e })}
            >
                <b>{e.tagSerialNumber}</b> | {e.productName} - {e.extraLabel} {e.extraCode}
            </Suggestion>
        ))
    };

    buildChooseEquipmentButtons = () => {
        const { chosenEquipment } = this.state
        const { currentLanguage } = this.props

        return (
            <React.Fragment>
                <div>
                    <Button onClick={this.showReplaceEquipment}>Voltar</Button>
                </div>

                <div>
                    <PrimaryButton
                        style={{ alignSelf: 'center' }}
                        disabled={chosenEquipment === null}
                        onClick={this.showReplaceEquipment}
                    >

                        {currentLanguage.select_equipment}

                    </PrimaryButton>
                </div>

            </React.Fragment>
        )
    }

    buildChooseEquipment = () => {
        return (
            <DialogContent>
                <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                    {this.buildChooseEquipmentContent()}
                </DialogContent>

                <DialogActions style={{ justifyContent: 'space-between' }}>
                    {this.buildChooseEquipmentButtons()}
                </DialogActions>

            </DialogContent>
        )
    }

    buildReplaceEquipment = () => {
        const { closeDialog, equipment, currentLanguage } = this.props
        const { chosenEquipment } = this.state

        return (
            <DialogContent>
                <DialogContent style={{ height: 'calc(100vh - 20rem)', padding: '0rem' }}>
                    <div style={{ padding: '1rem' }}>
                        {this.buildSuggestedEquipment()}
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <div>
                        <PrimaryButton
                            onClick={this.transferInspections.bind(this, chosenEquipment === null ? null : chosenEquipment.tagSerialNumber, equipment.tagSerialNumber)}
                            disabled={chosenEquipment === null}>

                            {currentLanguage.transfer_inspections_and_maintenances}
                        </PrimaryButton>
                    </div>

                    <div>
                        <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                    </div>
                </DialogActions>
            </DialogContent>
        )
    }

    buildContent = () => {
        const { stage } = this.state;

        switch (stage) {
            case STAGES.REPLACE_EQUIPMENT: return this.buildReplaceEquipment()
            case STAGES.CHOOSE_EQUIPMENT: return this.buildChooseEquipment()
            default: throw new Error(`unimplemented: ${stage}`)
        }
    }

    transferInspections = (oldSerialNumber, newSerialNumber) => {
        const { closeDialog, currentLanguage } = this.props

        if (!newSerialNumber) {
            this.notify(currentLanguage.select_an_equipment_to_transfer_information, 'warning')
        } else {
            closeDialog()
            this.replaceTag(oldSerialNumber, newSerialNumber)
        }
    }

    replaceTag = (oldSerialNumber, newSerialNumber) => TagService.replaceTag(oldSerialNumber, newSerialNumber)
        .then(this.notify(this.props.currentLanguage.replacement_done, 'success'))

    notify = (title, type) => {
        const MySwal = withReactContent(Swal)

        MySwal.fire({
            position: 'center',
            icon: type,
            html: title, 
            showConfirmButton: false,
            timer: 3500
        })
    }

    render() {
        const { suggestedEquipment } = this.props
        const { chosenEquipment } = this.state

        if (suggestedEquipment === null) {
            return (
                <Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} />
            )
        }

        if (chosenEquipment === null && suggestedEquipment !== '')
            this.setState({ chosenEquipment: suggestedEquipment })

        return (
            <React.Fragment>
                {this.buildContent()}
            </React.Fragment>
        )
    }
}
