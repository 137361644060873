import React from 'react'
import {
    Table, TableBody, TableHead, TableRow,
    Card,
    CardContent,
    CardMedia,
    Typography,
    ButtonBase,
    TextField,
    Button
} from '@material-ui/core'
import {
    ContainerDiv,
    HeaderTableCell, 
    OrangeDiv, 
    BodyTableCell,
    FlexDiv
} from '../components/components'
import { withSnackbar } from 'notistack'
import IconMob from './images/ic-mob.png'
import { MyPlanService } from './myPlanService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AxiosService } from '../../app/axiosService'
import Iframe from 'react-iframe'
import LoadingScreen from 'react-loading-screen'
import LOGO from '../../anonymous/landpage/images/logo-white.png'
import { HistoryService } from '../../app/historyService'
import moment from 'moment'

const STAGES = {
    MAIN: 'MAIN',
    PAYMENT: 'PAYMENT',
    LOADING: 'LOADING'
}

class MyPlanPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            actualPlanId: {},
            selectedPlan: {},
            plans: [],
            paymentMethod: 'bank_slip',
            stage: STAGES.MAIN,
            paymentUrl: '',
            tagQrCodeQty: 0,
            tagQrCodeValue: 1.15,
            tagNfcQty: 0,
            tagNfcValue: 3.25,
            subscription: {},
            bill: {}
        }
    }

    componentDidMount() {
        const { loggedUser } = this.props

        MyPlanService.getPlan(loggedUser.companyGroup).then(actualPlanId => {
            this.setState({ actualPlanId })
        })

        MyPlanService.listPlan().then(plans => {
            this.setState({ plans })
        })

        if(loggedUser.subscription_id !== null && loggedUser.subscription_id !== 0) {
            MyPlanService.getSubscription(loggedUser.subscription_id).then(data => {
                this.setState({ subscription: data.subscription })
            })

            MyPlanService.getBill(loggedUser.subscription_id).then(data => {
                this.setState({ bill: data.bills[0] })
            })
        }
    }

    handlePaymentMethodChange = (paymentMethodSelected) => {
        this.setState({ paymentMethod: paymentMethodSelected })
    }

    notify = (selectedPlan) => {
        const { actualPlanId, paymentMethod } = this.state
        const { loggedUser } = this.props

        const MySwal = withReactContent(Swal)

        if (actualPlanId !== selectedPlan.id && selectedPlan.id === 4) {

            MySwal.fire({
                title: 'Deseja migrar para o ' + selectedPlan.name + '?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F08302',
                cancelButtonColor: '#D0BBB6',
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    var subscriptionData = {
                        plan_id: selectedPlan.vindiId,
                        customer_id: loggedUser.vindi_id,
                        payment_method_code: paymentMethod,
                        product_items: [
                            {
                                "product_id": 670199,
                                "quantity": selectedPlan.limit
                            }
                        ]
                    }

                    this.setState({ stage: STAGES.LOADING })

                    var token = AxiosService.getAxiosToken()
                    AxiosService.unsetAxiosToken()

                    if(loggedUser.subscription_id === null || loggedUser.subscription_id === 0) {
                        MyPlanService.createSubscription(subscriptionData).then(res => {
                            AxiosService.setAxiosToken(token)
    
                            var body = JSON.parse(res.body)
    
                            window.open(body.bill.url, '_blank')

                            loggedUser.subscription_id = body.bill.id

                            MyPlanService.getBill(loggedUser.subscription_id).then(data => {
                                this.setState({ bill: data.bills[0] })
                            })

                            this.setState({ stage: STAGES.MAIN })
                        })
                    } else {
                        console.log(subscriptionData)
                        MyPlanService.updateSubscription(loggedUser.subscription_id, subscriptionData).then(res => {
                            MyPlanService.getBill(loggedUser.subscription_id).then(res => {
                                AxiosService.setAxiosToken(token)

                                window.open(res.bills[0].url, '_blank')
                               
                                this.setState({ stage: STAGES.MAIN })
                            })
                        })
                    }
                }
            })
        }

        if (actualPlanId !== selectedPlan.id && selectedPlan.id === 1) {
            MySwal.fire({
                title: 'Deseja migrar para o ' + selectedPlan.name + '?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F08302',
                cancelButtonColor: '#D0BBB6',
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    var subscriptionData = {
                        plan_id: selectedPlan.vindiId,
                        customer_id: loggedUser.vindi_id,
                        payment_method_code: paymentMethod,
                        product_items: [
                            {
                                "product_id": 650243,
                                "quantity": 1
                            }
                        ]
                    }

                    this.setState({ stage: STAGES.LOADING })

                    var token = AxiosService.getAxiosToken()
                    AxiosService.unsetAxiosToken()

                    if(loggedUser.subscription_id === null || loggedUser.subscription_id === 0) {
                        MyPlanService.createSubscription(subscriptionData).then(res => {
                            AxiosService.setAxiosToken(token)
    
                            var body = JSON.parse(res.body)
    
                            window.open(body.bill.url, '_blank')

                            loggedUser.subscription_id = body.bill.id

                            MyPlanService.getBill(loggedUser.subscription_id).then(data => {
                                this.setState({ bill: data.bills[0] })
                            })
                            
                            this.setState({ stage: STAGES.MAIN })
                        })
                    } else {
                        MyPlanService.updateSubscription(loggedUser.subscription_id, subscriptionData).then(res => {

                            MyPlanService.getBill(loggedUser.subscription_id).then(res => {
                                AxiosService.setAxiosToken(token)
    
                                window.open(res.bills[0].url, '_blank')

                                this.setState({ stage: STAGES.MAIN })
                            })
                        })
                    }
                }
            })
        }

        if (actualPlanId !== selectedPlan.id && selectedPlan.id === 5) {
            MySwal.fire({
                title: 'Deseja migrar para o ' + selectedPlan.name + '?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F08302',
                cancelButtonColor: '#D0BBB6',
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {

                }
            })
        }
    }

    handleCustomChange = (selectedValue) => {
        const { plans } = this.state

        if (selectedValue >= 50) {
            plans[2].value = (selectedValue * 0.99).toFixed(2)
            plans[2].value = plans[2].value.toString().replace('.', ',')

            plans[2].limit = selectedValue

            this.setState({ plans })
        }
    }

    handleCancelPlan = () => {
        const { loggedUser } = this.props

        const MySwal = withReactContent(Swal)

        MySwal.fire({
            title: 'Tem certez que deseja cancelar o seu plano?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D0BBB6',
            cancelButtonColor: '#D0BBB6',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                MyPlanService.cancelSubscription(loggedUser.subscription_id).then(data => {
                    MySwal.fire({
                        title: 'Solicitação realizada com sucesso!',
                        text: 'Sua assinatura foi cancelada.',
                        icon: 'success',
                        confirmButtonColor: '#F08302',
                        cancelButtonColor: '#D0BBB6',
                        confirmButtonText: 'Ok!'
                    })

                    MyPlanService.getSubscription(loggedUser.subscription_id).then(data => {
                        this.setState({ subscription: data.subscription })
                    })
                })
            }
        })
    }

    handleViewBill = () => {
        const { loggedUser } = this.props

        var token = AxiosService.getAxiosToken()
        AxiosService.unsetAxiosToken()

        MyPlanService.getBill(loggedUser.subscription_id).then(res => {
            AxiosService.setAxiosToken(token)

            window.open(res.bills[0].url, '_blank')

            this.setState({ stage: STAGES.MAIN })
        })
    }

    buildPlans = () => {
        const { actualPlanId, plans } = this.state

        if (plans.length > 0) {

            return (
                <FlexDiv style={{ flexWrap: 'wrap', justifyContent: 'center', position: 'relative', padding: 0, marginTop: 10 }}>
                    <div style={{ padding: 30 }}>
                        <Card style={{ maxWidth: 250, borderRadius: 10 }}>
                            
                                <CardMedia
                                    component="img"
                                    alt="Contemplative Reptile"
                                    height="140"
                                    image={IconMob}
                                    title="Contemplative Reptile"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        <Typography variant="h5" color="#000000" style={{ fontWeight: 500, fontSize: '1.4rem', textAlign: 'center' }}>{plans[0].name}</Typography>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        - {plans[0].description}
                                        <br />
                                        <Typography variant="h5" style={{ fontWeight: 500, color: '#dd7b19', fontSize: '1.6rem', textAlign: 'center', marginTop: '2rem' }}>
                                            <FlexDiv style={{ flexWrap: 'wrap', justifyContent: 'center', position: 'relative', padding: 0, marginTop: 70 }}>
                                                R${plans[0].value} <Typography style={{ fontWeight: 500, color: '#606060', fontSize: '0.6rem', textAlign: 'center', marginTop: '1rem' }}>/mês</Typography>
                                            </FlexDiv>
                                        </Typography>

                                        <br />

                                        {plans[0].id === actualPlanId ?
                                            <Typography variant="h5" style={{ fontWeight: 500, color: '#0e5197', fontSize: '1.0rem', textAlign: 'center', marginTop: '2rem' }}>ATUAL</Typography>
                                            :
                                            <Typography variant="h5" style={{ fontWeight: 500, color: '#0e5197', fontSize: '1.0rem', textAlign: 'center', marginTop: '2rem' }}><ButtonBase onClick={() => this.notify(plans[0])}>SELECIONAR</ButtonBase></Typography>
                                        }
                                    </Typography>
                                </CardContent>
                        </Card>
                    </div>
                    <div style={{ padding: 30 }}>
                        <Card style={{ maxWidth: 250, borderRadius: 10 }}>
                            
                                <CardMedia
                                    component="img"
                                    alt="Contemplative Reptile"
                                    height="140"
                                    image={IconMob}
                                    title="Contemplative Reptile"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        <Typography variant="h5" color="#000000" style={{ fontWeight: 500, fontSize: '1.4rem', textAlign: 'center' }}>{plans[1].name}</Typography>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        - {plans[1].description}
                                        <br />
                                        <Typography variant="h5" style={{ fontWeight: 500, color: '#dd7b19', fontSize: '1.6rem', textAlign: 'center', marginTop: '2rem' }}>
                                            <FlexDiv style={{ flexWrap: 'wrap', justifyContent: 'center', position: 'relative', padding: 0, marginTop: 70 }}>
                                                R${plans[1].value.toFixed(2).toString().replace('.', ',')} <Typography style={{ fontWeight: 500, color: '#606060', fontSize: '0.6rem', textAlign: 'center', marginTop: '1rem' }}>/mês</Typography>
                                            </FlexDiv>
                                        </Typography>

                                        <br />

                                        {plans[1].id === actualPlanId ?
                                            <Typography variant="h5" style={{ fontWeight: 500, color: '#0e5197', fontSize: '1.0rem', textAlign: 'center', marginTop: '2rem' }}> ATUAL </Typography>
                                            :
                                            <Typography variant="h5" style={{ fontWeight: 500, color: '#0e5197', fontSize: '1.0rem', textAlign: 'center', marginTop: '2rem' }}><ButtonBase onClick={() => this.notify(plans[1])}>SELECIONAR</ButtonBase></Typography>
                                        }
                                    </Typography>
                                </CardContent>
                        </Card>
                    </div>
                    <div style={{ padding: 30 }}>
                        <Card style={{ maxWidth: 250, borderRadius: 10 }}>
                                <CardMedia
                                    component="img"
                                    alt="Contemplative Reptile"
                                    height="140"
                                    image={IconMob}
                                    title="Contemplative Reptile"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        <Typography variant="h5" color="#000000" style={{ fontWeight: 500, fontSize: '1.4rem', textAlign: 'center' }}>{plans[2].name}</Typography>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">

                                        <TextField label={"Equipamentos"} minValue="50" type="number" value={plans[2].limit} onChange={event => this.handleCustomChange(event.target.value)} />

                                        <br />
                                        <Typography variant="h5" style={{ fontWeight: 500, color: '#dd7b19', fontSize: '1.6rem', textAlign: 'center', marginTop: '2rem' }}>
                                            <FlexDiv style={{ flexWrap: 'wrap', justifyContent: 'center', position: 'relative', padding: 0, marginTop: 70 }}>
                                                R${plans[2].value} <Typography style={{ fontWeight: 500, color: '#606060', fontSize: '0.6rem', textAlign: 'center', marginTop: '1rem' }}>/mês</Typography>
                                            </FlexDiv>
                                        </Typography>

                                        <br />


                                        {plans[2].id === actualPlanId ?
                                            <Typography variant="h5" style={{ fontWeight: 500, color: '#0e5197', fontSize: '1.0rem', textAlign: 'center', marginTop: '2rem' }} > ATUAL </Typography>
                                            :
                                            <Typography variant="h5" style={{ fontWeight: 500, color: '#0e5197', fontSize: '1.0rem', textAlign: 'center', marginTop: '2rem' }} ><ButtonBase onClick={() => this.notify(plans[2])}>SELECIONAR</ButtonBase></Typography>
                                        }
                                    </Typography>
                                </CardContent>
                        </Card>
                    </div>
                </FlexDiv>
            )

        } else {
            return null
        }
    }

    handleSubscriptionStatus = (status) => {
        switch (status) {
            case 'active':
                return "ATIVA"
            case 'canceled':
                return "CANCELADA"
            case 'future':
                return "FUTURO"
            case 'expired':
                return "EXPIRADA"
            default:
                return status
        }
    }

    handleBilllStatus = (status) => {
        switch (status) {
            case 'review':
                return "REVISANDO"
            case 'pending':
                return "PENDENTE"
            case 'paid':
                return "PAGA"
            case 'canceled':
                return "CANCELADA"
            case 'scheduled':
                return "AGENDADA"
            default:
                return status
        }
    }

    buildSubscription = () => {
        const { subscription, bill } = this.state
        const { loggedUser } = this.props

        if(loggedUser.subscription_id === null || loggedUser.subscription_id === 0) {
            return (<Table></Table>)
        }
        
        return (
            <Table style={{marginTop: '10px'}}>
                <TableHead>
                    <TableRow>
                        <HeaderTableCell>Data da Fatura</HeaderTableCell>
                        <HeaderTableCell>Valor</HeaderTableCell>
                        <HeaderTableCell>Próxima Fatura</HeaderTableCell>
                        <HeaderTableCell>Status da Fatura</HeaderTableCell>
                        <HeaderTableCell>Opções</HeaderTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <BodyTableCell> {moment(subscription.created_at).format('DD/MM/YYYY')} </BodyTableCell>
                        <BodyTableCell> R$ {bill.amount ? bill.amount.replace('.', ',') : ''} </BodyTableCell>
                        <BodyTableCell> {subscription.next_billing_at !== null ? moment(subscription.next_billing_at).format('DD/MM/YYYY') : '--/--/----'} </BodyTableCell>
                        <BodyTableCell> {this.handleBilllStatus(bill.status)} </BodyTableCell>
                        <BodyTableCell>
                            <OrangeDiv> 
                                {this.buildSubscriptionButton(subscription.status)}                                
                            </OrangeDiv>
                        </BodyTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    buildSubscriptionButton = (status) => {
        switch (status) {
            case 'active':
                return (<div> <Button onClick={() => this.handleViewBill()}>Visualizar</Button> | <Button color="secondary" onClick={() => this.handleCancelPlan()}>Cancelar</Button> </div>)
            case 'canceled':
                return (<Button >Reativar</Button>)
        
            default:
                return (<div></div>)
        }
    }

    buildMainContent = () => {
        const { currentLanguage } = this.props

        return (
            <React.Fragment>
                <ContainerDiv>
                    <Typography variant="h5" style={{ fontWeight: 500, fontSize: '1.6rem', textAlign: 'center', marginTop: '1rem' }}>
                        {currentLanguage.my_plan}
                    </Typography>

                    {this.buildPlans()}

                    Deseja comprar Tags? Clique <ButtonBase style={{ textAlign: 'center' }} onClick={() => HistoryService.goto('/buy-tag')}> <b>AQUI</b> </ButtonBase> e veja nossas opções!

                    {this.buildSubscription()}
                </ContainerDiv>
            </React.Fragment>
        )
    }

    buildPaymentContent = () => {
        const { paymentUrl } = this.state

        return (
            <React.Fragment>
                <ContainerDiv>
                    <Iframe url={paymentUrl}
                        width="450px"
                        height="450px"
                        id="myId"
                        display="initial"
                        position="relative" />
                </ContainerDiv>
            </React.Fragment>
        )
    }

    buildLoadingContent = () => {
        return (
            <LoadingScreen
                loading={true}
                bgColor='#000000'
                spinnerColor='#FFFFFF'
                textColor='#FFFFFF'
                text="Redirecionando para o pagamento"
                logoSrc={LOGO}
            >

            </LoadingScreen>
        )
    }

    buildContent = () => {
        const { stage } = this.state

        switch (stage) {
            case STAGES.MAIN: return this.buildMainContent()
            case STAGES.PAYMENT: return this.buildPaymentContent()
            case STAGES.LOADING: return this.buildLoadingContent()
            default: throw new Error(`unimplemented: ${stage}`)
        }
    }

    render() {
        return (
            this.buildContent()
        )
    }
}

export default withSnackbar(MyPlanPage)
