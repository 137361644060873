import React from 'react';
import { FlexSpaceBetweenDiv } from '../authenticated/components/components';

export default function Footer (props) {
    return (
        <FlexSpaceBetweenDiv style={{padding: '1rem 2rem', fontSize: '0.8rem', background: '#0a5e79', flexWrap: 'wrap'}}>
            <div style={{color: 'rgba(255, 255, 255, 0.5)', margin: '0rem 0.5rem'}}>&copy;{(new Date()).getFullYear()} MOB Soluções Digitais</div>
            <a href="mailto:contato@mobsd.com.br" style={{ color: 'rgba(255, 255, 255, 0.5)', margin: '0rem 0.5rem' }}>contato@mobsd.com.br</a>
        </FlexSpaceBetweenDiv>
    );
}
