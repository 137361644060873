import _ from 'lodash';
import * as d3 from 'd3';

export class PercentageData {

    constructor ({ sum, xScale, xData, viewBox }) {
        this.sum = sum;
        this.xScale = xScale;
        this.xData = xData;
        this.viewBox = viewBox;
    }

    static of (data, keys, calculateLabel, calculateColor, currentLanguage) { 

        const [ width, height, padding, labelHeight ] = [1000, 40, 12, 10];

        const viewBox = `0 0 ${width} ${height + padding}`;

        const sum = _(data).values().sum();

        if (sum === 0) {
            return this.empty(keys, calculateLabel, calculateColor, width, height, labelHeight, viewBox, currentLanguage);
        }

        return this.calculate(data, keys, calculateLabel, calculateColor, sum, width, height, labelHeight, viewBox, currentLanguage);
    }

    static calculate (data, keys, calculateLabel, calculateColor, sum, width, height, labelHeight, viewBox, currentLanguage) {
        const xScale = d3.scaleLinear().domain([ 0, sum ]).rangeRound([ 0, width ]);

        const xByKey = keys.reduce((result, key) => {
            const value = data[key] || 0;
            const width = xScale(value);
            const start = result.lastKey ? result[result.lastKey].end : 0;
            const end = start + width;

            return { ...result, [key]: { value, start, end }, lastKey: key };
        }, {});

        const xData = keys.map(key => {
            const value = xByKey[key].value;
            const xStart = xByKey[key].start;
            const xEnd = xByKey[key].end;
            const yStart = 0;
            const yEnd = height - labelHeight;

            return {
                key, value, xStart, xEnd, yStart, yEnd,
                xMiddle: (xStart + xEnd) / 2,
                yMiddleLabel: (yStart + yEnd + labelHeight) / 2,
                yBottomLabel: yEnd + labelHeight + 1,
                height: yEnd - yStart,
                width: xEnd - xStart,
                label: calculateLabel(key, data[key], currentLanguage),
                fill: calculateColor(key)
            };
        });

        return new PercentageData({ sum, xScale, xData, viewBox });
    }

    static empty (keys, calculateLabel, calculateColor, width, height, labelHeight, viewBox, currentLanguage) {
        const xScale = d3.scaleLinear().domain([ 0, keys.length ]).rangeRound([ 0, width ]);

        const xData = keys.map((key, index) => {
            const value = '-';
            const xStart = xScale(index);
            const xEnd = xScale(index + 1);
            const yStart = 0;
            const yEnd = height - labelHeight;

            return {
                key, value, xStart, xEnd, yStart, yEnd,
                xMiddle: (xStart + xEnd) / 2,
                yMiddleLabel: (yStart + yEnd + labelHeight) / 2,
                yBottomLabel: yEnd + labelHeight + 1,
                height: yEnd - yStart,
                width: xEnd - xStart,
                label: calculateLabel(key, currentLanguage),
                fill: calculateColor(key)
                
            };
        });

        return new PercentageData({ sum: 0, xScale, xData, viewBox });
    }
}
