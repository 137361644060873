import React from 'react';
import { Button, Checkbox, DialogActions, DialogContent, FormControlLabel, TextField } from '@material-ui/core';
import { PrimaryButton } from '../components/components';

export class CategoryDialogTabGeneral extends React.Component {

    render () {
        const {
            submitLabel,
            categoryData,
            allValidAndNonEmpty,
            closeDialog,
            handleSubmit,
            updateState,
            currentLanguage
        } = this.props

        return (
            <React.Fragment>
                <DialogContent style={{height: 'calc(100vh - 20rem)'}}>
                    <div>
                        <TextField
                            required
                            margin="normal"
                            style={{ width: '100%' }}
                            label={currentLanguage.name}
                            value={categoryData.name}
                            onChange={event => updateState({ categoryData: { ...categoryData, name: event.target.value } })}
                        />
                    </div>
                    <div>
                        <FormControlLabel
                            label={currentLanguage.able}
                            control={
                                <Checkbox
                                    checked={categoryData.enabled}
                                    onChange={event => updateState({ categoryData: { ...categoryData, enabled: event.target.checked } })}
                                />
                            }
                        />
                    </div>
                </DialogContent>

                <DialogActions style={{justifyContent: 'space-between'}}>
                    <div>
                    </div>

                    <div>
                        <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                        <PrimaryButton onClick={handleSubmit} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                    </div>
                </DialogActions>
            </React.Fragment>
        );
    }

}
