import axios from 'axios';

function emailPasswordReset (email) {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Email ${email}`
    };
    return axios.post('/web/password/reset/', null, { headers });
}

function resetPassword (emailToken, password) {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `EmailToken ${emailToken}`
    };
    return axios.put('/web/password/reset/', password, { headers });
}

export const PasswordResetApi = { emailPasswordReset, resetPassword };
