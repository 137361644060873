import axios from 'axios';
import { DownloadService } from '../utils/downloadService';

export const EquipmentApi = {
    summary: () => axios.get('/web/equipments/'),
    downloadPdf: (query) => DownloadService.downloadAs('/web/equipments/pdf', 'inspection_report.pdf', query),
    downloadExcel: (query) => DownloadService.downloadAs('/web/equipments/excel', 'inspection_report.xlsx', query),
    listInspections: (serialNumber) => axios.get(`/web/equipments/${serialNumber}/inspections`),
    listMaintenances: (serialNumber) => axios.get(`/web/equipments/${serialNumber}/maintenances`),
    listSnapshots: (serialNumber) => axios.get(`/web/equipments/${serialNumber}/snapshots`),
    getSuggestReplace: (serialNumber) => axios.get(`/web/equipments/replace/${serialNumber}/suggest`),
    listEquipmentsReplace: (serialNumber) => axios.get(`/web/equipments/replace/${serialNumber}`)
};
