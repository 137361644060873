import React from 'react';

import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';

import {
    BodyTableCell,
    ContainerDiv,
    FlexSpaceBetweenDiv,
    HeaderTableCell,
    OverflowXDiv,
    PrimaryButton
} from '../components/components';

import { TableFooterWithPaginator } from '../components/tableFooterWithPaginator';
import { SortHeaderCell } from '../components/sortHeaderCell';
import SearchIcon from '../equipment/images/search_list_ico.png';
import { withSnackbar } from 'notistack';
import { CategoryService } from './categoryService';
import { CategoryDialogButton } from './categoryDialogButton';
import { CollectionViewUpdater } from '../utils/collectionViewUpdater';

import Spinner from 'react-spinner-material'
import { StringService } from '../utils/stringService';

class CategoriesPage extends React.Component {
    constructor (props) {
        super(props)

        this.collectionUpdater = CollectionViewUpdater.ofCategories(this)

        this.state = {
            isLoading: false,
            companyData: {id: null, name: ''},
            filterQuery: '',
            filterEnabled: '',
            collection: this.collectionUpdater.collection
        }
    }

    componentDidMount () {
        this.setState({isLoading: true})

        CategoryService.list().then(items => {
            this.collectionUpdater.initialData(items)
            this.setState({isLoading: false})
        })
    }

    notify = message => () => this.props.enqueueSnackbar(message)

    handleCreateSubmit = (categoryData, inspectionAttributes, maintenanceAttributes) => CategoryService.create(categoryData, inspectionAttributes, maintenanceAttributes)
        .then(this.collectionUpdater.createItem)
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.created_category, categoryData.name)))
    handleUpdateSubmit = (categoryId, categoryData, inspectionAttributes, maintenanceAttributes) => CategoryService.update(categoryId, categoryData, inspectionAttributes, maintenanceAttributes)
        .then(this.collectionUpdater.updateItem)
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.updated_category, categoryData.name)))

    buildList = () => {
        const { isLoading, collection } = this.state
        const { currentLanguage } = this.props

        if (isLoading) {
            return (
                <TableRow> 
                    <BodyTableCell colSpan={3}><div style={{position:'relative', top:'45%', left:'45%'}}> <Spinner  size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /> </div></BodyTableCell>
                </TableRow>
            )
        }

        const items = collection.paginator.pagedItems

        if (items.length === 0) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={3}>{currentLanguage.empty_list}</BodyTableCell>
                </TableRow>
            )
        }

        return items.map(category => (
            <TableRow key={category.id}>
                <BodyTableCell>{category.name}</BodyTableCell>
                <BodyTableCell align="center">{category.enabled ? currentLanguage.yes : currentLanguage.no}</BodyTableCell>
                <BodyTableCell align="center">
                    <CategoryDialogButton
                        currentLanguage={currentLanguage}
                        submitLabel={currentLanguage.update_category}
                        categoryId={category.id}
                        categoryData={{ name: category.name, enabled: category.enabled }}
                        onSubmit={(categoryData, inspectionAttributes, maintenanceAttributes) => this.handleUpdateSubmit(category.id, categoryData, inspectionAttributes, maintenanceAttributes)}
                        ModalButton={({onClick}) => (
                            <IconButton onClick={onClick}><img src={SearchIcon} alt="search" /></IconButton>
                        )}
                    />
                </BodyTableCell>
            </TableRow>
        ))
    }

    render () {
        const { collection, filterQuery, filterEnabled } = this.state
        const { currentLanguage } = this.props
        const { updateCollection, handleFilterChange, handleSort } = this.collectionUpdater

        return (
            <React.Fragment>
                <ContainerDiv>
                    <FlexSpaceBetweenDiv style={{flexWrap: 'wrap'}}>
                        <FormControl margin="normal">
                            <TextField
                                label={currentLanguage.search}
                                helperText={currentLanguage.name}
                                value={filterQuery}
                                style={{marginBottom: '1rem'}}
                                onChange={event => handleFilterChange({ filterQuery: event.target.value })}
                            />
                        </FormControl>

                        <FormControl margin="normal" style={{width: '10rem'}}>
                            <InputLabel htmlFor="enabled-select">{currentLanguage.able}</InputLabel>
                            <Select value={filterEnabled} onChange={event => handleFilterChange({ filterEnabled: event.target.value })} inputProps={{ id: 'enabled-select' }}>
                                <MenuItem value="">{currentLanguage.all}</MenuItem>
                                <MenuItem value="true">{currentLanguage.yes}</MenuItem>
                                <MenuItem value="false">{currentLanguage.no}</MenuItem>
                            </Select>
                        </FormControl>

                        <CategoryDialogButton
                            currentLanguage={currentLanguage}
                            submitLabel={currentLanguage.add_category}
                            categoryId={null}
                            categoryData={{ name: '', enabled: false }}
                            onSubmit={(categoryData, inspectionAttributes, maintenanceAttributes) => this.handleCreateSubmit(categoryData, inspectionAttributes, maintenanceAttributes)}
                            ModalButton={({onClick}) => (
                                <PrimaryButton onClick={onClick} style={{alignSelf: 'center'}}>{currentLanguage.add_category}</PrimaryButton>
                            )} />
                    </FlexSpaceBetweenDiv>
                </ContainerDiv>

                <OverflowXDiv>
                <Table>
                    <TableHead>
                        <TableRow>
                            <SortHeaderCell sorter={collection.sorter} onSort={handleSort} field="name">{currentLanguage.name}</SortHeaderCell>
                            <SortHeaderCell sorter={collection.sorter} onSort={handleSort} field="enabled" align="center">{currentLanguage.able}</SortHeaderCell>
                            <HeaderTableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.buildList()}
                    </TableBody>
                    <TableFooterWithPaginator collection={collection} onCollectionUpdate={updateCollection} colSpan={3} />
                </Table>
                </OverflowXDiv>
            </React.Fragment>
        )
    }
}

export default withSnackbar(CategoriesPage)
