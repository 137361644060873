import React from 'react';
import styled from 'styled-components';
import { Button, Dialog } from '@material-ui/core';
import IconEmail from './images/icon-email.png';
import { PasswordResetService } from '../passwordReset/passwordResetService';
import { FlexDiv, FlexSpaceBetweenDiv, RedDiv } from '../../authenticated/components/components';

const EmailInput = styled.input`
    background: #d8d8d8 url('${IconEmail}') no-repeat 15px center;
    border: 1px solid #d8d8d8;
    width: 100%;
    line-height: 40px;
    padding-left: 40px;
    font-size: 15px;
    font-style: italic;
    color: #777777;
    outline-color: transparent;
    outline-style: none;

    &:focus {
        border: 1px solid #777777;
    }
`;

const ForgotPasswordButton = styled.button`
    position: absolute;
    color: #555555;
    margin-top: 5px;
    background: none;
    border: none;
    z-index: 100;

    &:hover {
        color: #555555;
        text-decoration: none;

        span {
            text-decoration: underline;
        }
    }
`;

const STAGES = {
    DETAILS: 'INPUTTING',
    CHOOSE_COMPANY_GROUP: 'SUBMITTING',
    DONE: 'DONE'
}

const FAILURES = {
    INVALID_EMAIL: 'INVALID_EMAIL',
    SERVER_ERROR: 'SERVER_ERROR'
}

function failureByResponseStatus (responseStatus) {
    switch (responseStatus) {
        case 400: return FAILURES.INVALID_EMAIL
        case 500: return FAILURES.SERVER_ERROR
        default: return FAILURES.SERVER_ERROR
    }
}

class ForgotPassword extends React.Component {
    state = {
        email: '',
        isDialogOpen: false,
        failure: null,
        stage: STAGES.INPUTTING
    }

    whenKey = (key, callback) => event => event.key === key && callback(event)

    handlePasswordResetSuccess = () => this.setState({ stage: STAGES.DONE })
    handlePasswordResetFailure = error => this.setState({
        stage: STAGES.INPUTTING,
        failure: failureByResponseStatus(error.response.status)
    })

    submitResetPassword = () => {
        const { email } = this.state
        this.setState({ stage: STAGES.SUBMITTING })
        PasswordResetService.emailPasswordReset(email).then(this.handlePasswordResetSuccess).catch(this.handlePasswordResetFailure)
    }

    buildFailureMessage = () => {
        const { failure } = this.state;

        switch (failure) {
            case FAILURES.INVALID_EMAIL: return (<RedDiv>Email inválido</RedDiv>)
            case FAILURES.SERVER_ERROR: return (<RedDiv>Problema com o servidor</RedDiv>)
            default: return (<span />);
        }
    }

    handleEmailChange = event => this.setState({ email: event.target.value, failure: null })

    buildInputEmail = () => {
        return (
            <React.Fragment>
                <div>Preencha seu email para receber as instruções para redefinir sua senha.</div>
                <div>
                    <EmailInput type="text" onChange={this.handleEmailChange} onKeyPress={this.whenKey('Enter', this.submitResetPassword)} />
                </div>
                <FlexSpaceBetweenDiv style={{marginTop: '1rem'}}>
                    <div>{this.buildFailureMessage()}</div>
                    <div>
                        <Button color="inherit" variant="text" style={{marginRight: '1rem'}} onClick={this.closeDialog}>Fechar</Button>
                        <Button color="secondary" variant="contained" onClick={this.submitResetPassword}>Enviar</Button>
                    </div>
                </FlexSpaceBetweenDiv>
            </React.Fragment>
        )
    }

    buildSubmitting = () => {
        const { email } = this.state

        return (
            <div>Enviando email para <i>{email}</i>...</div>
        )
    }

    buildDone = () => {
        const { email } = this.state

        return (
            <React.Fragment>
                <div>O email foi enviado para o seguinte endereço:</div>
                <div style={{fontWeight: 500, fontSize: '1.2rem', textAlign: 'center', marginTop: '1rem', marginBottom: '1rem'}}>{email}</div>
                <div>Verifique sua caixa de spam se o email não chegar na sua caixa de entrada.</div>
                <FlexDiv style={{justifyContent: 'flex-end'}}>
                    <Button color="inherit" variant="text" onClick={this.closeDialog}>Fechar</Button>
                </FlexDiv>
            </React.Fragment>
        )
    }

    buildContent = () => {
        const { stage } = this.state
        switch (stage) {
            case STAGES.INPUTTING: return this.buildInputEmail()
            case STAGES.SUBMITTING: return this.buildSubmitting()
            case STAGES.DONE: return this.buildDone()
            default: throw new Error(`unimplemented: ${stage}`)
        }
    }

    openDialog = () => this.setState({ isDialogOpen: true, stage: STAGES.INPUTTING, email: '' })
    closeDialog = () => this.setState({ isDialogOpen: false })

    render () {
        const { isDialogOpen } = this.state;

        return (
            <div>
                <ForgotPasswordButton onClick={this.openDialog}> Esqueceu sua senha?</ForgotPasswordButton>

                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="sm">
                    <div style={{margin: '2rem', color: '#0a5e79', textTransform: 'uppercase', fontSize: '2rem', fontWeight: '100'}}>Esqueci minha senha</div>
                    <div style={{padding: '1rem 2rem 1rem 2rem'}}>{this.buildContent()}</div>
                </Dialog>
            </div>
        )
    }
}

export default ForgotPassword
