import React from 'react'
import { FormControl, IconButton, Table, TableBody, TableHead, TableRow, TextField } from '@material-ui/core'
import {
    BodyTableCell,
    ContainerDiv,
    FlexSpaceBetweenDiv,
    HeaderTableCell,
    OverflowXDiv,
    PrimaryButton
} from '../components/components'
import { DateTimeService } from '../utils/dateTimeService'
import { InspectionTypeService } from '../inspectionType/inspectionTypeService'
import SearchIcon from '../equipment/images/search_list_ico.png'
import { TableFooterWithPaginator } from '../components/tableFooterWithPaginator'
import { SortHeaderCell } from '../components/sortHeaderCell'
import { InspectionTypeDialogButton } from './inspectionTypeDialogButton'
import { CategoryService } from '../category/categoryService'
import { CollectionViewUpdater } from '../utils/collectionViewUpdater'
import { withSnackbar } from 'notistack'
import Spinner from 'react-spinner-material'
import { StringService } from '../utils/stringService'

class InspectionTypesPage extends React.Component {
    constructor (props) {
        super(props)

        this.collectionUpdater = CollectionViewUpdater.ofInspectionTypes(this)

        this.state = {
            isLoading: false,
            filterQuery: '',
            isImageDialogOpen: false,
            imageProductId: null,
            inspectionTypes: [],
            categories: [],
            collection: this.collectionUpdater.collection
        }
    }

    componentDidMount() {
        this.setState({isLoading: true})

        CategoryService.list().then(categories => this.setState({ categories }))
        InspectionTypeService.list().then(items => {
            this.collectionUpdater.initialData(items)
            this.setState({isLoading: false})
        });
    }

    notify = message => () => this.props.enqueueSnackbar(message)

    handleCreateSubmit = (inspectionTypeData, inspectionQuestions, maintenanceQuestions, categoryName, inspectionTypeAttributes) => InspectionTypeService.create(inspectionTypeData, inspectionQuestions, maintenanceQuestions, categoryName, inspectionTypeAttributes)
        .then(this.collectionUpdater.createItem)
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.created_subcategory, inspectionTypeData.name)))
    
    handleUpdateSubmit = (inspectionTypeId, inspectionTypeData, inspectionQuestions, maintenanceQuestions, categoryName, inspectionTypeAttributes) => InspectionTypeService.update(inspectionTypeId, inspectionTypeData, inspectionQuestions, maintenanceQuestions, categoryName, inspectionTypeAttributes)
        .then(this.collectionUpdater.updateItem)
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.updated_subcategory, inspectionTypeData.name)))

    buildList = () => {
        const { isLoading, collection, categories } = this.state
        const { currentLanguage } = this.props

        if (isLoading) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={4}><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></BodyTableCell>
                </TableRow>
            )
        }

        const items = collection.paginator.pagedItems;

        if (items.length === 0) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={4}>{currentLanguage.empty_list}</BodyTableCell>
                </TableRow>
            )
        }

        return (
            items.map(inspectionType => (
                <TableRow key={inspectionType.id}>
                    <BodyTableCell>{inspectionType.name}</BodyTableCell>
                    <BodyTableCell>{inspectionType.categoryName}</BodyTableCell>
                    <BodyTableCell align="center">{DateTimeService.formatDate(inspectionType.updateDate)}</BodyTableCell>
                    <BodyTableCell>
                        <InspectionTypeDialogButton
                            currentLanguage={currentLanguage}
                            submitLabel={currentLanguage.update_subcategory}
                            inspectionTypeId={inspectionType.id}
                            inspectionTypeData={{ name: inspectionType.name, categoryId: inspectionType.categoryId, enabled: inspectionType.enabled }}
                            categories={categories}
                            onSubmit={(inspectionTypeData, inspectionQuestions, maintenanceQuestions, categoryName, inspectionTypeAttributes) => this.handleUpdateSubmit(inspectionType.id, inspectionTypeData, inspectionQuestions, maintenanceQuestions, categoryName, inspectionTypeAttributes)}
                            ModalButton={({onClick}) => (
                                <IconButton onClick={onClick}><img src={SearchIcon} alt="search" /></IconButton>
                            )}
                        />
                    </BodyTableCell>
                </TableRow>
            ))
        );
    };

    render () {
        const { collection, categories, filterQuery } = this.state
        const { currentLanguage } = this.props
        const { updateCollection, handleFilterChange, handleSort } = this.collectionUpdater

        return (
            <React.Fragment>
                <ContainerDiv>
                    <FlexSpaceBetweenDiv style={{flexWrap: 'wrap'}}>
                        <FormControl margin="normal">
                            <TextField
                                label={currentLanguage.search}
                                helperText={currentLanguage.name+", "+currentLanguage.or+" "+currentLanguage.category}
                                value={filterQuery}
                                style={{marginBottom: '1rem'}}
                                onChange={event => handleFilterChange({ filterQuery: event.target.value })}
                            />
                        </FormControl>
                        <InspectionTypeDialogButton
                            currentLanguage={currentLanguage}
                            submitLabel={currentLanguage.add_subcategory}
                            inspectionTypeId={null}
                            inspectionTypeData={{ name: '', categoryId: '', enabled: false }}
                            categories={categories}
                            onSubmit={(inspectionTypeData, inspectionQuestions, maintenanceQuestions, categoryName, inspectionTypeAttributes) => this.handleCreateSubmit(inspectionTypeData, inspectionQuestions, maintenanceQuestions, categoryName, inspectionTypeAttributes)}
                            ModalButton={({onClick}) => (
                                <PrimaryButton onClick={onClick} style={{alignSelf: 'center'}}>{currentLanguage.add_subcategory}</PrimaryButton>
                            )}
                        />
                    </FlexSpaceBetweenDiv>
                </ContainerDiv>

                <OverflowXDiv>
                <Table>
                    <TableHead>
                        <TableRow>
                            <SortHeaderCell sorter={collection.sorter} onSort={handleSort} field="name">{currentLanguage.name}</SortHeaderCell>
                            <SortHeaderCell sorter={collection.sorter} onSort={handleSort} field="categoryName">{currentLanguage.category}</SortHeaderCell>
                            <SortHeaderCell sorter={collection.sorter} onSort={handleSort} field="updateDate">{currentLanguage.last_update}</SortHeaderCell>
                            <HeaderTableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.buildList()}
                    </TableBody>
                    <TableFooterWithPaginator collection={collection} onCollectionUpdate={updateCollection} colSpan={4} />
                </Table>
                </OverflowXDiv>
            </React.Fragment>
        );
    }
}

export default withSnackbar(InspectionTypesPage)
