import axios from 'axios'
import { DownloadService } from '../utils/downloadService'

export const TagApi = {
    list: () => axios.get('/web/tags/'),
    listReplaceTag: () => axios.get('/web/equipments/replace/'),
    toggleTag: (serialNumber, enabled) => axios.put(`/web/tags/${serialNumber}/toggle/`, { enabled }),
    replaceTag: (oldSerialNumber, newSerialNumber) => axios.put(`/web/tags/${oldSerialNumber}/replace-to/${newSerialNumber}`),
    getGeneratedQty: (companyId) => axios.get(`/web/qr-code/generated/${companyId}`),
    downloadGeneratedTag: (companyId, qrCodeQty) => DownloadService.downloadAs(`/web/qr-code/generate/${companyId}/${qrCodeQty}`, 'qr_code_tags.pdf'),
    exportGeneratedTag: (companyId, qrCodeQty) => DownloadService.downloadAs(`/web/qr-code/generate/${companyId}/${qrCodeQty}/excel`, 'qr_code_tags.xlsx')
}
