import { DashboardApi } from './dashboardApi';

function buildEmptyStats () {
    return {allTimeTotal: null, last7DaysTotal: null, lastCreateTime: null};
}

function formatStats (stats) {
    return {
        allTimeTotal: stats.allTimeTotal,
        last7DaysTotal: stats.last7DaysTotal,
        lastCreateTime: stats.lastCreateTime
    };
}

function formatData (data) {
    return {
        users: formatStats(data.users),
        equipments: formatStats(data.equipments),
        inspections: formatStats(data.inspections)
    };
} 

function summary () {
    return DashboardApi.summary().then(response => formatData(response.data));
}

function monthlyInspections () {
    return DashboardApi.monthlyInspections().then(response => response.data);
}

function equipmentStatus () {
    return DashboardApi.equipmentStatus().then(response => response.data);
}

function employeeInspections (dateStart, dateEnd) {
    return DashboardApi.employeeInspections(dateStart, dateEnd).then(response => response.data);
}

function inspectionTypeInspections () {
    return DashboardApi.inspectionTypeInspections().then(response => response.data);
}

function productInspections (dateStart, dateEnd) {
    return DashboardApi.productInspections(dateStart, dateEnd).then(response => response.data);
}

export const DashboardService = { 
    buildEmptyStats,
    summary, 
    monthlyInspections, 
    equipmentStatus, 
    employeeInspections, 
    inspectionTypeInspections,
    productInspections
}
