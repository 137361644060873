import { orderBy } from 'lodash/collection';
import { get } from 'lodash/object';
import { StringService } from './stringService';

export class SingleFieldSorter {

    constructor ({ items, field, direction, sortedItems }) {
        this.items = items;
        this.field = field;
        this.direction = direction;
        this.sortedItems = sortedItems;

        Object.freeze(this);
    }

    static of = ({ items, field, direction }) => {
        const sortedItems = field ? orderBy(items, [ item => StringService.sortNormalize(get(item, field)) ], [ direction ]) : items;

        return new SingleFieldSorter({ items, field, direction, sortedItems });
    };
    static initial = items => SingleFieldSorter.of({ items });
    static asc = (items, field) => SingleFieldSorter.of({ items, field, direction: 'asc' });
    static desc = (items, field) => SingleFieldSorter.of({ items, field, direction: 'desc' });

    static next = (sorter, nextField) => {
        const { items, direction, field } = sorter;

        if (nextField !== field) {
            return SingleFieldSorter.asc(items, nextField);
        }

        switch (direction) {
            case 'asc': return SingleFieldSorter.desc(items, nextField);
            case 'desc': return SingleFieldSorter.asc(items, nextField);
            default: throw new Error('unimplemented direction: ' + direction);
        }
    };

    isActive = field => field === this.field;

}
