import { MyPlanApi } from './myPlanApi'
import { VindiApi } from './vindiApi'

function getPlan (companyGroup) {
    return MyPlanApi.getPlan(companyGroup).then(response => response.data)
}

function listPlan () {
    return MyPlanApi.listPlan().then(response => response.data)
}

function createSubscription (subscriptionData) {
    return VindiApi.createSubscription(subscriptionData).then(response => response.data)
}

function getSubscription (subscriptionId) {
    return VindiApi.getSubscription(subscriptionId).then(response => response.data)
}

function getBill (subscriptionId) {
    return VindiApi.getBill(subscriptionId).then(response => response.data)
}

function cancelSubscription (subscriptionId) {
    return VindiApi.cancelSubscription(subscriptionId).then(response => response.data)
}

function updateSubscription (subscriptionId, subscriptionData) {
    return VindiApi.updateSubscription(subscriptionId, subscriptionData).then(response => response.data)
}

function buyItem (buyItemData) {
    return VindiApi.buyItem(buyItemData).then(response => response.data)
}

export const MyPlanService = { getPlan, listPlan, createSubscription, getSubscription, getBill, cancelSubscription, updateSubscription, buyItem }
