import React from 'react';
import { TableFooter, TableRow } from '@material-ui/core';

import { TablePaginator } from './tablePaginator';
import { DefaultCollection } from '../collection/defaultCollection';

export function TableFooterWithPaginator ({ collection, onCollectionUpdate, colSpan }) {

    const handleChangePage = paginator => onCollectionUpdate(collection => DefaultCollection.withPaginator(collection, paginator));
    const handleChangeRowsPerPage = rowsPerPage => onCollectionUpdate(collection => DefaultCollection.withRowsPerPage(collection, rowsPerPage));

    return (
        <TableFooter>
            <TableRow>
                <TablePaginator
                    paginator={collection.paginator}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    onChangePage={handleChangePage}
                    colSpan={colSpan}
                />
            </TableRow>
        </TableFooter>
    );

}
