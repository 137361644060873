import React from 'react'
import {
    FormControl,
    IconButton,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core'
import {
    BodyTableCell,
    ContainerDiv,
    FlexSpaceBetweenDiv,
    FlexDiv,
    HeaderTableCell, OverflowXDiv,
    PrimaryButton
} from '../components/components'
import { FilterListTwoTone, Clear, Edit } from '@material-ui/icons'
import { OsFormFilterListOS } from './osFormFilterListOS';
import { DefaultCollection } from '../collection/defaultCollection'
import { FilterService } from '../utils/filterService'
import { TableFooterWithPaginator } from '../components/tableFooterWithPaginator'
import { SortHeaderCell } from '../components/sortHeaderCell'
import { withSnackbar } from 'notistack'
import { OsService } from './osService'
import { OsFormDialogUpdateOS } from './osFormDialogUpdateOS'
import { LoggedUserService } from '../loggedUser/loggedUserService'
import Moment from 'moment';
import { translateStatusOS } from '../utils/translateStatusOS';
import { ViewDetailsOSDialog } from './viewDetailsOSDialog';

class OsPage extends React.Component {
    state = {
        osDataSubmit: {},
        filterQuery: '',
        filterEnabled: '',
        collection: DefaultCollection.ofItemsAndPredicates([], []),
        loggedUser: {}
    }

    buildPredicates = (items, filterValues) => {
        const { filterQuery, filterEnabled } = filterValues

        return [
            FilterService.containsQuery(filterQuery, items, ['id', 'responsibleUserName', 'status']),
            FilterService.isSameString('enabled', filterEnabled)
        ]
    }

    componentDidMount() {
        OsService.list().then(serviceOrders => this.setState(state => ({
            collection: DefaultCollection.ofItemsAndPredicates(serviceOrders, this.buildPredicates(serviceOrders, state))
        })))

        LoggedUserService.getLoggedUser().then(loggedUser => {
            this.setState({ loggedUser })
        })
    }

    updateCollection = updater => this.setState(state => ({ collection: updater(state.collection, state) }));

    handleApllyFilters = (filters) => {
        this.updateCollection((collection, state) => DefaultCollection.withItemsFilterOS(collection, collection.items, filters));
    }

    handleSort = field => this.updateCollection(collection => DefaultCollection.withNextSorter(collection, field));

    updateWithCreatedItem = createdOs => (collection, state) => DefaultCollection.withCreatedItem(collection, 'id', createdOs, this.buildPredicates(collection.items, state))
    updateWithUpdatedItem = updatedOs => (collection, state) => DefaultCollection.withUpdatedItem(collection, 'id', updatedOs, this.buildPredicates(collection.items, state))

    notify = message => () => this.props.enqueueSnackbar(message)

    handleUpdateSubmit = (osId, osDataSubmit) => OsService.update(osId, osDataSubmit)
        .then(updatedOs => this.updateCollection(this.updateWithUpdatedItem(updatedOs)))
        .then(this.props.enqueueSnackbar(this.props.currentLanguage.os_updated, { variant: 'success' }));

    render() {
        const { collection, loggedUser } = this.state
        const { currentLanguage } = this.props
        const { sorter, paginator } = collection
        const pageItems = paginator.pagedItems

        return (
            <React.Fragment>
                <FlexDiv style={{ background: 'rgb(14, 74, 100)', justifyContent: 'center' }}>
                    <Typography variant="h5" style={{ color: '#fff', fontWeight: 500, fontSize: '1.5rem', textAlign: 'center', marginTop: '0.4rem',  marginBottom: '0.54rem' }}>
                        {currentLanguage.list_os}
                    </Typography>
                </FlexDiv>
                <ContainerDiv>

                    <FlexSpaceBetweenDiv style={{ flexWrap: 'wrap' }}>
                        <FlexDiv style={{ flexWrap: 'wrap' }}>
                            <FormControl margin="normal">
                                <OsFormFilterListOS
                                    currentLanguage={currentLanguage}
                                    submitLabel={currentLanguage.filter}
                                    loggedUser={loggedUser}
                                    osData={{
                                        id: "",
                                        startDate: null,
                                        endDate: null,
                                        requestUserId: "",
                                        responsibleUserId: "",
                                        observation: "",
                                        status: "",
                                        equipment: {
                                            equipmentSerialNumber: "",
                                            name: "",
                                            priority: "LOWEST",
                                            type: "INSPECTION",
                                            responsibleUserId: "",
                                            completionDate: null
                                        }
                                    }}
                                    onSubmit={this.handleApllyFilters}
                                    listFilterRequestersUsers={[...new Map(collection?.items?.map((m) => [m.requestUserId, m])).values()].map(item => ({
                                        id: item.requestUserId,
                                        label: item.requestUserName
                                    }))}
                                    ModalButton={({ onClick }) => (
                                        <PrimaryButton onClick={onClick} style={{ alignSelf: 'center', margin: '0 20px 0 0' }}>
                                            <FilterListTwoTone />
                                            {currentLanguage.filter_os}</PrimaryButton>
                                    )}
                                />
                            </FormControl>

                            <FormControl margin="normal">
                                <PrimaryButton onClick={() => this.handleApllyFilters(null)} style={{ alignSelf: 'center', margin: '0 20px 0 0' }}>
                                    <Clear />
                                    {currentLanguage.clear_fields}</PrimaryButton>
                            </FormControl>
                        </FlexDiv>
                    </FlexSpaceBetweenDiv>
                </ContainerDiv>

                <OverflowXDiv>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <SortHeaderCell
                                    sorter={sorter}
                                    onSort={this.handleSort}
                                    field="id"
                                >
                                    {currentLanguage.number_os}
                                </SortHeaderCell>
                                <SortHeaderCell
                                    sorter={sorter}
                                    onSort={this.handleSort}
                                    field="startDate"
                                >
                                    {currentLanguage.start_date}
                                </SortHeaderCell>
                                <SortHeaderCell
                                    sorter={sorter}
                                    onSort={this.handleSort}
                                    field="endDate"
                                >
                                    {currentLanguage.end_date}
                                </SortHeaderCell>
                                <SortHeaderCell
                                    sorter={sorter}
                                    onSort={this.handleSort}
                                    field="userCurrent"
                                >
                                    {currentLanguage.user_current}

                                </SortHeaderCell>
                                <SortHeaderCell
                                    sorter={sorter}
                                    onSort={this.handleSort}
                                    field="responsibleUser"
                                >
                                    {currentLanguage.responsible_user}
                                </SortHeaderCell>
                                <SortHeaderCell
                                    sorter={sorter}
                                    onSort={this.handleSort}
                                    field="status"
                                >
                                    {currentLanguage.os_status}
                                </SortHeaderCell>
                                <SortHeaderCell
                                    sorter={sorter}
                                    onSort={this.handleSort}
                                    field="comments"
                                >
                                    {currentLanguage.comments}
                                </SortHeaderCell>
                                <HeaderTableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pageItems?.map(os => (
                                <TableRow key={os.id + Math.floor(Math.random() * Date.now())}>
                                    <BodyTableCell>{os.id}</BodyTableCell>
                                    <BodyTableCell>{Moment(os.startDate).format('DD/MM/YYYY')}</BodyTableCell>
                                    <BodyTableCell>{Moment(os.endDate).format('DD/MM/YYYY')}</BodyTableCell>
                                    <BodyTableCell>{os.requestUserName}</BodyTableCell>
                                    <BodyTableCell>{os.responsibleUserName}</BodyTableCell>
                                    <BodyTableCell>{translateStatusOS(os.status, currentLanguage)}</BodyTableCell>
                                    <BodyTableCell>{os.observation}</BodyTableCell>
                                    <BodyTableCell align="center" style={{ paddingRight: 0 }}>
                                        <OsFormDialogUpdateOS
                                            enqueueSnackbar={this.props.enqueueSnackbar}
                                            currentLanguage={currentLanguage}
                                            submitLabel={currentLanguage.update}
                                            loggedUser={loggedUser}
                                            idOS={os.id}
                                            osData={{
                                                startDate: os.startDate,
                                                endDate: os.endDate,
                                                requestUserId: os.requestUserId,
                                                responsibleUserId: os.responsibleUserId,
                                                responsibleUserName: os.responsibleUserName,
                                                priority: os.priority,
                                                observation: os.observation,
                                                type: os?.equipments?.find(equip => !!equip?.type)?.type,
                                                status: os.status,
                                                equipments: os?.equipments,
                                            }}
                                            onSubmit={osData => this.handleUpdateSubmit(os.id, osData)}
                                            ModalButton={({ onClick }) => (
                                                <IconButton onClick={onClick}><Edit color='secondary' /></IconButton>
                                            )} />
                                        <ViewDetailsOSDialog os={os} currentLanguage={currentLanguage} />
                                    </BodyTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooterWithPaginator collection={collection} onCollectionUpdate={this.updateCollection} colSpan={8} />
                    </Table>
                </OverflowXDiv>
            </React.Fragment>
        );
    }
}

export default withSnackbar(OsPage)
