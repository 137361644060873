
function isNonEmptyCurrentPassword (currentPassword) {
    return currentPassword && currentPassword.length > 0;
}

function isSamePassword (newPassword, verification) {
    return newPassword && verification && (newPassword === verification);
}

function hasAtLeast6Characters (newPassword) {
    return newPassword && newPassword.length >= 6;
}

function hasAtLeast1Number (newPassword) {
    return newPassword && /\d/.test(newPassword);
}

function hasAtLeast1UpperCase (newPassword) {
    return newPassword && /[A-Z]/.test(newPassword);
}

function hasAtLeast1LowerCase (newPassword) {
    return newPassword && /[a-z]/.test(newPassword);
}

function hasAtLeast1SpecialCharacter (newPassword) {
    return newPassword && /[!@#$%&*()_\-+=]/.test(newPassword);
}

export class PasswordValidator {

    constructor (samePassword, atLeast6Characters, atLeast1Number, atLeast1UpperCase, atLeast1LowerCase, atLeast1SpecialCharacter, allValid) {
        this.samePassword = samePassword;
        this.atLeast6Characters = atLeast6Characters;
        this.atLeast1Number = atLeast1Number;
        this.atLeast1UpperCase = atLeast1UpperCase;
        this.atLeast1LowerCase = atLeast1LowerCase;
        this.atLeast1SpecialCharacter = atLeast1SpecialCharacter;
        this.allValid = allValid;

        Object.freeze(this);
    }

    static validatePasswordUpdate (currentPassword, newPassword, verification) {
        const nonEmptyCurrentPassword = isNonEmptyCurrentPassword(currentPassword);
        const samePassword = isSamePassword(newPassword, verification);
        const atLeast6Characters = hasAtLeast6Characters(newPassword);
        const atLeast1Number = hasAtLeast1Number(newPassword);
        const atLeast1UpperCase = hasAtLeast1UpperCase(newPassword);
        const atLeast1LowerCase = hasAtLeast1LowerCase(newPassword);
        const atLeast1SpecialCharacter = hasAtLeast1SpecialCharacter(newPassword);
        const allValid = (nonEmptyCurrentPassword && samePassword && atLeast6Characters && atLeast1Number && atLeast1UpperCase && atLeast1LowerCase && atLeast1SpecialCharacter);

        return new PasswordValidator(
            samePassword,
            atLeast6Characters,
            atLeast1Number,
            atLeast1UpperCase,
            atLeast1LowerCase,
            atLeast1SpecialCharacter,
            allValid
        );
    }

    static validatePasswordCreate (password, verification) {
        const samePassword = isSamePassword(password, verification);
        const atLeast6Characters = hasAtLeast6Characters(password);
        const atLeast1Number = hasAtLeast1Number(password);
        const atLeast1UpperCase = hasAtLeast1UpperCase(password);
        const atLeast1LowerCase = hasAtLeast1LowerCase(password);
        const atLeast1SpecialCharacter = hasAtLeast1SpecialCharacter(password);
        const allValid = (samePassword && atLeast6Characters && atLeast1Number && atLeast1UpperCase && atLeast1LowerCase && atLeast1SpecialCharacter);

        return new PasswordValidator(
            samePassword,
            atLeast6Characters,
            atLeast1Number,
            atLeast1UpperCase,
            atLeast1LowerCase,
            atLeast1SpecialCharacter,
            allValid
        );
    }

}
