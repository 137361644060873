import React from 'react'
import { Dialog, DialogContent, IconButton, Tab, Tabs } from '@material-ui/core'
import Spinner from 'react-spinner-material'
import { Search } from '@material-ui/icons'

import { OsDialogTabGeneral } from './tabsDialogDetailsOS/osDialogTabGeneral'
import { OsDialogTabEquipments } from './tabsDialogDetailsOS/osDialogTabEquipments'
import { FlexDiv } from '../components/components'
import { OsService } from './osService'

export class ViewDetailsOSDialog extends React.Component {
    state = {
        tabValue: 0,
        isDialogOpen: false,
        inspections: null,
        listEquipments: [],
        isLoading: false
    }

    handleClickOpen = async () => {
        const { os } = this.props

        try {
            this.setState({ isLoading: true })
            const listEquipmentServer = await OsService.equipments();
            const listFiltered = os?.equipments?.map(equip => {
                const equipServerFind = listEquipmentServer.find(equipServ => equipServ?.tagSerialNumber === equip?.equipmentSerialNumber)
                if (!!equipServerFind) {
                    return {
                        ...equip,
                        product: equipServerFind?.product,
                        extraLabel: equipServerFind?.extraLabel
                    }
                }
                return equip;
            })

            this.setState({
                tabValue: 0,
                isDialogOpen: true,
                listEquipments: listFiltered,
                isLoading: false
            })

        } catch (error) {
            console.log('Error: ', error);
            this.setState({
                isLoading: false
            })
        }

    };

    closeDialog = () => {
        this.setState({ isDialogOpen: false })
    };

    showTabContent = () => {
        const { os, currentLanguage } = this.props
        const { tabValue, listEquipments } = this.state

        switch (tabValue) {
            case 0: return (<OsDialogTabGeneral currentLanguage={currentLanguage} closeDialog={this.closeDialog} os={os} />)
            case 1: return (<OsDialogTabEquipments currentLanguage={currentLanguage} closeDialog={this.closeDialog} equipments={listEquipments} />)
            default: throw new Error(`unimplemented: ${tabValue}`);
        }
    };

    render() {
        const { isDialogOpen, tabValue, isLoading } = this.state
        const { currentLanguage } = this.props

        return (
            <React.Fragment>
                <IconButton onClick={this.handleClickOpen}><Search color='secondary' /></IconButton>
                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">
                    <Tabs value={tabValue} onChange={(event, tabValue) => this.setState({ tabValue })}>
                        <Tab style={{ color: 'white' }} label={currentLanguage.general_information} />
                        <Tab style={{ color: 'white' }} label={currentLanguage.equipments} />
                    </Tabs>
                    {isLoading ? <DialogContent style={{ height: 'calc(60vh - 20rem)' }}>
                        <FlexDiv style={{ alignItems: 'center', marginTop: '1rem', flexDirection: 'column' }}>
                            <Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} />
                        </FlexDiv>
                    </DialogContent> : this.showTabContent()
                    }
                </Dialog>
            </React.Fragment>
        );
    }
}
