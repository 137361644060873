import { CustomAnswerApi } from './customAnswerApi'

function listInspectionCustomAnswer (inspectionQuestionId) {
    if (!inspectionQuestionId) 
        return Promise.resolve([])

    return CustomAnswerApi.listInspectionCustomAnswer(inspectionQuestionId).then(response => response.data)
}

function updateInspectionCustomAnswer (inspectionQuestionId, inspectionCustomAnswers) {
    return CustomAnswerApi.updateInspectionCustomAnswer(inspectionQuestionId, inspectionCustomAnswers)
        .then(response => response.data)
}

export const CustomAnswerService = { listInspectionCustomAnswer, updateInspectionCustomAnswer }
