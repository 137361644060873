import React from 'react'
import {
    Typography
} from '@material-ui/core'
import {
    ContainerDiv,
    FlexDiv,
} from '../components/components'
import { withSnackbar } from 'notistack'
import AWS from 'aws-sdk'
import ReactPlayer from 'react-player'

class VideoLessonsPage extends React.Component {
    state = {
        players: []
    }

    componentDidMount() {
        this.getVideoLessons()         
    }

    notify = message => () => this.props.enqueueSnackbar(message)

    getVideoLessons = () => {
        AWS.config.update({
            accessKeyId: "AKIAXZCQUHNKDLYEJLFK",
            secretAccessKey: "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",
            region: "sa-east-1"
        })

        const s3 = new AWS.S3()

        s3.listObjectsV2({
            Bucket: 'video-aulas-mob-check'
        }, function(err, data) {
            if(err) console.log(err)
            else {    
                data.Contents.forEach(function(content) {   
                    const params = {
                        Bucket: 'video-aulas-mob-check',
                        Key: content.Key,
                        Expires: 60 * 5
                    }

                    
                    s3.getSignedUrl('getObject', params, (err, url) => {
                        if(err) console.log(err)
                        else {
                            var players = this.state.players
                            players.push(url)
                            this.setState({ players: players })
                        }
                    })
                }.bind(this))                   
            }
        }.bind(this))      
    }

    render() {
        const videosTitle = ['Cadastro de Equipamentos', 'Cadastro de Produtos', 'Tela de Dashboard', 'Cadastro de Empresas', 'Cadastro de Funcionários', 'Realizando Inspeção', 'Realizando Manutenção', 'Relatórios Inspeções', 'Relatórios Manutenções', 'Tela de Tags']
        const { currentLanguage } = this.props
        const { players } = this.state

        return (
            <React.Fragment>
                <ContainerDiv>
                    <Typography variant="h5" style={{ fontWeight: 500, fontSize: '1.6rem', textAlign: 'center', marginTop: '1rem' }}>
                        {currentLanguage.video_lessons}
                    </Typography>

                    <FlexDiv style={{flexWrap: 'wrap', justifyContent: 'center', position: 'relative', padding: 0, marginTop: 10}}> 
                        {players.map((url, index) => (
                            <div style={{ padding: 30, maxWidth: 350, textAlign: 'center' }}>
                                <ReactPlayer width="100%" height="100%" controls="true" url={url} margin />
                                <b style={{textAlign: 'center'}}>{videosTitle[index]}</b>
                            </div>
                        ))}
                    </FlexDiv>

                </ContainerDiv>
            </React.Fragment>
        )
    }
}

export default withSnackbar(VideoLessonsPage)
