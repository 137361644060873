import React from 'react';
import { Button, DialogActions, DialogContent, TextField } from '@material-ui/core';
import { CenterTitleDiv, FlexBaselineDiv, PrimaryButton } from '../components/components';
import Spinner from 'react-spinner-material'

export class CategoryDialogTabMaintenanceAttributes extends React.Component {

    buildRow = (maintenanceAttribute, index) => {
        const {
            maintenanceAttributes,
            maintenanceAttributesValidator,
            updateState
        } = this.props;

        if (maintenanceAttribute.id === null) {
            return (
                <FlexBaselineDiv key={index}>
                    <TextField
                        required
                        margin="normal"
                        style={{ flex: '1 1 auto' }}
                        value={maintenanceAttribute.name}
                        label="Nome do Atributo de Manutenção (ex: Número de Série)"
                        error={!maintenanceAttributesValidator.isValid(maintenanceAttribute.name)}
                        onChange={event => updateState({ maintenanceAttributes: maintenanceAttributes.map((attribute, i) => i === index ? { ...attribute, name: event.target.value } : attribute) })}
                    />
                    <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={() => updateState({ maintenanceAttributes: maintenanceAttributes.filter((attribute, i) => i !== index) })}>Remover</Button>
                </FlexBaselineDiv>
            );
        }

        if (maintenanceAttribute.enabled === false) {
            return (
                <FlexBaselineDiv key={index}>
                    <TextField
                        disabled
                        margin="normal"
                        style={{ flex: '1 1 auto' }}
                        value={maintenanceAttribute.name}
                        label="Nome do Atributo de Manutenção (ex: Número de Série)"
                        error={!maintenanceAttributesValidator.isValid(maintenanceAttribute.name)}
                        onChange={event => updateState({ maintenanceAttributes: maintenanceAttributes.map((attribute, i) => i === index ? { ...attribute, name: event.target.value } : attribute) })}
                    />
                    <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={() => updateState({ maintenanceAttributes: maintenanceAttributes.map((attribute, i) => i === index ? { ...attribute, enabled: true } : attribute) })}>Habilitar</Button>
                </FlexBaselineDiv>
            );
        }

        return (
            <FlexBaselineDiv key={index}>
                <TextField
                    required
                    margin="normal"
                    style={{ flex: '1 1 auto' }}
                    value={maintenanceAttribute.name}
                    label="Nome do Atributo de Manutenção (ex: Número de Série)"
                    error={!maintenanceAttributesValidator.isValid(maintenanceAttribute.name)}
                    onChange={event => updateState({ maintenanceAttributes: maintenanceAttributes.map((attribute, i) => i === index ? { ...attribute, name: event.target.value } : attribute) })}
                />
                <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={() => updateState({ maintenanceAttributes: maintenanceAttributes.map((attribute, i) => i === index ? { ...attribute, enabled: false } : attribute) })}>Desabilitar</Button>
            </FlexBaselineDiv>
        );
    };

    render () {
        const {
            submitLabel,
            maintenanceAttributes,
            allValidAndNonEmpty,
            closeDialog,
            handleSubmit,
            updateState
        } = this.props;

        const emptyMaintenanceAttribute = { id: null, enabled: true, name: '' };

        const addMaintenanceAttribute = () => updateState({ maintenanceAttributes: [ ...maintenanceAttributes, emptyMaintenanceAttribute ] });

        if (maintenanceAttributes === null) {
            return (
                <React.Fragment>
                    <DialogContent style={{height: 'calc(100vh - 20rem)'}}>
                        <CenterTitleDiv><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></CenterTitleDiv>
                    </DialogContent>

                    <DialogActions style={{justifyContent: 'space-between'}}>
                        <div />

                        <div>
                            <Button onClick={closeDialog}>Fechar</Button>
                            <PrimaryButton onClick={handleSubmit} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                        </div>
                    </DialogActions>
                </React.Fragment>
            );
        }

        if (maintenanceAttributes.length === 0) {
            return (
                <React.Fragment>
                    <DialogContent style={{height: 'calc(100vh - 20rem)'}}>
                        <CenterTitleDiv>Lista vazia</CenterTitleDiv>
                    </DialogContent>

                    <DialogActions style={{justifyContent: 'space-between'}}>
                        <div>
                            <PrimaryButton onClick={addMaintenanceAttribute}>Adicionar Atributo de Manutenção</PrimaryButton>
                        </div>

                        <div>
                            <Button onClick={closeDialog}>Fechar</Button>
                            <PrimaryButton onClick={handleSubmit} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                        </div>
                    </DialogActions>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <DialogContent style={{height: 'calc(100vh - 20rem)'}}>
                    {maintenanceAttributes.map(this.buildRow)}
                </DialogContent>

                <DialogActions style={{justifyContent: 'space-between'}}>
                    <div>
                        <PrimaryButton onClick={addMaintenanceAttribute}>Adicionar Atributo de Manutenção</PrimaryButton>
                    </div>

                    <div>
                        <Button onClick={closeDialog}>Fechar</Button>
                        <PrimaryButton onClick={handleSubmit} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                    </div>
                </DialogActions>
            </React.Fragment>
        );
    }

}

