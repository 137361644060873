import { LoggedUserApi } from './loggedUserApi';

import { Session } from '../../app/session';
import { AxiosService } from '../../app/axiosService';


function getSessionLoggedUser () {
    const token = Session.get('token')

    if (!token) {
        return Promise.reject('no session token')
    }

    AxiosService.setAxiosToken(token)

    return LoggedUserApi.getLoggedUser().then(response => response.data).catch(response => {
        AxiosService.unsetAxiosToken()
        return Promise.reject(response)
    })
}

function getLoggedUser () {
    return LoggedUserApi.getLoggedUser().then(response => response.data)
}

function getCompanyData () {
    return LoggedUserApi.getLoggedUser().then(response => response.data)
        .then(loggedUser => ({id: loggedUser.companyId, name: loggedUser.companyName}))
}

function changePassword (currentPassword, newPassword) {
    return LoggedUserApi.changePassword(currentPassword, newPassword)
}

function update (loggedUserData) {
    return LoggedUserApi.update(loggedUserData)
}

function changeCompanyGroupCompany (companyGroup, companyId) {
    return LoggedUserApi.changeCompanyGroupCompany(companyGroup, companyId);
}

export const LoggedUserService = {
    getSessionLoggedUser,
    getLoggedUser,
    getCompanyData,
    changePassword,
    update,
    changeCompanyGroupCompany
}
