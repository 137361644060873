import React from 'react';
import { HistoryService } from './historyService';

export class RedirectPage extends React.Component {
    componentDidMount () {
        const { to, callback } = this.props;

        if (callback) {
            callback();
        }

        HistoryService.goto(to);
    }

    render = () => {
        return (
            <div>Redirecting...</div>
        );
    }
}
