import _ from 'lodash';

export class UniqueNonEmptyValidator {

    constructor (countByValue, allDistinct, allNonEmpty) {
        this.countByValue = countByValue;
        this.allDistinct = allDistinct;
        this.allNonEmpty = allNonEmpty;

        Object.freeze(this);
    }

    static of (objects, field) {
        const countByValue = _(objects).map(field).countBy().value();

        return new UniqueNonEmptyValidator(
            countByValue,
            _(countByValue).values().every(count => count === 1),
            !countByValue['']
        );
    }

    static empty () {
        return new UniqueNonEmptyValidator(
            {},
            true,
            true
        );
    }

    static ofNullable (objects, field) {
        return objects === null ? UniqueNonEmptyValidator.empty() : UniqueNonEmptyValidator.of(objects, field);
    }

    isValid = (value) => {
        return !value || this.countByValue[value] === 1;
    };

    isAllValidAndNonEmpty = () => {
        return this.allDistinct && this.allNonEmpty;
    };

}
