import { createMuiTheme } from '@material-ui/core';

function buildTheme () {
    return createMuiTheme({
        palette: {
            primary: {
                main: 'rgb(14, 74, 100)'
            },
            secondary: {
                main: 'rgb(213, 117, 48)'
            },
            error: {
                main: 'rgb(165, 35, 47)'
            }
        },
        typography: {
            useNextVariants: true,
        },
        overrides: {
            MuiPaper: {
                rounded: {
                    borderRadius: 0
                }
            },
            MuiSnackbarContent: {
                root: {
                    borderRadius: 0
                }
            },
            MuiButton: {
                root:  {
                    borderRadius: 0
                }
            },
            MuiDialogActions: {
                root: {
                    margin: '0px',
                    padding: '0 24px 12px 24px'
                }
            },
            MuiTabs: {
                root: {
                    backgroundColor: 'rgb(213, 117, 48)'
                }
            }
        }
    });
}

export const ThemeService = { buildTheme };
