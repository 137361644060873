import React from 'react';
import { Button, DialogActions, DialogContent, Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import { BodyTableCell, formatMainDescription, HeaderTableCell } from '../components/components';
import { DateTimeService } from '../utils/dateTimeService';

import Spinner from 'react-spinner-material'

export class EquipmentDialogTabSnapshots extends React.Component {

    buildSnapshotList = (snapshots) => {
        const { currentLanguage } = this.props

        if (snapshots === null) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={5}><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></BodyTableCell>
                </TableRow>
            )
        }
    
        if (snapshots.length === 0) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={5}>{currentLanguage.empty_list}</BodyTableCell>
                </TableRow>
            )
        }
    
        return (
            snapshots.map(snapshot => (
                <TableRow key={snapshot.id}>
                    <BodyTableCell>{DateTimeService.formatDateTime(snapshot.transaction)}</BodyTableCell>
                    <BodyTableCell>{formatMainDescription(snapshot.userName, snapshot.userEmail)}</BodyTableCell>
                    <BodyTableCell>{formatMainDescription(snapshot.productName, snapshot.productSku)}</BodyTableCell>
                    <BodyTableCell>{snapshot.extraLabel}</BodyTableCell>
                    <BodyTableCell>{snapshot.extraCode}</BodyTableCell>
                </TableRow>
            ))
        )
    }

    render () {
        const { closeDialog, snapshots, currentLanguage } = this.props

        return (
            <React.Fragment>
                <DialogContent style={{height: 'calc(100vh - 20rem)', padding: '0rem'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <HeaderTableCell style={{position: 'sticky', top: '0rem', zIndex: '1'}}>{currentLanguage.date}</HeaderTableCell>
                                <HeaderTableCell style={{position: 'sticky', top: '0rem', zIndex: '1'}}>{currentLanguage.modified_by}</HeaderTableCell>
                                <HeaderTableCell style={{position: 'sticky', top: '0rem', zIndex: '1'}}>{currentLanguage.equipment}</HeaderTableCell>
                                <HeaderTableCell style={{position: 'sticky', top: '0rem', zIndex: '1'}}>{currentLanguage.equipment_information}</HeaderTableCell>
                                <HeaderTableCell style={{position: 'sticky', top: '0rem', zIndex: '1'}}>{currentLanguage.number_of_control}</HeaderTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.buildSnapshotList(snapshots)}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between'}}>
                    <div>
                    </div>

                    <div>
                        <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                    </div>
                </DialogActions>
            </React.Fragment>
        )
    }

}
