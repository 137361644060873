import React from 'react'
import { flatMap } from 'lodash/collection'
import styled from 'styled-components'
import {
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Tab,
    Tabs
} from '@material-ui/core'
import { CenterMessageDiv, CenterTitleDiv, FlexSpaceBetweenDiv, PrimaryButton } from '../components/components'
import { StringService } from '../utils/stringService'
import Button from '@material-ui/core/Button'
import SearchIcon from '../equipment/images/search_list_ico.png'
import { UserService } from './userService'
import { DateTimeService } from '../utils/dateTimeService'

const STAGES = Object.freeze({
    DETAILS: 'DETAILS',
    CHOOSE_COMPANY_NAME: 'CHOOSE_COMPANY_NAME',
    ACTIVATE_GENERIC_CONFIRMATION: 'ACTIVATE_GENERIC_CONFIRMATION',
    MIGRATE_GENERIC_PENDING_CONFIRMATION: 'MIGRATE_GENERIC_PENDING_CONFIRMATION',
    ACTIVATING: 'ACTIVATING',
    CHOOSE_COMPANY_GROUP: 'CHOOSE_COMPANY_GROUP',
    MIGRATE_CONFIRMATION: 'MIGRATE_CONFIRMATION',
    MIGRATING: 'MIGRATING',
    CHOOSE_PASSWORD: 'CHOOSE_PASSWORD',
    UPDATING_PASSWORD: 'UPDATING_PASSWORD',
    DELETE_USER: 'DELETE_USER'
})

const Suggestion = styled.div`
    cursor: pointer;
    &:hover {
        color: rgb(213, 117, 48);
    };
`;

const CONFIRMATION = 'CONFIRMO'

export class UserDialogButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isDialogOpen: false,
            companyGroup: '',
            companyId: '',
            companyName: '',
            companyNameGeneric: '',
            password: '',
            companyGroupCompany: null,
            stage: STAGES.DETAILS,
            tabValue: 0,
            confirmationDelete: ''
        };
    }

    handleStageChange = stage => event => this.setState({ stage, confirmationDelete: '' });

    handleDialogOpen = () => {
        this.setState({
            isDialogOpen: true,
            companyGroup: '',
            companyId: '',
            companyName: '',
            password: '',
            companyGroupCompany: null,
            stage: STAGES.DETAILS
        });
    };
    closeDialog = () => this.setState({ isDialogOpen: false });

    showDetails = () => this.setState({ stage: STAGES.DETAILS });
    showChooseCompanyName = () => this.setState({ stage: STAGES.CHOOSE_COMPANY_NAME });
    showActivateGenericConfirmation = () => this.setState({ stage: STAGES.ACTIVATE_GENERIC_CONFIRMATION });
    showMigrateGenericPendingConfirmation = () => this.setState({ stage: STAGES.MIGRATE_GENERIC_PENDING_CONFIRMATION });
    showActivating = () => this.setState({ stage: STAGES.ACTIVATING });
    showChooseCompanyGroup = () => this.setState({ stage: STAGES.CHOOSE_COMPANY_GROUP });
    showMigrateConfirmation = () => this.setState({ stage: STAGES.MIGRATE_CONFIRMATION });
    showMigrating = () => this.setState({ stage: STAGES.ACTIVATING });
    showChoosePassword = () => this.setState({ stage: STAGES.CHOOSE_PASSWORD });
    showUpdatingPassword = () => this.setState({ stage: STAGES.UPDATING_PASSWORD });
    showDeleteUser = () => this.setState({ stage: STAGES.DELETE_USER });

    migrate = () => {
        const { user, migrate } = this.props;
        const { companyGroup, companyId } = this.state;

        migrate(user.id, { companyGroup, companyId }).then(this.closeDialog);

        this.showMigrating();
    };

    activateGeneric = () => {
        const { activateGeneric, user } = this.props;
        const { companyName } = this.state;

        activateGeneric(user.id, { companyName }).then(this.closeDialog);

        this.showActivating();
    }

    migrateGenericPending = () => {
        const { migrateGenericPending, user } = this.props;
        const { companyGroupCompany } = this.state;

        migrateGenericPending(user.id, { companyId: companyGroupCompany.companyId, companyGroup: companyGroupCompany.companyGroup }).then(this.closeDialog);

        this.showMigrating();
    }

    updatePassword = () => {
        const { updatePassword, user } = this.props;
        const { password } = this.state;

        updatePassword(user.id, { password, companyGroup: user.companyGroup }).then(this.closeDialog);

        this.showUpdatingPassword();
    }

    handleDeleteUser = () => {
        const { deleteUser, user } = this.props;

        deleteUser(user.email).then(this.closeDialog);
    }

    buildDetails = () => {
        const { user, currentLanguage } = this.props

        return (
            <React.Fragment>
                <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                    <CenterMessageDiv>
                        <Typography variant="h5">{user.name}</Typography>
                        <div>{user.occupation}</div>
                        <div>{DateTimeService.formatDate(user.createdAt)}</div>
                        <FlexSpaceBetweenDiv style={{ marginTop: '2rem', width: '100%' }}>
                            <div style={{ flex: '1 1 33.33%' }}>
                                <div style={{ textAlign: 'center' }}>{user.email}</div>
                                <div style={{ textAlign: 'center' }}>{StringService.formatPhoneNumber(user.phone)}</div>
                            </div>
                            <div style={{ flex: '1 1 33.34%' }}>
                                <div style={{ textAlign: 'center' }}>{UserService.formatUserStatus(user.status, currentLanguage)}</div>
                                <div style={{ textAlign: 'center' }}>{UserService.formatUserRole(user.role, currentLanguage)}</div>
                            </div>
                            <div style={{ flex: '1 1 33.33%' }}>
                                <div style={{ textAlign: 'center' }}>{user.companyName}</div>
                                <div style={{ textAlign: 'center' }}>{user.companyGroup}</div>
                            </div>
                        </FlexSpaceBetweenDiv>
                    </CenterMessageDiv>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button onClick={this.closeDialog}>{currentLanguage.close}</Button>
                    <PrimaryButton onClick={this.showDeleteUser}>{currentLanguage.remove}</PrimaryButton>
                    <PrimaryButton onClick={this.showChoosePassword}>{currentLanguage.change_password}</PrimaryButton>
                    <PrimaryButton onClick={this.showChooseCompanyName} disabled={user.status !== 'PENDING'}>{currentLanguage.active_pending_user}</PrimaryButton>
                </DialogActions>
            </React.Fragment>
        );
    };

    updateCompanyGroup = (companyGroup) => {
        const { companyGroups } = this.props
        const companies = companyGroups.filter(cg => cg.name === companyGroup).map(cg => cg.companies).pop();
        const companyId = companies.length === 1 ? companies[0].id : '';

        this.setState({ companyGroup, companyId });
    };

    buildChooseCompanyGroup = () => {
        const { companyGroups, currentLanguage } = this.props
        const { companyGroup, companyId } = this.state

        const companies = companyGroups.filter(cg => cg.name === companyGroup).map(cg => cg.companies).pop()

        return (
            <React.Fragment>
                <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                    <CenterMessageDiv>
                        <FormControl margin="normal" required style={{ width: '20rem' }} >
                            <InputLabel htmlFor="companyGroup-select">{currentLanguage.company_group}</InputLabel>
                            <Select value={companyGroup} onChange={(event) => this.updateCompanyGroup(event.target.value)} inputProps={{ id: 'companyGroup-select' }}>
                                {companyGroups.map(cg => (
                                    <MenuItem key={cg.name} value={cg.name}>{cg.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl margin="normal" required style={{ width: '20rem' }} >
                            <InputLabel htmlFor="companyId-select">{currentLanguage.company}</InputLabel>
                            <Select value={companyId} onChange={(event) => this.setState({ companyId: event.target.value })} inputProps={{ id: 'companyId-select' }}>
                                {companies && companies.map(company => (
                                    <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </CenterMessageDiv>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button onClick={this.showDetails}>{currentLanguage.close}</Button>
                    <PrimaryButton onClick={this.showMigrateConfirmation} disabled={!companyGroup || !companyId}>Ir para confirmação da migração</PrimaryButton>
                </DialogActions>
            </React.Fragment>
        );
    };

    buildMigrateConfirmation = () => {
        const { companyGroup } = this.state
        const { user } = this.props

        return (
            <React.Fragment>
                <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                    <CenterMessageDiv>
                        <Typography variant="h6">Migrar usuário {user.name} para o Grupo de Empresa {companyGroup}?</Typography>
                        <div>Ao migrar um usuário, todas as informações associadas ao usuário também serão migradas.</div>
                    </CenterMessageDiv>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button onClick={this.showChooseCompanyGroup}>Voltar</Button>
                    <PrimaryButton onClick={this.migrate}>Migrar</PrimaryButton>
                </DialogActions>
            </React.Fragment>
        );
    };

    buildMigrating = () => {
        const { user } = this.props;
        const { companyGroup } = this.state;

        return (
            <React.Fragment>
                <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                    <CenterTitleDiv>Migrando usuário {user.name} para {companyGroup}...</CenterTitleDiv>
                </DialogContent>
            </React.Fragment>
        );
    };

    showTabContent = () => {
        const { tabValue } = this.state;

        switch (tabValue) {
            case 0: return this.showChooseCompany();
            case 1: return this.showChooseCompanyGeneric();
            default: throw new Error(`unimplemented: ${tabValue}`);
        }
    };

    showChooseCompanyGeneric = () => {
        const { user } = this.props;
        const { companyNameGeneric } = this.state;


        return (
            <React.Fragment>
                <FlexSpaceBetweenDiv style={{ width: '100%' }}>
                    <TextField
                        label="Nome da Empresa Genérica"
                        helperText={`Digite um novo nome da Empresa Genérica para o usuário ${user.name}.`}
                        value={companyNameGeneric}
                        onFocus={event => event.target.setSelectionRange(0, -1)}
                        onChange={event => this.setState({ companyNameGeneric: event.target.value })}
                    />
                </FlexSpaceBetweenDiv>
            </React.Fragment>
        );
    };

    showChooseCompany = () => {
        const { user } = this.props;
        const { companyName, companyGroupCompany } = this.state;


        return (
            <React.Fragment>
                <FlexSpaceBetweenDiv style={{ width: '100%' }}>
                    <div>
                        <div>Empresa/grupo de empresa selecionada: <span style={{ fontWeight: 500, fontSize: '1.2rem' }}> {companyGroupCompany === null ? 'Nenhuma' : `${companyGroupCompany.companyGroup} - ${companyGroupCompany.companyName}`} </span> </div>
                    </div>
                </FlexSpaceBetweenDiv>

                <div style={{borderTop: '1px solid gray', width: '100%', textAlign: 'center', marginTop: '1rem', marginBottom: '1rem'}}></div>

                <FlexSpaceBetweenDiv style={{ width: '100%' }}>
                    <TextField
                        label="Nome da Empresa"
                        helperText={`Digite o nome da Empresa para adicionar o usuário ${user.name}.`}
                        value={companyName}
                        onFocus={event => event.target.setSelectionRange(0, -1)}
                        onChange={event => this.setState({ companyName: event.target.value })}
                    />
                </FlexSpaceBetweenDiv>
                <div style={{ height: '8rem', width: '100%', overflowY: 'auto', marginTop: '2rem' }}>
                    {this.buildCompanyGroupsAndCompanies()}
                </div>
            </React.Fragment>
        );
    };

    buildCompanyGroupsAndCompanies = () => {
        const { companyGroups } = this.props;
        const { companyName } = this.state;

        const companiesAndCompanyGroups = flatMap(
            companyGroups,
            cg => cg.companies.map(company => ({
                companyId: company.id,
                companyName: company.name,
                companyGroup: cg.name
            }))
        )
            .filter(c => c.companyGroup !== 'generic')
            .filter(c => StringService.contains(c.companyName, companyName));

        if (companiesAndCompanyGroups.length === 0) {
            return (
                <div>Lista vazia.</div>
            );
        }

        return companiesAndCompanyGroups.map(c => (
            <Suggestion
                key={c.companyGroup + c.companyId}
                onClick={event => this.setState({ companyGroupCompany: c })}
            >
                {c.companyGroup} - {c.companyName}
            </Suggestion>
        ));
    };

    showChooseCompanyGenericButtons = () => {

        const { companyNameGeneric } = this.state;

        return (
            <React.Fragment>
                <div>
                    <Button onClick={this.showDetails}>Voltar</Button>
                </div>

                <div>
                    <PrimaryButton
                        style={{ alignSelf: 'center' }}
                        onClick={this.showActivateGenericConfirmation}
                        disabled={companyNameGeneric.length === 0}>

                        Ativar em Empresa Genérica

                    </PrimaryButton>
                </div>

            </React.Fragment>
        );
    };

    showChooseCompanyButtons = () => {
        const { companyGroupCompany } = this.state;

        return (
            <React.Fragment>
                <div>
                    <Button onClick={this.showDetails}>Voltar</Button>
                </div>

                <div>
                    <PrimaryButton
                        style={{ alignSelf: 'center' }}
                        onClick={this.showMigrateGenericPendingConfirmation}
                        disabled={companyGroupCompany === null}>

                        Ativar e migrar para Grupo de Empresa

                    </PrimaryButton>
                </div>

            </React.Fragment>
        );
    };

    showTabButtons = () => {
        const { tabValue } = this.state;

        switch (tabValue) {
            case 0: return this.showChooseCompanyButtons();
            case 1: return this.showChooseCompanyGenericButtons();
            default: throw new Error(`unimplemented: ${tabValue}`);
        }
    };

    buildChooseCompanyName = () => { 
        const { tabValue } = this.state;

        return (
            <React.Fragment>
                <React.Fragment>
                    {/*<ModalButton onClick={this.handleDialogOpen} />*/}
                    <DialogContent>
                        <Tabs value={tabValue} onChange={(event, tabValue) => this.setState({ tabValue })}>
                            <Tab style={{ color: 'white' }} label="Empresas" />
                            <Tab style={{ color: 'white' }} label="Empresa Genérica" />
                        </Tabs>

                        <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                            {this.showTabContent()}
                        </DialogContent>

                        <DialogActions style={{ justifyContent: 'space-between' }}>
                            {this.showTabButtons()}
                        </DialogActions>

                    </DialogContent>
                </React.Fragment>
            </React.Fragment>
        )
    }

    buildActivateGenericConfirmation = () => {
        const { user } = this.props;
        const { companyName } = this.state;

        return (
            <React.Fragment>
                <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                    <CenterMessageDiv>
                        <div>Ativar o usuário <span style={{ fontWeight: 500, fontSize: '1.2rem' }}>{user.name}</span> para a seguinte Empresa Genérica?</div>
                        <div style={{ fontWeight: 500, fontSize: '1.2rem', textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>{companyName}</div>
                    </CenterMessageDiv>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button onClick={this.showChooseCompanyName}>Voltar</Button>
                    <PrimaryButton onClick={this.activateGeneric}>Ativar</PrimaryButton>
                </DialogActions>
            </React.Fragment>
        );
    };

    buildMigrateGenericPendingConfirmation = () => {
        const { user } = this.props;
        const { companyGroupCompany } = this.state;

        return (
            <React.Fragment>
                <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                    <CenterMessageDiv>
                        <div>Ativar o usuário <span style={{ fontWeight: 500, fontSize: '1.2rem' }}>{user.name}</span> e migrar para a Empresa/Grupo de Empresa?</div>
                        <div style={{ fontWeight: 500, fontSize: '1.2rem', textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                            {companyGroupCompany.companyGroup} - {companyGroupCompany.companyName}
                        </div>
                    </CenterMessageDiv>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button onClick={this.showChooseCompanyName}>Voltar</Button>
                    <PrimaryButton onClick={this.migrateGenericPending}>Ativar</PrimaryButton>
                </DialogActions>
            </React.Fragment>
        );
    };

    buildActivating = () => {
        const { user } = this.props;

        return (
            <React.Fragment>
                <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                    <CenterTitleDiv>Ativando usuário {user.name}...</CenterTitleDiv>
                </DialogContent>
            </React.Fragment>
        );
    };

    buildChoosePassword = () => {
        const { user } = this.props;
        const { password } = this.state;

        return (
            <React.Fragment>
                <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                    <CenterMessageDiv>
                        <TextField
                            label="Senha"
                            helperText={`Digite a nova senha para o usuário ${user.name}.`}
                            value={password}
                            onChange={event => this.setState({ password: event.target.value })}
                        />
                    </CenterMessageDiv>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button onClick={this.showDetails}>Voltar</Button>
                    <PrimaryButton onClick={this.updatePassword} disabled={password.length === 0}>Alterar senha</PrimaryButton>
                </DialogActions>
            </React.Fragment>
        );
    };

    buildUpdatingPassword = () => {
        const { user } = this.props;

        return (
            <React.Fragment>
                <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                    <CenterTitleDiv>Atualizando senha do usuário {user.name}...</CenterTitleDiv>
                </DialogContent>
            </React.Fragment>
        );
    };

    buildDeleteUser = () => {
        const { confirmation } = this.state;

        return (
            <React.Fragment>
                <DialogContent style={{ height: 'calc(100vh - 20rem)' }}>
                    <CenterMessageDiv>
                    <TextField margin="normal" required variant="outlined" onChange={event => this.setState({ confirmation: event.target.value.toUpperCase() })} value={confirmation} label="Confirmação" helperText={`Para confirmar essa operação, digite ${CONFIRMATION}`} />
                    </CenterMessageDiv>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button onClick={this.showDetails}>Voltar</Button>
                    <PrimaryButton onClick={this.handleDeleteUser} disabled={confirmation !== CONFIRMATION}>Excluir</PrimaryButton>
                </DialogActions>
            </React.Fragment>
        );
    };

    buildContent = () => {
        const { stage } = this.state;

        switch (stage) {
            case STAGES.DETAILS: return this.buildDetails()
            case STAGES.CHOOSE_COMPANY_GROUP: return this.buildChooseCompanyGroup()
            case STAGES.MIGRATE_CONFIRMATION: return this.buildMigrateConfirmation()
            case STAGES.MIGRATING: return this.buildMigrating()
            case STAGES.CHOOSE_COMPANY_NAME: return this.buildChooseCompanyName()
            case STAGES.ACTIVATE_GENERIC_CONFIRMATION: return this.buildActivateGenericConfirmation()
            case STAGES.MIGRATE_GENERIC_PENDING_CONFIRMATION: return this.buildMigrateGenericPendingConfirmation()
            case STAGES.ACTIVATING: return this.buildActivating()
            case STAGES.CHOOSE_PASSWORD: return this.buildChoosePassword()
            case STAGES.UPDATING_PASSWORD: return this.buildUpdatingPassword()
            case STAGES.DELETE_USER: return this.buildDeleteUser()
            default: throw new Error(`unimplemented: ${stage}`)
        }
    };

    render() {
        const { isDialogOpen } = this.state;

        return (
            <React.Fragment>
                <IconButton onClick={this.handleDialogOpen} style={{ alignSelf: 'center' }}><img src={SearchIcon} alt="search" /></IconButton>
                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">
                    {this.buildContent()}
                </Dialog>
            </React.Fragment>
        );
    }

}
