import React from 'react';

import { Dialog, Tab, Tabs } from '@material-ui/core';
import { InspectionTypeService } from './inspectionTypeService';
import { InspectionTypeDialogTabGeneral } from './inspectionTypeDialogTabGeneral';
import { InspectionTypeDialogTabInspectionQuestions } from './inspectionTypeDialogTabInspectionQuestions';
import { InspectionTypeDialogTabMaintenanceQuestions } from './inspectionTypeDialogTabMaintenanceQuestions';
import { InspectionTypeDialogTabAttribute } from './inspectionTypeDialogTabAttribute';
import { UniqueNonEmptyValidator } from '../utils/uniqueNonEmptyValidator'
import { Tooltip } from 'react-tippy'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import 'react-tippy/dist/tippy.css'

export class InspectionTypeDialogButton extends React.Component {

    constructor(props) {
        super(props)

        const { inspectionTypeData } = props

        this.state = {
            tabValue: 0,
            isDialogOpen: false,
            inspectionQuestions: null,
            maintenanceQuestions: null,
            inspectionTypeAttributes: null,
            inspectionTypeData
        }
    }

    handleDialogOpen = () => {
        const { inspectionTypeId, inspectionTypeData } = this.props

        this.setState({
            tabValue: 0,
            isDialogOpen: true,
            inspectionQuestions: null,
            maintenanceQuestions: null,
            inspectionTypeAttributes: null,
            inspectionTypeData
        })

        InspectionTypeService.listInspectionQuestions(inspectionTypeId).then(inspectionQuestions => this.setState({ inspectionQuestions }));
        InspectionTypeService.listMaintenanceQuestions(inspectionTypeId).then(maintenanceQuestions => this.setState({ maintenanceQuestions }));
        InspectionTypeService.listInspectionTypeAttributes(inspectionTypeId).then(inspectionTypeAttributes => this.setState({ inspectionTypeAttributes }));
    }

    closeDialog = () => this.setState({ isDialogOpen: false })

    handleSubmit = () => {
        const { categories, onSubmit } = this.props
        const { inspectionTypeData, inspectionQuestions, maintenanceQuestions, inspectionTypeAttributes } = this.state;

        const categoryName = categories.filter(category => category.id === inspectionTypeData.categoryId)
            .map(category => category.name)
            .pop();

        return onSubmit(inspectionTypeData, inspectionQuestions, maintenanceQuestions, categoryName, inspectionTypeAttributes).then(this.closeDialog);
    }

    updateState = (stateData) => this.setState(state => stateData);

    showTabContent = () => {
        const { submitLabel, categories, currentLanguage } = this.props
        const { tabValue, inspectionTypeData, inspectionQuestions, maintenanceQuestions, inspectionTypeAttributes } = this.state

        const inspectionQuestionsValidator = UniqueNonEmptyValidator.ofNullable(inspectionQuestions, 'question')
        const maintenanceQuestionsValidator = UniqueNonEmptyValidator.ofNullable(maintenanceQuestions, 'question')
        const inspectionTypeAttributesValidator = UniqueNonEmptyValidator.ofNullable(inspectionTypeAttributes, 'attribute')

        const validInspectionQuestions = inspectionQuestionsValidator.isAllValidAndNonEmpty();
        const validMaintenanceQuestions = maintenanceQuestionsValidator.isAllValidAndNonEmpty();
        const validInspectionTypeAttributes = inspectionTypeAttributesValidator.isAllValidAndNonEmpty();

        switch (tabValue) {
            case 0: return (
                <InspectionTypeDialogTabGeneral
                    currentLanguage={currentLanguage}
                    submitLabel={submitLabel}
                    categories={categories}
                    inspectionTypeData={inspectionTypeData}
                    allValidAndNonEmpty={validInspectionQuestions && validMaintenanceQuestions}
                    closeDialog={this.closeDialog}
                    handleSubmit={this.handleSubmit}
                    updateState={this.updateState}
                />
            );
            case 1: return (
                <InspectionTypeDialogTabInspectionQuestions
                    currentLanguage={currentLanguage}
                    submitLabel={submitLabel}
                    inspectionQuestions={inspectionQuestions}
                    allValidAndNonEmpty={validInspectionQuestions && validMaintenanceQuestions}
                    inspectionQuestionsValidator={inspectionQuestionsValidator}
                    closeDialog={this.closeDialog}
                    handleSubmit={this.handleSubmit}
                    updateState={this.updateState}
                />
            );
            case 2: return (
                <InspectionTypeDialogTabMaintenanceQuestions
                    currentLanguage={currentLanguage}
                    submitLabel={submitLabel}
                    maintenanceQuestions={maintenanceQuestions}
                    allValidAndNonEmpty={validInspectionQuestions && validMaintenanceQuestions}
                    maintenanceQuestionsValidator={maintenanceQuestionsValidator}
                    closeDialog={this.closeDialog}
                    handleSubmit={this.handleSubmit}
                    updateState={this.updateState}
                />
            );
            case 3: return (
                <InspectionTypeDialogTabAttribute
                    currentLanguage={currentLanguage}
                    submitLabel={submitLabel}
                    inspectionTypeAttributes={inspectionTypeAttributes}
                    allValidAndNonEmpty={validInspectionQuestions && validMaintenanceQuestions && validInspectionTypeAttributes}
                    inspectionTypeAttributesValidator={inspectionTypeAttributesValidator}
                    closeDialog={this.closeDialog}
                    handleSubmit={this.handleSubmit}
                    updateState={this.updateState}
                />
            );
            default: throw new Error(`unimplemented: ${tabValue}`);
        }
    };

    render() {
        const { ModalButton, currentLanguage } = this.props
        const { tabValue, isDialogOpen } = this.state

        return (
            <React.Fragment>
                <ModalButton onClick={this.handleDialogOpen} />
                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">
                    <Tabs value={tabValue} onChange={(event, tabValue) => this.setState({ tabValue })}>
                        <Tab style={{ color: 'white' }} label={currentLanguage.general_settings} />
                        <Tab style={{ color: 'white' }} label={currentLanguage.inspection_questions} />
                        <Tab style={{ color: 'white' }} label={currentLanguage.maintenance_questions} />
                        <Tab style={{ color: 'white' }} label={currentLanguage.attribute_register} />
                        <Tooltip
                            title={currentLanguage.attributeType_Tippy}
                            arrow={true}
                            arrowSize={'small'}
                            style={{ position: "relative", left: "-13px" }}>
                            <InfoOutlinedIcon style ={{fontSize: "small", color: "#fff",}}/>
                        </Tooltip>
                    </Tabs>
                    {this.showTabContent()}
                </Dialog>
            </React.Fragment>
        )
    }

}
