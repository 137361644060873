import axios from 'axios';

export const CategoryApi = {
    list: () => axios.get('/web/categories/'),
    listInspectionAttributes: (categoryId) => axios.get(`/web/categories/${categoryId}/inspection-attributes/`),
    listMaintenanceAttributes: (categoryId) => axios.get(`/web/categories/${categoryId}/maintenance-attributes/`),
    create: (categoryData) => axios.post('/web/categories/', categoryData),
    update: (categoryId, categoryData) => axios.put(`/web/categories/${categoryId}/`, categoryData),
    updateInspectionAttributes: (categoryId, inspectionAttributes) => axios.put(`/web/categories/${categoryId}/inspection-attributes/`, inspectionAttributes),
    updateMaintenanceAttributes: (categoryId, maintenanceAttributes) => axios.put(`/web/categories/${categoryId}/maintenance-attributes/`, maintenanceAttributes)
};
