import axios from 'axios'
import { HistoryService } from './historyService'

function setAxiosToken (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

function unsetAxiosToken () {
    delete axios.defaults.headers.common['Authorization']
}

function getAxiosToken () {
    return axios.defaults.headers.common['Authorization'].replace('Bearer ', '')
}

function extractStatusCode (error) {
    if (HistoryService.path() === '/logout') {
        return null
    }

    if (!error.response) {
        return null
    }

    return error.response.status
}

function handleErrorInterceptor (error) {
    switch (extractStatusCode(error)) {
        case 401:
            console.warn('Logging out...')
            HistoryService.goto('/logout')
            break
        case 403:
            console.warn('Forbidden. Please, contact suporte@mobsd.com.br')
            break
        case 504:
            console.warn('Retrying in 5 seconds...')
            return new Promise(resolve => setTimeout(() => axios(error.config).then(resolve), 5000))
        default: break
    }

    return Promise.reject(error)
}

function initializeInterceptors () {
    axios.interceptors.response.use(response => response, handleErrorInterceptor)

    axios.interceptors.request.use(request => {
        return request
    })
}

export const AxiosService = { setAxiosToken, unsetAxiosToken, initializeInterceptors, getAxiosToken }
