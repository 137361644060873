import React from 'react';
import { Button, DialogActions, DialogContent, MenuItem, Select, TextField } from '@material-ui/core';
import { CenterTitleDiv, FlexBaselineDiv, PrimaryButton } from '../components/components';
import Spinner from 'react-spinner-material'
import { Tooltip } from 'react-tippy'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import 'react-tippy/dist/tippy.css'

export class InspectionTypeDialogTabMaintenanceQuestions extends React.Component {

    buildRow = (maintenanceQuestion, index) => {
        const {
            maintenanceQuestions,
            maintenanceQuestionsValidator,
            updateState,
            currentLanguage
        } = this.props

        if (maintenanceQuestion.id === null) {
            return (
                <FlexBaselineDiv key={index}>
                    <TextField
                        required
                        margin="normal"
                        style={{ flex: '1 1 auto' }}
                        value={maintenanceQuestion.question}
                        label={`${currentLanguage.maintenance_question} #${index + 1}`}
                        error={!maintenanceQuestionsValidator.isValid(maintenanceQuestion.question)}
                        onChange={event => updateState({ maintenanceQuestions: maintenanceQuestions.map((question, i) => i === index ? { ...question, question: event.target.value } : question) })}
                    />
                    <Select
                        style={{ marginLeft: '1rem', width: '10rem' }}
                        inputProps={{ id: 'type-select' }}
                        value={maintenanceQuestion.type}
                        onChange={event => updateState({ maintenanceQuestions: maintenanceQuestions.map((question, i) => i === index ? { ...question, type: event.target.value } : question) })}
                    >
                        <MenuItem value="YES_NO_NA">{currentLanguage.yes}/{currentLanguage.no}/{currentLanguage.na.replace('/','')}</MenuItem>
                        <MenuItem value="TEXT">{currentLanguage.text}</MenuItem>
                    </Select>
                    <Tooltip
                        title={currentLanguage.inspection_question_Tippy}
                        arrow={true}
                        arrowSize={'small'}
                        style={{ position: "relative", top: "-10px" }}>
                        <InfoOutlinedIcon style={{ fontSize: "small" }} />
                    </Tooltip>
                    <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={event => updateState({ maintenanceQuestions: maintenanceQuestions.filter((attribute, i) => i !== index) })}>{currentLanguage.remove}</Button>
                </FlexBaselineDiv>
            )
        }

        if (maintenanceQuestion.enabled === false) {
            return (
                <FlexBaselineDiv key={index}>
                    <TextField
                        disabled
                        margin="normal"
                        style={{ flex: '1 1 auto' }}
                        value={maintenanceQuestion.question}
                        label={`${currentLanguage.maintenance_question} #${index + 1}`}
                        error={!maintenanceQuestionsValidator.isValid(maintenanceQuestion.question)}
                        onChange={event => updateState({ maintenanceQuestions: maintenanceQuestions.map((question, i) => i === index ? { ...question, question: event.target.value } : question) })}
                    />
                    <Select
                        disabled
                        style={{ marginLeft: '1rem', width: '10rem' }}
                        inputProps={{ id: 'type-select' }}
                        value={maintenanceQuestion.type}
                        onChange={event => updateState({ maintenanceQuestions: maintenanceQuestions.map((question, i) => i === index ? { ...question, type: event.target.value } : question) })}
                    >
                        <MenuItem value="YES_NO_NA">{currentLanguage.yes}/{currentLanguage.no}/{currentLanguage.na.replace('/','')}</MenuItem>
                        <MenuItem value="TEXT">{currentLanguage.text}</MenuItem>
                    </Select>
                    <Tooltip
                        title={currentLanguage.inspection_question_Tippy}
                        arrow={true}
                        arrowSize={'small'}
                        style={{ position: "relative", top: "-10px" }}>
                        <InfoOutlinedIcon style={{ fontSize: "small" }} />
                    </Tooltip>
                    <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={event => updateState({ maintenanceQuestions: maintenanceQuestions.map((question, i) => i === index ? { ...question, enabled: true } : question) })}>{currentLanguage.able}</Button>
                </FlexBaselineDiv>
            )
        }

        return (
            <FlexBaselineDiv key={index}>
                <TextField
                    required
                    margin="normal"
                    style={{ flex: '1 1 auto' }}
                    value={maintenanceQuestion.question}
                    label={`${currentLanguage.maintenance_question} #${index + 1}`}
                    error={!maintenanceQuestionsValidator.isValid(maintenanceQuestion.question)}
                    onChange={event => updateState({ maintenanceQuestions: maintenanceQuestions.map((question, i) => i === index ? { ...question, question: event.target.value } : question) })}
                />
                <Select
                    style={{ marginLeft: '1rem', width: '10rem' }}
                    inputProps={{ id: 'type-select' }}
                    value={maintenanceQuestion.type}
                    onChange={event => updateState({ maintenanceQuestions: maintenanceQuestions.map((question, i) => i === index ? { ...question, type: event.target.value } : question) })}
                >
                    <MenuItem value="YES_NO_NA">{currentLanguage.yes}/{currentLanguage.no}/{currentLanguage.na.replace('/','')}</MenuItem>
                    <MenuItem value="TEXT">{currentLanguage.text}</MenuItem>
                </Select>
                <Tooltip
                        title={currentLanguage.inspection_question_Tippy}
                        arrow={true}
                        arrowSize={'small'}
                        style={{ position: "relative", top: "-10px" }}>
                        <InfoOutlinedIcon style={{ fontSize: "small" }} />
                    </Tooltip>
                <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={event => updateState({ maintenanceQuestions: maintenanceQuestions.map((question, i) => i === index ? { ...question, enabled: false } : question) })}>{currentLanguage.disable}</Button>
            </FlexBaselineDiv>
        )
    }

    render () {
        const {
            submitLabel,
            maintenanceQuestions,
            allValidAndNonEmpty,
            closeDialog,
            handleSubmit,
            updateState,
            currentLanguage
        } = this.props

        const emptyMaintenanceQuestion = { id: null, type: 'YES_NO_NA', question: '', enabled: true };

        const addMaintenanceQuestion = () => updateState({ maintenanceQuestions: [ ...maintenanceQuestions, emptyMaintenanceQuestion ] });

        if (maintenanceQuestions === null) {
            return (
                <React.Fragment>
                    <DialogContent style={{height: 'calc(100vh - 20rem)'}}>
                        <CenterTitleDiv><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></CenterTitleDiv>
                    </DialogContent>
                    <DialogActions style={{justifyContent: 'space-between'}}>
                        <div />

                        <div>
                            <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                            <PrimaryButton onClick={handleSubmit} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                        </div>
                    </DialogActions>
                </React.Fragment>
            )
        }

        if (maintenanceQuestions.length === 0) {
            return (
                <React.Fragment>
                    <DialogContent style={{height: 'calc(100vh - 20rem)'}}>
                        <CenterTitleDiv>Lista vazia</CenterTitleDiv>
                    </DialogContent>
                    <DialogActions style={{justifyContent: 'space-between'}}>
                        <div>
                            <PrimaryButton onClick={addMaintenanceQuestion}>{currentLanguage.add_maintenance_question}</PrimaryButton>
                        </div>

                        <div>
                            <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                            <PrimaryButton onClick={handleSubmit} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                        </div>
                    </DialogActions>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <DialogContent style={{height: 'calc(100vh - 20rem)'}}>
                    {maintenanceQuestions.map(this.buildRow)}
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between'}}>
                    <div>
                        <PrimaryButton onClick={addMaintenanceQuestion}>{currentLanguage.add_maintenance_question}</PrimaryButton>
                    </div>

                    <div>
                        <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                        <PrimaryButton onClick={handleSubmit} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                    </div>
                </DialogActions>
            </React.Fragment>
        )
    }
}
