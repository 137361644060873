import { Session } from './session';
import { AuthenticationApi } from './authenticationApi';
import { AxiosService } from './axiosService';

function handleAuthenticateSuccess (response) {
    Session.set('token', response.data);
    AxiosService.setAxiosToken(response.data);
    return response;
}

function handleDeauthenticateSuccess (response) {
    Session.unset('token');
    AxiosService.unsetAxiosToken();
    return response;
}

function authenticate (email, password) {
    Session.set('email', email);
    return AuthenticationApi.loginWithEmailAndPassword(email, password).then(handleAuthenticateSuccess);
}

function deauthenticate () {
    return AuthenticationApi.logout().then(handleDeauthenticateSuccess);
}

export const AuthenticationService = { authenticate, deauthenticate };

