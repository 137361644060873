import React from 'react';
import { OrangeDiv } from './components';
import { DateTimeService } from '../utils/dateTimeService';

function buildExpiringText (daysToExpire) {
    switch (daysToExpire) {
        case 0: return 'Válido até hoje';
        case 1: return 'Válido até amanhã';
        default: return `Válido, expira em ${daysToExpire} dias`;
    }
}

export function DateWithExpiration (props) {
    const { date, daysToExpire, expirationStatus } = props;

    switch (expirationStatus) {
        case 'NA': return (
            <React.Fragment>
                <div>{DateTimeService.formatDate(date)}</div>
            </React.Fragment>
        );
        case 'EXPIRED': return (
            <React.Fragment>
                <OrangeDiv>{DateTimeService.formatDate(date)}</OrangeDiv>
            </React.Fragment>
        );
        case 'EXPIRING': return (
            <React.Fragment>
                <div>{DateTimeService.formatDate(date)}</div>
                <OrangeDiv>{buildExpiringText(daysToExpire)}</OrangeDiv>
            </React.Fragment>
        );
        case 'VALID': return (
            <React.Fragment>
                <div>{DateTimeService.formatDate(date)}</div>
            </React.Fragment>
        );
        default: throw new Error(`Invalid expirationStatus: ${expirationStatus}`);
    }

}

