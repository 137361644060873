
function set (key, value) {
    return localStorage.setItem(key, value);
}

function get (key) {
    return localStorage.getItem(key);
}

function unset (key) {
    return localStorage.removeItem(key);
}

export const Session = { set, get, unset };
