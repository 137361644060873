import React from 'react'
import styled from 'styled-components'
import { AppBar, Button, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core'
import LogoMobcheck from './images/logo_mobcheck_white.png'
import IconUser from './images/icon-user-white.png'
import { RoleConstant } from '../../app/roleConstant'
import { HistoryService } from '../../app/historyService'

import Spinner from 'react-spinner-material'

const LargeTypography = styled(Typography)`
    flex-grow: 1;
`;

function HeaderLinkButton(props) {
    const { to, children, path, setPath } = props;

    const fontWeight = path === to ? 700 : 500;

    return (
        <Button onClick={() => setPath(to)} style={{ color: 'white', fontWeight }} children={children} />
    );
}

function LinksByRole(props) {
    const {
        currentRole,
        reportMenuAnchorElement,
        formMenuAnchorElement,
        tagMenuAnchorElement,
        configMenuAnchorElement,
        handleOpenReportMenu,
        handleCloseReportMenu,
        handleOpenFormMenu,
        handleCloseFormMenu,
        handleOpenTagMenu,
        handleCloseTagMenu,
        serviceOrderMenuAnchorElement,
        handleOpenOSMenu,
        handleCloseOSMenu,
        handleCloseConfigMenu,
        path,
        setPath,
        currentLanguage
    } = props

    switch (currentRole) {
        case RoleConstant.MOBCHECK_ADMIN: return (
            <React.Fragment>
                <HeaderLinkButton path={path} setPath={setPath} to="/">{currentLanguage.dashboard}</HeaderLinkButton>
                <Button style={{ color: 'white' }} onClick={handleOpenOSMenu} >{currentLanguage.os}</Button>
                <Button style={{ color: 'white' }} onClick={handleOpenReportMenu}>{currentLanguage.reports}</Button>
                <Button style={{ color: 'white' }} onClick={handleOpenFormMenu}>{currentLanguage.registrations}</Button>
                <Button style={{ color: 'white' }} onClick={handleOpenTagMenu}>{currentLanguage.tags}</Button>
                <HeaderLinkButton path={path} setPath={setPath} to="/company-users">{currentLanguage.employees}</HeaderLinkButton>
                <HeaderLinkButton path={path} setPath={setPath} to="/companies">{currentLanguage.companies}</HeaderLinkButton>
                <HeaderLinkButton path={path} setPath={setPath} to="/company-groups">{currentLanguage.company_group}</HeaderLinkButton>
                <HeaderLinkButton path={path} setPath={setPath} to="/users">{currentLanguage.all_users}</HeaderLinkButton>
                <HeaderLinkButton path={path} setPath={setPath} to="/config-system">{currentLanguage.settings}</HeaderLinkButton>
                {/*<Button style={{color: 'white'}} onClick={handleOpenConfigMenu}>Configurações</Button>*/}

                <Menu anchorEl={serviceOrderMenuAnchorElement} open={Boolean(serviceOrderMenuAnchorElement)} onClose={handleCloseOSMenu}>
                    <MenuItem onClick={event => setPath('/create-os')}>{currentLanguage.register_new_os_abbreviated}</MenuItem>
                    <MenuItem onClick={event => setPath('/os')}>{currentLanguage.list_os_abbreviated}</MenuItem>
                </Menu>
                
                <Menu anchorEl={reportMenuAnchorElement} open={Boolean(reportMenuAnchorElement)} onClose={handleCloseReportMenu}>
                    <MenuItem onClick={event => setPath('/inspections')}>{currentLanguage.inspections}</MenuItem>
                    <MenuItem onClick={event => setPath('/maintenances')}>{currentLanguage.maintenances}</MenuItem>
                </Menu>

                <Menu anchorEl={formMenuAnchorElement} open={Boolean(formMenuAnchorElement)} onClose={handleCloseFormMenu}>
                    <MenuItem onClick={event => setPath('/categories')}>{currentLanguage.categories}</MenuItem>
                    <MenuItem onClick={event => setPath('/subcategories')}>{currentLanguage.subcategories}</MenuItem>
                    <MenuItem onClick={event => setPath('/products')}>{currentLanguage.products}</MenuItem>
                </Menu>

                <Menu anchorEl={tagMenuAnchorElement} open={Boolean(tagMenuAnchorElement)} onClose={handleCloseTagMenu}>
                    <MenuItem onClick={event => setPath('/tags')}>{currentLanguage.list}</MenuItem>
                    <MenuItem onClick={event => setPath('/tags-replace')}>{currentLanguage.tag_replace}</MenuItem>
                    <MenuItem onClick={event => setPath('/tags-qr-code')}>{currentLanguage.generate_qr_code}</MenuItem>
                    {/* <MenuItem onClick={event => setPath('/buy-tag')}>Comprar Tags</MenuItem> */}
                </Menu>

                <Menu anchorEl={configMenuAnchorElement} open={Boolean(configMenuAnchorElement)} onClose={handleCloseConfigMenu}>
                    <MenuItem onClick={event => setPath('/config-user')}>{currentLanguage.user}</MenuItem>
                    <MenuItem onClick={event => setPath('/config-system')}>{currentLanguage.settings}</MenuItem>
                </Menu>
            </React.Fragment>
        )
        case RoleConstant.COMPANY_GROUP_ADMIN: return (
            <React.Fragment>
                <HeaderLinkButton path={path} setPath={setPath} to="/">{currentLanguage.dashboard}</HeaderLinkButton>
                <Button style={{ color: 'white' }} onClick={handleOpenReportMenu}>{currentLanguage.reports}</Button>
                <Button style={{ color: 'white' }} onClick={handleOpenFormMenu}>{currentLanguage.registrations}</Button>
                <Button style={{ color: 'white' }} onClick={handleOpenTagMenu}>{currentLanguage.tags}</Button>
                <HeaderLinkButton path={path} setPath={setPath} to="/company-users">{currentLanguage.employees}</HeaderLinkButton>
                <HeaderLinkButton path={path} setPath={setPath} to="/companies">{currentLanguage.companies}</HeaderLinkButton>
                <HeaderLinkButton path={path} setPath={setPath} to="/config-system">{currentLanguage.settings}</HeaderLinkButton>
                {/*<Button style={{color: 'white'}} onClick={handleOpenConfigMenu}>Configurações</Button>*/}

                <Menu anchorEl={reportMenuAnchorElement} open={Boolean(reportMenuAnchorElement)} onClose={handleCloseReportMenu}>
                    <MenuItem onClick={event => setPath('/inspections')}>{currentLanguage.inspections}</MenuItem>
                    <MenuItem onClick={event => setPath('/maintenances')}>{currentLanguage.maintenances}</MenuItem>
                </Menu>

                <Menu anchorEl={formMenuAnchorElement} open={Boolean(formMenuAnchorElement)} onClose={handleCloseFormMenu}>
                    <MenuItem onClick={event => setPath('/categories')}>{currentLanguage.categories}</MenuItem>
                    <MenuItem onClick={event => setPath('/subcategories')}>{currentLanguage.subcategories}</MenuItem>
                    <MenuItem onClick={event => setPath('/products')}>{currentLanguage.products}</MenuItem>
                </Menu>

                <Menu anchorEl={tagMenuAnchorElement} open={Boolean(tagMenuAnchorElement)} onClose={handleCloseTagMenu}>
                    <MenuItem onClick={event => setPath('/tags')}>{currentLanguage.list}</MenuItem>
                    <MenuItem onClick={event => setPath('/tags-replace')}>{currentLanguage.tag_replace}</MenuItem>
                    <MenuItem onClick={event => setPath('/tags-qr-code')}>{currentLanguage.generate_qr_code}</MenuItem>
                    {/* <MenuItem onClick={event => setPath('/buy-tag')}>Comprar Tags</MenuItem> */}
                </Menu>

                <Menu anchorEl={configMenuAnchorElement} open={Boolean(configMenuAnchorElement)} onClose={handleCloseConfigMenu}>
                    <MenuItem onClick={event => setPath('/config-user')}>{currentLanguage.user}</MenuItem>
                    <MenuItem onClick={event => setPath('/config-system')}>{currentLanguage.settings}</MenuItem>
                </Menu>
            </React.Fragment>
        )
        case RoleConstant.COMPANY_ADMIN: return (
            <React.Fragment>
                <HeaderLinkButton path={path} setPath={setPath} to="/">{currentLanguage.dashboard}</HeaderLinkButton>
                <Button style={{ color: 'white' }} onClick={handleOpenReportMenu}>{currentLanguage.reports}</Button>
                <Button style={{ color: 'white' }} onClick={handleOpenFormMenu}>{currentLanguage.registrations}</Button>
                <Button style={{ color: 'white' }} onClick={handleOpenTagMenu}>{currentLanguage.tags}</Button>
                <HeaderLinkButton path={path} setPath={setPath} to="/company-users">{currentLanguage.employees}</HeaderLinkButton>
                <HeaderLinkButton path={path} setPath={setPath} to="/config-system">{currentLanguage.settings}</HeaderLinkButton>
                {/*<Button style={{color: 'white'}} onClick={handleOpenConfigMenu}>Configurações</Button>*/}

                <Menu anchorEl={reportMenuAnchorElement} open={Boolean(reportMenuAnchorElement)} onClose={handleCloseReportMenu}>
                    <MenuItem onClick={event => setPath('/inspections')}>{currentLanguage.inspections}</MenuItem>
                    <MenuItem onClick={event => setPath('/maintenances')}>{currentLanguage.maintenances}</MenuItem>
                </Menu>

                <Menu anchorEl={formMenuAnchorElement} open={Boolean(formMenuAnchorElement)} onClose={handleCloseFormMenu}>
                    <MenuItem onClick={event => setPath('/categories')}>{currentLanguage.categories}</MenuItem>
                    <MenuItem onClick={event => setPath('/subcategories')}>{currentLanguage.subcategories}</MenuItem>
                    <MenuItem onClick={event => setPath('/products')}>{currentLanguage.products}</MenuItem>
                </Menu>

                <Menu anchorEl={tagMenuAnchorElement} open={Boolean(tagMenuAnchorElement)} onClose={handleCloseTagMenu}>
                    <MenuItem onClick={event => setPath('/tags')}>{currentLanguage.list}</MenuItem>
                    <MenuItem onClick={event => setPath('/tags-replace')}>{currentLanguage.tag_replace}</MenuItem>
                    <MenuItem onClick={event => setPath('/tags-qr-code')}>{currentLanguage.generate_qr_code}</MenuItem>
                    {/* <MenuItem onClick={event => setPath('/buy-tag')}>Comprar Tags</MenuItem> */}
                </Menu>

                <Menu anchorEl={configMenuAnchorElement} open={Boolean(configMenuAnchorElement)} onClose={handleCloseConfigMenu}>
                    <MenuItem onClick={event => setPath('/config-user')}>{currentLanguage.user}</MenuItem>
                    <MenuItem onClick={event => setPath('/config-system')}>{currentLanguage.settings}</MenuItem>
                </Menu>
            </React.Fragment>
        )
        case RoleConstant.COMPANY_OPERATOR: return (
            <React.Fragment>
                <HeaderLinkButton path={path} setPath={setPath} to="/">{currentLanguage.dashboard}</HeaderLinkButton>
                <Button style={{ color: 'white' }} onClick={handleOpenReportMenu}>{currentLanguage.reports}</Button>
                <Button style={{ color: 'white' }} onClick={handleOpenTagMenu}>{currentLanguage.tags}</Button>

                <Menu anchorEl={reportMenuAnchorElement} open={Boolean(reportMenuAnchorElement)} onClose={handleCloseReportMenu}>
                    <MenuItem onClick={event => setPath('/inspections')}>{currentLanguage.inspections}</MenuItem>
                    <MenuItem onClick={event => setPath('/maintenances')}>{currentLanguage.maintenances}</MenuItem>
                </Menu>

                <Menu anchorEl={tagMenuAnchorElement} open={Boolean(tagMenuAnchorElement)} onClose={handleCloseTagMenu}>
                    <MenuItem onClick={event => setPath('/tags')}>{currentLanguage.list}</MenuItem>
                    <MenuItem onClick={event => setPath('/tags-replace')}>{currentLanguage.tag_replace}</MenuItem>
                </Menu>
            </React.Fragment>
        )
        default: return (<div><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></div>);
    }
}

export class Header extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            path: HistoryService.path(),
            userMenuAnchorElement: null,
            formMenuAnchorElement: null,
            tagMenuAnchorElement: null,
            reportMenuAnchorElement: null,
            configMenuAnchorElement: null,
            totalExpiredInspections: null,
            serviceOrderMenuAnchorElement: null,
        }
    }

    gotoAndClose = path => {
        HistoryService.goto(path)
        this.setState({ path })
        this.handleCloseUserMenu()
        this.handleCloseFormMenu()
        this.handleCloseReportMenu()
        this.handleCloseOSMenu()
        this.handleCloseTagMenu()
        this.handleCloseConfigMenu()
    };

    handleOpenUserMenu = event => this.setState({ userMenuAnchorElement: event.currentTarget })
    handleCloseUserMenu = event => this.setState({ userMenuAnchorElement: null })

    handleOpenFormMenu = event => this.setState({ formMenuAnchorElement: event.currentTarget })
    handleCloseFormMenu = event => this.setState({ formMenuAnchorElement: null })

    handleOpenTagMenu = event => this.setState({ tagMenuAnchorElement: event.currentTarget })
    handleCloseTagMenu = event => this.setState({ tagMenuAnchorElement: null })

    handleOpenOSMenu = event => this.setState({ serviceOrderMenuAnchorElement: event.currentTarget })
    handleCloseOSMenu = event => this.setState({ serviceOrderMenuAnchorElement: null })

    handleOpenReportMenu = event => this.setState({ reportMenuAnchorElement: event.currentTarget })
    handleCloseReportMenu = event => this.setState({ reportMenuAnchorElement: null })

    handleOpenConfigMenu = event => this.setState({ configMenuAnchorElement: event.currentTarget })
    handleCloseConfigMenu = event => this.setState({ configMenuAnchorElement: null })

    render() {
        const { loggedUser, currentLanguage } = this.props
        const {
            userMenuAnchorElement,
            reportMenuAnchorElement,
            formMenuAnchorElement,
            tagMenuAnchorElement,
            configMenuAnchorElement,
            serviceOrderMenuAnchorElement,
            path
        } = this.state

        return (
            <AppBar position="static" color="secondary" style={{ borderBottom: '2px solid rgb(14, 74, 100)' }}>
                <Toolbar style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
                    <img alt="logo" src={LogoMobcheck} style={{ height: '3rem' }} />
                    <LargeTypography variant="h6" color="inherit" style={{ marginLeft: '2rem' }}>
                        <LinksByRole
                            currentRole={loggedUser.currentRole}
                            reportMenuAnchorElement={reportMenuAnchorElement}
                            formMenuAnchorElement={formMenuAnchorElement}
                            tagMenuAnchorElement={tagMenuAnchorElement}
                            configMenuAnchorElement={configMenuAnchorElement}
                            serviceOrderMenuAnchorElement={serviceOrderMenuAnchorElement}
                            handleOpenOSMenu={this.handleOpenOSMenu}
                            handleCloseOSMenu={this.handleCloseOSMenu}
                            handleOpenReportMenu={this.handleOpenReportMenu}
                            handleCloseReportMenu={this.handleCloseReportMenu}
                            handleOpenFormMenu={this.handleOpenFormMenu}
                            handleCloseFormMenu={this.handleCloseFormMenu}
                            handleOpenTagMenu={this.handleOpenTagMenu}
                            handleCloseTagMenu={this.handleCloseTagMenu}
                            handleOpenConfigMenu={this.handleOpenConfigMenu}
                            handleCloseConfigMenu={this.handleCloseConfigMenu}
                            currentLanguage={currentLanguage}
                            path={path}
                            setPath={this.gotoAndClose}
                        />
                    </LargeTypography>
                    <Button onClick={this.handleOpenUserMenu} style={{ color: 'white' }}>
                        {loggedUser.image === null ? <img src={IconUser} style={{ margin: '0rem 0.5rem' }} alt="" />
                            : <img src={'data:image/png;base64,' + loggedUser.image} style={{ margin: '0rem 0.5rem', height: '1.5rem' }} alt="" />}

                        <Typography style={{ fontSize: '0.7rem', color: '#FFFFFF', marginRight: '0.5rem' }}> {loggedUser.name} </Typography> | <Typography style={{ fontSize: '0.7rem', color: '#FFFFFF', marginLeft: '0.5rem' }}> {loggedUser.companyName}  </Typography>
                    </Button>
                </Toolbar>
                <Menu anchorEl={userMenuAnchorElement} open={Boolean(userMenuAnchorElement)} onClose={this.handleCloseUserMenu}>
                    <MenuItem onClick={event => this.gotoAndClose('/profile')}>{currentLanguage.profile}</MenuItem>
                    <MenuItem onClick={event => this.gotoAndClose('/change-password')}>{currentLanguage.change_password}</MenuItem>
                    <MenuItem onClick={event => this.gotoAndClose('/video-lessons')}>{currentLanguage.video_lessons}</MenuItem>
                    {loggedUser.currentRole === RoleConstant.COMPANY_GROUP_ADMIN || loggedUser.currentRole === RoleConstant.MOBCHECK_ADMIN ? <MenuItem onClick={event => this.gotoAndClose('/my-plan')}>{currentLanguage.my_plan}</MenuItem> : null}
                    <MenuItem onClick={event => this.gotoAndClose('/logout')}>{currentLanguage.logout}</MenuItem>
                </Menu>
            </AppBar>
        );
    }
}

export const headerFunctions = { LinksByRole, HeaderLinkButton }
