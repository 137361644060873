import React from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    IconButton,
    Table,
    TableBody,
    TableHead,
    TableRow
} from '@material-ui/core';
import {
    BodyTableCell,
    formatMainDescription,
    HeaderTableCell,
    OrangeDiv,
    RedDiv,
    SubDiv,
    BlackDiv
} from '../components/components';
import { DateTimeService } from '../utils/dateTimeService';
import { InspectionService } from '../inspection/inspectionService';
import PdfIcon from './images/icon-pdf-file.png';
import ExcelIcon from './images/icon-excel-file.png';
import { Check, Close, NotInterested } from '@material-ui/icons';

import Spinner from 'react-spinner-material'

export class EquipmentDialogTabInspections extends React.Component {
    
    formatAttributeValues = (attributesValues) => {
        if (attributesValues.length === 0) {
            return (
                <div/>
            )
        }
    
        return attributesValues.map(attributeValue => (
            <React.Fragment>
                <SubDiv>{attributeValue.attribute}</SubDiv>
                <div>{attributeValue.value}</div>
            </React.Fragment>
        ))
    }
    
    formatQuestionAnswer = (questionAnswer, index) => {
        const { currentLanguage } = this.props
    
        if (questionAnswer.type === 'TEXT' || questionAnswer.type === 'CUSTOM') {
            return (
                <div key={index}>
                    <BlackDiv>{currentLanguage.question_initial}: {questionAnswer.question}</BlackDiv>
                    <SubDiv>{currentLanguage.answer_initial}: {questionAnswer.answer}</SubDiv>
                </div>
            )
        }
    
        if (questionAnswer.type === 'YES_NO_NA') {
            switch (questionAnswer.answer) {
                case 'YES': return (
                    <div key={index}>
                        <Check />
                        {questionAnswer.question}
                    </div>
                )
                case 'NO': return (
                    <RedDiv key={index}>
                        <Close style={{color: 'rgb(165, 35, 47)'}} />
                        {questionAnswer.question}
                    </RedDiv>
                )
                case 'NA': return (
                    <OrangeDiv key={index}>
                        <NotInterested />
                        {questionAnswer.question}
                    </OrangeDiv>
                )
                default: throw new Error(`Unimplemented ${questionAnswer.answer}`)
            }
        }
    
        throw new Error(`Unimplemented ${questionAnswer.type}`)
    }
    
    formatQuestionsAnswers = (questionsAnswers) => {
        if (questionsAnswers.length === 0) {
            return (
                <div>Sem questões/respostas</div>
            )
        }
    
        return questionsAnswers.map(this.formatQuestionAnswer)
    }
    
    buildInspectionList = (inspections) => {
        if (inspections === null) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={4}><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></BodyTableCell>
                </TableRow>
            )
        }
    
        if (inspections.length === 0) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={4}>Lista vazia.</BodyTableCell>
                </TableRow>
            )
        }
     
        return inspections.map(inspection => (
            <TableRow key={inspection.inspectionId} style={{verticalAlign: 'top'}}>
                <BodyTableCell>
                    <div>{formatMainDescription(inspection.userName, inspection.userEmail)}</div>
                    <div>{DateTimeService.formatDateTime(inspection.createdAt)}</div>
                    <div style={{textAlign: 'center'}}>
                        <IconButton onClick={() => InspectionService.downloadPdf(inspection.inspectionId)}><img src={PdfIcon} alt="pdf" /></IconButton>
                        <IconButton onClick={() => InspectionService.downloadExcel(inspection.inspectionId)}><img src={ExcelIcon} alt="excel" /></IconButton>
                    </div>
                </BodyTableCell>
                <BodyTableCell>{this.formatAttributeValues(inspection.attributesValues)}</BodyTableCell>
                <BodyTableCell>{this.formatQuestionsAnswers(inspection.questionsAnswers)}</BodyTableCell>
            </TableRow>
        ))
    }

    render () {
        const { closeDialog, inspections, currentLanguage } = this.props

        return (
            <React.Fragment>
                <DialogContent style={{height: 'calc(100vh - 20rem)', padding: '0rem'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <HeaderTableCell style={{position: 'sticky', top: '0rem', zIndex: '1'}}>{currentLanguage.responsible}/{currentLanguage.date}/{currentLanguage.report}</HeaderTableCell>
                                <HeaderTableCell style={{position: 'sticky', top: '0rem', zIndex: '1'}}>{currentLanguage.attributes}/{currentLanguage.values}</HeaderTableCell>
                                <HeaderTableCell style={{position: 'sticky', top: '0rem', zIndex: '1'}}>{currentLanguage.questions}/{currentLanguage.answers}</HeaderTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.buildInspectionList(inspections)}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between'}}>
                    <div>
                    </div>

                    <div>
                        <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                    </div>
                </DialogActions>
            </React.Fragment>
        )
    }

}
