import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Typography,
} from '@material-ui/core'
import { Autocomplete, TextField } from '@mui/material'
import { Flex1150Div, FlexColumnDiv, FlexDiv, PrimaryButton, FlexBaselineDiv, SecondaryButton, FlexSpaceBetweenDiv } from '../components/components';
import { CollectionViewUpdater } from '../utils/collectionViewUpdater';
import { FilterDatePicker } from '../components/filterDatePicker';
import { OsService } from './osService';
import { OsFormFindEquipments } from './osFormFindEquipments'
import { Search } from '@material-ui/icons';

export class OsFormFilterListOS extends React.Component {

    constructor(props) {
        super(props)
        const { osData, listFilterRequestersUsers } = props

        this.collectionUpdater = CollectionViewUpdater.ofEquipments(this);
        this.state = {
            isDialogOpen: false,
            osData,
            listFilterRequestersUsers,
            equipmentList: [],
            userList: [],
            requestUserList: [],
            loggedUser: {},
            equipSelected: null,
            clearAutoClompletes: 'key'
        }
    }

    handleDialogOpen = () => {
        const { osData, listFilterRequestersUsers } = this.props

        this.setState({ isDialogOpen: true, osData })

        OsService.equipments().then(equipments => this.setState({
            equipmentList: equipments.map(equip =>
                ({ id: equip?.tagSerialNumber, label: equip?.product?.name, isCheck: false, ...equip }))
        }));
        OsService.userList().then(userList => this.setState({ userList: userList.map(user => ({ id: user.id, label: user.name })) }));
        this.setState({
            requestUserList: listFilterRequestersUsers
        });
    }

    closeDialog = () => this.setState({ isDialogOpen: false })

    updateCompanyData = newData => this.setState(state => ({
        osData: { ...state.osData, ...newData }
    }))

    handleValueChange = field => event => this.updateCompanyData({ [field]: event.target.value });

    handleSubmit = () => {
        const data = { ...this.state.osData };
        data.equipment = this.state.osData.equipment;

        this.props.onSubmit(data)
        this.setState({ equipSelected: null })
        this.closeDialog();
    }

    clearFilter = () => {
        this.setState({
            osData: {
                id: '',
                startDate: null,
                endDate: null,
                requestUserId: '',
                responsibleUserId: null,
                observation: '',
                status: null,
                priority: null,
                equipment: null,
            },
            equipSelected: null,
            clearAutoClompletes: 'keyChange'
        })
    }
    render() {
        const { isDialogOpen, osData, equipmentList, userList, requestUserList, equipSelected } = this.state
        const { submitLabel, ModalButton, currentLanguage } = this.props

        return (
            <React.Fragment>
                <ModalButton onClick={this.handleDialogOpen} />
                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">

                    <FlexDiv
                        style={{ alignItems: 'center', justifyContent: 'center', background: 'rgb(14, 74, 100)' }}>
                        <Typography variant="subtitle1" style={{ fontWeight: 500, fontSize: '1.2rem', textAlign: 'center', marginTop: '0.5rem', marginBottom: '0.5rem', color: '#ffffff' }}>
                            {currentLanguage.search_os}
                        </Typography>
                    </FlexDiv>

                    <DialogContent>
                        <FlexDiv>
                            <Flex1150Div>
                                <FlexColumnDiv>
                                    <FlexBaselineDiv style={{ flexWrap: 'wrap' }}>
                                        <FilterDatePicker
                                            style={{ flex: '1 0 9rem' }}
                                            value={osData?.startDate}
                                            onChange={date => this.updateCompanyData({ startDate: date })}
                                            label={currentLanguage.from}

                                        />
                                    </FlexBaselineDiv>

                                    <TextField
                                        variant="standard"
                                        margin="normal"
                                        label={currentLanguage.comments}
                                        value={osData?.observation}
                                        onChange={event => this.updateCompanyData({ observation: event.target.value })}

                                    />

                                    <TextField
                                        variant="standard"
                                        margin="normal"
                                        label={currentLanguage.number_os}
                                        value={osData?.id}
                                        onChange={event => this.updateCompanyData({ id: event.target.value })}

                                    />
                                    <FormControl margin="normal">
                                        <Autocomplete
                                            options={[
                                                { id: 'LOWEST', label: currentLanguage.priority_lowest },
                                                { id: 'LOW', label: currentLanguage.priority_low },
                                                { id: 'AVERAGE', label: currentLanguage.priority_average },
                                                { id: 'HIGH', label: currentLanguage.priority_high },
                                                { id: 'HIGHEST', label: currentLanguage.priority_very_high },
                                            ]}
                                            onChange={(event, newValue) => this.updateCompanyData({ priority: newValue?.id ?? '' })}
                                            key={this?.state?.clearAutoClompletes}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => <TextField
                                                required
                                                {...params}
                                                fullWidth
                                                variant="standard"
                                                label={currentLanguage.priority}
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />}
                                        />
                                    </FormControl>

                                </FlexColumnDiv>
                            </Flex1150Div>

                            <Flex1150Div style={{ marginLeft: '1rem' }}>
                                <FlexColumnDiv>
                                    <FlexBaselineDiv style={{ flexWrap: 'wrap' }}>
                                        <FilterDatePicker
                                            style={{ flex: '1 0 9rem' }}
                                            onChange={date => this.updateCompanyData({ endDate: date })}
                                            label={currentLanguage.to}
                                            value={osData?.endDate}

                                        />
                                    </FlexBaselineDiv>

                                    <FormControl margin="normal">
                                        <Autocomplete
                                            id="country-select-demo"
                                            options={userList}
                                            onChange={(event, newValue) => this.updateCompanyData({ responsibleUserId: newValue?.id ?? '' })}
                                            key={this?.state?.clearAutoClompletes}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    variant="standard"
                                                    label={currentLanguage.responsible_user}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                />
                                            }
                                        />
                                    </FormControl>

                                    <FormControl margin="normal">
                                        <Autocomplete
                                            options={requestUserList}
                                            onChange={(event, newValue) => this.updateCompanyData({ requestUserId: newValue?.id ?? '' })}
                                            getOptionLabel={(option) => option.label}
                                            key={this?.state?.clearAutoClompletes}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                fullWidth
                                                variant="standard"
                                                label={currentLanguage.user_current}
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />
                                            }
                                        />

                                    </FormControl>

                                    <FormControl margin="normal">
                                        <Autocomplete
                                            options={[
                                                { id: 'PENDING', label: currentLanguage.status_pending },
                                                { id: 'PARTIAL', label: currentLanguage.status_partial },
                                                { id: 'FINISHED', label: currentLanguage.status_finished },
                                                { id: 'CANCELLED', label: currentLanguage.status_cancelled },
                                            ]}
                                            onChange={(event, newValue) => this.updateCompanyData({ status: newValue?.id ?? '' })}
                                            getOptionLabel={(option) => option.label}
                                            key={this?.state?.clearAutoClompletes}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                fullWidth
                                                variant="standard"
                                                label={currentLanguage.os_status}
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />
                                            }
                                        />
                                    </FormControl>
                                </FlexColumnDiv>
                            </Flex1150Div>
                        </FlexDiv>

                        <FlexDiv style={{ alignItems: 'center' }}>
                            <FormControl margin="normal">
                                <OsFormFindEquipments
                                    currentLanguage={currentLanguage}
                                    equipmentListProp={equipmentList}
                                    isNotSelectAll
                                    submitLabel={currentLanguage.addSelecteds}
                                    onSubmit={this.handleSelectEquipaments}
                                    onSelectButton={(equip) => {
                                        this.setState({ equipSelected: equip })
                                        this.updateCompanyData({ equipment: equip })
                                    }}
                                    ModalButton={({ onClick }) => (
                                        <SecondaryButton onClick={onClick} style={{ alignSelf: 'center', margin: '0 1rem 0 0' }}>
                                            <Search />
                                            {currentLanguage.search_equipments}
                                        </SecondaryButton>
                                    )}
                                />
                            </FormControl>
                            {equipSelected !== null &&
                                <span style={{ maxWidth: '70%', marginTop: '0.6rem' }}>
                                    {`${equipSelected.tagSerialNumber} - ${equipSelected?.product?.name} - ${equipSelected?.product?.sku}`}
                                </span>
                            }
                        </FlexDiv>

                    </DialogContent>
                    <DialogActions>
                        <FlexSpaceBetweenDiv style={{ flex: 1 }}>
                            <PrimaryButton onClick={this.clearFilter}>{currentLanguage.clear_fields}</PrimaryButton>
                            <FlexDiv>
                                <Button onClick={this.closeDialog}>{currentLanguage.cancel}</Button>
                                <PrimaryButton onClick={this.handleSubmit}>{submitLabel}</PrimaryButton>
                            </FlexDiv>
                        </FlexSpaceBetweenDiv>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }

}

