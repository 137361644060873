import MaskedInput from 'react-text-mask';
import React from 'react';

function phoneMask (userInput) {
    const numbers = userInput.match(/\d/g);
    const length = numbers && numbers.length;

    if (length > 10) {
        return [ '(', /[1-9]/, /[1-9]/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ];
    } else if (length === 10) {
        return [ '(', /[1-9]/, /[1-9]/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/ ];
    } else {
        return [ '(', /[1-9]/, /[1-9]/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ];
    }

}

// TODO: fix last digit
export function PhoneInput (props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput {...other} mask={phoneMask} showMask keepCharPositions guide ref={ref => inputRef(ref ? ref.inputElement : null)} />
    );
}
