import React from 'react';

import { KeyboardDatePicker } from '@material-ui/pickers';


export function FilterDatePicker(props) {
    const { value, onChange, label, style, disableFuture, required, minDate, maxDate, disablePast } = props;

    return (
        <KeyboardDatePicker
            invalidDateMessage='Insira uma data válida'
            minDateMessage="Insirar uma data igual ou superior ao dia de hoje."
            required={required}
            autoOk clearable
            disableFuture={disableFuture}
            disablePast={disablePast}
            format="DD/MM/YYYY"
            cancelLabel="Cancelar"
            clearLabel="Limpar"
            okLabel="Ok"
            minDate={minDate}
            maxDate={maxDate}
            value={value}
            onChange={onChange}
            label={label}
            style={style} />
    );
}

