import axios from 'axios'

export const DashboardApi = {
  summary: () => axios.get('/web/dashboard/'),
  monthlyInspections: () => axios.get('/web/dashboard/monthly-inspections/'),
  equipmentStatus: () => axios.get('/web/dashboard/equipment-status/'),
  employeeInspections: (dateStart, dateEnd) => axios.get('/web/dashboard/employee-inspections-by-month/', {
    params: {
      dateStart,
      dateEnd
    }
  }),
  inspectionTypeInspections: () => axios.get('/web/dashboard/inspection-type-inspections/'),
  productInspections: (dateStart, dateEnd) => axios.get('/web/dashboard/product-inspections-by-month/', {
    params: {
      dateStart,
      dateEnd
    }
  })
}
