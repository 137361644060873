import React from 'react'
import {
    FormControl,
    IconButton,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core'
import {
    BodyTableCell,
    ContainerDiv,
    FlexDiv,
    HeaderTableCell, OverflowXDiv,
    PrimaryButton,
    SecondaryButton
} from '../components/components'
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material'
import { Search, Delete } from '@material-ui/icons'
import { withSnackbar } from 'notistack'
import { OsService } from './osService'
import { LoggedUserService } from '../loggedUser/loggedUserService'
import { RoleConstant } from '../../app/roleConstant'
import { FilterDatePicker } from '../components/filterDatePicker';
import { OsFormFindEquipments } from './osFormFindEquipments'

class CreateOsPage extends React.Component {
    state = {
        osDataSubmit: {
            endDate: null,
            startDate: null,
            observation: '',
            priority: null,
            type: null,
            responsibleUserId: null,
        },
        loggedUser: {},
        userList: [],
        equipmentList: [],
        clearAutoClompletes: 'key'
    }

    async componentDidMount() {
        const userListResopnse = await OsService.userList()

        const loggedUserReponse = await LoggedUserService.getLoggedUser()

        const listEquipmentsRespone = await OsService.equipments()

        this.setState({
            equipmentList: listEquipmentsRespone.filter(equip => equip?.enabledTag === true)
                .map(equip => ({ id: equip?.tagSerialNumber, label: equip?.product?.name, isCheck: false, ...equip })),
            loggedUser: loggedUserReponse,
            userList: userListResopnse.map(user => ({ id: user.id, label: user.name }))

        })
    }

    handleCreateSubmit = async () => {
        const locale = 'pt-br';
        moment.locale(locale);
        const { endDate, startDate, observation, priority, type, responsibleUserId } = this.state.osDataSubmit;
        if (moment(moment(startDate).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD"))) {
            return this.props.enqueueSnackbar('Insirar uma data de início igual ou superior ao dia de hoje.', { variant: 'error' })
        }
        if (this?.state?.equipmentList.filter(equip => equip.isCheck).length === 0) {
            return this.props.enqueueSnackbar('Insirar algum equipamento para cadastrar.', { variant: 'error' })
        }
        if (!endDate || !startDate || !priority || !type || !responsibleUserId) {
            return this.props.enqueueSnackbar('Existem dados obrigatórios em branco.', { variant: 'error' })
        }

        const formatedListEquipment = this?.state?.equipmentList.filter(e => e?.isCheck === true).map(equip => ({
            equipmentSerialNumber: equip?.tagSerialNumber,
            name: equip?.extraLabel,
            type,
            responsibleUserId,
            completionDate: null,
        }))

        const bodyRequest = {
            startDate,
            endDate,
            requestUserId: this.state.loggedUser?.id,
            responsibleUserId,
            observation,
            status: "PENDING",
            priority,
            equipments: formatedListEquipment
        }


        try {
            await OsService.create(bodyRequest);
            this.props.enqueueSnackbar('Ordem de Serviço cadastrada com sucesso!', { variant: 'success' })

            this.setState({
                osDataSubmit: {
                    endDate: null,
                    startDate: null,
                    observation: '',
                    priority: null,
                    type: null,
                    responsibleUserId: null,
                },
                equipmentList: this.state.equipmentList.map(equip => ({ ...equip, isCheck: false })),
                clearAutoClompletes: 'keyChange'
            })

        } catch (error) {
            if (!!error?.response?.data?.message) {
                return this.props.enqueueSnackbar(error?.response?.data?.message, { variant: 'error' })
            }
            this.props.enqueueSnackbar('Erro ao cadastrar OS', { variant: 'error' })
            console.log('Error: ', error);
        }
    }

    handleSelectEquipaments = (listEquipmentsSelecteds) => this.setState({ equipmentList: listEquipmentsSelecteds })

    updateCompanyData = newData => this.setState(state => ({
        osDataSubmit: { ...state.osDataSubmit, ...newData }
    }))

    removeEquipmentList = (tagSerialNumber) => {
        this.setState({
            equipmentList: this.state.equipmentList.map(equip => {
                if (equip.tagSerialNumber === tagSerialNumber) {
                    return {
                        ...equip,
                        isCheck: false
                    }
                } else {
                    return equip
                }
            })
        })
    }

    render() {
        const { loggedUser, osDataSubmit, userList, equipmentList } = this.state
        const { currentLanguage } = this.props

        return (
            <React.Fragment>
                <FlexDiv style={{ background: 'rgb(14, 74, 100)', justifyContent: 'center' }}>
                    <Typography variant="h5" style={{ color: '#fff', fontWeight: 500, fontSize: '1.5rem', textAlign: 'center', marginTop: '0.4rem', marginBottom: '0.54rem' }}>
                        {currentLanguage.register_new_os}
                    </Typography>
                </FlexDiv>

                <ContainerDiv style={{ marginBottom: '1rem' }}>
                    <FlexDiv style={{ marginBottom: '1rem' }}>
                        <FilterDatePicker
                            disablePast
                            style={{ flex: 1, marginRight: '2rem' }}
                            maxDate={osDataSubmit?.endDate || undefined}
                            value={osDataSubmit?.startDate}
                            onChange={date => this.updateCompanyData({ startDate: date })}
                            label={currentLanguage.start_date}
                            required
                        />
                        <FilterDatePicker
                            disablePast
                            style={{ flex: 1 }}
                            minDate={osDataSubmit?.startDate || undefined}
                            value={osDataSubmit?.endDate}
                            onChange={date => this.updateCompanyData({ endDate: date })}
                            label={currentLanguage.end_date}
                            required
                        />

                        <FormControl required style={{ flex: 1, marginLeft: '2rem' }} margin="none">
                            <Autocomplete
                                options={[
                                    { id: 'LOWEST', label: currentLanguage.priority_lowest },
                                    { id: 'LOW', label: currentLanguage.priority_low },
                                    { id: 'AVERAGE', label: currentLanguage.priority_average },
                                    { id: 'HIGH', label: currentLanguage.priority_high },
                                    { id: 'HIGHEST', label: currentLanguage.priority_very_high },
                                ]}
                                onChange={(event, newValue) => this.updateCompanyData({ priority: newValue?.id ?? '' })}
                                getOptionLabel={(option) => option.label}
                                key={this?.state?.clearAutoClompletes}
                                renderInput={(params) => <TextField
                                    required
                                    {...params}
                                    fullWidth
                                    variant="standard"
                                    label={currentLanguage.priority}
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />}
                            />
                        </FormControl>

                        <FormControl style={{ flex: 1, marginLeft: '2rem' }} margin="none">
                            <Autocomplete
                                options={userList}
                                onChange={(event, newValue) => this.updateCompanyData({ responsibleUserId: newValue?.id ?? '' })}
                                getOptionLabel={(option) => option.label}
                                key={this?.state?.clearAutoClompletes}
                                renderInput={(params) => <TextField
                                    required
                                    {...params}
                                    fullWidth
                                    variant="standard"
                                    label={currentLanguage.responsible_user}
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />}
                            />
                        </FormControl>
                    </FlexDiv>

                    <FlexDiv>
                        <TextField
                            style={{ flex: 2, marginRight: '2rem' }}
                            variant="standard"
                            margin="none"
                            label={currentLanguage.comments}
                            value={osDataSubmit?.observation}
                            onChange={event => this.updateCompanyData({ observation: event.target.value })}
                        />

                        <FormControl style={{ flex: 1 }} margin="none">
                            <Autocomplete
                                options={[
                                    { id: 'INSPECTION', label: currentLanguage.os_inspection },
                                    { id: 'MAINTENANCE', label: currentLanguage.os_maintenance },
                                ]}
                                onChange={(event, newValue) => this.updateCompanyData({ type: newValue?.id ?? '' })}
                                key={this?.state?.clearAutoClompletes}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField
                                    required
                                    {...params}
                                    fullWidth
                                    variant="standard"
                                    label={currentLanguage.os_type}
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />}
                            />
                        </FormControl>

                        {loggedUser.currentRole === RoleConstant.MOBCHECK_ADMIN ?
                            <FormControl margin="normal">
                                <OsFormFindEquipments
                                    currentLanguage={currentLanguage}
                                    submitLabel={currentLanguage.addSelecteds}
                                    loggedUser={loggedUser}
                                    equipmentListProp={equipmentList}
                                    osData={{
                                        id: "",
                                        startDate: null,
                                        endDate: null,
                                        requestUserId: "",
                                        responsibleUserId: "",
                                        observation: "",
                                        status: "",
                                        equipment: {
                                            equipmentSerialNumber: "",
                                            name: "",
                                            priority: "LOWEST",
                                            type: "INSPECTION",
                                            responsibleUserId: "",
                                            completionDate: null
                                        }
                                    }}
                                    onSubmit={this.handleSelectEquipaments}
                                    ModalButton={({ onClick }) => (
                                        <SecondaryButton onClick={onClick} style={{ alignSelf: 'center', margin: '0 2rem' }}>
                                            <Search />
                                            {currentLanguage.search_equipments}
                                        </SecondaryButton>
                                    )}
                                />
                            </FormControl>
                            : null}

                        <FormControl margin="normal">
                            <PrimaryButton onClick={this.handleCreateSubmit} style={{ alignSelf: 'center' }}>
                                {currentLanguage.register_os_abbreviated}</PrimaryButton>
                        </FormControl>
                    </FlexDiv>


                </ContainerDiv>

                <OverflowXDiv>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <HeaderTableCell field="equipment_serial">
                                    {currentLanguage.equipment_serial}
                                </HeaderTableCell>
                                <HeaderTableCell field="extra_nome_equipment">
                                    {currentLanguage.extra_nome_equipment}
                                </HeaderTableCell>
                                <HeaderTableCell field="product_name">
                                    {currentLanguage.product_name}
                                </HeaderTableCell>
                                <HeaderTableCell field="sku">
                                    {currentLanguage.sku}
                                </HeaderTableCell>
                                <HeaderTableCell field="description">
                                    {currentLanguage.description}
                                </HeaderTableCell>
                                <HeaderTableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {equipmentList?.map(equip => equip?.isCheck === true && (
                                <TableRow key={equip?.id + Math.floor(Math.random() * Date.now())}>
                                    <BodyTableCell>{equip?.tagSerialNumber}</BodyTableCell>
                                    <BodyTableCell>{equip?.extraLabel}</BodyTableCell>
                                    <BodyTableCell>{equip?.product?.name}</BodyTableCell>
                                    <BodyTableCell>{equip?.product?.sku}</BodyTableCell>
                                    <BodyTableCell>{equip?.product?.shortDescription}</BodyTableCell>
                                    <BodyTableCell align="center">
                                        <IconButton onClick={() => { this.removeEquipmentList(equip?.tagSerialNumber) }}><Delete color='secondary' /></IconButton>
                                    </BodyTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </OverflowXDiv>
            </React.Fragment>
        );
    }
}

export default withSnackbar(CreateOsPage)
