import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.css'
import './app.css'

import { Header } from '../authenticated/header/header'
import Footer from '../components/footer'
import { RoleConstant } from './roleConstant'
import { Flex11Div, FlexColumnDiv } from '../authenticated/components/components'
import { AuthenticationService } from './authenticationService'
import { HistoryService } from './historyService'
import { LoggedUserService } from '../authenticated/loggedUser/loggedUserService'

import RegisterUserPage from '../anonymous/registerUser/registerUserPage'
import LandpagePage from '../anonymous/landpage/login2'
import PasswordResetPage from '../anonymous/passwordReset/passwordResetPage'
import ActiveEnvironmentPage from '../anonymous/activeEnvironment/activeEnvironmentPage'
import PlanPage from '../anonymous/planPage/planPage'
import EquipmentsPage from '../authenticated/equipment/equipmentsPage'
import MaintenancesPage from '../authenticated/maintenance/maintenancePage'
import CategoriesPage from '../authenticated/category/categoriesPage'
import InspectionTypesPage from '../authenticated/inspectionType/inspectionTypesPage'
import ProductsPage from '../authenticated/product/productsPage'
import TagsPage from '../authenticated/tag/tagsPage'
import TagsReplacePage from '../authenticated/tag/tagsReplacePage'
import CompanyUsersPage from '../authenticated/companyUser/companyUsersPage'
import CompaniesPage from '../authenticated/company/companiesPage'
import CompanyGroupsPage from '../authenticated/companyGroup/companyGroupsPage'
import LoggedUserPage from '../authenticated/loggedUser/loggedUserPage'
import { CompanyGroupService } from '../authenticated/companyGroup/companyGroupService'
import ChangePasswordPage from '../authenticated/loggedUser/changePasswordPage'
import UsersPage from '../authenticated/user/usersPage'
import OsPage from '../authenticated/os/osPage'
import { DashboardCommonPage } from '../authenticated/dashboard/dashboardCommonPage'
import { DashboardPage } from '../authenticated/dashboard/dashboardPage'
import ConfigSystemPage from '../authenticated/configuration/configSystemPage'
import { RedirectPage } from './redirectPage'
import languages from "../translations"
import { CompanyGroupStatus } from '../authenticated/companyGroup/companyGroupStatus'
import MyPlanPage from '../authenticated/myPlan/myPlanPage'
import BuyTagPage from '../authenticated/myPlan/buyTagPage'
import VideoLessonsPage from '../authenticated/videoLessons/videoLessonsPage'
import TagQrCodePage from '../authenticated/tag/tagQrCodePage'

import LoadingScreen from 'react-loading-screen'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import LOGO from '../anonymous/landpage/images/logo-white.png'

import ReactGA from 'react-ga'
import CreateOSPage from '../authenticated/os/createOSPage';

const trackingId = "GTM-MKH7HGJ"

ReactGA.initialize(trackingId)


export class App extends React.Component {
    state = {
        initialRoute: '/',
        loggedUser: { currentRole: null },
        currentLanguage: languages.pt,
        companyGroupStatus: CompanyGroupStatus.OK
    }

    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search)

        let currentLanguage = JSON.parse(localStorage.getItem("current-language"))

        if (currentLanguage) {
            this.setState({ currentLanguage })
        }

        LoggedUserService.getSessionLoggedUser().then(this.authenticate).catch(this.deauthenticate)
    }

    componentDidUpdate = () => ReactGA.pageview(window.location.pathname + window.location.search)

    authenticate = loggedUser => {
        const { initialRoute } = this.state

        CompanyGroupService.getStatus(loggedUser.companyGroup).then(response => this.setState({ companyGroupStatus: response }))

        if (initialRoute !== '/') {
            HistoryService.goto(initialRoute)
        }

        this.setState({ loggedUser, initialRoute: '/' })
    }

    deauthenticate = () => {
        AuthenticationService.deauthenticate()

        this.setState({ loggedUser: { currentRole: RoleConstant.ANONYMOUS } })

        switch (HistoryService.path()) {
            case '/register': break
            case '/password-reset': break
            default: HistoryService.goto('/')
        }
    }

    updateLoggedUser = loggedUser => {
        this.setState({ loggedUser })
    }

    updateCompanyGroup = companyGroup => {
        const { loggedUser } = this.state

        LoggedUserService.getLoggedUser().then(loggedUser => {
            this.setState({ loggedUser })
        })

        this.setState({ loggedUser: { ...loggedUser, companyGroup } })
    }

    buildAnonymousRoutes = () => (
        <Switch>
            <Route exact path="/inspections-report" render={() => (<RedirectPage to="/" callback={() => this.setState({ initialRoute: '/report?view=inspections-report' })} />)} />
            <Route exact path="/register" render={() => (<RegisterUserPage currentLanguage={this.state.currentLanguage} />)} />
            <Route exact path="/password-reset" render={() => (<PasswordResetPage onPasswordReset={this.authenticate} currentLanguage={this.state.currentLanguage} />)} />
            <Route exact path="/active-environment" render={() => (<ActiveEnvironmentPage onActiveEnvironment={this.authenticate} currentLanguage={this.state.currentLanguage} />)} />
            <Route exact path="/plans" render={() => <PlanPage onAuthentication={this.authenticate} currentLanguage={this.state.currentLanguage} />} />
            <Route exact path="/" render={() => <LandpagePage onAuthentication={this.authenticate} currentLanguage={this.state.currentLanguage} />} />
            <Redirect push to="/" />
        </Switch>
    )

    buildMobcheckAdminRoutes = loggedUser => (
        <FlexColumnDiv style={{ minHeight: '100vh' }}>
            <Header loggedUser={loggedUser} currentLanguage={this.state.currentLanguage} />
            <Flex11Div>
                <Switch>
                    <Route exact path="/inspections-report" render={() => (<RedirectPage to="/report?view=inspections-report" />)} />
                    <Route exact path="/logout" render={() => (<RedirectPage to="/" callback={this.deauthenticate} />)} />
                    <Route exact path="/inspections" render={() => (<EquipmentsPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/maintenances" render={() => (<MaintenancesPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/categories" render={() => (<CategoriesPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/subcategories" render={() => (<InspectionTypesPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/products" render={() => (<ProductsPage isAdmin={true} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/tags" render={() => (<TagsPage loggedUser={loggedUser} allowTagToggle={true} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/tags-replace" render={() => (<TagsReplacePage allowTagToggle={true} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/tags-qr-code" render={() => (<TagQrCodePage loggedUser={loggedUser} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/company-users" render={() => (<CompanyUsersPage isAdmin={true} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/companies" render={() => (<CompaniesPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/company-groups" render={() => (<CompanyGroupsPage onUpdate={this.updateCompanyGroup} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/users" render={() => (<UsersPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/os" render={() => (<OsPage loggedUser={loggedUser} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/create-os" render={() => (<CreateOSPage loggedUser={loggedUser} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/profile" render={() => (<LoggedUserPage onUpdate={this.updateLoggedUser} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/change-password" render={() => (<ChangePasswordPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/config-system" render={() => (<ConfigSystemPage currentLanguage={this.state.currentLanguage} setCurrentLanguage={(lang) => this.setCurrentLanguage(lang)} />)} />
                    <Route exact path="/my-plan" render={() => (<MyPlanPage loggedUser={loggedUser} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/buy-tag" render={() => (<BuyTagPage loggedUser={loggedUser} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/video-lessons" render={() => (<VideoLessonsPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/" render={() => (<DashboardPage currentLanguage={this.state.currentLanguage} />)} />
                    <Redirect push to="/" />
                </Switch>
            </Flex11Div>
            <Footer />
        </FlexColumnDiv>
    )

    buildCompanyGroupAdminRoutes = loggedUser => (
        <FlexColumnDiv style={{ minHeight: '100vh' }}>
            <Header loggedUser={loggedUser} currentLanguage={this.state.currentLanguage} />
            <Flex11Div>
                <Switch>
                    <Route exact path="/inspections-report" render={() => (<RedirectPage to="/report?view=inspections-report" />)} />
                    <Route exact path="/logout" render={() => (<RedirectPage to="/" callback={this.deauthenticate} />)} />
                    <Route exact path="/inspections" render={() => (<EquipmentsPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/maintenances" render={() => (<MaintenancesPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/categories" render={() => (<CategoriesPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/subcategories" render={() => (<InspectionTypesPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/products" render={() => (<ProductsPage isAdmin={true} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/tags" render={() => (<TagsPage loggedUser={loggedUser} allowTagToggle={true} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/tags-replace" render={() => (<TagsReplacePage allowTagToggle={true} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/tags-qr-code" render={() => (<TagQrCodePage loggedUser={loggedUser} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/company-users" render={() => (<CompanyUsersPage isAdmin={false} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/companies" render={() => (<CompaniesPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/profile" render={() => (<LoggedUserPage onUpdate={this.updateLoggedUser} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/change-password" render={() => (<ChangePasswordPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/config-system" render={() => (<ConfigSystemPage currentLanguage={this.state.currentLanguage} setCurrentLanguage={(lang) => this.setCurrentLanguage(lang)} />)} />
                    <Route exact path="/my-plan" render={() => (<MyPlanPage loggedUser={loggedUser} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/buy-tag" render={() => (<BuyTagPage loggedUser={loggedUser} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/video-lessons" render={() => (<VideoLessonsPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/" render={() => (<DashboardPage currentLanguage={this.state.currentLanguage} />)} />
                    <Redirect push to="/" />
                </Switch>
            </Flex11Div>
            <Footer />
        </FlexColumnDiv>
    )

    buildCompanyAdminRoutes = loggedUser => (
        <FlexColumnDiv style={{ minHeight: '100vh' }}>
            <Header loggedUser={loggedUser} currentLanguage={this.state.currentLanguage} />
            <Flex11Div>
                <Switch>
                    <Route exact path="/inspections-report" render={() => (<RedirectPage to="/report?view=inspections-report" />)} />
                    <Route exact path="/logout" render={() => (<RedirectPage to="/" callback={this.deauthenticate} />)} />
                    <Route exact path="/inspections" render={() => (<EquipmentsPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/maintenances" render={() => (<MaintenancesPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/categories" render={() => (<CategoriesPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/subcategories" render={() => (<InspectionTypesPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/products" render={() => (<ProductsPage isAdmin={false} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/tags" render={() => (<TagsPage loggedUser={loggedUser} allowTagToggle={true} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/tags-replace" render={() => (<TagsReplacePage allowTagToggle={true} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/tags-qr-code" render={() => (<TagQrCodePage loggedUser={loggedUser} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/company-users" render={() => (<CompanyUsersPage isAdmin={false} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/profile" render={() => (<LoggedUserPage onUpdate={this.updateLoggedUser} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/change-password" render={() => (<ChangePasswordPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/config-system" render={() => (<ConfigSystemPage currentLanguage={this.state.currentLanguage} setCurrentLanguage={(lang) => this.setCurrentLanguage(lang)} />)} />
                    <Route exact path="/my-plan" render={() => (<MyPlanPage loggedUser={loggedUser} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/buy-tag" render={() => (<BuyTagPage loggedUser={loggedUser} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/video-lessons" render={() => (<VideoLessonsPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/" render={() => (<DashboardPage currentLanguage={this.state.currentLanguage} />)} />
                    <Redirect push to="/" />
                </Switch>
            </Flex11Div>
            <Footer />
        </FlexColumnDiv>
    )

    buildOperatorRoutes = loggedUser => (
        <FlexColumnDiv style={{ minHeight: '100vh' }}>
            <Header loggedUser={loggedUser} currentLanguage={this.state.currentLanguage} />
            <Flex11Div>
                <Switch>
                    <Route exact path="/inspections-report" render={() => (<RedirectPage to="/report?view=inspections-report" />)} />
                    <Route exact path="/logout" render={() => (<RedirectPage to="/" callback={this.deauthenticate} />)} />
                    <Route exact path="/inspections" render={() => (<EquipmentsPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/maintenances" render={() => (<MaintenancesPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/tags" render={() => (<TagsPage allowTagToggle={false} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/tags-replace" render={() => (<TagsReplacePage allowTagToggle={true} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/profile" render={() => (<LoggedUserPage onUpdate={this.updateLoggedUser} currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/change-password" render={() => (<ChangePasswordPage currentLanguage={this.state.currentLanguage} />)} />
                    <Route exact path="/config-system" render={() => (<ConfigSystemPage />)} currentLanguage={this.state.currentLanguage} setCurrentLanguage={(lang) => this.setCurrentLanguage(lang)} />
                    <Route exact path="/" render={() => (<DashboardCommonPage currentLanguage={this.state.currentLanguage} />)} />
                    <Redirect push to="/" />
                </Switch>
            </Flex11Div>
            <Footer />
        </FlexColumnDiv>
    )

    buildLoading = () => (
        <LoadingScreen
            loading={true}
            bgColor='#000000'
            spinnerColor='#FFFFFF'
            textColor='#FFFFFF'
            logoSrc={LOGO}
        >

        </LoadingScreen>
    )

    setCurrentLanguage(lang) {
        localStorage.setItem("current-language", JSON.stringify(languages[lang]))
        localStorage.setItem("language", JSON.stringify(lang))
        this.setState({ currentLanguage: languages[lang] })
    }

    showPendingPayment = () => {
        const MySwal = withReactContent(Swal)

        MySwal.fire({
            position: 'center',
            icon: 'warning',
            html: 'Você possui pagamentos pendentes. Favor acessar o menu "Meu Plano" e verificar a situação.',
            showConfirmButton: false,
            allowOutsideClick: false
        })
    }

    render() {
        const { loggedUser, companyGroupStatus } = this.state

        switch (loggedUser.currentRole) {
            case RoleConstant.MOBCHECK_ADMIN:
                return this.buildMobcheckAdminRoutes(loggedUser)
            case RoleConstant.COMPANY_GROUP_ADMIN:
                if (companyGroupStatus === CompanyGroupStatus.PENDING_PAYMENT && HistoryService.path() !== '/my-plan') {
                    this.showPendingPayment()
                }

                return this.buildCompanyGroupAdminRoutes(loggedUser)
            case RoleConstant.COMPANY_ADMIN:
                if (companyGroupStatus === CompanyGroupStatus.PENDING_PAYMENT && HistoryService.path() !== '/my-plan') {
                    this.showPendingPayment()
                }

                return this.buildCompanyAdminRoutes(loggedUser)
            case RoleConstant.COMPANY_OPERATOR:
                if (companyGroupStatus === CompanyGroupStatus.PENDING_PAYMENT && HistoryService.path() !== '/my-plan') {
                    this.showPendingPayment()
                }

                return this.buildOperatorRoutes(loggedUser)
            case RoleConstant.ANONYMOUS:
                return this.buildAnonymousRoutes()
            default: return this.buildLoading()
        }

    }

}


