import React from 'react'

import { Dialog, IconButton, Tab, Tabs } from '@material-ui/core'
import { EquipmentService } from './equipmentService'

import SearchIcon from './images/search_list_ico.png'
import { EquipmentDialogTabGeneral } from './equipmentDialogTabGeneral'
import { EquipmentDialogTabSnapshots } from './equipmentDialogTabSnapshots'
import { EquipmentDialogTabInspections } from './equipmentDialogTabInspections'
import { EquipmentDialogTabMaps } from './equipmentDialogTabMaps'
import { CenterTitleDiv } from '../components/components'

import Spinner from 'react-spinner-material'

export class EquipmentDialogButton extends React.Component {
    state = {
        tabValue: 0,
        isDialogOpen: false,
        snapshots: null,
        inspections: null
    }

    handleClickOpen = () => {
        const { equipment } = this.props

        this.setState({
            tabValue: 0,
            isDialogOpen: true,
            snapshots: null,
            inspections: null
        })

        EquipmentService.listSnapshots(equipment.tagSerialNumber).then(snapshots => this.setState({ snapshots }))
        EquipmentService.listInspections(equipment.tagSerialNumber).then(inspections => this.setState({ inspections }))
    };

    closeDialog = () => {
        this.setState({ isDialogOpen: false })
    };

    showTabContent = () => {
        const { equipment, currentLanguage } = this.props
        const { tabValue, inspections, snapshots } = this.state

        if (inspections === null || snapshots === null) {
            return (<CenterTitleDiv><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></CenterTitleDiv>);
        }

        switch (tabValue) { 
            case 0: return (<EquipmentDialogTabGeneral currentLanguage={currentLanguage} closeDialog={this.closeDialog} equipment={equipment} />)
            case 1: return (<EquipmentDialogTabInspections currentLanguage={currentLanguage} closeDialog={this.closeDialog} inspections={inspections} />)
            case 2: return (<EquipmentDialogTabMaps currentLanguage={currentLanguage} closeDialog={this.closeDialog} equipment={equipment} inspection={inspections[0]} />)
            case 3: return (<EquipmentDialogTabSnapshots currentLanguage={currentLanguage} closeDialog={this.closeDialog} snapshots={snapshots} />)
            default: throw new Error(`unimplemented: ${tabValue}`);
        }
    };

    render () {
        const { isDialogOpen, tabValue } = this.state
        const { currentLanguage } = this.props

        return (
            <React.Fragment>
                <IconButton onClick={this.handleClickOpen}><img src={SearchIcon} alt="search" /></IconButton>
                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">
                    <Tabs value={tabValue} onChange={(event, tabValue) => this.setState({ tabValue })}>
                        <Tab style={{ color: 'white' }} label={currentLanguage.general_information} />
                        <Tab style={{ color: 'white' }} label={currentLanguage.inspections} />
                        <Tab style={{ color: 'white' }} label="Mapa" />
                        <Tab style={{ color: 'white' }} label={currentLanguage.modifications} />
                    </Tabs>
                    {this.showTabContent()}
                </Dialog>
            </React.Fragment>
        );
    }
}
