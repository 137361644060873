import { createHashHistory } from 'history';
import * as queryString from 'query-string';

const slashHistory = createHashHistory({
    hashType: 'slash'
})

function path() {
    return slashHistory.location.pathname
}

function querystring() {
    return queryString.parse(slashHistory.location.search)
}

function goto(path, response) {
    if (path === slashHistory.location.pathname) {
        //console.warn(`Location already is ${path}`)
        return null
    }

    return slashHistory.push(path, 'test')
}

 function sendData(path, data) {
    return  slashHistory.push({
        pathname: path,
        search: queryString.stringify(data)
    })
}


export const HistoryService = { path, querystring, goto, sendData }
