import React from 'react';

import { Dialog, IconButton, Tab, Tabs } from '@material-ui/core';
import { EquipmentService } from '../equipment/equipmentService';

import SearchIcon from './images/search_list_ico.png';
import { EquipmentDialogTabGeneral } from './maintenanceDialogTabGeneral';
import { EquipmentDialogTabSnapshots } from './maintenanceDialogTabSnapshots';
import { EquipmentDialogTabMaintenances } from './maintenanceDialogTabMaintenances';
import { CenterTitleDiv } from '../components/components';

import Spinner from 'react-spinner-material'

export class EquipmentDialogButton extends React.Component {
    state = {
        tabValue: 0,
        isDialogOpen: false,
        snapshots: null,
        maintenances: null
    }

    handleClickOpen = () => {
        const { equipment } = this.props;

        this.setState({
            tabValue: 0,
            isDialogOpen: true,
            snapshots: null,
            maintenances: null
        })

        EquipmentService.listSnapshots(equipment.tagSerialNumber).then(snapshots => this.setState({ snapshots }));
        EquipmentService.listMaintenances(equipment.tagSerialNumber).then(maintenances => this.setState({ maintenances }));
    }

    closeDialog = () => {
        this.setState({ isDialogOpen: false })
    }

    showTabContent = () => {
        const { equipment, currentLanguage } = this.props
        const { tabValue, maintenances, snapshots } = this.state

        if (maintenances === null || snapshots === null) {
            return (<CenterTitleDiv><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></CenterTitleDiv>);
        }

        switch (tabValue) { 
            case 0: return (<EquipmentDialogTabGeneral currentLanguage={currentLanguage} closeDialog={this.closeDialog} equipment={equipment} />);
            case 1: return (<EquipmentDialogTabMaintenances currentLanguage={currentLanguage} closeDialog={this.closeDialog} maintenances={maintenances} />);
            case 2: return (<EquipmentDialogTabSnapshots currentLanguage={currentLanguage} closeDialog={this.closeDialog} snapshots={snapshots} />);
            default: throw new Error(`unimplemented: ${tabValue}`);
        }
    }

    render () {
        const { isDialogOpen, tabValue } = this.state
        const { currentLanguage } = this.props

        return (
            <React.Fragment>
                <IconButton onClick={this.handleClickOpen}><img src={SearchIcon} alt="search" /></IconButton>
                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">
                    <Tabs value={tabValue} onChange={(event, tabValue) => this.setState({ tabValue })}>
                        <Tab style={{ color: 'white' }} label={currentLanguage.general_information} />
                        <Tab style={{ color: 'white' }} label={currentLanguage.maintenances} />
                        <Tab style={{ color: 'white' }} label={currentLanguage.modifications} />
                    </Tabs>
                    {this.showTabContent()}
                </Dialog>
            </React.Fragment>
        );
    }
}
