import React from 'react'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash/object'
import * as queryString from 'query-string'

import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core'

import {
    BodyTableCell,
    ContainerDiv,
    FlexBaselineDiv,
    FlexColumnDiv,
    FlexSpaceBetweenDiv,
    formatMainDescription,
    HeaderTableCell,
    OverflowXDiv,
    SectionTableCell
} from '../components/components'

import { EquipmentService } from '../equipment/equipmentService'

import { EquipmentDialogButton } from './maintenanceDialogButton'
import { DateTimeService } from '../utils/dateTimeService'

import { SortHeaderCell } from '../components/sortHeaderCell'
import { FilterDatePicker } from '../components/filterDatePicker'
import { TableFooterWithPaginator } from '../components/tableFooterWithPaginator'
import { InspectionTypeService } from '../inspectionType/inspectionTypeService'
import { CategoryService } from '../category/categoryService'
import { ProductService } from '../product/productService'
import TableCell from '@material-ui/core/TableCell'
import { CollectionViewUpdater } from '../utils/collectionViewUpdater'
import { PrimaryButton } from '../components/components'
import { Tooltip } from 'react-tippy';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import 'react-tippy/dist/tippy.css';

import Spinner from 'react-spinner-material'

function calculateStatusColor(status) {
    switch (status) {
        case 'VALID': return 'transparent';
        case 'IMPROPER': return 'rgb(165, 35, 47)';
        case 'EXPIRED': return 'rgb(213, 117, 48)';
        case 'NA': return 'rgb(145, 145, 145)';
        default: throw new Error(`Unimplemented: ${status}`);
    }
}

class EquipmentsPage extends React.Component {

    constructor(props) {
        super(props)

        const { location } = props

        const { view } = queryString.parse(location.search)

        this.collectionUpdater = CollectionViewUpdater.ofMaintenance(this)

        this.state = {
            isLoading: false,
            categories: [],
            inspectionTypes: [],
            products: [],
            filterQuery: '',
            filterStatus: '',
            filterEnabledTagsOnly: true,
            filterCategoryId: '',
            filterInspectionTypeId: '',
            filterProductId: '',
            filterCreateFrom: null,
            filterCreateUpTo: null,
            filterLastInspectionFrom: null,
            filterLastInspectionUpTo: null,
            filterLastInspectionExpirationStatus: view === 'inspections-report' ? 'EXPIRED_OR_EXPIRING' : '',
            filterDueDateFrom: null,
            filterDueDateUpTo: null,
            filterDueDateExpirationStatus: '',
            collection: this.collectionUpdater.collection
        }
    }

    calculateQuery = () => {
        const {
            filterQuery: query,
            filterStatus: status,
            filterEnabledTagsOnly: enabledTagsOnly,
            filterCategoryId: categoryId,
            filterInspectionTypeId: inspectionTypeId,
            filterProductId: productId,
            filterCreateFrom: createFrom,
            filterCreateUpTo: createUpTo,
            filterLastInspectionFrom: lastInspectionFrom,
            filterLastInspectionUpTo: lastInspectionUpTo,
            filterLastInspectionExpirationStatus: lastInspectionExpirationStatus,
            filterDueDateFrom: dueDateFrom,
            filterDueDateUpTo: dueDateUpTo,
            filterDueDateExpirationStatus: dueDateExpirationStatus,
            collection
        } = this.state

        const { field, direction } = collection.sorter

        return {
            query,
            status,
            enabledTagsOnly,
            categoryId,
            inspectionTypeId,
            productId,
            createFrom: createFrom && createFrom.format('YYYY-MM-DD'),
            createUpTo: createUpTo && createUpTo.format('YYYY-MM-DD'),
            lastInspectionFrom: lastInspectionFrom && lastInspectionFrom.format('YYYY-MM-DD'),
            lastInspectionUpTo: lastInspectionUpTo && lastInspectionUpTo.format('YYYY-MM-DD'),
            lastInspectionExpirationStatus,
            dueDateFrom: dueDateFrom && dueDateFrom.format('YYYY-MM-DD'),
            dueDateUpTo: dueDateUpTo && dueDateUpTo.format('YYYY-MM-DD'),
            dueDateExpirationStatus,
            field,
            direction: field && direction
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true })

        EquipmentService.summary().then(items => {
            this.collectionUpdater.initialData(items)
            this.setState({ isLoading: false })
        })

        CategoryService.list().then(categories => this.setState({ categories }))
        InspectionTypeService.list().then(inspectionTypes => this.setState({ inspectionTypes }))
        ProductService.list().then(products => this.setState({ products }))
    }

    downloadPdf = () => EquipmentService.downloadPdf(this.calculateQuery())
    downloadExcel = () => EquipmentService.downloadExcel(this.calculateQuery())

    buildList = () => {
        const { isLoading, collection } = this.state
        const { currentLanguage } = this.props

        if (isLoading) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={9}><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></BodyTableCell>
                </TableRow>
            )
        }

        const items = collection.paginator.pagedItems

        if (items.length === 0) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={9}>Lista vazia.</BodyTableCell>
                </TableRow>
            )
        }

        return items.map((equipment, index) => (
            <TableRow key={index} style={{ borderLeftStyle: 'solid', borderLeftWidth: '0.5rem', borderLeftColor: calculateStatusColor(equipment.status), opacity: equipment.enabledTag ? 1 : 0.5 }}>
                <BodyTableCell align='start'>{formatMainDescription(equipment.product.name, equipment.product.sku)}</BodyTableCell>
                <BodyTableCell align='start'>{formatMainDescription(equipment.extraLabel, equipment.extraCode)}</BodyTableCell>
                <BodyTableCell align='start'>{DateTimeService.formatDate(equipment.createdAt)}</BodyTableCell>
                <BodyTableCell align='start'>{equipment.lastMaintenance !== null ? DateTimeService.formatDate(equipment.lastMaintenance.createdAt) : '--/--/--'}</BodyTableCell>
                <BodyTableCell align='start'>{EquipmentService.formatExpirationStatus(equipment.maintenanceStatus, currentLanguage)}</BodyTableCell>
                <BodyTableCell align='start'>{formatMainDescription(get(equipment, 'lastMaintenance.userName'), get(equipment, 'lastMaintenance.userEmail'))}</BodyTableCell>
                <BodyTableCell><EquipmentDialogButton currentLanguage={currentLanguage} equipment={equipment} /></BodyTableCell>
            </TableRow>
        ));
    };

    render() {
        const {
            collection,
            categories,
            inspectionTypes,
            products,
            filterQuery,
            filterStatus,
            filterCategoryId,
            filterInspectionTypeId,
            filterProductId,
            filterEnabledTagsOnly,
            filterCreateFrom,
            filterCreateUpTo,
            filterLastInspectionFrom,
            filterLastInspectionUpTo,
            filterLastInspectionExpirationStatus
        } = this.state

        const {
            currentLanguage
        } = this.props

        const { updateCollection, handleFilterChange, handleSort } = this.collectionUpdater

        const categoryInspectionTypes = inspectionTypes.filter(inspectionType => inspectionType.categoryId === filterCategoryId)
        const inspectionTypeProducts = products.filter(product => product.inspectionTypeId === filterInspectionTypeId)

        this.clearFilters = (event) => {

            event.preventDefault()

            handleFilterChange({
                categories: [],
                inspectionTypes: [],
                products: [],
                filterQuery: '',
                filterStatus: '',
                filterEnabledTagsOnly: true,
                filterCategoryId: '',
                filterInspectionTypeId: '',
                filterProductId: '',
                filterCreateFrom: null,
                filterCreateUpTo: null,
                filterLastInspectionFrom: null,
                filterLastInspectionUpTo: null,
                filterLastInspectionExpirationStatus: this.view === 'inspections-report' ? 'EXPIRED_OR_EXPIRING' : '',
                filterDueDateFrom: null,
                filterDueDateUpTo: null,
                filterDueDateExpirationStatus: '',
                collection: this.collectionUpdater.collection
            })

            this.componentDidMount()

        }

        return (
            <React.Fragment>
                <ContainerDiv>
                    <Typography variant="h5" style={{ fontWeight: 500, fontSize: '1.6rem', textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>{currentLanguage.maintenance_reports}</Typography>

                    <FlexSpaceBetweenDiv style={{ flexWrap: 'wrap' }}>
                        <FlexColumnDiv style={{ flex: '1 0 10rem', marginRight: '1rem' }}>
                            <FormControl margin="normal" style={{ marginBottom: '4px' }}>
                                <TextField
                                    label={currentLanguage.search}
                                    helperText={currentLanguage.equipment_sku + ", " + currentLanguage.equipment_information + " " + currentLanguage.or + " " + currentLanguage.maintenance_done_by}
                                    value={filterQuery}
                                    onChange={event => handleFilterChange({ filterQuery: event.target.value })}
                                />
                            </FormControl>

                            <FormControl>
                                <InputLabel htmlFor="status-select">{currentLanguage.status}</InputLabel>
                                <Select value={filterStatus} onChange={event => handleFilterChange({ filterStatus: event.target.value })} inputProps={{ id: 'status-select' }}>
                                    <MenuItem value="">{currentLanguage.all.toUpperCase()}</MenuItem>
                                    <MenuItem value="NA">{currentLanguage.na.toUpperCase()}</MenuItem>
                                    <MenuItem value="VALID">{currentLanguage.valid.toUpperCase()}</MenuItem>
                                    <MenuItem value="EXPIRED">{currentLanguage.expired.toUpperCase()}</MenuItem>
                                    <MenuItem value="IMPROPER">{currentLanguage.improper.toUpperCase()}</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControlLabel
                                margin="normal"
                                label={currentLanguage.active_tags_only}
                                control={
                                    <Checkbox
                                        checked={filterEnabledTagsOnly}
                                        onChange={event => handleFilterChange({ filterEnabledTagsOnly: event.target.checked })}
                                    />
                                }
                            />
                        </FlexColumnDiv>

                        <FlexColumnDiv style={{ flex: '1 0 8rem', marginRight: '1rem' }}>
                            <FormControl margin="normal">
                                <InputLabel htmlFor="categoryId-select">{currentLanguage.category}</InputLabel>
                                <Select value={filterCategoryId} onChange={event => handleFilterChange({ filterCategoryId: event.target.value, filterInspectionTypeId: '' })} inputProps={{ id: 'categoryId-select' }}>
                                    <MenuItem value="">{currentLanguage.all}</MenuItem>
                                    {categories.map(category => (
                                        <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl margin="normal">
                                <InputLabel htmlFor="inspectionTypeId-select">{currentLanguage.subcategory}</InputLabel>
                                <Select value={filterInspectionTypeId} onChange={event => handleFilterChange({ filterInspectionTypeId: event.target.value })} inputProps={{ id: 'inspectionTypeId-select' }}>
                                    <MenuItem value="">{currentLanguage.all}</MenuItem>
                                    {categoryInspectionTypes.map(inspectionType => (
                                        <MenuItem key={inspectionType.id} value={inspectionType.id}>{inspectionType.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl margin="normal">
                                <InputLabel htmlFor="productId-select">{currentLanguage.product}</InputLabel>
                                <Select value={filterProductId} onChange={event => handleFilterChange({ filterProductId: event.target.value })} inputProps={{ id: 'productId-select' }}>
                                    <MenuItem value="">{currentLanguage.all}</MenuItem>
                                    {inspectionTypeProducts.map(product => (
                                        <MenuItem key={product.id} value={product.id}>{product.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FlexColumnDiv>

                        <FlexColumnDiv>
                            <FormControl margin="normal">
                                <FlexBaselineDiv style={{ flexWrap: 'wrap' }}>
                                    <div style={{ width: '8rem', textAlign: 'right' }}>{currentLanguage.register}</div>
                                    <FilterDatePicker disableFuture style={{ flex: '1 0 9rem', marginLeft: '1rem' }} value={filterCreateFrom} onChange={date => handleFilterChange({ filterCreateFrom: date })} label={currentLanguage.from} />
                                    <FilterDatePicker disableFuture style={{ flex: '1 0 9rem', marginLeft: '1rem' }} value={filterCreateUpTo} onChange={date => handleFilterChange({ filterCreateUpTo: date })} label={currentLanguage.to} />
                                    <div style={{ flex: '1 0 9rem', marginLeft: '1rem' }} />
                                </FlexBaselineDiv>
                            </FormControl>
                            <FormControl margin="normal">
                                <FlexBaselineDiv style={{ flexWrap: 'wrap' }}>
                                    <div style={{ width: '8rem', textAlign: 'right' }}>{currentLanguage.last_maintenance}</div>
                                    <FilterDatePicker disableFuture style={{ flex: '1 0 9rem', marginLeft: '1rem' }} value={filterLastInspectionFrom} onChange={date => handleFilterChange({ filterLastInspectionFrom: date })} label={currentLanguage.from} />
                                    <FilterDatePicker disableFuture style={{ flex: '1 0 9rem', marginLeft: '1rem' }} value={filterLastInspectionUpTo} onChange={date => handleFilterChange({ filterLastInspectionUpTo: date })} label={currentLanguage.to} />

                                    <FormControl style={{ flex: '1 0 9rem', marginLeft: '1rem' }}>
                                        <InputLabel htmlFor="lastInspectionExpirationStatus-select">{currentLanguage.status}</InputLabel>
                                        <Select value={filterLastInspectionExpirationStatus} onChange={event => handleFilterChange({ filterLastInspectionExpirationStatus: event.target.value })} inputProps={{ id: 'lastInspectionExpirationStatus-select' }}>
                                            <MenuItem value="">{currentLanguage.all}</MenuItem>
                                            <MenuItem value="NA">{currentLanguage.na}</MenuItem>
                                            <MenuItem value="VALID">{currentLanguage.valid}</MenuItem>
                                            <MenuItem value="EXPIRED">{currentLanguage.expired}</MenuItem>
                                            <MenuItem value="EXPIRING">{currentLanguage.expiring}</MenuItem>
                                            <MenuItem value="EXPIRED_OR_EXPIRING">{currentLanguage.expired_or_expiring}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </FlexBaselineDiv>
                            </FormControl>
                        </FlexColumnDiv>
                    </FlexSpaceBetweenDiv>

                </ContainerDiv>

                <OverflowXDiv>
                    <Table id="table-equipments">
                        <TableHead>
                            <PrimaryButton onClick={event => this.clearFilters(event)} style={{ left: '10%', top: '10%', position: "relative" }}>{currentLanguage.clear_fields}</PrimaryButton>
                            <TableRow>
                                <TableCell colSpan={3} />
                                <SectionTableCell colSpan={4}>{currentLanguage.last_maintenance}</SectionTableCell>
                            </TableRow>
                            <TableRow>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} width="25%" field="product.name">{currentLanguage.equipment_sku}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} width="10%" field="extraLabel">{currentLanguage.equipment_information}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} width="10%" field="createdAt">{currentLanguage.register}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} width="10%" field="lastMaintenance.createdAt">{currentLanguage.date}
                                    <Tooltip
                                        title={currentLanguage.maintenance_date_Tippy}
                                        arrow={true}
                                        arrowSize={'small'}
                                        style={{ position: "relative", right: "25%" }}>
                                        <InfoOutlinedIcon style={{ fontSize: "small", position: "relative", top: "-10px" }} />
                                    </Tooltip>
                                </SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} width="5%" field="maintenanceStatus">{currentLanguage.status}</SortHeaderCell>
                                <SortHeaderCell sorter={collection.sorter} onSort={handleSort} width="10%" field="lastMaintenance.userName">{currentLanguage.maintenance_done_by}</SortHeaderCell>
                                <HeaderTableCell style={{ width: '5%' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.buildList()}
                        </TableBody>
                        <TableFooterWithPaginator collection={collection} onCollectionUpdate={updateCollection} colSpan={9} />
                    </Table>
                </OverflowXDiv>
            </React.Fragment>
        );
    }
}

export default withRouter(EquipmentsPage)
