import axios from 'axios';

function emailActiveEnvironment (email) {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `EmailToken ${email}`
    }
    return axios.post('/web/users/active-environment/', null, { headers })
}

export const ActiveEnvironmentApi = { emailActiveEnvironment }
