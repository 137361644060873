import React from 'react';
import { 
    Button, 
    DialogActions, 
    DialogContent, 
    Table, 
    TableBody, 
    TableHead, 
    TableRow,
    IconButton 
} from '@material-ui/core';
import {
    BodyTableCell,
    formatMainDescription,
    HeaderTableCell,
    OrangeDiv,
    RedDiv,
    SubDiv
} from '../components/components';
import { DateTimeService } from '../utils/dateTimeService';
import { Check, Close, NotInterested } from '@material-ui/icons';
import PdfIcon from './images/icon-pdf-file.png';
import ExcelIcon from './images/icon-excel-file.png';
import { MaintenanceService } from './maintenanceService';

import Spinner from 'react-spinner-material'

export class EquipmentDialogTabMaintenances extends React.Component {
    formatAttributeValues = (attributesValues) => {
        if (attributesValues.length === 0) {
            return (
                <div/>
            )
        }
    
        return attributesValues.map(attributeValue => (
            <React.Fragment>
                <SubDiv>{attributeValue.attribute}</SubDiv>
                <div>{attributeValue.value}</div>
            </React.Fragment>
        ));
    }
    
    formatQuestionAnswer = (questionAnswer, index) => {
        if (questionAnswer.type === 'TEXT') {
            return (
                <div key={index}>
                    <SubDiv>{questionAnswer.question}</SubDiv>
                    <div>{questionAnswer.answer}</div>
                </div>
            );
        }
    
        if (questionAnswer.type === 'YES_NO_NA') {
            switch (questionAnswer.answer) {
                case 'YES': return (
                    <div key={index}>
                        <Check />
                        {questionAnswer.question}
                    </div>
                );
                case 'NO': return (
                    <RedDiv key={index}>
                        <Close style={{color: 'rgb(165, 35, 47)'}} />
                        {questionAnswer.question}
                    </RedDiv>
                );
                case 'NA': return (
                    <OrangeDiv key={index}>
                        <NotInterested />
                        {questionAnswer.question}
                    </OrangeDiv>
                );
                default: throw new Error(`Unimplemented ${questionAnswer.answer}`);
            }
        }
    
        throw new Error(`Unimplemented ${questionAnswer.type}`);
    }
    
    formatQuestionsAnswers = (questionsAnswers) => {
        const { currentLanguage } = this.props

        if (questionsAnswers.length === 0) {
            return (
                <div>{currentLanguage.without_questions_answers}</div>
            )
        }
    
        return questionsAnswers.map(this.formatQuestionAnswer)
    }
    
    formatPartUnit = (partUnit) => {
        const { currentLanguage } = this.props

        switch (partUnit) {
            case 'UNITS': return currentLanguage.units
            case 'LITRES': return currentLanguage.litres
            case 'KILOGRAMS': return currentLanguage.units.kilograms
            case 'METRES': return currentLanguage.metres
            case 'NA': return ''
            default: throw new Error(`Unimplemented ${partUnit}`)
        }
    }
    
    formatPartsReplacements = (partsReplacements) => {
        const { currentLanguage } = this.props

        if (partsReplacements.length === 0) {
            return (
            <div>{currentLanguage.without_changing_parts}</div>
            )
        }
    
        return partsReplacements.map(partReplacement => (
            <React.Fragment>
                <SubDiv>{partReplacement.part}</SubDiv>
                <div>{partReplacement.quantity} {this.formatPartUnit(partReplacement.unit)}</div>
            </React.Fragment>
        ));
    }
    
    buildMaintenanceList = (maintenances) => {
        const { currentLanguage } = this.props

        if (maintenances === null) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={2}><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></BodyTableCell>
                </TableRow>
            )
        }
    
        if (maintenances.length === 0) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={2}>{currentLanguage.empty_list}</BodyTableCell>
                </TableRow>
            )
        }
    
        return maintenances.map(maintenance => (
            <TableRow key={maintenance.id} style={{verticalAlign: 'top'}}>
                <BodyTableCell>
                    {formatMainDescription(maintenance.userName, maintenance.userEmail)}
                    {DateTimeService.formatDateTime(maintenance.createdAt)}
                    <div style={{textAlign: 'center'}}>
                        <IconButton onClick={() => MaintenanceService.downloadPdf(maintenance.maintenanceId)}><img src={PdfIcon} alt="pdf" /></IconButton>
                        <IconButton onClick={() => MaintenanceService.downloadExcel(maintenance.maintenanceId)}><img src={ExcelIcon} alt="excel" /></IconButton>
                    </div>
                </BodyTableCell>
                <BodyTableCell>{this.formatAttributeValues(maintenance.attributesValues)}</BodyTableCell>
                <BodyTableCell>{this.formatQuestionsAnswers(maintenance.questionsAnswers)}</BodyTableCell>
                <BodyTableCell>{this.formatPartsReplacements(maintenance.partsReplacements)}</BodyTableCell>
            </TableRow>
        ))
    }

    render () {
        const { closeDialog, maintenances, currentLanguage } = this.props

        return (
            <React.Fragment>
                <DialogContent style={{height: 'calc(100vh - 20rem)', padding: '0rem'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <HeaderTableCell style={{position: 'sticky', top: '0rem', zIndex: '1'}}>{currentLanguage.responsible}/{currentLanguage.date}/{currentLanguage.reports}</HeaderTableCell>
                                <HeaderTableCell style={{position: 'sticky', top: '0rem', zIndex: '1'}}>{currentLanguage.attributes}/{currentLanguage.values}</HeaderTableCell>
                                <HeaderTableCell style={{position: 'sticky', top: '0rem', zIndex: '1'}}>{currentLanguage.questions}/{currentLanguage.answers}</HeaderTableCell>
                                <HeaderTableCell style={{position: 'sticky', top: '0rem', zIndex: '1'}}>{currentLanguage.parts}/{currentLanguage.replacments}</HeaderTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.buildMaintenanceList(maintenances)}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between'}}>
                    <div>
                    </div>

                    <div>
                        <Button onClick={closeDialog}>{currentLanguage.close}</Button>
                    </div>
                </DialogActions>
            </React.Fragment>
        )
    }

}
