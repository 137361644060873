import { CompanyUserApi } from './companyUserApi';

function update (id, userData, companies) {
    const { companyId } = userData;
    const companyName = companies.filter(company => company.id === companyId).map(company => company.name).pop();

    return CompanyUserApi.update(id, userData)
        .then(() => ({ id, updateDate: new Date().toISOString(), companyName, ...userData }));
}

function create (userData, companies) {
    const { companyId } = userData;
    const companyName = companies.filter(company => company.id === companyId).map(company => company.name).pop();

    return CompanyUserApi.create(userData)
        .then(response => response.data)
        .then(id => ({ id, updateDate: new Date().toISOString(), companyName, ...userData }));
}

export const CompanyUserService = {
    list: () => CompanyUserApi.list().then(response => response.data),
    update,
    create,
    remove: (userId) => CompanyUserApi.remove(userId).then(() => ({ id: userId }))
};
