import React from 'react';
import {
    Checkbox,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@material-ui/core';

import {
    ContainerDiv,
    Flex1150Div,
    FlexDiv,
} from '../components/components'
import { FilterService } from '../utils/filterService'
import { DefaultCollection } from '../collection/defaultCollection'
import { withSnackbar } from 'notistack'
import { ConfigSystemService } from './configSystemService'

class ConfigSystemPage extends React.Component {
    state = {
        filterQuery: '',
        filterEnabled: '',
        filterUpdateFrom: null,
        filterUpdateUpTo: null,
        collection: DefaultCollection.ofItemsAndPredicates([], [])
    }

    buildPredicates = (items, filterValues) => {
        const { filterQuery, filterEnabled, filterUpdateFrom, filterUpdateUpTo } = filterValues;

        return [
            FilterService.containsQuery(filterQuery, items, ['title']),
            FilterService.isSameString('enabled', filterEnabled),
            FilterService.isFromValid('updateDate', filterUpdateFrom),
            FilterService.isUpToValid('updateDate', filterUpdateUpTo)
        ]

    }

    updateConfigToggle = (configSystem, toggle) => ConfigSystemService.updateConfigToggle(configSystem, toggle)
        .then(() => this.updateCollection(this.updateWithUpdatedItem({ ...configSystem, configValue: configSystem.configValue })))
        .then(this.notify(`${configSystem.title} ${configSystem.value ? this.props.currentLanguage.activated : this.props.currentLanguage.disabled}`))


    toggleValue = (configSystem, toggle) => ConfigSystemService.toggleConfig(configSystem, toggle)
        .then(() => this.updateCollection(this.updateWithUpdatedItem({ ...configSystem, enabled: toggle })))
        .then(this.notify(`${configSystem.title} ${toggle ? this.props.currentLanguage.activated : this.props.currentLanguage.disabled}`))


    componentDidMount() {
        ConfigSystemService.list().then(configSystem => this.setState(state => ({ collection: DefaultCollection.ofItemsAndPredicates(configSystem, this.buildPredicates(configSystem, state)) })));
    }

    updateCollection = updater => this.setState(state => ({ collection: updater(state.collection, state) }))

    updateWithUpdatedItem = updatedConfig => (collection, state) => DefaultCollection.withUpdatedItem(collection, 'id', updatedConfig, this.buildPredicates(collection.items, state));

    notify = message => () => this.props.enqueueSnackbar(message)

    buildConfigRow = (configSystem) => {
        switch (configSystem.configType) {
            case "TOGGLE": return (
                <FlexDiv>
                    <Flex1150Div>
                        <Checkbox color="primary" checked={!!parseInt(configSystem.configValue)} onChange={event => this.updateConfigToggle(configSystem, event.target.checked)} /> {configSystem.title}
                    </Flex1150Div>
                </FlexDiv>
            )

            default: throw new Error(`unimplemented: ${configSystem}`)
        }
    }

    handleRadioChange = event => {
        const { setCurrentLanguage } = this.props

        setCurrentLanguage(event.target.value)
    }


    render() {
        const { collection } = this.state
        const { currentLanguage } = this.props
        
        const configSystem = collection.items

        return (
            <React.Fragment>
                <ContainerDiv>
                    <Typography variant="h5" style={{ fontWeight: 500, fontSize: '1.6rem', textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                        {currentLanguage.system_configuration}
                    </Typography>

                    <Typography variant="h3" style={{ fontWeight: 500, fontSize: '1.2rem', textAlign: 'left', marginTop: '1rem', marginBottom: '1rem' }}>
                        {currentLanguage.general_settings}
                    </Typography>

                    {configSystem.map(config => (
                        this.buildConfigRow(config)
                    ))}

                    <Typography variant="h3" style={{ fontWeight: 500, fontSize: '1.2rem', textAlign: 'left', marginTop: '1rem', marginBottom: '1rem' }}>
                        Idioma
                    </Typography>

                    <FlexDiv>
                        <RadioGroup defaultValue={JSON.parse(localStorage.getItem("language"))} aria-label="gender" name="customized-radios" onChange={this.handleRadioChange}>
                            <Flex1150Div>
                                <FormControlLabel value="pt" control={<Radio />} label="Português" />
                                <FormControlLabel value="en" control={<Radio />} label="English" />
                                <FormControlLabel value="es" control={<Radio />} label="Español" />
                            </Flex1150Div>
                        </RadioGroup>
                    </FlexDiv>


                </ContainerDiv>
            </React.Fragment>
        )
    }
}

export default withSnackbar(ConfigSystemPage)
