import React from 'react';

import { EquipmentApi } from './equipmentApi';
import { OrangeSpan, RedSpan } from '../components/components';

function compareLastInspection(a, b) {

    if (a.lastInspection == null) {
        return 1
    }

    else if (b.lastInspection == null) {
        return -1
    }

    else if (a.lastInspection.createdAt > b.lastInspection.createdAt) {
        return -1
    }

    else if (a.lastInspection.createdAt < b.lastInspection.createdAt) {
        return 1
    }

    else {
        return 0
    }

}




function formatExpirationStatus(status, currentLanguage) {
    switch (status) {
        case 'VALID': return (<span>{currentLanguage.valid}</span>)
        case 'IMPROPER': return (<RedSpan>{currentLanguage.improper}</RedSpan>)
        case 'EXPIRING': return (<OrangeSpan>{currentLanguage.expiring}</OrangeSpan>)
        case 'EXPIRED': return (<OrangeSpan>{currentLanguage.expired}</OrangeSpan>)
        case 'NA': return (<span>{currentLanguage.na}</span>)
        default: return (<span />)
    }
}



function calculateStatusLabel(status, len = 2, currentLanguage) {
    switch (status) {
        case 'VALID': return (len === 1 ? currentLanguage.valid : currentLanguage.validP)
        case 'IMPROPER': return (len === 1 ? currentLanguage.improper : currentLanguage.improperP)
        case 'EXPIRED': return (len === 1 ? currentLanguage.expired : currentLanguage.expiredP)
        case 'NA': return 'N/A'
        default: throw new Error(`unimplemented: ${status}`)
    }
}

function calculateStatusColor(status) {
    switch (status) {
        case 'VALID': return 'rgb(14, 74, 100)'
        case 'EXPIRED': return 'rgb(213, 117, 48)'
        case 'IMPROPER': return 'rgb(165, 35, 47)'
        case 'NA': return 'rgb(145, 145, 145)'
        default: throw new Error(`unimplemented: ${status}`)
    }
}


function summary() {
    return EquipmentApi.summary().then(response => response.data)
}

function listInspections(serialNumber) {
    return EquipmentApi.listInspections(serialNumber).then(response => response.data)
}

function listMaintenances(serialNumber) {
    return EquipmentApi.listMaintenances(serialNumber).then(response => response.data)
}

function listSnapshots(serialNumber) {
    return EquipmentApi.listSnapshots(serialNumber).then(response => response.data)
}

function downloadPdf(query) {
    return EquipmentApi.downloadPdf(query)
}

function downloadExcel(query) {
    return EquipmentApi.downloadExcel(query)
}

function getSuggestReplace(query) {
    return EquipmentApi.getSuggestReplace(query)
}

function listEquipmentsReplace(query) {
    return EquipmentApi.listEquipmentsReplace(query)
}

export const EquipmentService = {
    summary,
    downloadPdf,
    downloadExcel,
    formatExpirationStatus,
    calculateStatusLabel,
    calculateStatusColor,
    listInspections,
    listMaintenances,
    listSnapshots,
    getSuggestReplace,
    listEquipmentsReplace,
    compareLastInspection
}
