import React from 'react'
import {
    Table, TableBody, TableHead, TableRow,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
    ButtonBase,
    TextField,
    IconButton
} from '@material-ui/core'
import {
    ContainerDiv,
    HeaderTableCell, 
    OrangeDiv, 
    BodyTableCell,
    FlexDiv
} from '../components/components'
import { withSnackbar } from 'notistack'
import IconNfc from './images/ic-nfc.png'
import IconQrCode from './images/ic-qr-code.png'
import SearchIcon from '../equipment/images/search_list_ico.png'

import { MyPlanService } from './myPlanService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AxiosService } from '../../app/axiosService'
import Iframe from 'react-iframe'
import LoadingScreen from 'react-loading-screen'
import LOGO from '../../anonymous/landpage/images/logo-white.png'

const STAGES = {
    MAIN: 'MAIN',
    PAYMENT: 'PAYMENT',
    LOADING: 'LOADING'
}

class BuyTagPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            actualPlanId: {},
            selectedPlan: {},
            plans: [],
            paymentMethod: 'bank_slip',
            stage: STAGES.MAIN,
            paymentUrl: '',
            tagQrCodeQty: 10,
            tagQrCodeValue: '11,50',
            tagNfcQty: 10,
            tagNfcValue: '32,50',
            bill: {}
        }
    }

    componentDidMount() {
        const { loggedUser } = this.props

        MyPlanService.getPlan(loggedUser.companyGroup).then(actualPlanId => {
            this.setState({ actualPlanId })
        })

        MyPlanService.listPlan().then(plans => {
            this.setState({ plans })
        })

        MyPlanService.getSubscription(loggedUser.subscription_id).then(subscription => {
            console.log("SUBSCRIPTION GET")
            console.log(subscription)
        })
    }

    handlePaymentMethodChange = (paymentMethodSelected) => {
        this.setState({ paymentMethod: paymentMethodSelected })
    }

    handleTagQrCodeChange = (selectedValue) => {
        if(selectedValue > 0) {
            var newTagQrCodeValue = (selectedValue * 1.15).toFixed(2)
            newTagQrCodeValue = newTagQrCodeValue.toString().replace('.', ',')

            this.setState({ tagQrCodeValue: newTagQrCodeValue, tagQrCodeQty: selectedValue })
        }
    }

    handleTagNfcChange = (selectedValue) => {
        if(selectedValue > 0) {
            var newTagNfcValue = (selectedValue * 1.15).toFixed(2)
            newTagNfcValue = newTagNfcValue.toString().replace('.', ',')

            this.setState({ tagNfcValue: newTagNfcValue, tagNfcQty: selectedValue })
        }
    }

    handleBuyItem = (itemCode, itemQty) => {
        const { loggedUser } = this.props
        const MySwal = withReactContent(Swal)

        MySwal.fire({
            title: 'Atenção!',
            text: "Antes de confirmar a compra, verifique se seu endereço está correto no cadastro da sua empresa para enviarmos as Tag's.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F08302',
            cancelButtonColor: '#D0BBB6',
            confirmButtonText: 'Verificado!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                var subscriptionData = {
                    plan_id: 186961,
                    customer_id: loggedUser.vindi_id,
                    payment_method_code: 'bank_slip',
                    product_items: [
                        {
                            "product_id": itemCode,
                            "quantity": itemQty
                        }
                    ]
                }
        
                this.setState({ stage: STAGES.LOADING })
        
                var token = AxiosService.getAxiosToken()
                AxiosService.unsetAxiosToken()
        
                MyPlanService.createSubscription(subscriptionData).then(res => {
                    AxiosService.setAxiosToken(token)
        
                    var body = JSON.parse(res.body)
        
                    window.open(body.bill.url, '_blank')
        
                    MySwal.fire({
                        title: 'Pedido realizado com sucesso!',
                        text: 'Pague a fatura gerada para receber as Tags.',
                        icon: 'success',
                        confirmButtonColor: '#F08302',
                        cancelButtonColor: '#D0BBB6',
                        confirmButtonText: 'OK'
                    })
        
                    this.setState({ stage: STAGES.MAIN })
                })
            }
        })
    }

    buildTags = () => {
        const { tagQrCodeQty, tagQrCodeValue, tagNfcQty, tagNfcValue } = this.state

        return (
            <FlexDiv style={{ flexWrap: 'wrap', justifyContent: 'center', position: 'relative', padding: 0, marginTop: 10 }}>
                <div style={{ padding: 30 }}>
                    <Card style={{ maxWidth: 250, borderRadius: 10 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                height="140"
                                image={IconQrCode}
                                title="Contemplative Reptile"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Typography variant="h5" color="#000000" style={{ fontWeight: 500, fontSize: '1.4rem', textAlign: 'center' }}>Tag QR Code</Typography>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">

                                    <TextField label={"Quantidade"} minValue="1" type="number" value={tagQrCodeQty} onChange={event => this.handleTagQrCodeChange(event.target.value)} />

                                    <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: '30px'}}>
                                        <b style={{color: '#13aa52'}}>✔</b> Impressa em folha adesiva <br />
                                        <b style={{color: '#13aa52'}}>✔</b> Maior facilidade <br />
                                        <b style={{color: '#13aa52'}}>✔</b> Necessita apenas de celular com câmera
                                    </Typography>

                                    <br />
                                    <Typography variant="h5" style={{ fontWeight: 500, color: '#dd7b19', fontSize: '1.6rem', textAlign: 'center', marginTop: '2rem' }}>
                                        <FlexDiv style={{ flexWrap: 'wrap', justifyContent: 'center', position: 'relative', padding: 0, marginTop: 40 }}>
                                            R${tagQrCodeValue} <Typography style={{ fontWeight: 500, color: '#606060', fontSize: '0.6rem', textAlign: 'center' }}></Typography>
                                        </FlexDiv>
                                    </Typography>

                                    <br />

                                    <Typography variant="h5" style={{ fontWeight: 500, color: '#0e5197', fontSize: '1.0rem', textAlign: 'center' }} >
                                        <ButtonBase style={{ textAlign: 'center' }} onClick={() => this.handleBuyItem(650242, tagQrCodeQty)}> COMPRAR </ButtonBase>
                                    </Typography>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
                <div style={{ padding: 30 }}>
                    <Card style={{ maxWidth: 250, borderRadius: 10 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                height="140"
                                image={IconNfc}
                                title="Contemplative Reptile"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Typography variant="h5" color="#000000" style={{ fontWeight: 500, fontSize: '1.4rem', textAlign: 'center' }}>Tag NFC</Typography>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">

                                    <TextField label={"Quantidade"} minValue="1" type="number" value={tagNfcQty} onChange={event => this.handleTagNfcChange(event.target.value)} />

                                    <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: '30px'}}>
                                        <b style={{color: '#13aa52'}}>✔</b> Placa NFC <br />
                                        <b style={{color: '#13aa52'}}>✔</b> Material mais resistente <br />
                                        <b style={{color: '#13aa52'}}>✔</b> Necessita de celular com tecnologia NFC
                                    </Typography>

                                    <br />
                                    <Typography variant="h5" style={{ fontWeight: 500, color: '#dd7b19', fontSize: '1.6rem', textAlign: 'center', marginTop: '2rem' }}>
                                        <FlexDiv style={{ flexWrap: 'wrap', justifyContent: 'center', position: 'relative', padding: 0, marginTop: 40 }}>
                                            R${tagNfcValue} <Typography style={{ fontWeight: 500, color: '#606060', fontSize: '0.6rem', textAlign: 'center' }}></Typography>
                                        </FlexDiv>
                                    </Typography>

                                    <br />


                                    <Typography variant="h5" style={{ fontWeight: 500, color: '#0e5197', fontSize: '1.0rem', textAlign: 'center' }} >
                                        <ButtonBase style={{ textAlign: 'center' }} onClick={() => this.handleBuyItem(650490, tagNfcQty)}> COMPRAR </ButtonBase>
                                    </Typography>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
            </FlexDiv>
        )
    }

    buildBills = () =>{
        const{ bill } = this.state
        const {loggedUser, currentLanguage } = this.props

        return(
            <Table style={{marginTop: '10px'}}>
                <TableHead>
                    <TableRow>
                    <HeaderTableCell>Tipo de TAG</HeaderTableCell>
                        <HeaderTableCell>Quantidade</HeaderTableCell>
                        <HeaderTableCell>Total</HeaderTableCell>
                        <HeaderTableCell>Status Fatura</HeaderTableCell>
                        <HeaderTableCell>{currentLanguage.options}</HeaderTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <BodyTableCell> Tag QR Code</BodyTableCell>
                        <BodyTableCell> 100</BodyTableCell>
                        <BodyTableCell> R$ 115,00</BodyTableCell>
                        <BodyTableCell> Aguardando Pagamento</BodyTableCell>
                        <BodyTableCell><IconButton ><img src={SearchIcon} alt="search" /></IconButton></BodyTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    

    buildMainContent = () => {
        const { loggedUser } = this.props

        console.log(loggedUser)


        return (
            <React.Fragment>
                <ContainerDiv>
                    <Typography variant="h5" style={{ fontWeight: 500, fontSize: '1.6rem', textAlign: 'center', marginTop: '1rem' }}>
                        Comprar Tag's
                    </Typography>

                    {this.buildTags()}
                    {this.buildBills()}
                </ContainerDiv>
            </React.Fragment>
        )
    }

    buildPaymentContent = () => {
        const { paymentUrl } = this.state

        return (
            <React.Fragment>
                <ContainerDiv>
                    <Iframe url={paymentUrl}
                        width="450px"
                        height="450px"
                        id="myId"
                        display="initial"
                        position="relative" />
                </ContainerDiv>
            </React.Fragment>
        )
    }

    buildLoadingContent = () => {
        return (
            <LoadingScreen
                loading={true}
                bgColor='#000000'
                spinnerColor='#FFFFFF'
                textColor='#FFFFFF'
                text="Redirecionando para o pagamento"
                logoSrc={LOGO}
            >

            </LoadingScreen>
        )
    }

    buildContent = () => {
        const { stage } = this.state

        switch (stage) {
            case STAGES.MAIN: return this.buildMainContent()
            case STAGES.PAYMENT: return this.buildPaymentContent()
            case STAGES.LOADING: return this.buildLoadingContent()
            default: throw new Error(`unimplemented: ${stage}`)
        }
    }

    render() {
        return (
            this.buildContent()
        )
    }
}

export default withSnackbar(BuyTagPage)
