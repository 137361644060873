import { chain } from 'lodash';

function toQueryString (query) {
    return chain(query).pickBy().toPairs().map(pair => pair.join('=')).join('&');
}

function downloadAs (url, filename, query) {
    const queryString = `?` + toQueryString({ ...query });
    const link = document.createElement('a');
    link.href = `${url}${queryString}`;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export const DownloadService = { downloadAs };
