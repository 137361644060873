import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core'
import { Flex1150Div, FlexColumnDiv, FlexDiv, PrimaryButton } from '../components/components'
import { PhoneInput } from '../components/phoneInput'
import { CnpjInput } from '../components/cnpjInput'
import { StringService } from '../utils/stringService'
import { NonNegativeInput } from '../components/nonNegativeInput'
import NoImage from '../product/images/mobcheck-gray.png'
import { LoggedUserService } from '../loggedUser/loggedUserService'
import { RoleConstant } from '../../app/roleConstant'

export class CompanyFormDialogButton extends React.Component {

    constructor (props) {
        super(props)

        const { companyData } = props

        this.state = {
            isDialogOpen: false,
            companyData,
            previewDataUrl: companyData.image === null ? NoImage : 'data:image/png;base64,'+companyData.image,
            loggedUser: {}
        }
    }

    handleDialogOpen = () => {
        const { companyData } = this.props

        this.setState({ isDialogOpen: true, companyData })

        LoggedUserService.getLoggedUser().then(loggedUser => {
            this.setState({ loggedUser })
        })
    }

    closeDialog = () => this.setState({ isDialogOpen: false })

    updateCompanyData = newData => this.setState(state => ({companyData: { ...state.companyData, ...newData }}))

    handleSubmit = () => this.props.onSubmit(this.state.companyData).then(this.closeDialog)

    handlePhotoUpload = event => {
        const photo = event.target.files[0]
        const reader = new FileReader()
        reader.onload = event => this.setState({ previewDataUrl: event.target.result })
        reader.readAsDataURL(photo)

        const readerAux = new FileReader()
        const fileByteArray = []
        const self = this

        readerAux.readAsArrayBuffer(photo)
        readerAux.onloadend = function (evt) {
            if (evt.target.readyState === FileReader.DONE) {
                var arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer)
                for (var i = 0; i < array.length; i++) {
                    fileByteArray.push(array[i])
                }
                self.updateCompanyData({ image: fileByteArray })
            }
        }
    }

    render () {
        const { isDialogOpen, companyData, previewDataUrl, loggedUser } = this.state
        const { submitLabel, ModalButton, currentLanguage } = this.props

        return (
            <React.Fragment>
                <ModalButton onClick={this.handleDialogOpen} />
                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">
                    <DialogContent>
                        <FlexDiv>
                            <Flex1150Div> 
                                <FlexColumnDiv style={{alignItems: 'center'}}>
                                    <FlexDiv style={{height: '10rem', alignItems: 'center'}}>
                                        <img src={previewDataUrl} alt="" style={{maxHeight: '10rem'}} />
                                    </FlexDiv>
                                    <Button variant="contained" component="label">
                                        {currentLanguage.change_photo}
                                        <input type="file" accept="image/*" style={{ display: "none" }} onChange={this.handlePhotoUpload}/>
                                    </Button>
                                </FlexColumnDiv>
                            </Flex1150Div>
                            <Flex1150Div>
                                <FlexColumnDiv>
                                    <TextField margin="normal" onChange={event => this.updateCompanyData({ cnpj: StringService.extractDigits(event.target.value) })} value={companyData.cnpj} label={currentLanguage.cnpj} InputProps={{ inputComponent: CnpjInput }} />
                                    <TextField margin="normal" required onChange={event => this.updateCompanyData({ name: event.target.value })} value={companyData.name} label={currentLanguage.company_name} />
                                    
                                    
                                    
                                </FlexColumnDiv>
                            </Flex1150Div>
                            <Flex1150Div style={{marginLeft: '1rem'}}>
                                <FlexColumnDiv>
                                    <TextField margin="normal" required onChange={event => this.updateCompanyData({ mainContactName: event.target.value })} value={companyData.mainContactName} label={currentLanguage.main_contact} />
                                    <TextField margin="normal" required onChange={event => this.updateCompanyData({ email: event.target.value })} value={companyData.email} label={currentLanguage.email} />
                                    <TextField margin="normal" required onChange={event => this.updateCompanyData({ phone: StringService.extractDigits(event.target.value) })} value={companyData.phone} label={currentLanguage.phone} InputProps={{ inputComponent: PhoneInput }} />
                                </FlexColumnDiv>
                            </Flex1150Div>
                        </FlexDiv>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog}>{currentLanguage.cancel}</Button>
                        <PrimaryButton onClick={this.handleSubmit}>{submitLabel}</PrimaryButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }

}

