export default {
  // WORDS
  search: "Buscar",
  tags: "Etiquetas",
  tag: "Etiqueta",
  cnpj: "CIF (Código de Identificación Fiscal)",
  category: "Categoría",
  categories: "Categoríaa",
  subcategory: "Subcategoría",
  subcategories: "Subcategorías",
  product: "Producto",
  products: "Productos",
  status: "Estado",
  register: "Registrarse",
  from: "Desde",
  to: "Hasta",
  last_inspection: "Última inspección",
  last_maintenance: "Último mantenimiento",
  due_date: "Duracion",
  date: "Fecha",
  inspected_by: "Revisado por",
  items_by_page: "Artículos por página",
  valid: "Válido",
  validP: "Válidos",
  able: "Habilitado",
  yes: "Si",
  no: "No",
  all: "Todas",
  name: "Nombre",
  last_update: "Última actualización",
  exclusive: "Exclusivo",
  sku: "SKU",
  image: "Imagen",
  description: "Descripción",
  options: "Opciones",
  employee: "Empleado",
  company: "Empresa",
  phone: "Teléfono",
  active: "Activo",
  inactive: "Inactivo",
  migrated: "Migrado",
  email: "Correo electrónico",
  pending: "Pendiente",
  general_information: "Informaciones Generales",
  modifications: "Modificaciones",
  number_of_control: "Número de control",
  last_inspection_status: "Último estado de inspección",
  due_date_status: "Estado de fecha de caducidad",
  close: "Cerca",
  responsible: "Responsable",
  responsible_name: "Nombre responsable",
  responsible_email: "Correo electrónico responsable",
  responsible_phone: "Teléfono responsable",
  responsible_role: "Puesto de responsable",
  attributes: "Atributos",
  attribute: "Atributo",
  attribute_register: "Atributo de registro",
  values: "Valores",
  questions: "Preguntas",
  answers: "Respuestas",
  question_initial: "P",
  answer_initial: "R",
  modified_by: "Modificado por",
  equipment: "Equipo",
  parts: "Partes",
  maintenance_parts: "Piezas de mantenimiento",
  replacments: "Sustituciones",
  without_changing_parts: "Sin intercambio de piezas.",
  units: "Unidades",
  litres: "Litros",
  kilograms: "Kilogramos",
  metres: "Medidores",
  without_questions_answers: "No hay preguntas / respuestas",
  activated: "Habilitado",
  disabled: "Discapacitado",
  disable: "Inhabilitar",
  none: "Ninguna",
  cancel: "Cancelar",
  remove: "Eliminar",
  removing: "Quitando",
  confirmation: "Confirmación",
  remove_confirmation: "Para confirmar esta operación, ingrese #vr0#",
  password: "Contraseña",
  text: "Texto",
  custom: "Personalizado",
  positive: "Positivo",
  negative: "Negativo",
  change_password: "Cambiar contraseña",
  current_password: "Contraseña actual",
  new_password: "Nueva contraseña",
  confirm_new_password: "Confirmar nueva contraseña",
  change_password_requiriment: "La nueva contraseña debe cumplir los siguientes criterios:",
  at_least_characters: "Al menos 6 caracteres",
  at_least_numeral: "Al menos 1 número",
  at_least_uppercase: "Al menos 1 letra mayúscula",
  at_least_lowercase: "Al menos 1 letra minúscula",
  at_least_special_character: "Al menos 1 personaje especial",
  valid_special_character: "Caracteres especiales válidos: !@#$%&*()_-+=",
  logout: "Sal",
  my_plan: "Mi plan",
  video_lessons: "Clases de video",
  clear_fields: "Limpiar filtros",
  // MENU HEADER
  dashboard: "Dashboard",
  reports: "Informes",
  report: "Reporte",
  registrations: "Registro",
  employees: "Empleados",
  companies: "Compañías",
  company_group: "Grupos de empresas",
  all_users: "Todos los usuarios",
  settings: "Ajustes",
  maintenances: "Mantenimiento",
  list: "Lista",
  tag_replace: "Reemplazar Tag",
  user: "Usuario",
  system: "Sistema",
  or: "o",
  na: "N/A",
  expired: "Caducado",
  expiredP: "Caducados",
  improper: "No conforme",
  improperP: "No conformes",
  expiring: "Expirando",
  expired_or_expiring: "Vencido o a punto de caducar",
  export: "Exportar",
  empty_list: "Lista vacía.",
  created: "Creado",
  updated: "Actualizado",
  update: "Actualizar",
  removed: "Remoto",
  already_exists: "Ya existe",
  email_exists: "El correo electrónico #vr0# ya existe.",
  updated_user: "Usuario #vr0# actualizado correctamente.",
  created_user: "Usuario #vr0# creado con éxito.",
  removed_user: "Usuario #vr0# eliminado con éxito.",
  profile: "Perfil",
  add: "Añadir",
  qr_code: "QR Code",
  generate_qr_code: "Generar QR Code",
  export_qr_code: "Exportar QR Code",
  od: "Orden de servicio",
  id_equipament: "Id de equipo",
  name_equipament: "Nombre",
  priority_equipament: "Prioridad",
  type_equipament: "Tipo",
  responsible_user_equipament: "Usuario responsable",
  completion_date_equipament: "Fecha de finalización",
  report_equipament: "Informe",
  // DASHBOARD
  inspections_by_products: "Inspecciones de productos",
  equipments_status: "Estado del equipo",
  inspections_by_employees: "Inspecciones por empleados",
  inspections_by_month: "Inspecciones por mes (últimos 24 meses)",
  total: "Total",
  users: "Los usuarios",
  equipments: "Equipo",
  inspections: "Inspecciones",
  last_seven_days: "En los últimos 7 días",
  last_interactions: "Últimas interacciones",
  new_user: "Nuevo Usuario",
  new_equipment: "Nuevo equipamiento",
  new_inspection: "Nueva inspección",
  text_initial_video_lessons: "¿Quieres comprar etiquetas? ¡Vea nuestras opciones!",
  text_initial_buy_tags: "¿Quieres comprar etiquetas? ¡Vea nuestras opciones!",
  // INSPECTIONS REPORT
  inspection_reports: "Informes de inspección",
  equipment_sku: "Equipo/SKU",
  equipment_information: "Informacion del equipo",
  active_tags_only: "Solo tags activas",
  // MAINTENANCES REPORT
  maintenance_reports: "Reportes de mantenimiento",
  maintenance_done_by: "Mantenimiento por",
  // CATEGORIES
  add_category: "Añadir categoría",
  update_category: "Actualizar categoría",
  update_subcategory: "Actualizar subcategoría",
  created_category: "Categoría #vr0# creada.",
  updated_category: "Categoría actualizada #vr0#.",
  created_subcategory: "Subcategoría #vr0# creada.",
  updated_subcategory: "Subcategoría #vr0# actualizada.",
  // SUB CATEGORIES
  add_subcategory: "Agregar subcategoría",
  inspection_questions: "Problemas de inspección",
  inspection_question: "Problema de inspección",
  maintenance_questions: "Problemas de mantenimiento",
  maintenance_question: "Problema de inspección",
  able_subcategory: "Habilitar subcategoría",
  add_inspection_question: "Añadir pregunta de inspección",
  add_maintenance_question: "Agregar pregunta de mantenimiento",
  add_attribute: "Añadir atributo",
  custom_answer: "Respuesta personalizada",
  add_custom_answer: "Agregar respuesta personalizada",
  // PRODUCT
  add_product: "Añadir Producto",
  update_product: "Actualizar producto",
  created_product: "Producto #vr0# creado",
  updated_product: "Producto #vr0# actualizado",
  inspection_periodicity: "Periodicidad de inspección",
  yearly: "Anual",
  biannual: "Semestre",
  quarterly: "Trimestral",
  monthly: "Mensual",
  biweekly: "Quincenal",
  weekly: "Semanal",
  inspection_expiration_days: "Días de caducidad de inspección",
  has_expiration_date: "Tiene fecha de vencimiento",
  days_notification_due_date: "Días para la notificación de validez",
  remove_maintenance_part: "¿Desea eliminar la siguiente parte de mantenimiento?",
  maintenance_part: "Parte de mantenimiento",
  remove_maintenance_question: "Eliminar problema de mantenimiento",
  add_maintenance_part: "Agregar parte de mantenimiento",
  // LIST TAGS
  equipment_serial: "Equipo de serie",
  product_name: "Nombre del producto",
  product_exclusive: "Producto exclusivo?",
  active_tag: "Tag activa?",
  list_tag_table_description: "Los productos marcados como exclusivos no consumen ni afectan el total de etiquetas disponibles.",
  // TAG REPLACE
  new_equipament_serial: "Nuevo equipo en serie",
  request_date: "Fecha de solicitud",
  tag_replace_table_description: "A continuación se enumeran todas las solicitudes de reemplazo de Tag.",
  new_tag_information: "Información de  tag NOVA",
  old_tag_information: "Información de  tag ANTIGA",
  recommended_transfer_equipment: "Equipo de transferencia recomendado",
  find_other_equipment: "Encuentra otro equipo",
  find_equipment: "Busca equipo",
  selected_equipment: "Equipo seleccionado",
  select_equipment: "Seleccionar equipo",
  transfer_inspections_and_maintenances: "Transferencia a Inspecciones y Mantenimiento",
  select_an_equipment_to_transfer_information: "Seleccione un dispositivo para transferir información",
  replacement_done: "Datos transferidos con éxito!",
  // EMPLOYEES
  add_user: "Agregar usuario",
  role: "Cargo",
  company_operator: "Operador de la empresa",
  company_admin: "Administrador de la empresa",
  company_group_admin: "Administrador del grupo de empresas",
  mobcheck_admin: "Administrador MOB Inspeção",
  // COMPANIES
  company_name: "Nombre de la empresa",
  main_contact: "Contacto principal",
  tags_qtt_max: "Tags (Cantidad máxima)",
  tags_qtt_used: "Tags (Usado)",
  add_company: "Añadir empresa",
  company_created: "Empresa #vr0# creada.",
  company_updated: "Empresa #vr0# actualizada",
  change_photo: "Cambiar foto",
  // COMPANY GROUP
  company_group_database_name: "Nombre del grupo de la compañía",
  select_company: "Selecciona una empresa",
  add_company_group: "Registrarse Grupo de empresas",
  company_group_created: "Grupo de empresa #vr0# creado.",
  migrate_error: "No se pudo migrarr.",
  missing_field: "Por favor, llene los campos requeridos.",
  company_group_already_exists: "El grupo de empresas ya existe: #vr0#",
  company_group_invalid_name: "Nombre no válido: mobcheck_#vr0#",
  schema_name: "Nombre do Schema",
  tags_limit: "Limite de tags",
  creating: "Creando",
  // ALL USERS
  registred_on: "Registrado en",
  activated_user_success: "Usuario # vr0 # activado correctamente.",
  migrated_user_success: "El usuario # vr0 # migró con éxito.",
  updated_password_success: "La contraseña de usuario # vr0 # se actualizó correctamente.",
  deleted_user_success: "El usuario # vr0 # se eliminó correctamente.",
  active_pending_user: "Habilitar usuario pendiente",
  // SETTINGS
  system_configuration: "Ajustes del sistema",
  general_settings: "Configuración general",
  languagem: "Idioma",
  // User
  update_user_success: "Usuario actualizado con éxito",
  // Tippy
  attributeType_Tippy: "Atributos personalizados para todos los productos en las subcategorías, que se deben completar al registrar un dispositivo (ej. placa del coche)",
  due_date_Tippy: "Fecha de validez del producto",
  maintenance_date_Tippy: "Fecha del último mantenimiento del producto",
  inspection_date_Tippy: "Fecha de la última inspección del producto",
  inspection_question_Tippy: "Tipo de respuesta a seleccionar",
  user_profile_Tippy: "Tipo de perfil de permisos de usuario",
  inspection_customQuestion_Tippy: "Tipo de reacción del sistema al seleccionar una respuesta personalizada",
  // ORDER OF SERVICE
  number_os: "Número de OS",
  os_created: "Orden de trabajo creada.",
  os_updated: "Orden de trabajo actualizada.",
  start_date: "Fecha de inicio",
  end_date: "Fecha de finalización",
  user_current: "Usuario solicitante",
  responsible_user: "Usuario responsable",
  responsible_user_select: "Seleccione un usuario",
  comments: "Comentarios",
  os_status: "Estatus",
  os_status_select: "Seleccione un estado",
  status_pending: "Pendiente",
  status_partial: "Parcial",
  status_finished: "Acabado",
  status_cancelled: "Cancelado",
  pending: "Ningún equipo inspeccionado/mantenimiento",
  partial: "Inspección/mantenimiento incompleto",
  finished: "Todas las inspecciones y el mantenimiento se han completado",
  canceled: "Exclusión",
  equipment: "Equipo",
  priority: "Prioridad",
  priority_select: "Seleccione una prioridad",
  priority_lowest: "Muy bajo",
  priority_low: "Bajo",
  priority_average: "Promedio",
  priority_high: "Alto",
  priority_very_high: "Más alta",
  select_type: "Seleccione un tipo",
  os_type: "Tipo",
  os_inspection: "Inspección",
  os_maintenance: "Mantenimiento",
  os_select_equipment: "Seleccione un equipo",
  os_completion_date: "Fecha de finalizacion"
}
