import React from 'react';

import { FormControl, Table, TableBody, TableHead, TableRow, TextField } from '@material-ui/core';

import {
    BodyTableCell,
    ContainerDiv,
    FlexSpaceBetweenDiv,
    HeaderTableCell,
    OverflowXDiv,
    SecondaryTextButton
} from '../components/components';
import { StringService } from '../utils/stringService';
import { TableFooterWithPaginator } from '../components/tableFooterWithPaginator';
import { SortHeaderCell } from '../components/sortHeaderCell';
import { withSnackbar } from 'notistack';
import { CompanyGroupService } from './companyGroupService';
import { CollectionViewUpdater } from '../utils/collectionViewUpdater';
import { LoggedUserService } from '../loggedUser/loggedUserService';
import { CompanyGroupRegisterDialogButton } from './companyGroupRegisterDialogButton';
import Spinner from 'react-spinner-material'

class CompanyGroupsPage extends React.Component {

    constructor (props) {
        super(props)

        this.collectionUpdater = CollectionViewUpdater.ofCompanyGroups(this)

        this.state = {
            isLoading: false,
            companyData: {id: null, name: ''},
            filterQuery: '',
            collection: this.collectionUpdater.collection
        }
    }

    componentDidMount () {
        this.setState({isLoading: true})

        CompanyGroupService.list().then(items => {
            this.collectionUpdater.initialData(items);
            this.setState({ isLoading: false });
        })
    }

    changeCompanyGroupCompany = (companyGroup, companyId, companyName) => {
        const { onUpdate, enqueueSnackbar } = this.props

        LoggedUserService.changeCompanyGroupCompany(companyGroup, companyId).then(() => {
            onUpdate(companyGroup)

            enqueueSnackbar(`Usuário alterado para ${companyGroup} - ${companyName}`)
        })
    }

    notify = message => () => this.props.enqueueSnackbar(message);
    notifyError = companyGroupData => error => {
        const { enqueueSnackbar, currentLanguage } = this.props;

        if (error.response.data === 'CREATE_MISSING_FIELD') {
            enqueueSnackbar(currentLanguage.missing_field, {variant: 'error'})
        }
        if (error.response.data === 'CREATE_EXISTING_COMPANY_GROUP') {
            enqueueSnackbar(StringService.formatTextVariable(currentLanguage.company_group_already_exists, companyGroupData.companyGroup), {variant: 'error'})
        }
        if (error.response.data === 'CREATE_BAD_COMPANY_GROUP_NAME') {
            enqueueSnackbar(StringService.formatTextVariable(currentLanguage.company_group_invalid_name, companyGroupData.companyGroup), {variant: 'error'})
        }
        if (error.response.data === 'MIGRATE_INVALID_CATALOG') {
            enqueueSnackbar(StringService.formatTextVariable(currentLanguage.company_group_invalid_name, companyGroupData.companyGroup), {variant: 'error'})
        }
        if (error.response.data === 'MIGRATE_COULD_NOT_MIGRATE') {
            enqueueSnackbar(currentLanguage.migrate_error, {variant: 'error'})
        }

        return Promise.reject(error)
    }

    handleCreateSubmit = companyGroupData => CompanyGroupService.create(companyGroupData)
        .then(this.collectionUpdater.createItem)
        .then(this.notify(StringService.formatTextVariable(this.props.currentLanguage.company_group_created, companyGroupData.companyGroup)))
        .catch(this.notifyError(companyGroupData))

    buildList = () => {
        const { isLoading, collection } = this.state
        const { currentLanguage } = this.props

        if (isLoading) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={2}><Spinner size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></BodyTableCell>
                </TableRow>
            )
        }

        const items = collection.paginator.pagedItems;

        if (items.length === 0) {
            return (
                <TableRow>
                    <BodyTableCell colSpan={2}>{currentLanguage.empty_list}</BodyTableCell>
                </TableRow>
            )
        }

        return items.map(companyGroup => (
            <TableRow key={companyGroup.name}>
                <BodyTableCell>{companyGroup.name}</BodyTableCell>
                <BodyTableCell>
                    {companyGroup.companies.map(company => {
                        return (
                            <SecondaryTextButton onClick={() => this.changeCompanyGroupCompany(companyGroup.name, company.id, company.name)}>{company.name}</SecondaryTextButton>
                        )
                    })}
                </BodyTableCell>
            </TableRow>
        ));
    };

    render () {
        const { collection, filterQuery } = this.state
        const { currentLanguage } = this.props
        const { updateCollection, handleFilterChange, handleSort } = this.collectionUpdater;

        return (
            <React.Fragment>
                <ContainerDiv>
                    <FlexSpaceBetweenDiv style={{flexWrap: 'wrap'}}>
                        <FormControl margin="normal">
                            <TextField
                                label={currentLanguage.search}
                                helperText={currentLanguage.company_group_database_name}
                                value={filterQuery}
                                style={{marginBottom: '1rem'}}
                                onChange={event => handleFilterChange({ filterQuery: StringService.normalize(event.target.value) })}
                            />
                        </FormControl>
                        <CompanyGroupRegisterDialogButton currentLanguage={currentLanguage} onSubmit={(companyGroupData) => this.handleCreateSubmit(companyGroupData)} />
                    </FlexSpaceBetweenDiv>
                </ContainerDiv>

                <OverflowXDiv>
                <Table>
                    <TableHead>
                        <TableRow>
                            <SortHeaderCell sorter={collection.sorter} onSort={handleSort} field="name" width="8rem">{currentLanguage.company_group_database_name}</SortHeaderCell>
                            <HeaderTableCell>{currentLanguage.select_company}</HeaderTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.buildList()}
                    </TableBody>
                    <TableFooterWithPaginator collection={collection} onCollectionUpdate={updateCollection} colSpan={8} />
                </Table>
                </OverflowXDiv>
            </React.Fragment>
        );
    }
}

export default withSnackbar(CompanyGroupsPage);
