import React from 'react';

import { Dialog, IconButton, Tab, Tabs } from '@material-ui/core';

import ReplaceIcon from './images/replace_tag_icon.png'; 
import { TagReplaceDialogTabGeneral } from './tagReplaceDialogTabGeneral'
import { EquipmentService } from '../equipment/equipmentService';

export class TagReplaceDialogButton extends React.Component {
    state = {
        tabValue: 0,
        isDialogOpen: false,
        snapshots: null,
        inspections: null,
        maintenances: null
    }

    handleClickOpen = () => {
        const { equipment } = this.props

        this.setState({
            tabValue: 0,
            isDialogOpen: true,
            suggestedEquipment: null,
            chosenEquipment: null,
            equipmentList: null
        })

        EquipmentService.getSuggestReplace(equipment.tagSerialNumber).then(equipment => this.setState({ suggestedEquipment: equipment.data }))
        EquipmentService.listEquipmentsReplace(equipment.tagSerialNumber).then(equipmentList => this.setState({ equipmentList: equipmentList.data }))
    }

    closeDialog = () => {
        this.setState({ isDialogOpen: false })
    }

    showTabContent = () => {
        const { equipment, currentLanguage } = this.props
        const { tabValue, chosenEquipment, suggestedEquipment, equipmentList } = this.state

        switch (tabValue) { 
            case 0: return (<TagReplaceDialogTabGeneral 
                                currentLanguage={currentLanguage}
                                closeDialog={this.closeDialog} 
                                equipment={equipment} 
                                suggestedEquipment={suggestedEquipment} 
                                chosenEquipment={chosenEquipment}
                                equipmentList={equipmentList} />)
            default: throw new Error(`unimplemented: ${tabValue}`)
        }
    }

    render () {
        const { currentLanguage } = this.props
        const { isDialogOpen, tabValue } = this.state

        return (
            <React.Fragment>
                <IconButton onClick={this.handleClickOpen}><img src={ReplaceIcon} alt="search" /></IconButton>
                <Dialog open={isDialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="md">
                    <Tabs value={tabValue} onChange={(event, tabValue) => this.setState({ tabValue })}>
                        <Tab style={{ color: 'white' }} label={currentLanguage.tag_replace} />
                    </Tabs>
                    {this.showTabContent()}
                </Dialog>
            </React.Fragment>
        );
    }
}
