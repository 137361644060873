import React from 'react'
import {
    FormControl,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core'

import {
    BodyTableCell,
    ContainerDiv,
    FlexBaselineDiv,
    FlexSpaceBetweenDiv,
    formatMainDescription,
    OverflowXDiv
} from '../components/components'
import { TagReplaceDialogButton } from './tagReplaceDialogButton'
import { DateTimeService } from '../utils/dateTimeService'
import { TagService } from './tagService'
import { SortHeaderCell } from '../components/sortHeaderCell'
import { FilterDatePicker } from '../components/filterDatePicker'
import { FilterService } from '../utils/filterService'
import { DefaultCollection } from '../collection/defaultCollection'
import { TableFooterWithPaginator } from '../components/tableFooterWithPaginator'
import { withSnackbar } from 'notistack';

class TagsPage extends React.Component {
    state = {
        filterQuery: '',
        filterUpdateFrom: null,
        filterUpdateUpTo: null,
        collection: DefaultCollection.ofItemsAndPredicates([], [])
    };

    buildPredicates = (items, filterValues) => {
        const { filterQuery, filterUpdateFrom, filterUpdateUpTo } = filterValues;

        return [
            FilterService.containsQuery(filterQuery, items, ['tagSerialNumber', 'extraLabel', 'productSku', 'productName']),
            FilterService.isFromValid('createdAtMillis', filterUpdateFrom),
            FilterService.isUpToValid('createdAtMillis', filterUpdateUpTo)
        ];
    };

    componentDidMount () {
        TagService.listReplaceTag().then(tags => this.setState(state => ({ collection: DefaultCollection.ofItemsAndPredicates(tags, this.buildPredicates(tags, state)) })))
    }

    updateCollection = updater => this.setState(state => ({ collection: updater(state.collection, state) }))

    notify = message => () => this.props.enqueueSnackbar(message)

    handleFilterChange = newFilterState => {
        this.setState(newFilterState)

        return this.updateCollection((collection, state) => DefaultCollection.withItems(collection, collection.items, this.buildPredicates(collection.items, state)));
    }

    handleSort = field => this.updateCollection(collection => DefaultCollection.withNextSorter(collection, field));

    render () {
        const { collection, filterQuery, filterUpdateFrom, filterUpdateUpTo } = this.state
        const { currentLanguage } = this.props
        const pageItems = collection.paginator.pagedItems

        return (
            <React.Fragment>
                <ContainerDiv>
                    <FlexSpaceBetweenDiv style={{flexWrap: 'wrap'}}>
                        <FormControl margin="normal" style={{flex: '1 0 10rem'}} >
                            <TextField label="Buscar" value={filterQuery}
                                       helperText={currentLanguage.equipment_serial+", "+currentLanguage.name+", "+currentLanguage.description+" "+currentLanguage.or+" "+currentLanguage.number_of_control}
                                       onChange={event => this.handleFilterChange({ filterQuery: event.target.value })} />
                        </FormControl>                        

                        <FormControl margin="normal">
                            <FlexBaselineDiv style={{flexWrap: 'wrap'}}>
                                <div style={{flex: '1 0 8rem', textAlign: 'right'}}>{currentLanguage.request_date}</div>
                                <FilterDatePicker disableFuture style={{flex: '1 0 9rem', marginLeft: '1rem'}} value={filterUpdateFrom} onChange={date => this.handleFilterChange({ filterUpdateFrom: date })} label={currentLanguage.from} />
                                <FilterDatePicker disableFuture style={{flex: '1 0 9rem', marginLeft: '1rem'}} value={filterUpdateUpTo} onChange={date => this.handleFilterChange({ filterUpdateUpTo: date })} label={currentLanguage.to} />
                            </FlexBaselineDiv>
                        </FormControl>
                    </FlexSpaceBetweenDiv>
                </ContainerDiv>
                <div style={{paddingLeft: '1rem'}}>* {currentLanguage.tag_replace_table_description}</div>

                <OverflowXDiv>
                <Table>
                    <TableHead>
                        <TableRow>
                            <SortHeaderCell sorter={collection.sorter} onSort={this.handleSort} field="equipmentNewSerialNumber">{currentLanguage.new_equipment_serial}</SortHeaderCell>
                            <SortHeaderCell sorter={collection.sorter} onSort={this.handleSort} field="equipmentOldSerialNumber">{currentLanguage.equipment_information}</SortHeaderCell>
                            <SortHeaderCell sorter={collection.sorter} onSort={this.handleSort} field="requestUsername">{currentLanguage.product}</SortHeaderCell>
                            <SortHeaderCell sorter={collection.sorter} onSort={this.handleSort} field="requestDate" align="center">{currentLanguage.request_date}</SortHeaderCell>
                            <SortHeaderCell sorter={collection.sorter} onSort={this.handleSort} field="options" align="center">{currentLanguage.options}</SortHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pageItems.map(equipment => (
                            <TableRow key={equipment.tagSerialNumber}>
                                <BodyTableCell>{equipment.tagSerialNumber}</BodyTableCell>
                                <BodyTableCell>{formatMainDescription(equipment.extraLabel, equipment.extraCode)}</BodyTableCell>
                                <BodyTableCell>{equipment.productName}</BodyTableCell>
                                <BodyTableCell align="center">{DateTimeService.formatDate(equipment.updateDate)}</BodyTableCell>
                                <BodyTableCell align="center"><TagReplaceDialogButton currentLanguage={currentLanguage} equipment={equipment} /></BodyTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooterWithPaginator collection={collection} onCollectionUpdate={this.updateCollection} colSpan={6} />
                </Table>
                </OverflowXDiv>
            </React.Fragment>
        );
    }
}

export default withSnackbar(TagsPage);
