import { FilterService } from '../utils/filterService';

export class DefaultFilter {

    constructor ({ items, filteredItems }) {
        this.items = items;
        this.filteredItems = filteredItems;

        Object.freeze(this);
    }

    static ofItemsAndPredicates = (items, predicates) => {
        const filteredItems = FilterService.filterWithPredicates(items, predicates);

        return new DefaultFilter({ items, filteredItems });
    };

    static ofItemsAndFilters = (items, filters) => {
        const filteredItems = FilterService.filterAllItensSelected(items, filters);
        return new DefaultFilter({ items, filteredItems });
    };


}
