import { ProductApi } from './productApi';

function list () {
    return ProductApi.list().then(response => response.data);
}

function listMaintenanceParts (productId) {
    if (!productId) {
        return Promise.resolve([]);
    }

    return ProductApi.listMaintenanceParts(productId).then(response => response.data);
}

function update (productId, productData, photoFile, maintenanceParts) {
    return ProductApi.update(productId, productData)
        .then(() => photoFile && ProductApi.updatePhoto(productId, photoFile))
        .then(() => ProductApi.updateMaintenanceParts(productId, maintenanceParts))
        .then(() => ({ id: productId, updateDate: new Date().toISOString(), ...productData }));
}

function create (productData, photoFile, maintenanceParts) {
    return ProductApi.create(productData)
        .then(response => response.data)
        .then(productId => ProductApi.updatePhoto(productId, photoFile).then(() => productId))
        .then(productId => ProductApi.updateMaintenanceParts(productId, maintenanceParts).then(() => productId))
        .then(productId => ({ id: productId, updateDate: new Date().toISOString(), ...productData }));
}

export const ProductService = { list, listMaintenanceParts, update, create };
