import React from 'react';
import { Button, DialogActions, DialogContent, TextField } from '@material-ui/core';
import { CenterTitleDiv, FlexBaselineDiv, PrimaryButton } from '../components/components';
import Spinner from 'react-spinner-material'

export class CategoryDialogTabInspectionAttributes extends React.Component {

    buildRow = (inspectionAttribute, index) => {
        const {
            inspectionAttributes,
            inspectionAttributesValidator,
            updateState
        } = this.props;

        if (inspectionAttribute.id === null) {
            return (
                <FlexBaselineDiv key={index}>
                    <TextField
                        required
                        margin="normal"
                        style={{ flex: '1 1 auto' }}
                        value={inspectionAttribute.name}
                        label="Nome do Atributo de Inspeção (ex: Número de Série)"
                        error={!inspectionAttributesValidator.isValid(inspectionAttribute.name)}
                        onChange={event => updateState({ inspectionAttributes: inspectionAttributes.map((attribute, i) => i === index ? { ...attribute, name: event.target.value } : attribute) })}
                    />
                    <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={() => updateState({ inspectionAttributes: inspectionAttributes.filter((attribute, i) => i !== index) })}>Remover</Button>
                </FlexBaselineDiv>
            );
        }

        if (inspectionAttribute.enabled === false) {
            return (
                <FlexBaselineDiv key={index}>
                    <TextField
                        disabled
                        margin="normal"
                        style={{ flex: '1 1 auto' }}
                        value={inspectionAttribute.name}
                        label="Nome do Atributo de Inspeção (ex: Número de Série)"
                        error={!inspectionAttributesValidator.isValid(inspectionAttribute.name)}
                        onChange={event => updateState({ inspectionAttributes: inspectionAttributes.map((attribute, i) => i === index ? { ...attribute, name: event.target.value } : attribute) })}
                    />
                    <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={() => updateState({ inspectionAttributes: inspectionAttributes.map((attribute, i) => i === index ? { ...attribute, enabled: true } : attribute) })}>Habilitar</Button>
                </FlexBaselineDiv>
            );
        }

        return (
            <FlexBaselineDiv key={index}>
                <TextField
                    required
                    margin="normal"
                    style={{ flex: '1 1 auto' }}
                    value={inspectionAttribute.name}
                    label="Nome do Atributo de Inspeção (ex: Número de Série)"
                    error={!inspectionAttributesValidator.isValid(inspectionAttribute.name)} 
                    onChange={event => updateState({ inspectionAttributes: inspectionAttributes.map((attribute, i) => i === index ? { ...attribute, name: event.target.value } : attribute) })}
                />
                <Button style={{ marginLeft: '1rem', width: '8rem' }} onClick={() => updateState({ inspectionAttributes: inspectionAttributes.map((attribute, i) => i === index ? { ...attribute, enabled: false } : attribute) })}>Desabilitar</Button>
            </FlexBaselineDiv>
        );
    };

    render () {
        const {
            submitLabel,
            inspectionAttributes,
            allValidAndNonEmpty,
            closeDialog,
            handleSubmit,
            updateState
        } = this.props;

        const emptyInspectionAttribute = { id: null, enabled: true, name: '' };

        const addInspectionAttribute = () => updateState({ inspectionAttributes: [ ...inspectionAttributes, emptyInspectionAttribute ] });

        if (inspectionAttributes === null) {
            return (
                <React.Fragment>
                    <DialogContent style={{height: 'calc(100vh - 20rem)'}}>
                        <CenterTitleDiv><div style={{position:'relative', top:'45%', left:'45%'}}> <Spinner  size={50} spinnerColor="#2CA8FF" spinnerWidth={2} visible={true} /></div></CenterTitleDiv>
                    </DialogContent>

                    <DialogActions style={{justifyContent: 'space-between'}}>
                        <div />

                        <div>
                            <Button onClick={closeDialog}>Fechar</Button>
                            <PrimaryButton onClick={handleSubmit} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                        </div>
                    </DialogActions>
                </React.Fragment>
            );
        }

        if (inspectionAttributes.length === 0) {
            return (
                <React.Fragment>
                    <DialogContent style={{height: 'calc(100vh - 20rem)'}}>
                        <CenterTitleDiv>Lista vazia</CenterTitleDiv>
                    </DialogContent>

                    <DialogActions style={{justifyContent: 'space-between'}}>
                        <div>
                            <PrimaryButton onClick={addInspectionAttribute}>Adicionar Atributo de Inspeção</PrimaryButton>
                        </div>

                        <div>
                            <Button onClick={closeDialog}>Fechar</Button>
                            <PrimaryButton onClick={handleSubmit} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                        </div>
                    </DialogActions>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <DialogContent style={{height: 'calc(100vh - 20rem)'}}>
                    {inspectionAttributes.map(this.buildRow)}
                </DialogContent>

                <DialogActions style={{justifyContent: 'space-between'}}>
                    <div>
                        <PrimaryButton onClick={addInspectionAttribute}>Adicionar Atributo de Inspeção</PrimaryButton>
                    </div>

                    <div>
                        <Button onClick={closeDialog}>Fechar</Button>
                        <PrimaryButton onClick={handleSubmit} disabled={!allValidAndNonEmpty}>{submitLabel}</PrimaryButton>
                    </div>
                </DialogActions>
            </React.Fragment>
        );
    }

}

